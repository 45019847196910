const combineUnionArray = <T>(
  arr1: T[],
  arr2: T[],
  comparisonFn = (a: T, b: T) => a === b
) => {
  const result: T[] = [...arr1];

  for (const item2 of arr2) {
    if (!result.some((item1) => comparisonFn(item1, item2))) {
      result.push(item2);
    }
  }

  return result;
};

export default combineUnionArray;
