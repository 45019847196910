import { Box, Modal, Paper } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IGeneralDetails,
  IThreatDraftCreationPayload,
} from "../../../Common/Constants/types";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import StepperComponent from "../../../Common/Stepper";
import { RootState } from "../../../redux/reducers";
import { LanguageRecordType } from "../../../redux/reducers/generic_reducer";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { useStyles } from "../style";
import { prepareThreatInfoFromEdit } from "../threats.helper";
import { GetThreatStageDetails } from "../ThreatsApiCalls";
import { ActionType, STEPS } from "../../../Common/Constants";
import { StagesToCreateThreat } from "../stepperStages";
import { GeneralDetailsThreats } from "./GeneralDetailsandFAQ";
import { ThreatStage } from "./ThreatStage";
import getRandomizeValue from "../../../Utils/randomizer";

export type GeneralDetailsKeys = {
  language: string;
  filteredCrops: any;
  threatSystemName: string | undefined;
  threatDisplayLabel: string | undefined;
  imagePath: string | undefined;
  imageFile: any;
  threatFAQtitle: string | undefined;
  threatFAQresponse: string | undefined;
  ThreatFAqArrary: Array<any>;
  // threatFAQ?:Array<any>
};
export const initialGeneralDetailStage: IGeneralDetails = {
  filteredCrops: undefined,
  languageId: 0,
  threatSystemName: "",
  threatDisplayLabel: "",
  imagePath: "",
  threatFAQ: [],
};
export type ThreatStageKeys = {
  threatSystemName: string;
  stageSystemName: string;
};
export const initialThreatStageDetail = {
  threatSystemName: "",
  stageSystemName: "",
};

export const AddNewThreat = (props: any) => {
  const classes = useStyles();
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic.ListOfLanguages.languageList,
      primaryLanguageCode: state.auth.languageCode,
    };
  });
  const [generalDetails, setGeneralDetails] = useState<IGeneralDetails[]>([]);
  const [selectedGeneralStage, setSelectedGeneralStage] =
    useState<IGeneralDetails>(initialGeneralDetailStage);
  const [threatStageDetails, setThreatStageDetails] = useState<any[]>([]);
  const [selectedthreatStage, setSelectedThreatStage] =
    useState<ThreatStageKeys>(initialThreatStageDetail);
  const [activeState, setActiveState] = useState<number>(1);
  const [uniqueKey, setUniqueKey] = useState<number>(getRandomizeValue());
  const [isDraftRecord, setRecordAsDraft] = useState<boolean>(true);
  const [recordDraftId, setDraftId] = useState<number | null | undefined>();
  const [, setDraftPayload] = useState<IThreatDraftCreationPayload>();
  const [isPrimaryThreat, setIsPrimaryThreat] = useState<boolean>(false);
  const [recordBaseLanguage, setRecordBaseLanguage] = useState<string>("");
  /**STEP-2 methods start*/
  /**
   * @description Method to update crop growth stage
   * @param data CropGrowthStageKeys
   */
  const updateGeneralStages = (data: any) => {
    if (selectedGeneralStage && selectedGeneralStage.threatSystemName) {
      /**updating existing record */
      const updatedList = updateRecord(
        [...generalDetails],
        selectedGeneralStage,
        data,
        "threatSystemName"
      );
      setGeneralDetails([...updatedList]);
      setSelectedGeneralStage(initialGeneralDetailStage);
    } else {
      /**creating new record */
      setGeneralDetails([...generalDetails, data]);
    }
  };
  /**
   * @description Method to remove selected crop growth stage
   * @param systemName string
   */
  const removeSelectedGeneralStage = (systemName: string) => {
    // if systemName matches with selected growth stage
    if (systemName === selectedGeneralStage.threatSystemName) {
      setSelectedGeneralStage(initialGeneralDetailStage);
    }
    // remove the entry from general details array
    const threatGeneralInfo = _.reject(generalDetails, (o: any) => {
      return (
        o.threatSystemName.toLocaleLowerCase() ===
        systemName.toLocaleLowerCase()
      );
    });

    // remove entry from threat stages
    const threatStagesInfo = _.reject(threatStageDetails, (o: any) => {
      return (
        o.threatSystemName.toLocaleLowerCase() ===
        systemName.toLocaleLowerCase()
      );
    });
    setGeneralDetails([...threatGeneralInfo]);
    setThreatStageDetails([...threatStagesInfo]);
  };

  const getUniqueKey = () => {
    setUniqueKey(getRandomizeValue());
  };

  /** Generic logic to update existing record */
  const updateRecord = (
    mainList: any,
    prevRecord: any,
    newRecord: any,
    key: string
  ) => {
    mainList.map((eachItem: any, index: number) => {
      if (eachItem[key] === prevRecord[key]) {
        mainList.splice(index, 1, newRecord);
      }
    });
    return mainList;
  };

  const updateThreatStages = (data: any) => {
    if (selectedthreatStage && selectedthreatStage.threatSystemName) {
      /**updating existing record */
      updateRecord(
        threatStageDetails,
        selectedthreatStage,
        data,
        "threatSystemName"
      );
      setSelectedThreatStage(initialThreatStageDetail);
    } else {
      /**creating new record */
      setThreatStageDetails([...threatStageDetails, data]);
    }
  };
  /**
   * @description Method to remove selected crop growth stage
   * @param value
   */
  const removeSelectedThreatStage = (value: string) => {
    const threatAndStageSystemNames: string[] = value.split(",");
    // if systemName matches with selected growth stage
    if (threatAndStageSystemNames[0] === selectedthreatStage.threatSystemName) {
      setSelectedThreatStage(initialThreatStageDetail);
    }
    // remove the entry from threat detials array
    const threstStagesAfterRejection = _.reject(threatStageDetails, {
      threatSystemName: threatAndStageSystemNames[0],
      stageSystemName: threatAndStageSystemNames[1],
    });
    setThreatStageDetails([...threstStagesAfterRejection]);
  };

  const setSelectedThreatInfo = (data: IGeneralDetails) => {
    const info: IGeneralDetails = _.cloneDeep(data);
    /**
     * if selected record is for adding a translation
     * check if the record is added under primary
     * if primary language is detected, empty the values of
     * languageName, languageCode, languageId
     */
    if (
      props.actionType === ActionType.ADD_TRANSLATION &&
      data.languageCode === storeData.primaryLanguageCode
    ) {
      info.languageCode = "";
      info.languageId = null;
      info.languageName = "";
    }
    setSelectedGeneralStage({ ...info });
  };

  const renderThreatStages = (nextStep: number) => {
    switch (nextStep) {
      case 1:
        return (
          <GeneralDetailsThreats
            ListOfStages={[...generalDetails]}
            selectedStage={selectedGeneralStage}
            onSave={(data: GeneralDetailsKeys) => {
              updateGeneralStages(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedGeneralStage(systemName);
            }}
            onSelect={(data: IGeneralDetails) => {
              setSelectedThreatInfo(data);
            }}
            onNext={(nextStep: number) => gotoNextStage(nextStep)}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            updateListOrder={(newList: Array<any>) =>
              fetchThreatInformationUpdate(newList)
            }
            isEdit={props.isEdit}
            disableSystemName={
              props.isEdit || props.actionType === ActionType.ADD_TRANSLATION
            }
            actionType={props.actionType}
            isPrimaryThreat={isPrimaryThreat}
            baseLanguageOfThreat={recordBaseLanguage}
          />
        );
      case 2:
        return (
          <ThreatStage
            ListOfStages={threatStageDetails}
            selectedStage={selectedthreatStage}
            onSave={(data: ThreatStageKeys) => {
              updateThreatStages(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedThreatStage(systemName);
            }}
            onSelect={(data: ThreatStageKeys) => {
              setSelectedThreatStage({ ...data });
            }}
            onNext={(nextStep: number) => gotoNextStage(nextStep)}
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            threatGeneralDetails={generalDetails}
            updateListOrder={(newList: Array<any>) =>
              setThreatStageDetails(newList)
            }
            isEdit={props.isEdit}
            actionType={props.actionType}
            disableSystemName={
              props.isEdit || props.actionType === ActionType.ADD_TRANSLATION
            }
            onClose={props.onClose}
            isRecordInDraftStatus={isDraftRecord}
            draftID={recordDraftId}
            isPrimaryThreat={isPrimaryThreat}
          />
        );
    }
  };

  const gotoNextStage = (nextStep: number, values?: any) => {
    if (nextStep !== STEPS.STEP3) {
      setActiveState(nextStep);
      renderThreatStages(nextStep);
    }
    switch (nextStep - 1) {
      case 1:
        if (values) {
          setGeneralDetails(values);
        }
        break;
      case 2:
        break;
      default:
        return;
    }
  };

  const closeAddNewPopup = () => {
    setGeneralDetails([]);
    setThreatStageDetails([]);
    setSelectedGeneralStage(initialGeneralDetailStage);
    setSelectedThreatStage(initialThreatStageDetail);
    setRecordAsDraft(false);
    props.onClose(true, props.isEdit);
  };

  const getThreatsStageDetails = async (payload: any, listOrder?: any[]) => {
    try {
      const response: any = await GetThreatStageDetails(payload);
      if (response.statusCode !== 200) {
        TriggerToastMessage("Unable to fetch data.", "error");
        return;
      }

      setDraftPayload(response.data);
      const {
        threatGeneralDetails,
        threatStages,
        isDraftRecord,
        adminDraftId,
      } = prepareThreatInfoFromEdit(response.data, props.actionType);

      setRecordAsDraft(isDraftRecord);
      setDraftId(adminDraftId);

      let selectedLanguage: any = [];
      if (props.actionType !== ActionType.ADD_TRANSLATION) {
        selectedLanguage = storeData.languages.filter(
          (language: LanguageRecordType) =>
            language.languageCode === response.data.languageCode
        );
        setSelectedGeneralStage((prevState) => ({
          ...prevState,
          languageName: selectedLanguage?.[0]?.languageName ?? listOrder?.[0].languageName,
          languageCode: selectedLanguage?.[0]?.languageCode ?? listOrder?.[0].languageCode,
          languageId: selectedLanguage?.[0]?.languageId ?? listOrder?.[0].languageId,
        }));
      }

      if (threatGeneralDetails) {
        let newGeneralDetails = generalDetails?.length > 0 ? generalDetails : threatGeneralDetails;
        if (props.actionType === ActionType.ADD_TRANSLATION) {
          newGeneralDetails = newGeneralDetails.map((each: any) => {
            const existingRecord = threatGeneralDetails.find((record: any) => record.threatSystemName === each.threatSystemName);
            if (existingRecord) {
              return {
                ...each,
                ...existingRecord,
              }
            }
            return each;
          })
        }

        const threatGeneralDetailsSort = _.sortBy(newGeneralDetails, ['displayOrder', 'asc'])

        setGeneralDetails(threatGeneralDetailsSort);
        setRecordBaseLanguage(threatGeneralDetailsSort[0].languageName || "");
      }

      if (threatStages) {
        const threatStagesSort = _.sortBy(threatStages, ['displayOrder', 'asc'])
        setThreatStageDetails(threatStagesSort);
      }

      setIsPrimaryThreat(
        selectedLanguage[0] &&
        selectedLanguage[0].languageCode === storeData.primaryLanguageCode
      );
    } catch (err) {
      console.log(err);
    }
  }


  const fetchThreatInformationUpdate = (listOrder: any[]) => {
    setGeneralDetails(listOrder);

    const payload = _.cloneDeep(props);
    payload.details.languageCode = listOrder[0]?.languageCode;
    getThreatsStageDetails(payload, listOrder)
  };

  const fetchThreatInformation = () => {
    const payload = _.cloneDeep(props);
    const languageObj: any = _.find(storeData.languages, {
      languageId: payload.details.languageId,
    });
    payload.details.languageCode = languageObj?.languageCode;
    getThreatsStageDetails(payload)
  };

  useEffect(() => {
    if (props.isEdit) {
      fetchThreatInformation();
    }
  }, []);
  return (
    <>
      <div>
        <Modal open={true} disableEscapeKeyDown={true} onClose={() => { }}>
          <Paper className={classes.formPaper}>
            <Paper elevation={3} square>
              <ModalHeader
                header={
                  props.actionType === ActionType.ADD_TRANSLATION
                    ? `New Threat Translation`
                    : props.actionType === ActionType.UPDATE
                      ? `Update Threat`
                      : "New Threat"
                }
                showDelete={false}
                showEdit={false}
                onClose={() => {
                  if (confirm("You may lose your data.")) {
                    closeAddNewPopup();
                  }
                }}
              ></ModalHeader>
              {/** stepper component */}
              <StepperComponent
                steps={StagesToCreateThreat}
                activeStep={activeState}
                stepChangehandler={(step: number) => {
                  if (props.isEdit) setActiveState(step);
                }}
              />
            </Paper>
            <Box>{renderThreatStages(activeState)}</Box>
          </Paper>
        </Modal>
      </div>
    </>
  );
};
