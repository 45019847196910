import AddIcon from "@mui/icons-material/Add";
import { Grid, Stack } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import * as React from "react";
import CustomPagination from "../Pagination";
import RenderTableAction, { ACTION_TYPE } from "../RenderTableActions";
import { useStyles } from "./styles";

type DataTableActionsProps = {
  actionsType?: ACTION_TYPE;
  onActionClick?: (action: string, details?: any) => void;
  showActionProgress?: boolean;
  pageNumber: number;
  onPageChange: Function;
  canShowAvatar?: boolean;
  totalpages?: any;
  actiontype2?: any;
  buttonVisible?: Boolean;
  buttonExportVisible?: Boolean;
  activeTab?: string | number;
};

const DataTableActions: React.FC<DataTableActionsProps> = ({
  actionsType,
  onActionClick,
  showActionProgress,
  pageNumber,
  onPageChange,
  canShowAvatar,
  totalpages,
  actiontype2,
  buttonVisible,
  buttonExportVisible,
  activeTab,
}) => {
  // classes
  const classes = useStyles();
  const handleDelete = () => {
    onActionClick && onActionClick("Delete");
  };

  const handleExportToExcel = () => {
    onActionClick && onActionClick("Export to excel");
  };

  const handlePublish = () => {
    onActionClick && onActionClick("Publish");
  };

  return (
    <div className={classes.actionHelper}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          {buttonVisible === true && (
            <Stack spacing={2} direction="row">
              <RenderTableAction
                showActionProgress={showActionProgress}
                buttonVisible={buttonVisible}
                actionType={actiontype2}
                onDelete={() => handleDelete()}
                activeTab={activeTab}
                onExportToExcelClick={() => handleExportToExcel()}
              />
              <RenderTableAction
                showActionProgress={showActionProgress}
                actionType={actionsType}
                onDelete={() => handleDelete()}
                onExportToExcelClick={() => handleExportToExcel()}
                buttonVisible={buttonVisible}
                buttonExportVisible={buttonExportVisible}
                activeTab={activeTab}
                onPublish={() => handlePublish()}
              />
            </Stack>
          )}
        </Grid>
        <Grid item xs={4} key={"pagination" + totalpages}>
          {totalpages > 1 ? (
            <div
              style={{ margin: "auto" }}
              className={classes.paginationHelper}
            >
              <CustomPagination
                pageNumber={pageNumber}
                onChangePageNumber={onPageChange}
                totalPages={totalpages}
              />
            </div>
          ) : null}
        </Grid>
        <Grid item xs={4}>
          {canShowAvatar && (
            <Avatar
              onClick={() => {
                onActionClick && onActionClick("Create_Push");
              }}
              data-testid="create-push"
              className={classes.circleAddButtonHelper}
            >
              <AddIcon />
            </Avatar>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(DataTableActions);
