import axios, { AxiosInstance, AxiosRequestConfig, ResponseType } from "axios";
import appActions from "../redux/action";
import { RootState } from "../redux/reducers";
import { TriggerToastMessage } from "../Utils/toastTrigger";
import { store } from "../redux/store";
import api_endpoints, { base_url } from "./end_points";

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  showLoader?: boolean;
}

class APIService {
  api_urls = api_endpoints;
  axiosNoInterceptor: AxiosInstance;
  axiosOptions: AxiosRequestConfig = {
    timeout: 10000 * 10,
    withCredentials: true,
  };

  BaseDomain = {
    APP_ID: process.env.APP_ID,
    APP_VERSION: process.env.APP_VERSION,
  };

  ContentHeaders = {
    Json: "application/json",
    FormData: "multipart/form-data",
    Plain: "text/plain",
    Binary: "application/octet-stream",
  };

  isNotificationCall = false;
  apiCount = 0;

  constructor() {
    this.axiosNoInterceptor = axios.create();
    axios.interceptors.request.use((request) => this.requestHandler(request));
    axios.interceptors.response.use(
      (response: any) => this.responseHandler(response),
      (error) => this.errorHandler(error)
    );

    this.axiosNoInterceptor?.interceptors.response.use(
      (response) => this.reduceAPICount(),
      (error) => this.noInterceptorErrorHandler(error)
    );
  }

  private requestHandler(requestObject: CustomAxiosRequestConfig) {
    if (requestObject.showLoader) {
      store.dispatch(appActions.generic_actions.showLoader(true));
    }
    this.apiCount++;
    return requestObject;
  }

  private responseHandler(response: any) {
    this.reduceAPICount();
    if (!this.isNotificationCall) {
      return response;
    }
    this.isNotificationCall = false;
    return response;
  }

  private handleError(error: any) {
    this.reduceAPICount();
    if (!navigator.onLine) {
      TriggerToastMessage(
        "No internet connection. Please check your network settings.",
        "error"
      );
      return Promise.reject({
        message: "No internet connection",
        ...error,
      });
    }

    if (error.response) {
      if (error.response.status === 401) {
        TriggerToastMessage(
          "Your session has expired. Please re-login to continue.",
          "warn"
        );
      }
      if (error.response.status >= 400) {
        return error.response;
      }
    }
    return this.responsePromise(error);
  }

  private errorHandler(error: any) {
    return this.handleError(error);
  }

  private noInterceptorErrorHandler(error: any) {
    return this.handleError(error);
  }

  private reduceAPICount() {
    this.apiCount--;
    if (this.apiCount === 0) {
      store.dispatch(appActions.generic_actions.showLoader(false));
    }
  }

  private responsePromise(res: any) {
    this.reduceAPICount();
    return new Promise((resolve, reject) => {
      if (res.response) {
        resolve(res);
      } else {
        reject(res);
      }
    });
  }

  private getHeadersByType(
    requestMethod: string,
    headerType: string | undefined,
    customHeaders?: any
  ): any {
    const headers: any = {};
    headers["Content-Type"] = headerType || this.ContentHeaders.Json;

    const appState: RootState = store.getState();
    headers["Authorization"] = `Bearer ${appState.auth?.authToken || ""}`;
    headers["country-code"] = appState.auth?.countryCode || "IN";
    headers["userType"] = "SR";
    headers["language-code"] = appState.auth?.languageCode || "en";

    return { ...headers, ...customHeaders };
  }

  private async makeRequest(
    method: "get" | "post" | "put" | "delete" | "patch",
    config: CustomAxiosRequestConfig
  ) {
    this.apiCount++;
    try {
      const response = await axios({ method, ...config });
      this.reduceAPICount();
      return response;
    } catch (error) {
      this.reduceAPICount();
      throw error;
    }
  }

  delete(data: any) {
    const {
      customHeaders,
      domain = base_url,
      endPoint,
      headerType,
      payLoad,
      showLoader = true,
    } = data;
    return this.makeRequest("delete", {
      url: endPoint,
      baseURL: domain,
      data: payLoad,
      headers: this.getHeadersByType("delete", headerType, customHeaders),
      showLoader,
    });
  }

  get(data: any) {
    const {
      customHeaders,
      domain = base_url,
      endPoint,
      headerType,
      noHeadersRequired,
      payLoad,
      showLoader = true,
    } = data;
    return this.makeRequest("get", {
      url: endPoint,
      baseURL: domain,
      params: payLoad,
      headers: noHeadersRequired
        ? undefined
        : this.getHeadersByType("get", headerType, customHeaders),
      showLoader,
    });
  }

  post(data: any) {
    const {
      customHeaders,
      domain = base_url,
      endPoint,
      headerType,
      payLoad,
      params,
      showLoader = true,
      useNonInterceptor,
      responseType,
    } = data;
    const axiosInstance = useNonInterceptor ? this.axiosNoInterceptor : axios;
    return this.makeRequest("post", {
      url: endPoint,
      baseURL: domain,
      data: payLoad,
      params,
      headers: this.getHeadersByType("post", headerType, customHeaders),
      responseType,
      showLoader,
    });
  }

  put(data: any) {
    const {
      customHeaders,
      domain = base_url,
      endPoint,
      headerType,
      payLoad,
      showLoader = true,
    } = data;
    return this.makeRequest("put", {
      url: endPoint,
      baseURL: domain,
      data: payLoad,
      headers: this.getHeadersByType("put", headerType, customHeaders),
      showLoader,
    });
  }

  patch(data: any) {
    const {
      customHeaders,
      domain = base_url,
      endPoint,
      headerType,
      payLoad,
      showLoader = true,
    } = data;
    return this.makeRequest("patch", {
      url: endPoint,
      baseURL: domain,
      data: payLoad,
      headers: this.getHeadersByType("patch", headerType, customHeaders),
      showLoader,
    });
  }

  notificationGet(data: any) {
    this.isNotificationCall = true;
    return this.get(data);
  }

  getByID(data: any) {
    return this.get(data);
  }

  postByID(data: any) {
    return this.post(data);
  }
}

export const api_service = new APIService();
