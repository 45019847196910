import * as React from "react";
import RetailersAndNurseries from "../../Components/RetailersAndNurseries";


export const RetailersComponent = () => {
  return (
    <>
      <RetailersAndNurseries title={"Retailers & Nurseries"} />
    </>
  );
};

export default RetailersComponent
