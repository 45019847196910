import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  HeaderWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  BoxRightMargin: { marginRight: "24px" },
  IconStyle: { marginLeft: "10px" },
  UnderlineTextStyle: {
    textDecoration: "underline",
  },
  carouselDiv: {
    height: "500px",
  },
  carousel: {
    height: "6% !important",
  },
  flexStyleCarousel: {
    width: "100%",
  },
  playerDiv: {
    paddingTop: "8%",
    paddingLeft: "25%",
  },
  ImageStyleCarosel: {
    marginTop: "4px",
    alignSelf: "center",
  },
  playerWrapper: {
    flex: 1,
    paddingLeft: "25%",
  },
  flexStyle: {
    display: "flex",
  },
  ImageStyle: {
    height: "85px",
  },
  PaperStyle: {
    margin: "30px auto",
    width: "80%",
    height: "85%",
    overflowY: "scroll",
  },
});
