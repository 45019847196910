import React, { useState } from 'react';

function useSharedState() {
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [open, setOpen] = useState(false);
    const [actionType, setActionType] = useState("");
    const [showCreateOrEditModal, setShowCreateOrEditModal] = useState(false);
    const [showBulkUpload, setShowBulkUpload] = useState(false);
    const [dataToDisplay, setDataToDisplay] = useState<Array<any>>([]);
    const [dataToDisplayIsChecked, setDataToDisplayIsChecked] = useState<Array<any>>([]);

    return {
        currentPage,
        setCurrentPage,
        totalPages,
        setTotalPages,
        open,
        setOpen,
        actionType,
        setActionType,
        showCreateOrEditModal,
        setShowCreateOrEditModal,
        showBulkUpload,
        setShowBulkUpload,
        dataToDisplay,
        setDataToDisplay,
        dataToDisplayIsChecked,
        setDataToDisplayIsChecked,
    };
}

export default useSharedState;
