import { ArrowForwardIosSharp } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

type AccordionListProps = {
  title: string;
};

const AccordionList: React.FC<AccordionListProps> = ({ title, children }) => {
  return (
    <Accordion disableGutters elevation={0} sx={{ p: 0, m: 0 }}>
      <AccordionSummary
        expandIcon={<ArrowForwardIosSharp fontSize="inherit" />}
        sx={{
          flexDirection: "row-reverse",
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            transform: "rotate(90deg)",
          },
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ paddingLeft: 1, margin: "0 !important" }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          marginLeft: 1,
          flexDirection: "column",
          display: "flex",
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionList;
