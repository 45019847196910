import { LOGOUT, USER_LOGIN_DETAILS } from './actions-constants';

const userDetails = (userData: any) => {
    return {
        type: USER_LOGIN_DETAILS,
        payload: userData
    };
};

const logout = () => {
    return {
        type: LOGOUT
    };
};

export default { userDetails, logout };
