import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { v4 as uuidv4 } from "uuid";
import { getIconImage } from "../../Components/Crop";
import { OddTableRow } from "./style";

interface ISortableTableComponentProps {
  name?: string;
  items?: any;
  updateCropStageOrder?: any;
  isEdit?: boolean;
  listProps: any; // for now passing all the props
  displayKey: string;
  initialState: any;
  selectedRecord: any;
  isDisabled?: boolean;
  isDraggable?: boolean;
  secMatchKey?: string;
  thirdMatchKey?: string;
  multipleMatchKey?: any;
  showRemoveButton?: boolean;
  showEditButton?: boolean;
  showViewButton?: boolean;
}

interface ISortableTableComponentState {
  items?: any[];
}
const initialCropGrowthStage = {
  systemName: "",
  description: "",
  imagePath: "",
  imageFile: undefined,
};

const handleRemoveClick = (props: any, value: any) => {
  if (confirm(`are you sure to delete ${value.split(",")[0]} ?`)) {
    props.onRemove(value);
  }
};

const renderRemoveAndEditButtons = (
  props: any,
  value: string,
  record: any,
  showRemoveButton = true,
  showEditButton = true
) => {
  return (
    <ButtonGroup variant="text">
      {showRemoveButton && (
        <Button onClick={() => handleRemoveClick(props, value)}>Remove</Button>
      )}
      {showEditButton && (
        <Button onClick={() => props.onSelect(record)}>Edit</Button>
      )}
    </ButtonGroup>
  );
};
const renderCancelButton = (listProps: any, initialState: any) => {
  return (
    <ButtonGroup variant="text">
      <Button onClick={() => listProps.onSelect(initialState)}>Cancel</Button>
    </ButtonGroup>
  );
};
const renderViewButton = (listProps: any, record: any) => {
  return (
    <ButtonGroup variant="text">
      <Button onClick={() => listProps.onSelect(record)}>View</Button>
    </ButtonGroup>
  );
};
/**
 * listProps will contain all the parent prop properties
 * value will be displayKey coming from props
 * record is representing each item
 * initialState will contain default values
 */
const SortableItem = SortableElement(
  ({
    value,
    sortIndex,
    listProps,
    record,
    displayKey,
    initialState,
    selectedRecord,
    isDisabled,
    secMatchKey,
    thirdMatchKey,
    multipleMatchKey,
    showRemoveButton = true,
    showEditButton = true,
    showViewButton = false,
  }: any) => {
    const isSelected = (keys: string[]) => {
      return keys.every((key, index) => value.split(",")[index] === selectedRecord[key]);
    };

    const renderButtons = () => {
      const keys = multipleMatchKey || [displayKey, secMatchKey, thirdMatchKey].filter(Boolean);
      const isSelectedValue = isSelected(keys);

      return (
        <>
          {!isSelectedValue && !isDisabled && renderRemoveAndEditButtons(listProps, value, record, showRemoveButton, showEditButton)}
          {isSelectedValue && renderCancelButton(listProps, initialState)}
          {!isSelectedValue && isDisabled && renderViewButton(listProps, record)}
        </>
      );
    };

    return (
      <OddTableRow
        className={
          isSelected([displayKey]) ||
          (!multipleMatchKey && !thirdMatchKey && isSelected([displayKey, secMatchKey])) ||
          (!multipleMatchKey && thirdMatchKey && isSelected([displayKey, secMatchKey, thirdMatchKey])) ||
          (multipleMatchKey && isSelected(multipleMatchKey))
            ? "higlightSelected"
            : ""
        }
      >
        <Stack
          direction="row"
          spacing={3}
          alignItems="center"
          className="OddTableRow"
        >
          <Box>
            <Typography>{sortIndex + 1}</Typography>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "400" }}
            width="120px"
            title={value}
          >
            {!secMatchKey ? value : value.split(",")[0]}
          </Typography>
          <Box className="sortableActionBtnsMxAuto">
            {renderButtons()}
          </Box>
          <Box className="nineDotsIcon">{getIconImage("nineDots")}</Box>
        </Stack>
      </OddTableRow>
    );
  }
);
const generateSortableItemValue = (
  item: any,
  displayKey: string,
  secMatchKey: string,
  thirdMatchKey: string,
  multipleMatchKey: any
): string => {
  if (multipleMatchKey) {
    return multipleMatchKey
      .map((_item: string) => {
        return item[_item];
      })
      .join(",");
  }
  if (thirdMatchKey && item[thirdMatchKey]) {
    return `${item[displayKey]},${item[secMatchKey]},${item[thirdMatchKey]}`;
  }
  if (secMatchKey && item[secMatchKey]) {
    return `${item[displayKey]},${item[secMatchKey]}`;
  }
  if (displayKey && item[displayKey]) {
    return item[displayKey];
  }
  return item?.systemName;
};

const SortableList = SortableContainer(
  ({
    items,
    listProps,
    displayKey,
    initialState,
    selectedRecord,
    isDisabled,
    isDraggable = true,
    secMatchKey,
    thirdMatchKey,
    multipleMatchKey,
    showRemoveButton = true,
    showEditButton = true,
    showViewButton = false,
  }: any) => {
    return (
      <Box className="ReactSortableTableComponent-container">
        {items.map((item: any, index: number) => {
          return (
            <SortableItem
              key={uuidv4()}
              index={index}
              sortIndex={index}
              // value={thirdMatchKey ? `${item[displayKey]},${item[secMatchKey]},${item[thirdMatchKey]}` :
              //     secMatchKey ? `${item[displayKey]},${item[secMatchKey]}` :
              //         displayKey ? item[displayKey] : item?.systemName}
              value={generateSortableItemValue(
                item,
                displayKey,
                secMatchKey,
                thirdMatchKey,
                multipleMatchKey
              )}
              listProps={listProps}
              record={item}
              displayKey={displayKey}
              initialState={initialState}
              selectedRecord={selectedRecord}
              isDisabled={isDisabled || false}
              disabled={!isDraggable || isDisabled}
              secMatchKey={secMatchKey}
              thirdMatchKey={thirdMatchKey}
              multipleMatchKey={multipleMatchKey}
              showRemoveButton={showRemoveButton}
              showEditButton={showEditButton}
              showViewButton={showViewButton}
            />
          );
        })}
      </Box>
    );
  }
);

class SortableTableComponent extends React.Component<
  ISortableTableComponentProps,
  ISortableTableComponentState
> {
  constructor(props: ISortableTableComponentProps) {
    super(props);
    this.state = { items: [] };
  }
  state = {
    items: [],
  };
  componentDidMount() {
    this.setState((prev) => ({
      ...prev,
      items: this.props.items ? this.props.items : prev.items,
    }));
  }

  handleSortEnd = ({ oldIndex, newIndex }: any) => {
    this.setState(
      (prevState) => ({
        items: arrayMove(prevState.items ?? [], oldIndex, newIndex),
      }),
      () => {
        this.props.updateCropStageOrder(this.state.items);
      }
    );
  };

  handleSortMove = (e: any) => {
    //console.log(e);
  };

  render() {
    return (
      <SortableList
        items={this.props.items}
        onSortEnd={this.handleSortEnd}
        onSortMove={this.handleSortMove}
        listProps={this.props.listProps}
        displayKey={this.props.displayKey}
        initialState={this.props.initialState}
        selectedRecord={this.props.selectedRecord}
        isDisabled={this.props.isDisabled}
        isDraggable={this.props.isDraggable}
        secMatchKey={this.props.secMatchKey}
        thirdMatchKey={this.props.thirdMatchKey}
        multipleMatchKey={this.props.multipleMatchKey}
        showRemoveButton={this.props.showRemoveButton}
        showEditButton={this.props.showEditButton}
        showViewButton={this.props.showViewButton}
      />
    );
  }
}

export default SortableTableComponent;
