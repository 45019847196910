import { FormControlLabel, Radio, Typography } from '@mui/material';
import React from 'react';

export type RadioWithLabelProps = {
  checked: boolean;
  label: string;
  name: string;
  onChange: Function;
  value: string;
};

const RadioWithLabel: React.FC<RadioWithLabelProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.value);
  };

  return (
    <FormControlLabel
      control={
        <Radio
          name={props.name}
          sx={{ p: 0.5 }}
          size='small'
          checked={props.checked}
          value={props.value}
          onChange={handleChange}
        />
      }
      label={
        <Typography
          style={{ lineHeight: 'unset', paddingLeft: 8 }}
          variant='body2'
          fontSize={14}
        >
          {props.label}
        </Typography>
      }
    />
  );
};

export default RadioWithLabel;
