import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    datePicerCustomStyles: {
        border: 'none',
        color: '#696F88',
        display: 'block',
        '&:focus-visible': {
            outline: 'none'
        }
    },
    DatePickerContainer: {
        border: '1px solid rgba(0,0,0,0.23)',
        paddingLeft: '10px',
        borderRadius: '4px',
        height: '40px',
        '& .MuiOutlinedInput-notchedOutline': {
            border: '0'
        },
        '& .MuiOutlinedInput-input': {
            paddingLeft: '0px',
            '&::placeholder': {
                fontSize: '14px'
            }
        }
    },
    iconStyles: {
        color: '#696F88',
        '&:hover': {
            cursor: 'pointer'
        }
    },
    inputTagStyles: {
        display: 'block',
        border: 'none',
        color: '#696F88',
        maxWidth: '160px',
        fontFamily: 'Noto Sans',
        fontSize: '14px',
        '&:focus-visible': {
            outline: 'none'
        }
    },
    'reactdatepickerpopper': {
        zIndex: '10 !important'
    }
});
