import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import _ from "lodash";
import React, { ChangeEvent, useEffect } from "react";
import { useStyles } from "./styles";

interface ISearchBarProps {
  onEnterClick: Function;
  onSearchValueChange: Function;
  placeholderText: string;
  searchValue: string;
  disabled?: Boolean;
  isResetSearchValue?: boolean;
  durationDebounce?: number;
}

const SearchBar: React.FC<ISearchBarProps> = (props) => {
  const classes = useStyles();

  useEffect(() => {
    if (props.isResetSearchValue) {
      let elem: any = document.getElementById("genericSearchField");
      elem.value = "";
    }
  }, [props.isResetSearchValue]);

  const handleSearchValueChange = _.debounce((event: ChangeEvent<HTMLInputElement>) => {
    props.onSearchValueChange(event.target?.value);
  }, props.durationDebounce ?? 500);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLImageElement>) => {
    const { keyCode } = event;
    if (keyCode === 13) {
      props.onEnterClick();
    }
  };

  return (
    <div className={classes.searchBoxHelper}>
      <TextField
        variant="outlined"
        placeholder={props.placeholderText}
        className={classes.textFieldHelper}
        InputProps={{
          sx: {
            height: "40px",
            minWidth: "350px",
            fontSize: "14px",
          },
          startAdornment: (
            <SearchIcon fontSize="small" className={classes.iconHelper} />
          ),
        }}
        name={'search'}
        onChange={handleSearchValueChange}
        onKeyDown={handleKeyDown}
        id="genericSearchField"
      />
    </div>
  );
};

export default SearchBar;
