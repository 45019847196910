import React from "react";
import { Box, TableContainer, Stack } from "@mui/material";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import { DownLoadSampleTemplate } from "../../Common/DownloadSampleTemplate";
import FiltersAndSort from "../../Common/FilterAndSort";
import PageTitle from "../../Common/PageTitle";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import ModalCreatePopUp from "./ModalCreatePopUp";
import { useStyles } from "./style";
import { ACTION_TYPE } from "../../Common/RenderTableActions";

interface CommonProps {
    typePlantix: 'map' | 'cp';
    searchValue: string;
    activeTab: number;
    limit: number;
    sortId: "" | "0" | "1";
    filterObj: any;
    currentPage: number;
    totalPages: number;
    open: boolean;
    showCreateOrEditModal: boolean;
    actionType: string;
    showBulkUpload: boolean;
    dataToDisplay: any[];
    showViewModal: boolean;
    buttonExportVisible?: boolean;
    tabsList: string[];
    dataColumns: ColumnTypes[];
    downloadLiteral: string;
    actionsType: ACTION_TYPE;
    viewModalComponent?: React.ReactNode;

    onSearchValueChange: (newValue: string) => void;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
    onClickAction: (clickAction: string, detail?: any) => void;
    onKnowMorePopUp: (flag: boolean, rowData: any) => void;
    onCloseModal: () => void;
    onShowCreateOrEditModal: (toggle: boolean) => void;
    onShowBulkUpload: (toggle: boolean) => void;
    setLimit: React.Dispatch<React.SetStateAction<number>>;
    setSortId: React.Dispatch<React.SetStateAction<"" | "0" | "1">>;
    setFilterObj: React.Dispatch<React.SetStateAction<any>>;
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
    setShowViewModal?: React.Dispatch<React.SetStateAction<boolean>>;
    onSelectAll: (status: boolean) => void;
    onRowSelect: (status: boolean, rowInfo: any) => void;
}

type ColumnTypes = {
    fieldLabel: string;
    fieldName: string;
};

const PlantixView: React.FC<CommonProps> = ({
    searchValue,
    activeTab,
    limit,
    sortId,
    filterObj,
    currentPage,
    totalPages,
    open,
    showCreateOrEditModal,
    actionType,
    showBulkUpload,
    dataToDisplay,
    showViewModal,
    onSearchValueChange,
    onChange,
    onClickAction,
    onKnowMorePopUp,
    onCloseModal,
    onShowCreateOrEditModal,
    onShowBulkUpload,
    setLimit,
    setSortId,
    setFilterObj,
    setCurrentPage,
    setShowViewModal,
    onSelectAll,
    onRowSelect,
    buttonExportVisible,
    tabsList,
    dataColumns,
    downloadLiteral,
    actionsType,
    viewModalComponent,
    typePlantix
}) => {
    const classes = useStyles();

    return (
        <TableContainer>
            <div>
                <Stack
                    direction="row"
                    alignItems="center"
                    className={classes.pageTitleNSearchStylesHelper}
                >
                    <PageTitle title={typePlantix === 'map' ? "Plantix - Map Products" : "Plantix CP Products"} />
                    <SearchBar
                        placeholderText={`Search by ${typePlantix === 'map' ? "Crop, Disease, CP System Name" : "Title, Systemname"}`}
                        searchValue={searchValue}
                        onSearchValueChange={onSearchValueChange}
                        onEnterClick={() => { }}
                        isResetSearchValue={searchValue === ""}
                    />
                </Stack>
                <Box>
                    <Box className={classes.customTabBtmBorder}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <CustomTabs
                                value={activeTab}
                                tabsList={tabsList}
                                onChange={onChange}
                            />
                            {activeTab === 1 && (
                                <DownLoadSampleTemplate
                                    literal={downloadLiteral}
                                    displayAsButton={false}
                                />
                            )}
                        </Stack>
                    </Box>
                    <Stack direction="row" className={classes.rowsPerPageStylesHelper}>
                        <RowsPerPage
                            pagSize={5}
                            onChangeRowsPerPage={(data: number) => setLimit(data)}
                        />
                        <FiltersAndSort
                            sortBy={sortId}
                            onSort={(sortType: any) => setSortId(sortType?.toString() ?? "1")}
                            onChangeFilters={(data: any) => setFilterObj(data)}
                            filters={{ ...filterObj }}
                            hasFilter={activeTab === 0}
                            isResetFilter={activeTab}
                            showLanguageSelection={typePlantix === 'cp'}
                            showSecondarySort={false}
                            showCropSelection={typePlantix === 'map'}
                            showLastUpdatedOn={true}
                        />
                    </Stack>
                    <DataTable
                        columns={dataColumns}
                        data={dataToDisplay}
                        enableSelection={buttonExportVisible && activeTab !== 1}
                        pageNumber={1}
                        onPageChange={() => { }}
                        onSelectAll={onSelectAll}
                        onDeselectAll={() => { }}
                        onRowSelect={onRowSelect}
                        isEditEnabled={activeTab === 0 && typePlantix === 'cp'}
                        isViewButton={activeTab === 0}
                        isDeleteEnabled={activeTab === 0}
                        onActionClick={(type, rowInfo) => onClickAction(type, rowInfo)}
                        hasTranslation={typePlantix === 'cp'}
                        languageKey="languageName"
                        handleKnowMorePopup={(flag: boolean, rowData: any) => onKnowMorePopUp(flag, rowData)}
                    />
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <DataTableActions
                            actionsType={actionsType}
                            pageNumber={currentPage + 1}
                            onPageChange={(selectedPageNo: number) => setCurrentPage(selectedPageNo - 1)}
                            buttonVisible={activeTab === 0}
                            buttonExportVisible={buttonExportVisible}
                            onActionClick={(data: string) => onClickAction(data)}
                            canShowAvatar={activeTab === 0}
                            activeTab={activeTab}
                            totalpages={totalPages}
                        />
                    </Stack>
                </Box>
            </div>
            <ModalCreatePopUp
                open={open}
                actionType={actionType}
                showCreateOrEditModal={showCreateOrEditModal}
                showBulkUpload={showBulkUpload}
                onCloseModal={onCloseModal}
                onShowCreateOrEditModal={onShowCreateOrEditModal}
                onShowBulkUpload={onShowBulkUpload}
            />
            {showViewModal && viewModalComponent}
        </TableContainer>
    );
};

export default PlantixView;
