import { Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ButtonWithIcon, {
  CANCEL_NOTIFICATION_TYPE,
  DELETE_TYPE,
  EXPORT_TYPE,
  PUBLISH_TYPE,
} from "../Buttons";

export enum ACTION_TYPE {
  TYPE_CROP = "Crop",
  TYPE_PUSH = "Push",
  TYPE_PUBLISH = "Publish",
  TYPE_QUERY = "Query",
  TYPE_CROP_PROTECTION = "CROP_PROTECTION",
  TYPE_RETAILER = "Retailer",
  TYPE_CP_PRODUCT = "CP_PRODUCT",
}

type RenderTableActionProps = {
  actionType?: ACTION_TYPE;
  actionType2?: ACTION_TYPE;
  buttonVisible?: Boolean;
  buttonExportVisible?: Boolean;
  onDelete?: Function;
  showActionProgress?: boolean;
  activeTab?: string | number;
  onPublish?: Function;
  onExportToExcelClick?: Function;
};

const RenderTableAction: React.FC<RenderTableActionProps> = (props) => {
  const { t } = useTranslation();
  let component = null;
  switch (props.actionType) {
    case ACTION_TYPE.TYPE_CROP:
      return (
        <Stack spacing={2} direction="row">
          {props.activeTab === 0 && props.buttonExportVisible && (
            <ButtonWithIcon
              type={EXPORT_TYPE}
              label="Export to excel"
              data-testid={'export-to-excel'}
              onClick={() =>
                props.onExportToExcelClick && props.onExportToExcelClick()
              }
            />
          )}
        </Stack>
      );
    case ACTION_TYPE.TYPE_PUSH:
      return props.buttonVisible && props.activeTab === 0 ? (
        /**Added delete and cancel notification icon, using in Push notification tabs */
        <ButtonWithIcon
          showActionProgress={props.showActionProgress}
          type={DELETE_TYPE}
          label={"Delete"}
          onClick={props.onDelete}
        />
      ) : props.buttonVisible && props.activeTab === 1 ? (
        <ButtonWithIcon
          showActionProgress={props.showActionProgress}
          type={CANCEL_NOTIFICATION_TYPE}
          label={"Cancel Notification"}
          onClick={props.onDelete}
        />
      ) : null;
    case ACTION_TYPE.TYPE_PUBLISH:
      return props.buttonVisible ? (
        <>
          <ButtonWithIcon
            showActionProgress={props.showActionProgress}
            type={PUBLISH_TYPE}
            label="Publish"
            onClick={props.onPublish}
          />
          <ButtonWithIcon
            showActionProgress={props.showActionProgress}
            type={DELETE_TYPE}
            label={"Delete"}
            onClick={props.onDelete}
          />
        </>
      ) : null;
    case ACTION_TYPE.TYPE_QUERY:
      return (
        <>
          <Stack spacing={2} direction="row">
            {props.activeTab === 0 && props.buttonExportVisible && (
              <ButtonWithIcon
                type={EXPORT_TYPE}
                label={t("str_export_excel")}
                onClick={() =>
                  props.onExportToExcelClick && props.onExportToExcelClick()
                }
              />
            )}
          </Stack>
        </>
      );
    case ACTION_TYPE.TYPE_RETAILER:
      return (
        <>
          <Stack spacing={2} direction="row">
            <ButtonWithIcon
              type={EXPORT_TYPE}
              label={t("str_export_excel")}
              onClick={() =>
                props.onExportToExcelClick && props.onExportToExcelClick()
              }
            />
          </Stack>
        </>
      );
    case ACTION_TYPE.TYPE_CP_PRODUCT:
      return (
        <>
          <Stack spacing={2} direction="row">
            {props.buttonExportVisible ? (
              <ButtonWithIcon
                type={EXPORT_TYPE}
                label={t("str_export_excel")}
                onClick={() =>
                  props.onExportToExcelClick && props.onExportToExcelClick()
                }
              />
            ) : null}
          </Stack>
        </>
      );
    default:
      return component;
  }
};

export default RenderTableAction;
