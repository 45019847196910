import {Box, Modal, Paper} from "@mui/material";
import _ from "../../../Utils/lodash";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import api_endpoints, {base_url} from "../../../Api/end_points";
import {GetCropStagesWithSystemName, GetFileS3BucketURL, RemoveImageList,} from "../../../Api/generic_apicalls";
import {ActionType, DO_DONT, ModuleImageUpload, STEPS,} from "../../../Common/Constants";
import {CP_S3ImageUploadType} from "../../../Common/Constants/types";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import StepperComponent from "../../../Common/Stepper";
import {IRequestBody} from "../../../Constants/interfaces";
import {RoutePaths} from "../../../Constants/routeConstants";
import {RootState} from "../../../redux/reducers";
import {LanguageRecordType} from "../../../redux/reducers/generic_reducer";
import {canAddRecord, canUpdateRecord, checkIfSystemNameIsEditable,} from "../../../Utils/genericUtils";
import {logger} from "../../../Utils/logger";
import {TriggerToastMessage} from "../../../Utils/toastTrigger";
import {StagesToNewProduct} from "../stepperStages";
import {Styles} from "../style";
import {GetCPDetails, GetCrops, SaveUpdateCpProduct,} from "../CPprouctsAPICalls";
import CropCalendarRecommendation from "./CropCalendarRecommendation";
import DosandDonts from "./DosandDonts";
import Features from "./Features";
import GenralForm from "./GeneralForm";
import GrowerTestimonialForm from "./GrowerTestimonial";
import TargetThreats from "./TargetThreatsForm";
import getRandomizeValue from "../../../Utils/randomizer";
import {removeSpacesImagePath} from "../../../Utils/removeSpaceImagePath";
import combineUnionArray from "../../../Utils/combineUnionArray";

export type GeneralFormType = {
  language: string;
  media: [];
  image: string;
  productTitle: string;
  systemName: string;
  crop: string;
  category: string;
  dosage: string;
  whenToUse: string;
  howToUse: string;
  applicableCrop: string;
  benefit1: string;
  benefit2: string;
  benefit3: string;
};
export type DosAndDontType = {
  type: string;
  // id: number;
  // displayOrder: number;
  text: string;
  systemName: string;
};

export type FeaturesType = {
  displayOrder?: number;
  systemName: string;
  header: string;
  description: string;
  imagePath: string;
};

export type GrowerTestimonialsType = {
  systemName: string;
  text: string;
  mediaType: string;
  subtext: string;
  mediaUrl: string;
};

export type TargetThreatsType = {
  systemName: string;
  stages: string;
  displayOrder: string;
  stageThreatDisplayOrders: [];
};

export type RecommendationType = {
  calendarSystemName: string;
  stageSystemName: string;
  threatSystemName: string;
  dosage: string;
  whenToUse: string;
  whyToUse: string;
  reminderMessage: string;
  applicationOrder: string;
};

export const initialDoAndDont: DosAndDontType = {
  type: "",
  text: "",
  systemName: "",
};
export const initialFeature: FeaturesType = {
  systemName: "",
  header: "",
  description: "",
  imagePath: "",
};
export const initialTestimonial: GrowerTestimonialsType = {
  systemName: "",
  text: "",
  mediaType: "",
  subtext: "",
  mediaUrl: "",
};
export const initialThreat: TargetThreatsType = {
  systemName: "",
  stages: "",
  displayOrder: "",
  stageThreatDisplayOrders: [],
};
export const initialRecommendation: RecommendationType = {
  calendarSystemName: "",
  stageSystemName: "",
  threatSystemName: "",
  dosage: "",
  whenToUse: "",
  whyToUse: "",
  reminderMessage: "",
  applicationOrder: "",
};
export const CPCreateEdit = (props: any) => {
  const nav = useNavigate();
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
      primaryLangCode: state.auth.languageCode,
    };
  });
  const [activeStep, setActiveStep] = React.useState(1);
  const generalDetailsFormRef = useRef<HTMLFormElement>();
  const [cropList, setCropList] = useState<Array<any>>();
  const [isPrimaryCP, setIsPrimaryCP] = useState<boolean>(false);
  const [latestImgsList, setLatestImgsList] = useState<Array<string>>([]);

  const [threatList, setThreatList] = useState<Array<any>>([]);
  const [stagesList, setStagesList] = useState<Array<any>>([]);
  const [calendarList, setCalendarList] = useState<Array<any>>([]);

  /** step-1,2,3,4,5,6 payload specific states: START */
  const [uniqueKey, setUniqueKey] = useState<number>(getRandomizeValue());

  const [generalDetails, setGeneralDetails] = useState<any>({});

  const [doAndDontList, setDoAndDontList] = useState<DosAndDontType[]>([]);
  const [selectedDoAndDont, setSelectedDoAndDont] =
    useState<DosAndDontType>(initialDoAndDont);

  const [featureList, setFeatureList] = useState<FeaturesType[]>([]);
  const [selectedFeature, setSelectedFeature] =
    useState<FeaturesType>(initialFeature);

  const [testimonialList, setTestimonialList] = useState<
    GrowerTestimonialsType[]
  >([]);
  const [selectedTestimonial, setSelectedTestimonial] =
    useState<GrowerTestimonialsType>(initialTestimonial);

  const [targetThreatList, setTargetThreatList] = useState<TargetThreatsType[]>(
    []
  );
  const [selectedThreat, setSelectedThreat] =
    useState<TargetThreatsType>(initialThreat);

  const [recommendationList, setRecommendationList] = useState<
    RecommendationType[]
  >([]);
  const [selectedRecommenddation, setSelectedRecommendation] =
    useState<RecommendationType>(initialRecommendation);

  /** step-1,2,3,4,5,6 payload specific states: END */

  const [isSystemNameEditable, canEditSystemName] = useState<boolean>(true);
  const [canAddNewRecord, toggleAddNewRecord] = useState<boolean>(true);
  const [canUpdateExistingRecord, toggleUpdateExistingRecord] =
    useState<boolean>(true);
  const [recordBaseLanguage, setBaseLanguageOfRecord] = useState<string>("");

  const classes = Styles();

  /** STEP-2 methods: START*/
  /**
   * @description Method to update Do's and Dont
   * @param data DosAndDontType
   */
  const updateDoAndDontList = (data: DosAndDontType) => {
    if (selectedDoAndDont && selectedDoAndDont.systemName) {
      /**updating existing record */
      updateRecord(doAndDontList, selectedDoAndDont, data, "systemName");
      setSelectedDoAndDont(initialDoAndDont);
    } else {
      /**creating new record */
      setDoAndDontList([...doAndDontList, data]);
    }
  };
  /**
   * @description Method to remove selected Do's and Dont
   * @param systemName string
   */
  const removeSelectedDoAndDont = (systemName: string) => {
    // if systemName matches with selected data
    if (systemName === selectedDoAndDont.systemName) {
      setSelectedDoAndDont(initialDoAndDont);
    }
    // remove the entry from doAndDontList array
    const doAndDontAfterRemoval = _.reject(doAndDontList, {
      systemName: systemName,
    });
    setDoAndDontList([...doAndDontAfterRemoval]);
  };
  /** STEP-2 methods: END */

  /** STEP-3 methods: START*/
  /**
   * @description Method to update Feature
   * @param data FeaturesType
   */
  const updateFeatureList = (data: FeaturesType) => {
    if (selectedFeature && selectedFeature.systemName) {
      /**updating existing record */
      updateRecord(featureList, selectedFeature, data, "systemName");
      setSelectedFeature(initialFeature);
    } else {
      /**creating new record */
      setFeatureList([...featureList, data]);
    }
  };
  /**
   * @description Method to remove selected Feature
   * @param systemName string
   */
  const removeSelectedFeature = (systemName: string) => {
    // if systemName matches with selected data
    if (systemName === selectedFeature.systemName) {
      setSelectedFeature(initialFeature);
    }
    // remove the entry from featureList array
    const featureAfterRemoval = _.reject(featureList, {
      systemName: systemName,
    });
    setFeatureList([...featureAfterRemoval]);
  };
  /** STEP-3 methods: END */

  /** STEP-4 methods: START*/
  /**
   * @description Method to update Grower-Testimonial
   * @param data GrowerTestimonialsType
   */
  const updateTestimonialList = (data: GrowerTestimonialsType) => {
    if (selectedTestimonial && selectedTestimonial.systemName) {
      /**updating existing record */
      updateRecord(testimonialList, selectedTestimonial, data, "systemName");
      setSelectedTestimonial(initialTestimonial);
    } else {
      /**creating new record */
      setTestimonialList([...testimonialList, data]);
    }
  };
  /**
   * @description Method to remove selected Grower-Testimonial
   * @param systemName string
   */
  const removeSelectedTestimonial = (systemName: string) => {
    // if systemName matches with selected data
    if (systemName === selectedTestimonial.systemName) {
      setSelectedTestimonial(initialTestimonial);
    }
    // remove the entry from testimonialList array
    const featureAfterRemoval = _.reject(testimonialList, {
      systemName: systemName,
    });
    setTestimonialList([...featureAfterRemoval]);
  };
  /** STEP-4 methods: END */

  /**STEP-5 methods starts*/
  /**
   * @description Method to update target threat
   * @param data TargetThreatsType
   */
  const updateThreatList = (data: TargetThreatsType) => {
    if (selectedThreat && selectedThreat.systemName) {
      /**updating existing record */
      updateRecord(
        targetThreatList,
        selectedThreat,
        data,
        "systemName",
        "stages"
      );
      setSelectedThreat(initialThreat);
    } else {
      /**creating new record */
      let order = targetThreatList.sort(
        (a: any, b: any) => a.displayOrder - b.displayOrder
      );
      data.displayOrder =
        order.length > 0
          ? String(Number(order[order.length - 1].displayOrder) + 1)
          : "0";
      setTargetThreatList([...targetThreatList, data]);
    }
  };

  /**
   * @description Method to remove selected target threat
   * @param systemName string
   */
  const removeSelectedThreat = (systemName: string) => {
    // resetting selected record to initial state, If any
    if (
      systemName.split(",")[0] === selectedThreat.systemName &&
      systemName.split(",")[1] === selectedThreat.stages
    ) {
      setSelectedThreat(initialThreat);
    }
    // remove the entry from targetThreatList array
    const stringTargetThreatList = targetThreatList.map((item) => {
      return { ...item, displayOrder: String(item.displayOrder) };
    });
    const updatedThreat = _.reject(stringTargetThreatList, {
      systemName: systemName.split(",")[0],
      stages: systemName.split(",")[1],
      displayOrder: systemName.split(",")[2],
    });
    setTargetThreatList([...updatedThreat]);
  };
  /**STEP-5 methods end*/

  /**STEP-6 methods starts*/
  /**
   * @description Method to update target threat
   * @param data RecommendationType
   */
  const updateRecommendationList = (data: RecommendationType) => {
    if (selectedRecommenddation && selectedRecommenddation.calendarSystemName) {
      /**updating existing record */
      updateRecord(
        recommendationList,
        selectedRecommenddation,
        data,
        "calendarSystemName",
        "stageSystemName",
        "threatSystemName",
        [
          "calendarSystemName",
          "stageSystemName",
          "threatSystemName",
          "whenToUse",
        ]
      );
      setSelectedRecommendation(initialRecommendation);
    } else {
      /**creating new record */
      const unorderedList = [...recommendationList, data];
      // const orderdList = unorderedList.sort((a: any, b:any) => a.applicationOrder - b.applicationOrder);
      setRecommendationList(unorderedList);
    }
  };

  /**
   * @description Method to remove selected target threat
   * @param calendarSystemName string
   */
  const removeSelectedRecommendation = (calendarSystemName: string) => {
    // resetting selected record to initial state, If any
    if (
      calendarSystemName.split(",")[0] ===
        selectedRecommenddation.calendarSystemName &&
      calendarSystemName.split(",")[1] ===
        selectedRecommenddation.stageSystemName &&
      calendarSystemName.split(",")[2] ===
        selectedRecommenddation.threatSystemName &&
      calendarSystemName.split(",")[3] == selectedRecommenddation.whenToUse
    ) {
      setSelectedRecommendation(initialRecommendation);
    }
    const setstringrecommendationList = recommendationList.map((item) => ({
      ...item,
      whenToUse: String(item.whenToUse),
    }));
    // remove the entry from recommendationList array
    const updatedRecommendation = _.reject(setstringrecommendationList, {
      calendarSystemName: calendarSystemName.split(",")[0],
      stageSystemName: calendarSystemName.split(",")[1],
      threatSystemName: calendarSystemName.split(",")[2],
      whenToUse: calendarSystemName.split(",")[3],
    });
    setRecommendationList([...updatedRecommendation]);
  };
  /**STEP-6 methods end*/

  /** Generic logic to update existing record */
  const updateRecord = (
    mainList: any,
    prevRecord: any,
    newRecord: any,
    matchKey1: string,
    matchKey2?: string,
    matchKey3?: string,
    multiMatchKey?: Array<string>
  ) => {
    const isMatch = (eachItem: any) => {
      if (multiMatchKey) {
        return multiMatchKey.every((key) => eachItem[key] === prevRecord[key]);
      }
      if (matchKey3) {
        return (
          eachItem[matchKey1] === prevRecord[matchKey1] &&
          matchKey2 && eachItem[matchKey2] === prevRecord[matchKey2] &&
          eachItem[matchKey3] === prevRecord[matchKey3]
        );
      }
      if (matchKey2) {
        return (
          eachItem[matchKey1] === prevRecord[matchKey1] &&
          eachItem[matchKey2] === prevRecord[matchKey2]
        );
      }
      return eachItem[matchKey1] === prevRecord[matchKey1];
    };

    mainList.forEach((eachItem: any, index: number) => {
      if (isMatch(eachItem)) {
        mainList.splice(index, 1, newRecord);
      }
    });
  };
  const handleClosePopup = () => {
    // props.setShowCreateModal(false);
    props.onClose();
    /** API call to remove images */
    if (latestImgsList && latestImgsList.length) {
      const apiData = {} as IRequestBody;
      apiData.domain = base_url;
      apiData.endPoint = api_endpoints.remove_img_list;
      apiData.showLoader = true;
      apiData.payLoad = { fileUrlsList: latestImgsList };
      return RemoveImageList(apiData, successCallBack, errorCallback);
    }
  };

  const gotoNextStage = (nextStep: number, values?: any) => {
    if (nextStep !== STEPS.STEP7) {
      setActiveStep(nextStep);
      renderCpProductsStage(nextStep);
    } else {
      saveUpdateCrop();
    }
    switch (nextStep - 1) {
      case 1:
        logger.info("Step-1 data:", values);
        if (values) {
          // if ((generalDetails.crop && (values.crop !== generalDetails.crop)) ||
          // !generalDetails.crop) {
          fetchThreatStagesCalendars(values.crop);
          // }
          setGeneralDetails((prevState: any) => ({
            ...prevState,
            language: values.language,
            languageCode: values.languageCode,
            languageId: values.languageId,
            image: values.image,
            productTitle: values.productTitle,
            systemName: values.systemName,
            crop: values.crop,
            media: values.media,
            category: values.category,
            dosage: values.dosage,
            whenToUse: values.whenToUse,
            howToUse: values.howToUse,
            applicableCrop: values.applicableCrop,
            benefit1: values.benefit1,
            benefit2: values.benefit2,
            benefit3: values.benefit3,
          }));
        }
        break;
      case 2:
        logger.info("Step-2 data:", doAndDontList);
        break;
      case 3:
        logger.info("Step-3 data:", featureList);
        break;
      case 4:
        logger.info("Step-4 data:", testimonialList);
        break;
      case 5:
        logger.info("Step-5 data:", targetThreatList);
        break;
      case 6:
        logger.info("Step-6 data:", recommendationList);
        break;
      default:
        return;
    }
  };

  const getUniqueKey = () => {
    setUniqueKey(getRandomizeValue());
  };
  const resetThreatAndCalendar = () => {
    /** resetting step-5 and 6 data  */
    setTargetThreatList([]);
    setRecommendationList([]);
    setSelectedThreat(initialThreat);
    setSelectedRecommendation(initialRecommendation);
  };
  const renderCpProductsStage = (nextStep: number) => {
    switch (nextStep) {
      case 1:
        return (
          <GenralForm
            generalFormDetails={generalDetails}
            formRef={generalDetailsFormRef}
            onNext={(nextStep: number, values: any) =>
              gotoNextStage(nextStep, values)
            }
            cropList={cropList}
            getFileURL={(file: any, data: CP_S3ImageUploadType) =>
              getFileURL(file, data)
            }
            targetThreatList={targetThreatList}
            recommendationList={recommendationList}
            resetThreatAndCalendar={() => resetThreatAndCalendar()}
            actionType={props.actionType}
            isPrimaryCP={isPrimaryCP}
            isEdit={props.isEdit}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            baseLanguage={recordBaseLanguage}
            getLatestImgURL={(imgURL: string) =>
              setLatestImgsList((prev) => [...prev, imgURL])
            }
          />
        );
      case 2:
        return (
          <DosandDonts
            onNext={(nextStep: number) => gotoNextStage(nextStep)}
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateListOrder={(newList: Array<DosAndDontType>) =>
              setDoAndDontList(newList)
            }
            listOfDoAndDont={doAndDontList}
            selectedDoAndDont={selectedDoAndDont}
            onSave={(data: DosAndDontType) => {
              updateDoAndDontList(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedDoAndDont(systemName);
            }}
            onSelect={(data: DosAndDontType) => {
              setSelectedDoAndDont({ ...data });
            }}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            isEdit={props.isEdit}
            actionType={props.actionType}
            isPrimaryCP={isPrimaryCP}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            canUpdateExistingRecord={canUpdateExistingRecord}
          />
        );
      case 3:
        return (
          <Features
            onNext={(nextStep: number) => gotoNextStage(nextStep)}
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateListOrder={(newList: Array<FeaturesType>) =>
              setFeatureList(newList)
            }
            listOfFeatures={featureList}
            selectedFeature={selectedFeature}
            onSave={(data: FeaturesType) => {
              updateFeatureList(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedFeature(systemName);
            }}
            onSelect={(data: FeaturesType) => {
              setSelectedFeature({ ...data });
            }}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            productSystemName={generalDetails.systemName}
            cropSystemName={generalDetails.crop}
            getLatestImgURL={(imgURL: string) =>
              setLatestImgsList([...latestImgsList, imgURL])
            }
            isPrimaryCP={isPrimaryCP}
            actionType={props.actionType}
            isEdit={props.isEdit}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            canUpdateExistingRecord={canUpdateExistingRecord}
          />
        );
      case 4:
        return (
          <GrowerTestimonialForm
            onNext={(nextStep: number) => gotoNextStage(nextStep)}
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateListOrder={(newList: Array<GrowerTestimonialsType>) =>
              setTestimonialList(newList)
            }
            listOfTestimonial={testimonialList}
            selectedTestimonial={selectedTestimonial}
            onSave={(data: GrowerTestimonialsType) => {
              updateTestimonialList(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedTestimonial(systemName);
            }}
            onSelect={(data: GrowerTestimonialsType) => {
              setSelectedTestimonial({ ...data });
            }}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            productSystemName={generalDetails.systemName}
            cropSystemName={generalDetails.crop}
            getLatestImgURL={(imgURL: string) =>
              setLatestImgsList([...latestImgsList, imgURL])
            }
            isPrimaryCP={isPrimaryCP}
            actionType={props.actionType}
            isEdit={props.isEdit}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            canUpdateExistingRecord={canUpdateExistingRecord}
          />
        );
      case 5:
        return (
          <TargetThreats
            onNext={(nextStep: number) => gotoNextStage(nextStep)}
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateListOrder={(newList: Array<TargetThreatsType>) =>
              setTargetThreatList(newList)
            }
            listOfThreats={targetThreatList}
            selectedThreat={selectedThreat}
            onSave={(data: TargetThreatsType) => {
              updateThreatList(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedThreat(systemName);
            }}
            onSelect={(data: TargetThreatsType) => {
              setSelectedThreat({ ...data });
            }}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            generalDetails={generalDetails}
            isPrimaryCP={isPrimaryCP}
            actionType={props.actionType}
            threatList={threatList}
            stagesList={stagesList}
            isEdit={props.isEdit}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            canUpdateExistingRecord={canUpdateExistingRecord}
          />
        );
      case 6:
        return (
          <CropCalendarRecommendation
            onNext={(nextStep: number) => gotoNextStage(nextStep)}
            onPrevious={(nextStep: number) => gotoNextStage(nextStep)}
            updateListOrder={(newList: Array<RecommendationType>) =>
              setRecommendationList(newList)
            }
            listOfRecommendation={recommendationList}
            selectedRecommenddation={selectedRecommenddation}
            onSave={(data: RecommendationType) => {
              updateRecommendationList(data);
            }}
            onRemove={(systemName: string) => {
              removeSelectedRecommendation(systemName);
            }}
            onSelect={(data: RecommendationType) => {
              setSelectedRecommendation({ ...data });
            }}
            updateUniqueKey={getUniqueKey}
            uniqueKey={uniqueKey}
            isPrimaryCP={isPrimaryCP}
            actionType={props.actionType}
            threatList={threatList}
            stagesList={stagesList}
            calendarList={calendarList}
            isEdit={props.isEdit}
            isSystemNameEditable={isSystemNameEditable}
            canAddNewRecord={canAddNewRecord}
            canUpdateExistingRecord={canUpdateExistingRecord}
          />
        );
    }
  };

  const successCallBack = (response: any, type: string, imgType?: string) => {
    switch (type) {
      case "crops":
        setCropList(response.data.cropsList);
        break;
      case "s3_file_url":
        handleS3FileUrlResponse(response, imgType);
        break;
      case "remove_img":
        handleRemoveImageResponse(response);
        break;
      case "save_edit_cp":
        handleSaveEditCpResponse(response);
        break;
    }
  };

  const handleS3FileUrlResponse = (response: any, imgType?: string) => {
    if (response.data && imgType === ModuleImageUpload.CP_GENERAL) {
      let imgUrl: string = response.data && response.data.imageUrl;
      setGeneralDetails((prevState: any) => ({
        ...prevState,
        image: imgUrl,
      }));
      setLatestImgsList([...latestImgsList, imgUrl]);
    } else {
      errorCallback(response, "s3_file_url");
    }
  };

  const handleRemoveImageResponse = (response: any) => {
    if (response.statusCode === 200) {
      const message = response.message ? response.message : "Uploaded images removed successfully.";
      TriggerToastMessage(message, "success");
    }
  };

  const handleSaveEditCpResponse = (response: any) => {
    if (response && (response.statusCode === 200 || response.statusCode === 201)) {
      const SuccessPageURL = RoutePaths.successPage
        .replace(":module", "Cp Product")
        .replace(":mode", props.isEdit ? "updated" : "created");
      props.onClose();
      nav(SuccessPageURL);
    } else {
      TriggerToastMessage("Save/Update CP Product failed !!", "error");
    }
  };
  const errorCallback = (_error: any, type: string) => {
    TriggerToastMessage("Unable to process your request!!!" + type, "error");
  };
  /**
   * @description this fetch method is for list of crops
   * @returns success or error call back response object
   */
  const fetchCrops = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.crops;
    apiData.showLoader = true;
    apiData.customHeaders = {
      "language-code": storeData.primaryLangCode,
    };
    apiData.payLoad = {
      pageNo: 0,
      pageSize: 100,
    };
    return GetCrops(apiData, successCallBack, errorCallback);
  };
  /**processing get by id data for crop */
  const processCropStageCalendarSystemInfo = (response: any) => {
    const data = response.payload;
    const selectedLanguage = storeData.languages.filter(
      (language: LanguageRecordType) => language.languageId === data.languageId
    );

    // If adding product from Add Translation Button and the language is the primary language, clear the selected language
    if (
      props.actionType === ActionType.ADD_TRANSLATION &&
      selectedLanguage[0].languageCode === storeData.primaryLangCode
    ) {
      selectedLanguage.length = 0;
    }

    // Fetch crops based on the selected language
    fetchCrops();

    const generalDetails = {
      id: response.id,
      languageId: data.languageId,
      language: selectedLanguage[0]?.languageName,
      languageCode: selectedLanguage[0]?.languageCode,
      image: data.imagePath,
      productTitle: data.productTitle,
      systemName: data.productSystemName,
      crop: data.cropSystemName,
      media: data.media ?? [{ displayOrder: 1, path: data.imagePath }],
      category: data.category,
      dosage: data.dosage,
      whenToUse: data.whenToUse,
      howToUse: data.howToUse,
      applicableCrop: data.applicableCrop,
      benefit1: data.benefit1,
      benefit2: data.benefit2,
      benefit3: data.benefit3,
      productId: data.productId,
      smartGrowerCropId: response.smartGrowerCropId,
      smartGrowerProductId: response.smartGrowerProductId,
    };

    // Set general details and base language
    setGeneralDetails(generalDetails);
    setBaseLanguageOfRecord(generalDetails.language);

    // Process do and don't list
    const dos = _.sortBy(data.dos, ["displayOrder", "asc"]);
    dos.forEach((item: any) => (item.type = DO_DONT[0]));
    const donts = _.sortBy(data.donts, ["displayOrder", "asc"]);
    donts.forEach((item: any) => (item.type = DO_DONT[1]));
    setDoAndDontList([...dos, ...donts]);

    // Process feature list
    setFeatureList(
      [...data.keyFeatures].sort((a, b) => a.displayOrder - b.displayOrder)
    );

    // Process testimonial list
    const testimonialSort = _.sortBy(data.growerTestimonials, [
      "displayOrder",
      "asc",
    ]);
    setTestimonialList([...testimonialSort]);

    // Process threat data
    const threatData: Array<any> = [];
    if (data.threatStageMapping && data.threatStageMapping.length) {
      data.threatStageMapping.forEach((item: any) => {
        item.stages.forEach((s: any, index: number) => {
          const tempObj = { ...item };
          tempObj.stages = s;
          tempObj.displayOrder = index + 1;
          tempObj.stageThreatDisplayOrders = Array.isArray(
            tempObj.stageThreatDisplayOrders
          )
            ? tempObj.stageThreatDisplayOrders.filter(
                (item: any) => item.stageSystemName === s
              )
            : [];
          threatData.push(tempObj);
        });
      });
    }
    const threatSort = _.sortBy(threatData, ["displayOrder", "asc"]);
    setTargetThreatList(threatSort);

    // Process recommendation list
    setRecommendationList([...data.recommendations]);

    // Determine if the record is the primary language record
    const isPrimaryCP =
      !(props.actionType === ActionType.ADD_TRANSLATION) &&
      selectedLanguage[0].languageCode === storeData.primaryLangCode;
    setIsPrimaryCP(isPrimaryCP);

    // If the record is the primary language record, check if the system name is editable, if a new record can be added, and if the record can be updated
    if (isPrimaryCP) {
      const isSysNameEditable = checkIfSystemNameIsEditable(
        response,
        storeData.primaryLangCode,
        "smartGrowerProductId"
      );
      const checkIfNewRecordToAdd = canAddRecord(
        response,
        storeData.primaryLangCode,
        "smartGrowerProductId"
      );
      const checkIfRecordCanUpdate = canUpdateRecord(
        response,
        "smartGrowerProductId"
      );

      // Update the state based on the checks
      canEditSystemName(isSysNameEditable);
      toggleAddNewRecord(checkIfNewRecordToAdd);
      toggleUpdateExistingRecord(checkIfRecordCanUpdate);
    }
  };

  const compareWithPrimaryLanguage = (response: any) => {
    const data = response.payload;

    const dosWithType = data.dos.map((item: any) => ({
      ...item,
      type: DO_DONT[0],
    }));
    const dontsWithType = data.donts.map((item: any) => ({
      ...item,
      type: DO_DONT[1],
    }));

    const combinedDosAndDonts = combineUnionArray(
      doAndDontList,
      [...dosWithType, ...dontsWithType],
      (a, b) => a.systemName === b.systemName
    );

    const sortedDosAndDonts = [...combinedDosAndDonts].sort((a, b) => {
      // Sort by type first
      if (a.type === DO_DONT[0] && b.type === DO_DONT[1]) {
        return -1;
      }
      if (a.type === DO_DONT[1] && b.type === DO_DONT[0]) {
        return 1;
      }

      // If type is the same, sort by displayOrder
      return a.displayOrder - b.displayOrder;
    });

    const combinedFeatures = combineUnionArray(
      featureList,
      data.keyFeatures,
      (a, b) => a.systemName === b.systemName
    ).sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));

    const combinedGrowerTestimonials = combineUnionArray(
      testimonialList,
      data.growerTestimonials,
      (a, b) => a.systemName === b.systemName
    );

    const combinedRecommendations = combineUnionArray(
      recommendationList,
      data.recommendations,
      (a, b) =>
        a.calendarSystemName === b.calendarSystemName &&
        a.stageSystemName === b.stageSystemName &&
        a.threatSystemName === b.threatSystemName &&
        a.whenToUse === b.whenToUse
    );

    setDoAndDontList(sortedDosAndDonts);
    setFeatureList(combinedFeatures);
    setTestimonialList(combinedGrowerTestimonials);
    setRecommendationList(combinedRecommendations);
  };

  /**
   * get crop detail by Crop ID and language
   */
  const fetchCPDetails = (syncWithPrimary = false) => {
    const cropLanguageObject: LanguageRecordType[] = storeData.languages.filter(
      (lngRcd: LanguageRecordType) => {
        return lngRcd.languageId === props.lngId;
      }
    );
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    if (!props.cpViewID) {
      /** for published draft crop */
      apiData.endPoint = api_endpoints.published_cp_details.replace(
        "__SYSTEM__NAME__",
        props.sysNm
      );
    } else {
      /**for newly created or unpublished draft crop */
      apiData.endPoint = `crops/cp-product-drafts/${props.cpViewID}`;
    }
    if (cropLanguageObject.length > 0) {
      apiData.customHeaders = {
        "language-code": cropLanguageObject[0].languageCode,
      };
    }

    if (syncWithPrimary) {
      apiData.customHeaders = {
        "language-code": storeData.primaryLangCode,
      };
    }

    GetCPDetails(apiData)
      .then((response: any) => {
        if (response.statusCode === 200) {
          if (syncWithPrimary) {
            compareWithPrimaryLanguage(response.data);
          } else {
            processCropStageCalendarSystemInfo(response.data);
          }
          return;
        }
        throw new Error(response.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  /** getting primary language of the country
   * and fetching CROP list as per language
   */
  const setPrimaryLanguage = () => {
    let selectedLanguage: any = storeData.languages.filter(
      (language: LanguageRecordType) =>
        language.languageCode === storeData.primaryLangCode
    );
    /**get state list API call as per primary language of the country*/
    fetchCrops();
    setGeneralDetails((prevState: any) => ({
      ...prevState,
      language: selectedLanguage[0].languageName,
      languageId: selectedLanguage[0].languageId,
      languageCode: selectedLanguage[0].languageCode,
    }));
  };
  const getFileUploadPayload = (file: any, data: CP_S3ImageUploadType) => {
    let fileNameArr: any = file.name.split(".");
    let s3Data: any = {
      productSystemName: data.productSystemName?.replaceAll(" ", ""),
      cropSystemName: data.cropSystemName,
      type: data.type,
      fileNameWithExtension: `${data.productSystemName?.replaceAll(" ", "")}.${
        fileNameArr[fileNameArr.length - 1]
      }`,
    };
    return s3Data;
  };
  /**
   * @description performing saving and updation of Crop
   * @returns success or error call back response object
   */
  const getFileURL = (file: any, data: CP_S3ImageUploadType): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.fileupload_S3bucket;
    apiData.showLoader = true;
    if (file && typeof file !== "string") {
      let payload = getFileUploadPayload(file, data);
      let formData: any = new FormData();
      formData.append("file", file);
      formData.append("data", JSON.stringify(payload));
      apiData.payLoad = formData;
      return GetFileS3BucketURL(
        apiData,
        successCallBack,
        errorCallback,
        data.type
      );
    }
  };
  /** Fetching list of: Threats, Stages, Calendars for step-5,6 */
  const fetchThreatStagesCalendars = (cropSysName: any) => {
    const apiData = {} as IRequestBody;
    apiData.endPoint = api_endpoints.get_crop_stages.replace(
      "__CROP_SYSTEM_NAME__",
      cropSysName
    );
    apiData.payLoad = {
      pagaNo: 0,
      pageSize: 100,
    };
    GetCropStagesWithSystemName(
      apiData,
      (response: any) => {
        if (response.statusCode === 200) {
          let res: any = response.data;
          setThreatList(res.challenges);
          setStagesList(res.stages);
          setCalendarList(res.calendars);
          //if (!res.challenges.length || !res.stages.length || !res.calendars.length) {
          if (!res.challenges.length || !res.stages.length) {
            setActiveStep(STEPS.STEP1);
            TriggerToastMessage(
              `The selected Crop don't have either Challenges, Stage or Threats. Please select another Crop to continue`,
              "info"
            );
            setGeneralDetails((prevState: any) => ({
              ...prevState,
              image: "",
            }));
          }
        }
      },
      () => {
        TriggerToastMessage("Unable to get crop stages!", "error");
      }
    );
  };

  /** CP create/Edit: Payload preparation */
  const getCpPayload = () => {
    let attributes: any = {};
    let payload: any = {};
    /** To level payload before step-1 */
    if (props.isEdit && props.actionType !== ActionType.ADD_TRANSLATION) {
      attributes.id = generalDetails.id;
      attributes.smartGrowerProductId = generalDetails.smartGrowerProductId
        ? generalDetails.smartGrowerProductId
        : null;
      attributes.smartGrowerCropId = generalDetails.smartGrowerCropId
        ? generalDetails.smartGrowerCropId
        : null;
      payload.productId = generalDetails.productId
        ? generalDetails.productId
        : null;
    } else if (
      props.actionType === ActionType.ADD_TRANSLATION ||
      props.actionType === ActionType.CREATE
    ) {
      attributes.smartGrowerProductId = null;
      attributes.smartGrowerCropId = null;
      payload.productId = null;
    }
    attributes.productSystemName = generalDetails.systemName;
    attributes.cropSystemName = generalDetails.crop;
    attributes.languageId = generalDetails.languageId;
    attributes.languageName = generalDetails.language;
    attributes.languageCode = generalDetails.languageCode;

    /** step-1 payload */
    // payload.productId = null;
    payload.productSystemName = generalDetails.systemName;
    payload.productTitle = generalDetails.productTitle;
    payload.cropSystemName = generalDetails.crop;
    payload.languageId = generalDetails.languageId;
    payload.languageName = generalDetails.language;
    payload.imagePath = generalDetails.media[0].path ?? generalDetails.image;
    payload.media = generalDetails.media;
    payload.category = generalDetails.category;
    payload.dosage = generalDetails.dosage;
    payload.whenToUse = generalDetails.whenToUse;
    payload.howToUse = generalDetails.howToUse;
    payload.benefit1 = generalDetails.benefit1;
    payload.benefit2 = generalDetails.benefit2;
    payload.benefit3 = generalDetails.benefit3;
    payload.applicableCrop = generalDetails.applicableCrop;

    /** step-2 payload */
    let dos: any = [];
    let donts: any = [];
    let displayOrderDo: number = 0;
    let displayOrderDont: number = 0;
    doAndDontList.map((item: any) => {
      if (item.type === DO_DONT[0]) {
        displayOrderDo += 1;
        let data: any = { ...item };
        data.displayOrder = displayOrderDo;
        delete data.type;
        dos.push(data);
      } else {
        displayOrderDont += 1;
        let data: any = { ...item };
        data.displayOrder = displayOrderDont;
        delete data.type;
        donts.push(data);
      }
    });
    payload.dos = dos;
    payload.donts = donts;
    /** step-3 payload */
    let stpet3Data: any = [];
    if (featureList && featureList.length) {
      [...featureList].map((item: any, index: number) => {
        item.displayOrder = index + 1;
        item.imagePath = removeSpacesImagePath(item.systemName, item.imagePath);
        stpet3Data.push(item);
      });
    }
    payload.keyFeatures = stpet3Data;
    /** step-4 payload */
    let stpet4Data: any = [];
    if (testimonialList && testimonialList.length) {
      [...testimonialList].map((item: any, index: number) => {
        item.displayOrder = index + 1;
        stpet4Data.push(item);
      });
    }
    payload.growerTestimonials = stpet4Data;
    /** step-5 payload */
    let stpet5Data: any = [];
    let threatData: any = [...targetThreatList];

    threatData.map((item: any, index: number) => {
      if (!item.stageThreatDisplayOrders) {
        item.stageThreatDisplayOrders = [
          {
            threatSystemName: item.systemName,
            stageSystemName: item.stages,
            cpDisplayOrder: "",
            cpThreatDisplayOrder: "",
          },
        ];
      }

      const stpet5DIndex = stpet5Data.findIndex(
        (data: any) => data?.systemName === item.systemName
      );

      if (stpet5DIndex > -1) {
        if (!stpet5Data[stpet5DIndex].stageThreatDisplayOrders)
          stpet5Data[stpet5DIndex].stageThreatDisplayOrders = [];

        const displayOrderIndex = stpet5Data[
          stpet5DIndex
        ].stageThreatDisplayOrders.findIndex(
          (data: any) =>
            data?.threatSystemName === item.systemName &&
            data?.stageSystemName === item.stages
        );

        if (displayOrderIndex > -1) {
          stpet5Data[stpet5DIndex].stageThreatDisplayOrders[displayOrderIndex] =
            item.stageThreatDisplayOrders[0];
        } else {
          stpet5Data[stpet5DIndex].stageThreatDisplayOrders = stpet5Data[
            stpet5DIndex
          ].stageThreatDisplayOrders.concat(item.stageThreatDisplayOrders);
        }

        return stpet5Data[stpet5DIndex].stages.push(item.stages);
      }

      item.displayOrder = index + 1;
      item.stages = [item.stages];
      return stpet5Data.push(item);
    });

    payload.threatStageMapping = stpet5Data;
    /** step-6 payload */
    let stpet6Data: any = [];
    [...recommendationList].map((item: any) => {
      // item.applicationOrder = index + 1;
      item.whenToUse = Number(item.whenToUse);
      stpet6Data.push(item);
    });
    payload.recommendations = stpet6Data;

    attributes.payload = payload;
    return attributes;
  };
  /** using same API method for SAVE/UPDATE CP */
  const saveUpdateCrop = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.save_update_cp;
    apiData.showLoader = true;
    apiData.payLoad = getCpPayload();
    return SaveUpdateCpProduct(apiData, successCallBack, errorCallback);
  };
  /** useEffects here: START */
  useEffect(() => {
    if (props.isEdit) {
      fetchCPDetails();
    } else {
      setPrimaryLanguage();
    }
  }, [props.isEdit]);

  useEffect(() => {
    if (
      props.isEdit &&
      generalDetails?.languageCode !== undefined &&
      generalDetails.languageCode !== storeData.primaryLangCode
    ) {
      fetchCPDetails(true);
    }
  }, [props.isEdit, generalDetails?.languageCode, storeData.primaryLangCode]);

  /** useEffects here: END */
  return (
    <Modal open={true} className={classes.modalHelper}>
      <Paper className={classes.formPaperHelper}>
        <Paper elevation={3} square sx={{ pb: "1rem" }}>
          <ModalHeader
            header={
              props.actionType === ActionType.ADD_TRANSLATION && !isPrimaryCP
                ? `Add Translation`
                : (props.actionType === ActionType.UPDATE ||
                    props.actionType === ActionType.ADD_TRANSLATION) &&
                  isPrimaryCP
                ? `Update Primary CP Product`
                : props.actionType === ActionType.UPDATE && !isPrimaryCP
                ? `Update CP Product`
                : `New CP Product`
            }
            onClose={() => {
              if (confirm("You may loose your data.")) {
                handleClosePopup();
              }
            }}
          />
          <StepperComponent
            steps={StagesToNewProduct}
            activeStep={activeStep}
            stepChangehandler={(step: number) => {
              if (props.isEdit && activeStep !== 1) {
                setActiveStep(step);
              }
            }}
          />
        </Paper>
        <Box width="100%">
          <div>{renderCpProductsStage(activeStep)}</div>
        </Box>
      </Paper>
    </Modal>
  );
};

export default CPCreateEdit;
