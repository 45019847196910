import * as Yup from "yup";

export const NewDashboardValidation = () =>
  Yup.object().shape({
    languageName: Yup.string().required("Please select language"),
    stateNames: Yup.string().required("States required"),
    // startDate: Yup.date().required('Date required').nullable(),
    // endDate: Yup.date().required('Date required').nullable(),
    mediatitle: Yup.string().required("Media Title required"),
    mediaType: Yup.string().required("Media Type required"),
    // mediaurl: Yup.string().when('mediaType', {
    //     is: (mediaType: string) => mediaType && mediaType === 'Video',
    //     then: Yup.string().required('Media URL required')
    //     .matches(/^(http(s)??\:\/\/)?(www\.)?((youtube\.com\/watch\?v=)|(youtu.be\/))([a-zA-Z0-9\-_])+$/g, 'Only youtube urls are accepted'),
    //     otherwise: Yup.string().optional()
    // }),
    image: Yup.string().when("mediaType", {
      is: "Image", // condition: when mediaType is 'Image'
      then: Yup.string().required("Image is required"), // apply this validation if condition is true
      otherwise: Yup.string(), // apply this validation if condition is false
    }),
    mediaurl: Yup.string().when("mediaType", {
      is: (mediaType: string) => mediaType && mediaType === "Video",
      then: Yup.string()
        .required("Media URL required")
        .matches(
          /^(?:https?:)?(?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]{7,15})(?:[\?&][a-zA-Z0-9\_-]+=[a-zA-Z0-9\_-]+)*(?:[&\/\#].*)?$/g,
          "Only youtube urls are accepted"
        )
        .max(255, "Media URL must not exceed 255 characters"),
      otherwise: Yup.string().optional(),
    }),
    displayOrder: Yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .max(2, "Must be a maximum 2 digits")
      .required("Display order is required."),
    category: Yup.string().required("Category required"),
  });

export const NewDashboardValidationOnResponse = () =>
  Yup.object().shape({
    languageName: Yup.string().required("Please select language"),
    stateName: Yup.string().required("States required"),
    title: Yup.string().required("Media Title required"),
    type: Yup.string().required("Media Type required"),
    displayOrder: Yup.number().required("Display order is required."),
    category: Yup.string().required("Category required"),
    feedurl: Yup.string().required("Media Url required"),
  });
