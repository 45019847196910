export const TRANSLATIONS_EN = {
  str_query_response: "Query Response",
  str_search: "Search",
  str_data_list: "Data List",
  str_query_ID: "Query ID",
  str_crop: "Crop",
  str_category: "Category",
  str_phone_no: "Phone No.",
  str_user_message: "User Message",
  str_user_media: "User Media \n(Image/Audio)",
  str_received_on: "Received on",
  str_aging_days: "Aging Days",
  str_status: "Status",
  str_rating: "User Rating",
  str_action: "Action",
  str_view: "View",
  str_respond: "Respond",
  str_filter: "Filters",
  str_sort: "Sort",
  str_view_image: "View Image",
  str_view_video: "View Video",
  str_image: "Image",
  str_video: "Video",
  str_audio: "Audio",
  str_export_excel: "Export to excel",
  str_delete: "Delete",
  str_replace_media: "Replace Media",
  str_upload_media: "Upload Media",
  str_support_reply: "Support Reply",
  str_ascending: "Ascending",
  str_decending: "Decending",
  str_clear: "Clear",
  str_apply: "Apply",
  str_date_time: "Date & Time : ",
  str_support_message: "Support Response:",
  str_grower_state: "Grower State",
  str_grower_dist: "Grower Dist.",
  str_grower_message: "Grower Message: ",
  str_type_here: "Type here",
  str_response: "Response",
};
