import React from "react";
import { Button, FormControl, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector, useDispatch } from "react-redux";
import api_endpoints, { base_url } from "../../Api/end_points";
import {
  fetchConfigModules,
  fetchCountryModules,
  fetchCropsList,
  fetchLanguagesList,
  fetchMobileLengthNurseryData,
  fetchNotificationTypes,
  getApplicationConfig,
  getDefaultLanguage,
} from "../../Api/generic_apicalls";
import { IRequestBody } from "../../Constants/interfaces";
import { RootState } from "../../redux/reducers";
import appActions from "../../redux/action";
import { useStyles } from "../Login/loginStyles";
import Greetings from "../../Layouts/Greetings";

type CountryList = {
  baseUrl: string;
  countryCode: string;
  countryName: string;
};

const CountrySelection: React.FC = (props) => {
  const storeProps = useSelector((state: RootState) => {
    return {
      token: state.auth.authToken,
      countryModules: state.generic.ListOfModules,
    };
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const [countryList, setCountryList] = React.useState<Array<CountryList>>([]);
  const [selectedCountry, setSelectedCountry] = React.useState<string>("");
  const [, toggleAppLoader] = React.useState(true);

  const loadAppMetaData = () => {
    const countryInfo = selectedCountry.split(":::");
    getDefaultLanguage(countryInfo[1])
      .then((response: any) => {
        dispatch(appActions.apiData.userLanguage(response.data));
        dispatch(appActions.apiData.userCountry(countryInfo[1]));
        Promise.all([
          fetchLanguagesList(dispatch),
          fetchCropsList(dispatch),
          fetchNotificationTypes(dispatch),
          fetchCountryModules(countryInfo[1], dispatch),
          fetchMobileLengthNurseryData(countryInfo[1], dispatch),
        ])
          .then(async (values) => {
            dispatch(appActions.apiData.baseURL(countryInfo[0]));
          })
          .catch((err) => {
            throw new Error(err.message);
          });
      })
      .catch(() => { })
      .finally(() => {
        toggleAppLoader(false);
      });
  };

  const fetchApplicationConfig = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.app_config;
    apiData.showLoader = false;
    apiData.customHeaders = {
      Authorization: `Bearer ${storeProps.token}`,
    };
    getApplicationConfig(apiData)
      .then((response: any) => {
        dispatch(appActions.apiData.appCountries(response.data));
        setCountryList(response.data);
        console.log(response.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const onCountrySelect = (data: SelectChangeEvent) => {
    setSelectedCountry(data.target.value);
  };

  React.useEffect(() => {
    fetchApplicationConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const countryInfo = selectedCountry.split(":::");
    if (storeProps.countryModules.length > 0) {
      fetchConfigModules(countryInfo[1], dispatch, storeProps.countryModules);
    }
  }, [storeProps.countryModules, selectedCountry]);

  return (
    <Greetings>
      <FormControl variant="standard" fullWidth>
        <Typography fontSize={14}>Please select your country.</Typography>
        <FormControl variant="standard" fullWidth>
          <Typography
            className={classes.emailLabelHelper}
            variant="subtitle2"
          >
            Select Country *
          </Typography>
          <Select
            className={classes.countrySelectionHelper}
            value={selectedCountry}
            data-testid="country-select"
            MenuProps={{ classes: { paper: classes.Select } }}
            onChange={(event: SelectChangeEvent) => onCountrySelect(event)}
            IconComponent={() => (
              <ExpandMoreIcon className={classes.selectExpandMoreHelper} />
            )}
            variant="outlined"
          >
            {countryList?.map((o) => (
              <MenuItem key={o.countryCode} value={`${o.baseUrl}:::${o.countryCode}`}>
                {o.countryName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormControl>
      <Button
        color="success"
        className={classes.signInButtondHelper}
        variant="contained"
        disabled={selectedCountry === ""}
        onClick={loadAppMetaData}
      >
        <Typography fontSize={12} variant="subtitle2">
          Proceed
        </Typography>
      </Button>
    </Greetings>
  );
};

export default CountrySelection;
