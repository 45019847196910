export type TableHeaderTypes = {
  HeaderName: string;
  FieldName: string;
  isBoolean?: boolean;
  truthLabel?: string;
  falseLabel?: string;
  callback?: (content?: any, rowData?: any) => any;
};

export const CropGrowthStages: TableHeaderTypes[] = [
  {
    HeaderName: "Stage Image",
    FieldName: "imagePath",
  },
  {
    HeaderName: "Stage Title",
    FieldName: "title",
  },
  {
    HeaderName: "System Name",
    FieldName: "systemName",
  },
  {
    HeaderName: "Display Order",
    FieldName: "displayOrder",
  },
  {
    HeaderName: "Description",
    FieldName: "description",
  },
];

export const FilterKeys: TableHeaderTypes[] = [
  {
    HeaderName: "Filter Key System Name",
    FieldName: "systemName",
  },
  {
    HeaderName: "Display Label",
    FieldName: "filterName",
  },
  {
    HeaderName: "Display Order",
    FieldName: "displayOrder",
  },
  {
    HeaderName: "Filter Value",
    FieldName: "filterValue",
  },
];

export const CropCalendar: TableHeaderTypes[] = [
  {
    HeaderName: "Calendar System Name",
    FieldName: "calendarName",
  },
  {
    HeaderName: "Calendar Title",
    FieldName: "calendarName",
  },
  {
    HeaderName: "Farming Practices",
    FieldName: "practice",
  },
  {
    HeaderName: "Crop Duration",
    FieldName: "duration",
  },
  {
    HeaderName: "Date Of Sowing",
    FieldName: "isDosApplicable",
    isBoolean: true,
    truthLabel: "Applicable",
    falseLabel: "Not Applicable",
  },
  {
    HeaderName: "Date Of Transplant",
    FieldName: "isDotApplicable",
    isBoolean: true,
    truthLabel: "Applicable",
    falseLabel: "Not Applicable",
  },
  {
    HeaderName: "Is Generic?",
    FieldName: "generic",
    isBoolean: true,
    truthLabel: "Yes",
    falseLabel: "No",
  },
];

export const CropStageCalendar: TableHeaderTypes[] = [
  {
    HeaderName: "Calendar System Name",
    FieldName: "calendarName",
  },
  {
    HeaderName: "Stage System Name",
    FieldName: "stageSystemName",
  },
  {
    HeaderName: "Applicable",
    FieldName: "applicable",
    isBoolean: true,
    truthLabel: "Yes",
    falseLabel: "No",
  },
  {
    HeaderName: "Start Day",
    FieldName: "startDay",
  },
  {
    HeaderName: "End Day",
    FieldName: "endDay",
  },
  {
    HeaderName: "Reference",
    FieldName: "reference",
  },
  {
    HeaderName: "Display Order",
    FieldName: "displayOrder",
  },
];
