import { Box, Divider, Modal, Paper, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ModalHeader from '../../../Common/Modal/ModalHeader';
import { TriggerToastMessage } from '../../../Utils/toastTrigger';
import { viewDashboardMedia } from '../DashboardMediaApiCalls';
import { useStyles } from '../style';
import api_endpoints, { base_url } from './../../../Api/end_points';
import { IRequestBody } from '../../../Constants/interfaces';

export type ViewDashboardProps = {
  isDraftRecord: boolean,
  dashboardMediaDetails: any,
  onCloseViewDashboard: Function,
  canShowEditButton: boolean,
  onEditClick: Function,
};

type ViewDashboardPropsTypes = {
  fieldLabel: string;
  fieldName: string;
};

export const dashboardPreview: Array<ViewDashboardPropsTypes> = [

  {
    fieldLabel: 'Language',
    fieldName: 'languageName'
  },
  {
    fieldLabel: 'State',
    fieldName: 'stateName'
  },
  {
    fieldLabel: 'Media Type',
    fieldName: 'type'
  },
  {
    fieldLabel: 'Media Title',
    fieldName: 'title'
  },
  {
    fieldLabel: 'Scheduled Date',
    fieldName: 'scheduleDate'
  },
  {
    fieldLabel: 'Media URL',
    fieldName: 'feedurl'
  },
  {
    fieldLabel: 'Display Order',
    fieldName: 'displayOrder'
  },
  {
    fieldLabel: 'Category',
    fieldName: 'category'
  },

];

interface IRenderFieldProps extends ViewDashboardPropsTypes {
  data: any;

}

const getLabel = (label: string) => {
  return (
    <Typography variant='subtitle2' fontSize={14} marginY='1rem'>
      {label}
    </Typography>
  );
};

const RenderField = (props: IRenderFieldProps) => {
  const { fieldName, fieldLabel, data } = props;
  const classes = useStyles();

  switch (fieldName) {


    case 'stateNames':
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography fontSize={14} className={classes.testWrapControl} >
            {data && data[fieldName]}
          </Typography>
          <Divider />
        </>
      );

    case 'feedUrl':
      return (
        <>
          <Stack direction='row' spacing={10} >
            {getLabel(fieldLabel)}
            <div className={classes.viewImage}>
              {data && data.imageUrl && <img src={data.imageUrl} alt={'push img'} />}
            </div>
          </Stack>
          <Divider />
        </>
      );
    default:
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography fontSize={14} variant='body1' className={classes.testWrapControl}>
            {data && data[fieldName]}
          </Typography>
          <Divider />
        </>
      );
  }
};

const ViewDashboard: React.FC<ViewDashboardProps> = (props) => {
  const classes = useStyles();
  // states
  const [showViewDashboard, setViewDashboard] =
    useState<boolean>(true);
  const [dataToDisplayDashboard, setDataToDisplayDashboard] = useState<any>({});
  // variables
  const { dashboardMediaDetails } = props;

  const handleClose = () => {
    setViewDashboard(false);
    props.onCloseViewDashboard();
  };

  const getMediaDetailsByID = (feedId: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.view_dashboard_media.replace('__FEEDID__', feedId);
    if (props.isDraftRecord) {
      apiData.endPoint = api_endpoints.get_draft_media_content.replace('__MEDIA_ID__', feedId);
    }
    apiData.showLoader = true;
     return viewDashboardMedia(apiData, successCallBack, errorCallback);

  };
  const successCallBack =  (response: any, type: string) => {
    if (response.statusCode === 200) {
      setDataToDisplayDashboard(response?.data.payload);
    }

  };
  const errorCallback = (error: any, type: string) => {
    /**Generic alert to display API fail */
    TriggerToastMessage('Unable to process your request', 'error');
  };

  useEffect(() => {
    getMediaDetailsByID(dashboardMediaDetails.id)
  }, []);

  return (
    <Modal
      open={showViewDashboard}
      className={classes.formModal}
    >
      <Paper className={classes.formPaper} >
        <ModalHeader
          key={dataToDisplayDashboard.type}
          header={`${dataToDisplayDashboard.type || props.dashboardMediaDetails.dataType}: ${props.dashboardMediaDetails.title}`}
          showEdit={props.canShowEditButton}
          onClose={() => {
            handleClose();
          }}
          editHandler={props.onEditClick}
        />
        <Box className={classes.formContainer}>
          {/* Checkbox */}
          <Box className={classes.formFieldsHelper}>
            <Box>
              {dashboardPreview.map(
                (dashboardMedia: ViewDashboardPropsTypes) => {
                  return (
                    <>
                      <RenderField
                        fieldName={dashboardMedia.fieldName}
                        fieldLabel={dashboardMedia.fieldLabel}
                        data={dataToDisplayDashboard}
                      />
                    </>
                  );
                }
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ViewDashboard;
