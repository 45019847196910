import * as Yup from "yup";

export const PlantixCPValidationSchema = () =>
  Yup.object().shape({
    languageId: Yup.string(),
    languageName: Yup.string().required("Language required"),
    imagePath: Yup.string().required("Upload Plantix image").nullable(),
    productTitle: Yup.string()
      .required("Product Title required")
      .max(100, "Title name should be a max of 100 characters"),
    productSystemName: Yup.string()
      .required("Product System Name required")
      .max(100, "System name should be a max of 100 characters"),
    category: Yup.string()
      .max(100, "Category should be a max of 100 characters")
      .nullable(),
    cropSystemName: Yup.string(),
    dosage: Yup.string()
      .required("Dosage required")
      .max(100, "Dosage should be a max of 100 characters"),
    whenToUse: Yup.string()
      .max(500, "When To Use cannot exceed 500 characters")
      .required("When to Use required"),
    howToUse: Yup.string()
      .max(500, "How to Apply cannot exceed 500 characters")
      .required("How to Apply required")
      .nullable(),
    applicableCrop: Yup.string(),
    benefit1: Yup.string()
      .required("Benefit 1 required")
      .max(1000, "Benefit 1 should be a max of 1000 characters"),
    benefit2: Yup.string()
      .max(1000, "Benefit 2 should be a max of 1000 characters")
      .nullable(),
    benefit3: Yup.string()
      .max(1000, "Benefit 3 should be a max of 1000 characters")
      .nullable(),
  });
