import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  viewButtonHelper: {
    padding: 0,
    color: "#14803C !important",
    fontWeight: "400 !important",
    textDecoration: "underline !important",
  },
  viewImage: {
    background: "#f2f2f2",
    marginBottom: "1rem !important",
    width: "120px",
    height: "120px",
    "& img": {
      width: "100%",
    },
  },
  panelBoxHelper: {
    display: "flex",
    alignItems: "center",
    width: "-webkit-fill-available",
    margin: "8px 0",
    padding: "0 1.5rem",
  },
  chipBoxHelper: {
    margin: "8px 0",
    display: "inline-flex",
    width: "-webkit-fill-available",
    padding: "0 1.5rem",
  },
});
