import { Box, Modal, Paper, Stack, TextField, Typography } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TableComponent } from "../../../Common/BasicDataTable/BasicDataTable";
import CustomTabs from "../../../Common/CustomTabs";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { RootState } from "../../../redux/reducers";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { StagesToCreateThreat } from "../stepperStages";
import { useStyles } from "../style";
import { GetThreatStageDetails } from "../ThreatsApiCalls";
import { ThreatDetailsStages, ThreatStages } from "./TableHeaders";

const ViewThreat: React.FC<any> = (props) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [threatDetails, setThreatDetails] = useState<any>({});
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic.ListOfLanguages.languageList,
      primaryLanguageCode: state.auth.languageCode,
    };
  });

  // variables
  const classes = useStyles();

  const handleClose = () => {
    props.onCloseViewNotification();
  };

  const getStepStages = () => {
    return StagesToCreateThreat.map((stepObj: any) => {
      return stepObj.stepLabel;
    });
  };

  const changeActiveTab = (dataType = "", newValue: number) => {
    setActiveTab(newValue);
  };

  const threatStageInfo = (response: any) => {
    let data: any = response.payload;
    let updatedGeneralDetails: any = [];
    data.threatDetailsList.forEach((row: any) => {
      let obj = {
        threatId: row.threatId,
        displayOrder: row.displayOrder,
        imagePath: row.threatImagePath,
        threatFaq: row.threatFaq.map((e: any) => (
          <Stack>
            <Typography
              variant="body2"
              fontWeight={600}
            >{`Title: ${e.header}`}</Typography>
            <Typography variant="caption">{`Response: ${e.text}`}</Typography>
          </Stack>
        )),
        threatSystemName: row.threatSystemName,
        threatDisplayLabel: row.threatDisplayLabel,
      };
      updatedGeneralDetails.push(obj);
    });
    const threatStagesArray: any[] = [];
    // let selectedLanguage: any = storeData.languages.filter((language: LanguageRecordType) => language.languageId === response.languageId);
    data.stages.map((stageObj: any) => {
      stageObj.threats.map((threatObj: any) => {
        const obj: any = {
          threatSystemName: threatObj.systemName,
          stageSystemName: stageObj.stageSystemName,
          stageId: stageObj.stageId,
          threatId: threatObj.id,
          stageDisplayOrder: stageObj.displayOrder,
          threatDisplayOrder: threatObj.displayOrder,
        };
        threatStagesArray.push(obj);
      });
    });

    const ThreatInfoObj = {
      languageName: data.languageName,
      cropName: data.cropSystemName,
      GeneralDetailsFAQ: _.sortBy(updatedGeneralDetails, "displayOrder"),
      threatStage: _.sortBy(
        _.sortBy(threatStagesArray, "threatDisplayOrder"),
        "stageDisplayOrder"
      ),
    };
    setThreatDetails({ ...ThreatInfoObj });
  };

  const renderGeneralDetailsTab = () => {
    // const classes = useStyles();
    return (
      <>
        <Box className={classes.formContainer}>
          <Box className={classes.formFieldsViewHelper}>
            <Box className={classes.formFieldsLanguageHelperOne}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                justifyContent="space-around"
              >
                <LanguageDropdown
                  isDisabled={true}
                  selectedLanguage={threatDetails?.languageName}
                  key={threatDetails?.languageName}
                />
                <TextField
                  variant="outlined"
                  value={threatDetails?.cropName}
                  key={threatDetails?.cropName}
                />
              </Stack>
            </Box>
          </Box>
          <TableComponent
            headers={[...ThreatDetailsStages]}
            data={threatDetails.GeneralDetailsFAQ || []}
          />
        </Box>
      </>
    );
  };

  const renderThreatStages = () => {
    return (
      <>
        <Box className={classes.threadStagesTableContainer}>
          <TableComponent
            headers={[...ThreatStages]}
            data={threatDetails.threatStage || []}
          />
        </Box>
      </>
    );
  };

  const fetchthreatDetails = () => {
    const payload = _.cloneDeep(props);
    const languageObj: any = _.find(storeData.languages, {
      languageId: payload.details.languageId,
    });
    payload.details.languageCode = languageObj?.languageCode;
    GetThreatStageDetails(payload)
      .then((response: any) => {
        if (response.statusCode === 200) {
          threatStageInfo(response.data);
          return;
        }
        TriggerToastMessage("Unable to fetch data", "error");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchthreatDetails();
  }, []);
  return (
    <Modal
      open={true}
      disableEscapeKeyDown={true}
      className={classes.modalStylesHelper}
    >
      <Paper className={classes.formPaper}>
        <Paper elevation={3} square>
          <ModalHeader
            header={`Crop: ${threatDetails?.cropName}`}
            onClose={() => {
              handleClose();
            }}
            showEdit={props.canShowEditButton}
            editHandler={() => props.editHandler(threatDetails)}
          />
          <Box>
            <CustomTabs
              onChange={changeActiveTab}
              tabsList={getStepStages()}
              value={activeTab}
            />
          </Box>
        </Paper>
        <Box className={classes.viewCropFormContentHelper}>
          {activeTab === 0 && renderGeneralDetailsTab()}
          {activeTab === 1 && renderThreatStages()}

          {/* <LanguageDropdown selectedLanguage={'IN'} /> */}
        </Box>
      </Paper>
    </Modal>
  );
};

export default ViewThreat;
