import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#14803C !important',
      color: '#FFFFFF'
    },
    '& .MuiPagination-ul':{
      justifyContent:'center',

    }
  }
});
