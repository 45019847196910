import AddIcon from "@mui/icons-material/Add";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import React, { MouseEvent, MouseEventHandler } from "react";

export const DELETE_TYPE: string = "DELETE";
export const EXPORT_TYPE: string = "EXPORT";
export const CREATE_TYPE: string = "CREATE";
export const CANCEL_TYPE: string = "CANCEL";
export const MODAL_CANCEL_TYPE: string = "MODAL CANCEL";
export const MODAL_CREATE_TYPE: string = "MODAL CREATE";
export const PUBLISH_TYPE: string = "PUBLISH";
export const CANCEL_NOTIFICATION_TYPE: string = "CANCEL_NOTIFICATION_TYPE";

type ButtonType =
  | typeof DELETE_TYPE
  | typeof EXPORT_TYPE
  | typeof CREATE_TYPE
  | typeof CANCEL_TYPE
  | typeof MODAL_CREATE_TYPE
  | typeof MODAL_CANCEL_TYPE
  | typeof PUBLISH_TYPE;

interface IButtonWithIconProps {
  label: string;
  onClick?: Function;
  showActionProgress?: boolean;
  showCreateProgress?: boolean;
  type?: ButtonType;
}

enum ColorType {
  COLOR_SUCCESS = "success",
  COLOR_ERROR = "error",
  COLOR_PRIMARY = "primary",
  COLOR_INFO = "info",
}
enum BtnVariant {
  BTN_OUTLINED = "outlined",
  BTN_CONTAINED = "contained",
  BTN_TEXT = "text",
}

type ButtonProps = {
  children?: React.ReactNode;
  color:
    | ColorType.COLOR_ERROR
    | ColorType.COLOR_SUCCESS
    | ColorType.COLOR_PRIMARY
    | ColorType.COLOR_INFO;
  label: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  variant:
    | BtnVariant.BTN_CONTAINED
    | BtnVariant.BTN_OUTLINED
    | BtnVariant.BTN_TEXT;
};

const GetButton = (props: ButtonProps) => {
  return (
    <Button
      color={props.color}
      sx={{ textTransform: "unset", whiteSpace: "nowrap" }}
      variant={props.variant}
      startIcon={props.children}
      onClick={props.onClick}
    >
      <Typography fontSize={14} variant="subtitle2">
        {props.label}
      </Typography>
    </Button>
  );
};
const GetModalButton = (props: ButtonProps) => {
  return (
    <Button
      color={props.color}
      sx={{ textTransform: "unset", whiteSpace: "nowrap", height: "44px" }}
      variant={props.variant}
      startIcon={props.children}
      onClick={props.onClick}
    >
      <Typography fontSize={12} variant="subtitle2">
        {props.label}
      </Typography>
    </Button>
  );
};

const ButtonWithIcon: React.FC<IButtonWithIconProps> = ({
  label,
  type,
  showActionProgress,
  showCreateProgress,
  onClick,
}) => {
  const handleButtonClick = (event: MouseEvent) => {
    event.preventDefault();
    onClick!();
  };

  switch (type) {
    case DELETE_TYPE:
      return (
        <GetButton
          color={ColorType.COLOR_ERROR}
          label={label}
          variant={BtnVariant.BTN_CONTAINED}
          onClick={handleButtonClick}
        >
          {(showActionProgress && (
            <CircularProgress size={16} color="inherit" />
          )) ||
            CustomDeleteIcon()}
        </GetButton>
      );
    case EXPORT_TYPE:
      return (
        <GetButton
          color={ColorType.COLOR_SUCCESS}
          label={label}
          onClick={handleButtonClick}
          variant={BtnVariant.BTN_CONTAINED}
        >
          <FileDownloadOutlinedIcon />
        </GetButton>
      );
    case CREATE_TYPE:
      return (
        <GetButton
          color={ColorType.COLOR_SUCCESS}
          label={label}
          onClick={handleButtonClick}
          variant={BtnVariant.BTN_CONTAINED}
        >
          {showCreateProgress && <CircularProgress size={16} color="inherit" />}
        </GetButton>
      );
    case CANCEL_TYPE:
      return (
        <GetButton
          color={ColorType.COLOR_INFO}
          label={label}
          onClick={handleButtonClick}
          variant={BtnVariant.BTN_OUTLINED}
        >
          {showCreateProgress && <CircularProgress size={16} color="inherit" />}
        </GetButton>
      );
    case CANCEL_NOTIFICATION_TYPE:
      return (
        <GetButton
          color={ColorType.COLOR_ERROR}
          label={label}
          variant={BtnVariant.BTN_CONTAINED}
          onClick={handleButtonClick}
        >
          {(showActionProgress && (
            <CircularProgress size={16} color="inherit" />
          )) || <HighlightOffIcon />}
        </GetButton>
      );
    case MODAL_CANCEL_TYPE:
      return (
        <GetModalButton
          color={ColorType.COLOR_INFO}
          label={label}
          onClick={handleButtonClick}
          variant={BtnVariant.BTN_OUTLINED}
        >
          {<AddIcon />}
        </GetModalButton>
      );
    case MODAL_CREATE_TYPE:
      return (
        <GetModalButton
          color={ColorType.COLOR_SUCCESS}
          label={label}
          onClick={handleButtonClick}
          variant={BtnVariant.BTN_CONTAINED}
        ></GetModalButton>
      );
    case PUBLISH_TYPE:
      return (
        <GetButton
          color={ColorType.COLOR_SUCCESS}
          label={label}
          onClick={handleButtonClick}
          variant={BtnVariant.BTN_CONTAINED}
        >
          {showCreateProgress && <CircularProgress size={16} color="inherit" />}
          <PublishOutlinedIcon />
        </GetButton>
      );
    default:
      return (
        <GetButton
          color={ColorType.COLOR_SUCCESS}
          variant={BtnVariant.BTN_CONTAINED}
          label={label}
          onClick={handleButtonClick}
        ></GetButton>
      );
  }
};

export default ButtonWithIcon;

export const CustomDeleteIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 3H12.5"
        stroke="white"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 3V2H7.5M10.5 2.5V2H7.5M7.5 2V0.5M4 3.5L5 11.5H10L11 3.5"
        stroke="white"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 5.5V9"
        stroke="white"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 5.5V9"
        stroke="white"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  );
};
