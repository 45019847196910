export type PushNotificationColumnType = {
  fieldLabel: string;
  fieldName: string;
};

export const pushNotificationsColumn: Array<PushNotificationColumnType> = [
  {
    fieldLabel: 'ID',
    fieldName: 'id'
  },
  {
    fieldLabel: 'Type',
    fieldName: `notificationType`
  },
  {
    fieldLabel: 'Language',
    fieldName: 'languageName'
  },
  {
    fieldLabel: 'Geography',
    fieldName: 'Geography'
  },
  {
    fieldLabel: 'Scheduled Date',
    fieldName: 'ScheduleDate'
  },
  {
    fieldLabel: 'Message',
    fieldName: 'message'
  },
  {
    fieldLabel: 'Status',
    fieldName: 'pushStatus',
  },
];

export const PublishedPushNotificationColumn: Array<PushNotificationColumnType> = [
  {
    fieldLabel: 'Notification Title',
    fieldName: 'notificationTitle'
  },
  {
    fieldLabel: 'Published On',
    fieldName: 'modifiedDate'
  },
  {
    fieldLabel: 'Status',
    fieldName: 'statusId'
  },
  {
    fieldLabel: 'Frequency',
    fieldName: 'frequency'
  }
];

export const SentPushNotificationTableColumn: Array<PushNotificationColumnType> = [
  {
    fieldLabel: 'Notification Title',
    fieldName: 'title'
  },
  {
    fieldLabel: 'Sent On',
    fieldName: 'triggeredDate'
  },
  {
    fieldLabel: 'Receiver Count',
    fieldName: 'receiverCount'
  },
  {
    fieldLabel: 'Frequency',
    fieldName: 'frequency'
  }
];