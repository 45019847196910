import { makeStyles } from '@mui/styles';
import { ASSETS_PATH } from '../../Constants/contants';

export const useStyles = makeStyles({
  contentHelper: {
    backgroundImage: `url(${ASSETS_PATH}Login_Background.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    position: 'absolute',
    width: '100%',
    backgroundPosition: 'center center'
  },
  paperHelper: {
    marginInline: 'auto',
    width: '564px',
    height: '320px',
    marginTop: '2rem'
  },
  innerContentHelper: {
    textAlign: 'center',
    paddingTop: 30,
    paddingLeft: 40,
    paddingRight: 40
  },
  iconHelper: {
    height: 'auto !important',
    width: 'auto !important'
  },
  loginContainerHelper: {
    width: 'max-content', marginInline: 'auto', marginTop: '1rem'
  },
});
