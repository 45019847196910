import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import React from 'react';

export type CheckboxWithLabelProps = {
  checked: boolean;
  label: string;
  name: string;
  onChange: Function;
};

const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = (props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    props.onChange(name, checked);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          name={props.name}
          sx={{ p: 0.5 }}
          size='small'
          checked={props.checked}
          onChange={handleChange}
        />
      }
      label={
        <Typography
          style={{ lineHeight: 'unset', paddingLeft: 8, fontSize: '14px' }}
          variant='body2'
        >
          {props.label}
        </Typography>
      }
    />
  );
};

export default CheckboxWithLabel;
