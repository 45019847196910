import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  emailLabelHelper: {
    textAlign: 'start'
  },
  signInButtondHelper: {
    textTransform: 'unset !important' as 'unset',
    width: '-webkit-fill-available',
    margin: '30px 80px !important',
    height: '40px'
  },
  Select: {
    '& ul': {
      maxHeight: '20vh'
    }
  },
  countrySelectionHelper:{
    height: '40px',
    background: '#F3F4F6',
    color: '#696F88',
    textAlign: 'left'
  },
  selectExpandMoreHelper:{
    position: 'absolute', right: '3px'
  }
});
