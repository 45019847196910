import React, { lazy, Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthRoutes from "../../Routes/authRoutes";
import { useStyles } from "./style";

const Header = lazy(() => import("../../Common/Header"));
const AppBar = lazy(() => import("../../Common/AppBar"));

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const [selectedMenu, setSelectedMenu] = useState<string>("/");
  const navigate = useNavigate();

  const handleActionClick = (action: string) => {
    sessionStorage.setItem("currentMenu", action);
    setSelectedMenu(action);
    navigate(action, { replace: true });
  };

  React.useEffect(() => {
    if (sessionStorage.getItem("currentMenu")) {
      setSelectedMenu(sessionStorage.getItem("currentMenu")!);
    }
  }, []);

  return (
    <div>
      <Suspense fallback={null}>
        <Header />
      </Suspense>
      <div className={classes.containerHelper}>
        <div>
          <Suspense fallback={null}>
            <AppBar
              onSelectMenu={handleActionClick}
              selectedMenu={selectedMenu}
            />
          </Suspense>
        </div>
        <>
          <AuthRoutes />
        </>
      </div>
    </div>
  );
};

export default Dashboard;
