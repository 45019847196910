import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useState } from "react";
import RespondView from "./RespondView";
import { useStyles } from "./style";

type RespondQueryModalProps = {
  header: any;
  onClose: Function;
  showEdit?: boolean;
  showDelete?: boolean;
  QueryInRespond: any;
  OnReplySuccess: Function;
};

const RespondQueryModal: React.FC<RespondQueryModalProps> = (
  props: RespondQueryModalProps
) => {
  const classes = useStyles();
  const [isFill, setIsFill] = useState<boolean>();

  return (
    <>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 2, py: 3, backgroundColor: "#EAF6FF" }}
        >
          <TableContainer sx={{ pl: 1, width: "75%" }}>
            <Table>
              <TableHead>
                <TableRow>
                  {props.header.map((item: any) => {
                    return (
                      <TableCell style={{ borderBottom: "none", padding: 0 }}>
                        {item}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ borderBottom: "none", padding: 0 }}>
                    {props.QueryInRespond?.queryId}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none", padding: 0 }}>
                    {`+${props.QueryInRespond?.phoneNumber}`}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none", padding: 0 }}>
                    {props.QueryInRespond?.growerState}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none", padding: 0 }}>
                    {props.QueryInRespond?.growerDistrict}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none", padding: 0 }}>
                    {props.QueryInRespond?.name}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none", padding: 0 }}>
                    {props.QueryInRespond?.category}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box>
            <IconButton
                data-testid="close-button"
              className={classes.IconStyle}
              onClick={() => {
                if(isFill){
                  if(confirm('You may loose your data.')){
                    props.onClose();
                  }
                }else{
                  props.onClose();
                }
              }}
            >
              <CloseIcon fontSize="small" color="action" />
            </IconButton>
          </Box>
        </Stack>
      </Box>
      <RespondView
        setIsFill={setIsFill}
        queryItem={props.QueryInRespond}
        onClose={props.onClose}
        OnReplySuccess={props.OnReplySuccess}
      />
    </>
  );
};

export default RespondQueryModal;
