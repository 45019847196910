import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles({
  Select: {
    '& ul': {
      maxHeight: '20vh'
    }
  }
});

type MultiSelectProps = {
  labelKey: string;
  name: string;
  onCheckboxChange: Function;
  onClearSelection: Function;
  options: Array<any>;
  selectedOptions: Array<string>;
  showClearAll: boolean;
  isDisabled?: boolean;
};

const MultiSelect: React.FC<MultiSelectProps> = ({
  name,
  options,
  selectedOptions,
  labelKey,
  onCheckboxChange,
  onClearSelection,
  isDisabled
}) => {
  const classes = useStyles();
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onCheckboxChange(event);
  };

  const handleClearSelection = () => {
    onClearSelection();
  };

  return (
    <Select
      key={name}
      name={name}
      multiple
      value={selectedOptions}
      MenuProps={{ classes: { paper: classes.Select } }}
      renderValue={(value) => value?.join(",")}
      style={isDisabled ? { backgroundColor: '#F3F4F6' } : {}}
      disabled={isDisabled || false}
      IconComponent={() => <ExpandMoreIcon sx={{ position: 'absolute', right: '3px' }} />}
    >
      {options.map((option: any, index: number) => {
        return (
          <div key={index}>
            <MenuItem value={option[labelKey]}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ p: 0.5 }}
                    size='small'
                    value={option[labelKey]}
                    checked={option.checked}
                    onChange={handleCheckboxChange}
                  />
                }
                label={
                  <Typography style={{ lineHeight: 'unset' }} fontSize={14}>
                    {option[labelKey] ? option[labelKey] : option['stateName']}
                  </Typography>
                }
              />
            </MenuItem>
            {index === options.length - 1 && (
              <>
                <Divider />
                <Button data-testid="clear-all-selection" fullWidth onClick={handleClearSelection}>
                  <Typography align='center' variant='subtitle2' color='black'>
                    Clear all
                  </Typography>
                </Button>
              </>
            )}
          </div>
        );
      })}
    </Select>
  );
};

export default MultiSelect;
