import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ErrorMessage, Field, Form, Formik, useFormikContext } from "formik";
import _ from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { api_service } from "../../../Api/api_service";
import api_endpoints, { base_url } from "../../../Api/end_points";
import { StateListType } from "../../../Common/Constants/types";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import { IRequestBody } from "../../../Constants/interfaces";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { GeneralSchemaValidation } from "../CropValidationSchema";
import { useStyles } from "../useStyle";
import { SELECT_ALL } from "./";
import MultiSelect from "./../../../Common/MultiSelect";
import {
  ActionType,
  ModuleImageUpload,
  STEPS,
  ValidationType,
} from "../../../Common/Constants";
import {
  genericValidateImage,
  genericFormFieldValidation,
} from "../../../Utils/fileHelper";
import {handleStateChangeUtility} from "../../../Utils/handleSelection";

export type GeneralDetailsProps = {
  isEdit?: boolean;
  language: string | undefined;
  imageUrl: string | Blob | undefined;
  cropTitle: string | undefined;
  systemName: string | undefined;
  selectedStates: Array<string>;
  // formRef?: any;
  onNext: Function;
  getFileURL: Function;
  languageId: number;
  languageCode: string;
  stateList: Array<StateListType>;
  // doStateAPICall: Function;
  isPrimaryCrop: boolean;
  actionType: string;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  baseLanguage?: string;
};
export type LangObjectType = {
  languageId: number | undefined;
  languageName: string;
  languageCode: string;
};
const AutoSubmitToken = () => {
  // Grab values and submitForm from context
  const { values, submitForm } = useFormikContext();
  React.useEffect(() => {
    //console.log(isValid);
  }, [values, submitForm]);
  return null;
};
const Input = styled("input")({
  display: "none",
});
export const GeneralDetailsForm: React.FC<GeneralDetailsProps> = ({
  ...props
}: GeneralDetailsProps) => {
  const [selectedLangObj, setSelectedLangObj] = useState<LangObjectType>({
    languageId: undefined,
    languageName: "",
    languageCode: "",
  });
  const [imageFile, setImageFile] = useState<any>(props.imageUrl);
  const [stateList, setStateList] = useState<Array<StateListType>>(
    props.stateList
  );
  const [selectedStates, setSelectedStates] = useState<Array<any>>([]);
  let selectedStatesObj: any = [];
  const classes = useStyles();
  /**
   * handling language selection
   * @param language
   */
  const handleLanguageChange = (language: string) => {
    let langArrData = language.split("##");
    let langData: LangObjectType = {
      languageId: parseInt(langArrData[0]),
      languageName: langArrData[1],
      languageCode: langArrData[2],
    };
    setSelectedLangObj({ ...langData });
    // props.doStateAPICall({ ...langData });
  };
  const handleClearStateSelection = (checked: boolean) => {
    handleAllSatesSelection(checked);
  };
  const handleAllSatesSelection = (checked: boolean) => {
    let allStateList: any = stateList;
    allStateList.map((state: any) => {
      state.checked = checked;
    });
    setStateList(allStateList);
    let filteredStates = allStateList.filter(
      (state: StateListType) => state.checked && state.name !== SELECT_ALL
    );
    setSelectedStates(filteredStates.length ? filteredStates : null);
    selectedStatesObj = filteredStates.length ? filteredStates : null;
  };
  /**
   * handling state selection
   */
  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleStateChangeUtility(
      event,
      stateList,
      setStateList,
      (filteredStates) => {
        setSelectedStates(filteredStates?.length ? filteredStates : []);
        selectedStatesObj = filteredStates?.length ? filteredStates : null;
      },
      SELECT_ALL
    );
  };

  /** Filed level validation for Image file upload */
  const validateImage = (value: any) => {
    /** If, it is add trans, than ignore Image Validation */
    if (props.actionType !== ActionType.ADD_TRANSLATION) {
      return genericValidateImage(value);
    }
    return "";
  };
  /** Clear selected image */
  const clearImageObject = () => {
    /**remove img code to upload the same img again */
    let parent: any = document.getElementById("upload-img");
    let children = parent.children[0];
    children.value = "";
  };
  /** ----------Unique System name validation------------ */
  const checkSysNameAvailability = (generalDetails: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.verify_crop_sysnm.replace(
      "__CROP_NAME__",
      generalDetails.systemName
    );
    apiData.showLoader = true;
    apiData.customHeaders = {
      "language-code": selectedLangObj.languageCode,
    };
    api_service
      .get(apiData)
      ?.then((response) => {
        let res = response.data;
        if (res && res.statusCode === 200) {
          if (!res.data.isCropExist) {
            /** If given system name not exist than only go to next step */
            props.getFileURL(imageFile, {
              name: generalDetails.systemName,
              type: ModuleImageUpload.CROP,
            });
            props.onNext(STEPS.STEP2, generalDetails);
          } else {
            TriggerToastMessage(res.message, "info");
          }
        }
      })
      .catch(() => {
        TriggerToastMessage(
          "Unable to verify systemname for crop. Please try later",
          "error"
        );
      });
  };
  useEffect(() => {
    if (props?.languageId) {
      let langData: LangObjectType = {
        languageId: props.languageId,
        languageName: props.language ? props.language : "",
        languageCode: props.languageCode,
      };
      setSelectedLangObj(langData);
    }
    if (props?.selectedStates?.length) {
      setSelectedStates(props.selectedStates);
      selectedStatesObj = props.selectedStates;
    }
  }, []);
  useEffect(() => {
    const changeStateList = stateList.map((item) => {
      if (Array.isArray(selectedStates)) {
        if (
          selectedStates.some(
            (selectItem: any) => selectItem.name === item.name
          )
        ) {
          item.checked = true;
          return item;
        }
      }
      return item;
    });
    setStateList(changeStateList);
  }, [selectedStates]);
  return (
    <Formik
      initialValues={{
        language: props.language,
        imageUrl: props.imageUrl,
        cropName: props.cropTitle,
        systemName: props.systemName,
        cropStates: props.selectedStates,
      }}
      validationSchema={GeneralSchemaValidation}
      onSubmit={(values: any, _onSubmitProps: any) => {
        /** Calling save notification method */
        let generalDetails: any = { ...values };
        generalDetails.languageId = selectedLangObj.languageId;
        generalDetails.languageCode = selectedLangObj.languageCode;
        generalDetails.imageFile = imageFile;
        generalDetails.selectedStates = selectedStates;
        props.onNext(STEPS.STEP1, generalDetails);
        if (
          props.actionType === ActionType.CREATE ||
          (props.actionType === ActionType.ADD_TRANSLATION &&
            values.language !== props.baseLanguage)
        ) {
          /** for crop creation only check the sys name availability */
          checkSysNameAvailability(generalDetails);
        } else {
          /** no check for crop non-create flow */
          if (imageFile !== undefined) {
            props.getFileURL(imageFile, {
              name: values.systemName,
              type: ModuleImageUpload.CROP,
            });
          }
          props.onNext(STEPS.STEP2, generalDetails);
        }
      }}
    >
      {({ values, setFieldTouched }) => (
        <Form id="my-form">
          <Box className={classes.formContainer}>
            <Box className={classes.formFieldsHelperOne}>
              <Field name="language">
                {(_props: any) => {
                  const { setFieldValue } = _props.form;
                  return (
                    <LanguageDropdown
                      onChangeLanguage={(langData: string) => {
                        handleLanguageChange(langData);
                        setFieldValue("language", langData.split("##")[1]);
                      }}
                      key={selectedLangObj?.languageName}
                      selectedLanguage={
                        values.language || selectedLangObj?.languageName
                      }
                      isDisabled={
                        props.actionType !== ActionType.ADD_TRANSLATION
                      }
                      displayPrimaryLanguage={
                        props.actionType !== ActionType.ADD_TRANSLATION
                      }
                    />
                  );
                }}
              </Field>
              <FormHelperText
                error={true}
                className={classes.languagErrorMsgHepler}
              >
                <ErrorMessage name="language" />
              </FormHelperText>
            </Box>
            {/* Image upload start */}
            <div className={classes.formFieldsHelper}>
              {/* {UploadButtons('Crop Image')} */}
              <Field name="imageUrl" validate={validateImage}>
                {(imageProps: any) => {
                  const { form } = imageProps;
                  const { setFieldValue } = form;
                  return (
                    <Stack>
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent={
                          props.isSystemNameEditable ? "space-between" : ""
                        }
                        spacing={props.isSystemNameEditable ? 1 : 6}
                        marginTop={3}
                      >
                        <Typography
                          variant="subtitle2"
                          fontSize={14}
                          component="span"
                          width="100px"
                        >
                          Crop Image *
                        </Typography>
                        <Stack>
                          <label
                            htmlFor="contained-button-file"
                            id="upload-img"
                          >
                            <Input
                              accept=".jpg, .png, .jpeg"
                              id="contained-button-file"
                              type="file"
                              onChange={(
                                event: ChangeEvent<HTMLInputElement>
                              ) => {
                                if (
                                  event.target.files &&
                                  event.target.files.length
                                ) {
                                  setFieldValue(
                                    "imageUrl",
                                    event.target.files && event.target?.files[0]
                                  );
                                  setImageFile(
                                    event.target.files
                                      ? event.target.files[0]
                                      : undefined
                                  );
                                } else {
                                  setImageFile(undefined);
                                }
                              }}
                              disabled={!props.canAddNewRecord}
                            />
                            {values.imageUrl && (
                              <img
                                  alt={'crop image'}
                                src={
                                  typeof values.imageUrl !== "string"
                                    ? `${URL.createObjectURL(values.imageUrl)}`
                                    : `${values.imageUrl}`
                                }
                                width="128px"
                                height="128px"
                              />
                            )}
                            {!values.imageUrl && (
                              <Button
                                variant="contained"
                                size="small"
                                component="span"
                                disableRipple
                                disableElevation
                                disableTouchRipple
                                className={classes.uploadAnImageBtnHelper}
                                disabled={!props.canAddNewRecord}
                              >
                                <AddIcon
                                  className={
                                    classes.uploadImgBtnIconColorHelper
                                  }
                                />{" "}
                                Upload an image
                              </Button>
                            )}
                          </label>
                        </Stack>

                        {props.canAddNewRecord && (
                          <Stack spacing={2}>
                            <FormHelperText
                              className={classes.formHelperTextFontSizeHelper}
                            >
                              JPG, JPEG or PNG. Max size of 5 MB
                            </FormHelperText>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <PublishOutlinedIcon fontSize="inherit" />
                              <Typography variant="body2">
                                <label
                                  className={classes.rpPicture}
                                  htmlFor="contained-button-file2"
                                >
                                  {values.imageUrl && (
                                    <Input
                                      accept=".jpg, .png, .jpeg"
                                      id="contained-button-file2"
                                      type="file"
                                      name='replace-image'
                                      onChange={(
                                        event: ChangeEvent<HTMLInputElement>
                                      ) => {
                                        setFieldValue(
                                          "imageUrl",
                                          event.target.files &&
                                            event.target?.files[0]
                                        );
                                        setImageFile(
                                          event.target.files
                                            ? event.target.files[0]
                                            : undefined
                                        );
                                      }}
                                      disabled={!props.canAddNewRecord}
                                    />
                                  )}
                                  Replace picture
                                </label>
                              </Typography>
                            </Stack>
                            <Stack
                              className={classes.rmPicture}
                              direction="row"
                              alignItems="center"
                              justifyContent="center"
                              onClick={() => {
                                if (props.canAddNewRecord) {
                                  setFieldValue("imageUrl", "");
                                  clearImageObject();
                                }
                              }}
                            >
                              <DeleteOutlinedIcon fontSize="inherit" />
                              <Typography variant="body2">
                                Remove picture
                              </Typography>
                            </Stack>
                          </Stack>
                        )}
                      </Stack>
                    </Stack>
                  );
                }}
              </Field>
              <FormHelperText error={true}>
                <ErrorMessage name="imageUrl" />
              </FormHelperText>
              {/* Image upload end */}
              <Field name="cropName">
                {(_props: any) => {
                  const { form } = _props;
                  const { setFieldValue } = form;
                  return (
                    <FormControl
                      className={classes.formControlMrtHelper}
                      fullWidth
                    >
                      <Typography variant="subtitle2">Crop Title *</Typography>
                      <TextField
                        fullWidth
                        name="cropName"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setFieldValue("cropName", event.target.value);
                        }}
                        defaultValue={values?.cropName}
                      />
                    </FormControl>
                  );
                }}
              </Field>
              <FormHelperText error={true}>
                <ErrorMessage name="cropName" />
              </FormHelperText>
              <Field
                name="systemName"
                validate={(value: string) =>
                  genericFormFieldValidation(
                    value,
                    ValidationType.SYS_NM,
                    Boolean(props.systemName)
                  )
                }
              >
                {(_props: any) => {
                  const { form } = _props;
                  const { setFieldValue } = form;
                  return (
                    <FormControl
                      className={classes.formControlMrtHelper}
                      fullWidth
                    >
                      <Typography variant="subtitle2">System Name *</Typography>
                      <TextField
                        fullWidth
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setFieldValue(
                            "systemName",
                            event.target.value.trim().toLocaleUpperCase()
                          );
                          setFieldTouched("systemName");
                        }}
                        name={'systemName'}
                        defaultValue={values?.systemName}
                        value={values?.systemName}
                        disabled={!props.isSystemNameEditable}
                      />
                    </FormControl>
                  );
                }}
              </Field>

              <FormHelperText error={true}>
                <ErrorMessage name="systemName" />
              </FormHelperText>
              <Field name="cropStates">
                {(districtProps: any) => {
                  const { form } = districtProps;
                  const { setFieldValue } = form;
                  return (
                    <FormControl
                      className={classes.formControlMrtHelper}
                      fullWidth
                    >
                      <Typography variant="subtitle2">
                        Available States
                      </Typography>

                      <MultiSelect
                        key={"cropStates"}
                        name="stateId"
                        showClearAll={true}
                        labelKey="name"
                        options={stateList}
                        selectedOptions={
                          (selectedStates?.length &&
                            selectedStates.map((state: any) => state.name)) ||
                          []
                        }
                        onCheckboxChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          handleStateChange(event);
                          setFieldValue("cropStates", selectedStatesObj);
                        }}
                        onClearSelection={() => {
                          handleClearStateSelection(false);
                          setFieldValue("cropStates", null);
                        }}
                        isDisabled={!props.canAddNewRecord}
                      />
                    </FormControl>
                  );
                }}
              </Field>
              <FormHelperText error={true}>
                <ErrorMessage name="cropStates" />
              </FormHelperText>
            </div>
          </Box>
          <AutoSubmitToken />
          <>
            <Divider />
            <div className={classes.modalFooterActionBtnsHelper}>
              <Stack direction="row" spacing={2}>
                <Button type="submit" variant="contained" color="success">
                  Next
                </Button>
              </Stack>
            </div>
          </>
        </Form>
      )}
    </Formik>
  );
};
