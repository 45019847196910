import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { msalConfig } from "./Config/authConfig";
import "./index.css";
import prepareAppStore from "./redux/store/index";
import reportWebVitals from "./reportWebVitals";
import "../src/translations/i18n";
import ErrorBoundary from "./Components/Errorboundary";

export const msalInstance = new PublicClientApplication(msalConfig);
const { store, persistor } = prepareAppStore((err: any) => {
  if (!err) {
    renderApp();
  }
});

const renderApp = () => {
  const root = document.getElementById("root");
  if (root) {
    createRoot(root).render(
      <React.StrictMode>
        <MsalProvider instance={msalInstance}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ErrorBoundary>
                <App />
              </ErrorBoundary>
            </PersistGate>
          </Provider>
        </MsalProvider>
      </React.StrictMode>
    );
  }
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
