import {createColumn} from "../../Utils/genericUtils";

export type QueryColumnType = {
    fieldName: string;
    fieldLabel: string;
};

export const QueryColumn: QueryColumnType[] = [
    createColumn("Query ID", "queryId"),
    createColumn("Crop", "name"),
    createColumn("Category", "category"),
    createColumn("Phone No.", "phoneNumber"),
    createColumn("User Message", "message"),
    createColumn("User Media (Image/Audio)", "media"),
    createColumn("Received on", "createdDate"),
    createColumn("Aging Days", "agingDays"),
    createColumn("Status", "status"),
    createColumn("User Rating", "rating"),
    // createColumn("Experience", "experience"),
];
