import Pagination from '@mui/material/Pagination';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/reducers';
import { useStyles } from './styles';

export type CustomPaginationProps = {
  onChangePageNumber: Function;
  pageNumber: number;
  totalPages: number;
};

const CustomPagination: React.FC<CustomPaginationProps> = ({ onChangePageNumber, pageNumber, totalPages }: CustomPaginationProps) => {
  const isLoading = useSelector((storeState: RootState) => {
    return {
      loader: storeState.loader.showLoader
    }
  });

  const classes = useStyles();
  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    onChangePageNumber(value);
  };
  return (
    <>
      {!isLoading.loader && <Pagination
        key={totalPages}
        defaultPage={1}
        data-testid='pagination'
        className={classes.root}
        boundaryCount={1}
        siblingCount={1}
        count={totalPages}
        page={pageNumber}
        variant='outlined'
        shape='rounded'
        onChange={handleChange}
      />}
    </>
  );
};

export default memo(CustomPagination);
