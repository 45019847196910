import { Chip } from "@mui/material";

const CustomChip = (props: any) => {
  const { label, id, type, handleChipDelete } = props;
  return (
    <Chip
      label={label}
      onDelete={() => {
        handleChipDelete(id, type);
      }}
      variant="filled"
      color="primary"
    ></Chip>
  );
};
export default CustomChip;
