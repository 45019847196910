import * as Yup from "yup";
import { ActionType } from "../../Common/Constants";

/** step-1 validation schema */
export const GeneralSchemaValidation = () =>
  Yup.object().shape({
    language: Yup.string().required("Language required"),
    hybridTitle: Yup.string()
      .min(0, "Title name should be atleast 0 characters ")
      .max(100, "Title name should be a max of 100 characters")
      .required("Hybrid title required"),
    // systemName: Yup.string()
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .min(3, 'System Name should be minimum 3 characters')
    //     .max(50, 'System Name should be a max of 50 characters')
    //     // .matches(/^\S*$/, 'White space not allowed')
    //     .matches(/^[0-9a-zA-Z-_]+$/, { message: 'Only alphabets and -,_ are allowed', excludeEmptyString: true })
    //     .required('System name required'),
    crop: Yup.string().required("Crop required"),
    agronomyText_maturity: Yup.string()
      .max(200, "Agronomy text cannot exceed 200 characters")
      .required("Agronomy text required"),
    sowingPeriod: Yup.string()
      .max(200, "Sowing period cannot exceed 200 characters")
      .required("Sowing period required"),
    spacing_ExpectedYield: Yup.string()
      .max(200, "Expected yield cannot exceed 200 characters")
      .required("Expected yield required"),
    seedRate: Yup.string()
      .max(200, "Seed rate cannot exceed 200 characters")
      .required("Seed rate required"),
    plantPopulation: Yup.string()
      .max(200, "Plant population cannot exceed 200 characters")
      .required("Plant population required"),
    // rating:  Yup.number('Only numbers are allowed').required('Rating required'),
    // rating: Yup.number()
    //     .required('Rating required')
    //     .test(
    //         'Positive number only',
    //         'Value must be a positive number',
    //         (value) => value && Number(value) > 0 ? true : false
    //     ),
    rating: Yup.number()
      .typeError("Only numbers are allowed")
      .min(1, "Min allowed value is 1")
      .required("Rating required"),
    keyBenefitOne: Yup.string()
      .trim("Starting spaces are not allowed")
      .max(1000, "Key Benefits one cannot exceed 1000 characters")
      .required("Key benefit one required"),
    keyBenefitTwo: Yup.string()
      .trim("Starting spaces are not allowed")
      .max(1000, "Key Benefits two cannot exceed 1000 characters")
      .required("Key benefit two required"),
    keyBenefitThree: Yup.string()
      .trim("Starting spaces are not allowed")
      .max(1000, "Key Benefits three cannot exceed 1000 characters")
      .required("Key benefit three required"),
    displayOrder: Yup.number().typeError("Only numbers are allowed").nullable(),
    // states: Yup.array().required("States required").nullable(),
  });

/** step-2 validation schema */
export const FeatureSchemaValidation = () =>
  Yup.object().shape({
    // systemName: Yup.string()
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .min(3, 'System Name should be minimum 3 characters')
    //     .max(50, 'System Name should be a max of 50 characters')
    //     .matches(/^[0-9a-zA-Z-_]+$/, { message: 'Only alphabets and -,_ are allowed', excludeEmptyString: true })
    //     .required('Feature System name required'),
    heading: Yup.string()
      .min(0, "Heading should be atleast 0 characters ")
      .max(100, "Heading should be a max of 100 characters")
      .required("Feature Heading required"),
    text: Yup.string()
      .min(0, "Description should be atleast 0 characters ")
      .max(1000, "Description should be a max of 1000 characters")
      .required("Feature Description required"),
    imagePath: Yup.string()
      .required("Upload Feature image")
      .optional()
      .nullable(),
  });

/** step-3 validation schema */
export const PrecautionSchemaValidation = (systemName: string) =>
  Yup.object().shape({
    ...(!Boolean(systemName) && {
      systemName: Yup.string()
        .trim("Starting & trailing spaces are not allowed")
        .strict()
        .min(0, "System Name should be minimum 3 characters")
        .max(200, "System Name should be a max of 200 characters")
        .matches(/^[0-9a-zA-Z_]+$/, {
          message: "Only alphabets and _ are allowed",
          excludeEmptyString: true,
        })
        .required("Precaution System name required"),
    }),
    text: Yup.string()
      .min(0, "Description should be atleast 0 characters ")
      .max(1000, "Description should be a max of 1000 characters")
      .required("Precaution Description required"),
  });

/** step-4 validation schema */
export const AgronomySchemaValidation = () =>
  Yup.object().shape({
    // systemName: Yup.string()
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .min(3, 'System Name should be minimum 3 characters')
    //     .max(50, 'System Name should be a max of 50 characters')
    //     .matches(/^[0-9a-zA-Z-_]+$/, { message: 'Only alphabets and -,_ are allowed', excludeEmptyString: true })
    //     .required('Agronomy System name required'),
    heading: Yup.string()
      .min(0, "heading should be atleast 0 characters ")
      .max(100, "heading should be a max of 100 characters")
      .required("Agronomy Heading required"),
    text: Yup.string()
      .min(0, "Description should be atleast 0 characters ")
      .max(1000, "Description should be a max of 1000 characters")
      .required("Agronomy Description required"),
    imagePath: Yup.string()
      .required("Upload agronomy image")
      .optional()
      .nullable(),
  });

/** step-5 validation schema */
export const TestimonialSchemaValidation = () =>
  Yup.object().shape({
    // systemName: Yup.string()
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .min(3, 'System Name should be minimum 3 characters')
    //     .max(50, 'System Name should be a max of 50 characters')
    //     .matches(/^[0-9a-zA-Z-_]+$/, { message: 'Only alphabets and -,_ are allowed', excludeEmptyString: true })
    //     .required('Testimonial System name required'),
    subtext: Yup.string()
      // .min(5, 'Media Title should be atleast 5 characters ')
      // .max(50, 'Media Title should be a max of 15 characters')
      .required("Media title required"),
    mediaType: Yup.string().required("Media type required"),
    text: Yup.string()
      // .min(500, 'Description should be atleast 500 characters ')
      .max(1000, "Description should be a max of 1000 characters"),
    // .required("Testimonial Description required")
    // mediaUrl: Yup.string().when("mediaType", {
    //   is: (mediaType: any) => mediaType === "IMAGE",
    //   then: Yup.string().optional(),
    //   otherwise: Yup.string().required("Media required"),
    // }),
    // mediaImage: Yup.string().when('mediaType', {
    //     is: (mediaType:any)=> mediaType==='IMAGE',
    //     then: Yup.string().required('Media required'),
    //     otherwise: Yup.string().optional()
    // }),
  });

/** step-6 validation schema */
export const FilterSchemaValidation = () =>
  Yup.object().shape({
    keySystemName: Yup.string().required("System name required"),
    valueSystemName: Yup.string().required("Filter value required"),
    states: Yup.array().required("States required").nullable(),
  });
