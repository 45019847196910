import { useEffect, useState } from "react";

export type DownloadProgressType = {
  module: string;
  downloadName: string;
  totalDownloaded?: number;
  totalSize?: number;
  progress?: number;
  status: "Downloading" | "Preparing" | "Error";
  message?: string;
  onRefetchCallback?: () => void;
  canRefetch?: boolean;
};

const useDownloadProgress = () => {
  const [listDownloadProgress, setListDownloadProgress] = useState<
    DownloadProgressType[]
  >([]);

  const deleteDownloadProgress = (downloadName: string) => {
    setListDownloadProgress((prev) => {
      return prev.filter((item) => item.downloadName !== downloadName);
    });
  };

  const addDownloadProgress = (downloadProgress: DownloadProgressType) => {
    setListDownloadProgress((prev) => {
      const existingItemIndex = prev.findIndex(
        (item) => item.downloadName === downloadProgress.downloadName
      );

      if (existingItemIndex !== -1) {
        const newList = [...prev];
        newList[existingItemIndex] = downloadProgress;

        return newList;
      } else {
        return [...prev, downloadProgress];
      }
    });
  };

  useEffect(() => {
    listDownloadProgress.forEach((item) => {
      if (item.progress && item.progress === 100)
        deleteDownloadProgress(item.downloadName);
    });
  }, [listDownloadProgress]);

  return { listDownloadProgress, addDownloadProgress, deleteDownloadProgress };
};

export default useDownloadProgress;
