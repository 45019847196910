import { Close, Replay } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import { createContext, useContext } from "react";
import { convertBytesToMB } from "../../../Utils/unitConverter";
import CollapsibleBox from "../CollapsibleBox";
import { DownloadProgressType } from "./useDownloadProgress";

export type DownloadProgressContextType = {
  listDownloadProgress: DownloadProgressType[];
  addDownloadProgress: (downloadProgress: DownloadProgressType) => void;
  deleteDownloadProgress: (downloadName: string) => void;
};

export const DownloadProgressContext =
  createContext<DownloadProgressContextType>({
    listDownloadProgress: [],
    addDownloadProgress: () => {},
    deleteDownloadProgress: () => {},
  });

export const useDownloadProgressContext = () => {
  return useContext(DownloadProgressContext);
};

const DownloadProgress: React.FC = () => {
  const { listDownloadProgress, deleteDownloadProgress } =
    useDownloadProgressContext();
  const listDownloadProgressLength = listDownloadProgress.length;

  return listDownloadProgressLength > 0 ? (
    <CollapsibleBox title="Download Progress">
      <Stack
        sx={{
          maxHeight: "10em",
          overflowY: "auto",
          color: "rgba(0, 0, 0, 0.87)",
          bgcolor: "white",
          padding: "0.5em",
        }}
        gap={"0.5em"}
      >
        {listDownloadProgress.map((item) => (
          <Stack
            key={item.downloadName}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            paddingInlineEnd={"0.5em"}
          >
            <Stack>
              <Typography
                variant="body2"
                fontWeight={"bold"}
                data-testid="download-title"
              >
                {item.module} : {item.downloadName}
              </Typography>
              {item.status === "Downloading" && (
                <Typography variant="body2" data-testid="download-progress">
                  {convertBytesToMB(item.totalDownloaded ?? 0)} MB /{" "}
                  {convertBytesToMB(item.totalSize ?? 0)} MB
                </Typography>
              )}
              <Typography
                variant="body2"
                sx={{
                  fontStyle: item.status === "Preparing" ? "italic" : "inherit",
                }}
                color={item.status === "Error" ? "#CF3537" : "inherit"}
                data-testid="download-message"
              >
                {item.message}
              </Typography>
            </Stack>
            {item.status === "Downloading" && (
              <Typography
                fontSize={"16px"}
                data-testid="download-progress-percent"
              >
                {item.progress}%
              </Typography>
            )}
            {item.status === "Error" && (
              <Stack direction={"row"}>
                {item.canRefetch && (
                  <IconButton
                    size="small"
                    onClick={() => {
                      item.onRefetchCallback?.();
                    }}
                    data-testid="download-refetch-button"
                  >
                    <Replay />
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  onClick={() => {
                    deleteDownloadProgress(item.downloadName);
                  }}
                  data-testid="download-close-button"
                >
                  <Close />
                </IconButton>
              </Stack>
            )}
          </Stack>
        ))}
      </Stack>
    </CollapsibleBox>
  ) : null;
};

export default DownloadProgress;
