import {Close, ExpandLess, ExpandMore} from "@mui/icons-material";
import {Box, Collapse, IconButton, Stack, Typography} from "@mui/material";
import {useState} from "react";
import {usePublishProgressContext} from "./PublishProgress";

const CollapsibleBox: React.FC<{ title: string }> = ({title, children}) => {
    const [isOpen, setIsOpen] = useState(true);
    const {deleteAllPublishProgress} = usePublishProgressContext();
    return (
        <Box
            sx={{
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "8px",
                overflow: "hidden",
                width: "20em",
            }}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{
                    color: "white",
                    bgcolor: "rgb(0,0,75)",
                    padding: "0.5em",
                }}
            >
                <Typography variant="body1">{title}</Typography>
                <Box>
                    <IconButton
                        size="small"
                        color="inherit"
                        onClick={deleteAllPublishProgress}
                    >
                        <Close />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={() => setIsOpen((open) => !open)}
                        color="inherit"
                    >
                        {isOpen ? <ExpandLess/> : <ExpandMore/>}
                    </IconButton>
                </Box>
            </Stack>
            <Collapse in={isOpen} data-testid="collapsibleBox">
                {children}
            </Collapse>
        </Box>
    );
};

export default CollapsibleBox;
