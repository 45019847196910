import { Typography } from '@mui/material';
import * as React from 'react';

interface IPageTitleProps {
  title: string;
}

const PageTitle: React.FC<IPageTitleProps> = (props) => {
  return (
    <div>
      <Typography variant='subtitle1'>{props.title}</Typography>
    </div>
  );
};

export default PageTitle;
