export const TABS_DATA = ['Data List', 'My uploads'];

interface PayloadObj {
  category: Array<string>;
  district: string;
  districtId: number | undefined;
  fulladdress: string;
  imageurl: string;
  isactive: boolean;
  latitude: number;
  longitude: number;
  name: string;
  operatinghoursfields: string;
  phonenumber: string;
  pincode: string;
  shortaddress: string;
  state: string;
  stateId: number | undefined;
  storeId: number | null;
  taluka: string;
  talukaId: number | undefined;
  type: string;
  // lastupdateddate: string | null;
  village: string;
}

interface SubmitFormPayload {
  id: any;
  payload: PayloadObj;
  smartGrowerRetailerId: any;
  statusId: number;
}

interface ImageUploadObj {
  fileNameWithExtension: any;
  storeId: null | number;
  type: string;
}

interface ImageRemoveObj {
  fileUrlsList: Array<string>;
}

export const RETAILER_SUBMIT_FORM_PAYLOAD: SubmitFormPayload = {
  id: null,
  smartGrowerRetailerId: null,
  payload: {
    storeId: null,
    name: '',
    phonenumber: '',
    shortaddress: '',
    fulladdress: '',
    pincode: '',
    state: '',
    district: '',
    taluka: '',
    stateId: undefined,
    districtId: undefined,
    talukaId: undefined,
    type: '',
    imageurl: '',
    isactive: true,
    // lastupdateddate: null,
    category: [''],
    latitude: 0,
    longitude: 0,
    village: '',
    operatinghoursfields: ''
  },
  statusId: 0
};

export const IMAGE_UPLOAD_PAYLOAD: ImageUploadObj = {
  storeId: null,
  type: 'RETAILER_NURSERIES',
  fileNameWithExtension: ''
};

export const IMAGE_REMOVE_PAYLOAD: ImageRemoveObj = {
  fileUrlsList: ['']
};

export const RETAILER_CATEGORY_MULTISELECT_DATA = [
  {
    categoryId: 1,
    categoryName: 'Crop Protection'
  },
  {
    categoryId: 2,
    categoryName: 'Field crops'
  },
  {
    categoryId: 3,
    categoryName: 'Veg Seeds'
  },
];
