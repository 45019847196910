import { Box, Button, Divider, Modal, Paper, Stack, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import ModalHeader from '../../../Common/Modal/ModalHeader';
import { TriggerToastMessage } from '../../../Utils/toastTrigger';
import { submitLanguageDetails, updateLanguageDetails } from '../ApiCalls/Language.Api';
import { useStyles } from '../style';

export const AddLanguage = (props: any) => {
    const classes = useStyles();
    const validationSchema = yup.object({
        languageName: yup
            .string()
            .required('Please enter language label in english'),
        languageCode: yup
            .string()
            .uppercase()
            .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
            .min(2, 'Language code should be a minimum of 2 characters')
            .max(5, 'Language code should be a maximum of 5 characters')
            .required('Language code is required'),
        displayName: yup
            .string()
            .min(2, 'Display label should be a minimum of 2 characters')
            .max(25, 'Display label cannot exceed 25 characters')
            .required('Display label is required'),
    });
    const createLanguage = (values: any) => {

        let updatedValues =
        {
            "languageName": values.languageName,
            "displayName": values.displayName,
            "languageCode": values.languageCode,
            "inActiveFlag": values.inActiveFlag
        }

        submitLanguageDetails(updatedValues, successCallBack, errorCallback)
    }

    const updateLanguage = (values: any) => {
        let updatedValues =
        {
            "languageId": values.languageId,
            "status": values.inActiveFlag,
            "displayName": values.displayName
        }
        updateLanguageDetails(updatedValues, successCallBack, errorCallback)
    }
    const successCallBack = (response: any) => {
        if (response.statusCode === 400) {
            TriggerToastMessage('Language code is duplicate.', 'warn');
        }
        else if (response.statusCode === 201 || 200) {
            props.onClose();
        }
    };

    /**
     * @description A callback function triggered when an api is failed
     */
    const errorCallback = () => {
        TriggerToastMessage('Unable to process your request', 'error');
    };

    const formik = useFormik({
        initialValues: {
            languageName: props.data.languageName,
            languageCode: props.data.languageCode,
            displayName: props.data.displayName,
            languageId: props.data.languageId,
            inActiveFlag: 0
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (!props.data?.languageId) {
                createLanguage(values)
            } else {
                values.inActiveFlag = 1;
                updateLanguage(values)
            }

        },
    });



    return (
        <Modal
            open={props.isOpen}
            onClose={() => { }}>
            <Paper className={classes.formPaper}>
                <ModalHeader header={props.headerTitle} onClose={() => { props.onClose(); }} />
                <Box className={classes.formBoxHelper}>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={2} className={classes.innerModelWidth}>
                            <Stack>
                                <Typography variant='subtitle2' mb={1}>Enter Language Input (English) *</Typography>
                                <TextField
                                    fullWidth
                                    disabled={props.headerTitle === 'Edit Language'}
                                    id="languageName"
                                    autoFocus={false}
                                    name="languageName"
                                    value={formik.values.languageName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.languageName && Boolean(formik.errors.languageName)}
                                    helperText={formik.touched.languageName && formik.errors.languageName} />
                            </Stack>
                            <Stack>
                                <Typography variant='subtitle2' mb={1}>Language Code *</Typography>
                                <TextField fullWidth
                                    disabled={props.headerTitle === 'Edit Language'}
                                    id="languageCode"
                                    name="languageCode"
                                    value={formik.values.languageCode}
                                    onChange={formik.handleChange}
                                    onKeyDown={formik.handleBlur}
                                    error={formik.touched.languageCode && Boolean(formik.errors.languageCode)}
                                    helperText={formik.touched.languageCode && formik.errors.languageCode} />
                            </Stack>
                            <Stack>
                                <Typography variant='subtitle2' mb={1}>Display Label *</Typography>
                                <TextField fullWidth
                                    id="displayName"
                                    name="displayName"
                                    value={formik.values.displayName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.displayName && Boolean(formik.errors.displayName)}
                                    helperText={formik.touched.displayName && formik.errors.displayName} />
                            </Stack>
                        </Stack>
                        <Box className={classes.modelFooterBtns}>
                            <Divider />
                            <Button variant='contained' color='success' type='submit'>{props.headerTitle === 'Edit Language' ? 'Save' : 'Create'}</Button>
                        </Box>
                    </form>
                </Box>
            </Paper >
        </Modal >
    );
};
