import { api_service } from "../../../Api/api_service";
import { RequestMethods } from "../../../Constants/enums";
import { IRequestBody } from "../../../Constants/interfaces";

/**
 * @description function triggers an api call to fetch list of languages
 * @param data
 * @param successCallBack function
 * @param errorCallBack funciton
 */

export const getPublishableData = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, RequestMethods.Get);
    })
    .catch((err) => {
      errorCallBack(err, RequestMethods.Get);
    });
};

export const putPublish = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .put(data)
    ?.then((response: any) => {
      if (response.data.statusCode === 200) {
        successCallBack(response.data, RequestMethods.Put);
        return;
      }
      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      errorCallBack(err);
    });
};

export const DeletePublish = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .delete(data)
    ?.then((response) => {
      successCallBack(response.data, 'delete_notification');
    })
    .catch((err) => {
      errorCallBack(err, 'delete_notification');
    });
};
