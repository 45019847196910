import { makeStyles } from "@mui/styles";


export const Styles = makeStyles({
    formPaper: {
        margin: "30px auto",
        width: "750px",
        height: "80vh",
        position:'relative',
        '& .reuploadBtn':{
            position:'absolute',
            bottom:'0',
            textAlign:'right',
            width:'100%',
            boxSizing:'border-box',
            '& .MuiButton-root':{
                margin:'1rem'
            }
        }
    },
    knowMoreContainerHelper:{
        maxHeight:'62vh',
        overflowY:'auto',
        padding:'0 10px',
        
        '& .MuiTableCell-root':{
            boxSizing:'border-box'
        },
        '& .MuiTableRow-root':{
            '& .MuiTableCell-root:nth-child(1)':{
                width:'100px',  
                wordBreak:'break-all' 
            },
            '& .MuiTableCell-root:nth-child(2)':{
                width:'50px',  
                color:'#CF3537',
                textAlign:'center',
                fontWeight:'600'
            }
        }

    },
    knowMoreErrorMsgHelper:{
        margin:'1rem 0',
        '& .MuiPaper-root':{
            borderLeft:'3px solid #CF3537'
        },
        '& .MuiSvgIcon-root':{
            color:'#CF3537'
        },
        '& .msgContainer':{
            display:'flex',
            alignItems:'center',
            '& .errorTitle':{
                marginBottom:'0.5rem'
            }

        }

    }
})