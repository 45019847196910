import {
  Box,
  FormControl,
  FormHelperText,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { ChangeEvent, useState } from "react";
import SortableComponent from "../../../Common/SortableRecords";
import { checkForDuplicates } from "../../../Utils/genericUtils";
import { initialRecommendation, RecommendationType } from "./";
import { useStyles } from "./styles";
import { ValidationSchemaCropCalendar } from "./validationSchema";
import { ActionType, STEPS } from "../../../Common/Constants";
import RenderField from "../../../Common/RenderField";

export interface CalendarTextFormProps {
  onNext: Function;
  onPrevious: Function;
  updateListOrder: Function;
  listOfRecommendation: Array<RecommendationType>;
  selectedRecommenddation: RecommendationType;
  onSave: Function;
  onRemove: Function;
  onSelect: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  isPrimaryCP: boolean;
  actionType: string;
  threatList: Array<any>;
  stagesList: Array<any>;
  calendarList: Array<any>;
  isEdit: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
}

const CropCalendarForm: React.FC<CalendarTextFormProps> = (props) => {
  // variables
  const classes = useStyles();
  // states
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);
  /** Drag and Drop Menu lsiting */
  const renderRecommendationList = () => {
    return (
      <>
        <SortableComponent
          name={"Recommendation"}
          items={props.listOfRecommendation}
          updateCropStageOrder={(newList: Array<RecommendationType>) => {
            // const updatedList = newList.map((item, index) => ({
            //   ...item,
            //   applicationOrder: index,
            // }))
            // props.updateListOrder(newList);
          }}
          key={props.listOfRecommendation.length}
          listProps={props}
          displayKey={"calendarSystemName"}
          initialState={initialRecommendation}
          selectedRecord={props.selectedRecommenddation}
          secMatchKey="stageSystemName"
          thirdMatchKey="threatSystemName"
          multipleMatchKey={[
            "calendarSystemName",
            "stageSystemName",
            "threatSystemName",
            "whenToUse",
          ]}
          isDisabled={
            !props.isPrimaryCP && props.actionType !== ActionType.CREATE
          }
        />
      </>
    );
  };
  return (
    <Formik
      initialValues={{
        calendarSystemName: props.selectedRecommenddation.calendarSystemName,
        stageSystemName: props.selectedRecommenddation.stageSystemName,
        threatSystemName: props.selectedRecommenddation.threatSystemName,
        dosage: props.selectedRecommenddation.dosage,
        whenToUse: props.selectedRecommenddation.whenToUse,
        whyToUse: props.selectedRecommenddation.whyToUse,
        reminderMessage: props.selectedRecommenddation.reminderMessage,
        applicationOrder: props.selectedRecommenddation.applicationOrder,
      }}
      validationSchema={ValidationSchemaCropCalendar}
      onSubmit={(values: any, _onSubmitProps: any) => {
        /** submit form */
        if (
          checkForDuplicates(
            props.listOfRecommendation,
            {
              calendarSystemName: values!.calendarSystemName,
              stageSystemName: values!.stageSystemName,
              threatSystemName: values!.threatSystemName,
              whenToUse:
                typeof values!.whenToUse === "string"
                  ? parseInt(values!.whenToUse)
                  : values!.whenToUse,
            },
            values!.calendarSystemName,
            props.selectedRecommenddation.calendarSystemName
          )
        ) {
          _onSubmitProps.setFieldError(
            "calendarSystemName",
            "Combination with calendar system name, stage system name, threat system name & when to use exists!"
          );
          _onSubmitProps.setSubmitting(false);
          return;
        }

        if (
          checkForDuplicates(
            props.listOfRecommendation,
            {
              applicationOrder: values!.applicationOrder,
            },
            values!.applicationOrder,
            props.selectedRecommenddation.applicationOrder
          )
        ) {
          _onSubmitProps.setFieldError("applicationOrder", "order is exists!");
          _onSubmitProps.setSubmitting(false);
          return;
        }

        _onSubmitProps.setSubmitting(false);
        props.onSave(values);
        _onSubmitProps.resetForm();
        props.updateUniqueKey();
        setIsRuleErrorFlag(false);
      }}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, setFieldTouched }) => (
        <>
          <Form className={classes.formOutline}>
            <Box className={classes.formContainer}>
              <Box>
                <div className={classes.formContainerChildDivHelper}>
                  <Stack direction="row" spacing={2}>
                    <Stack
                      flex={1}
                      justifyContent="flex-start"
                      width={400}
                      borderRight="1px solid #C2C7D0"
                      paddingRight={2}
                    >
                      <Field name="calendarSystemName">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <RenderField
                                key={"calendarSystemName"}
                                name="calendarSystemName"
                                labelKey="calendarSystemName"
                                // options={['seeding tomato', 'seeding potato',]}
                                options={props.calendarList}
                                label="Calendar System Name *"
                                value={values.calendarSystemName}
                                onChange={async (name: string, value: any) => {
                                  await setFieldValue(name, value);
                                }}
                                isDisabled={
                                  props.canAddNewRecord
                                    ? !!props.selectedRecommenddation
                                      .calendarSystemName
                                    : true
                                }
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="calendarSystemName" />
                      </FormHelperText>
                      <Field
                        name="stageSystemName">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <RenderField
                                key={"stageSystemName"}
                                name="stageSystemName"
                                labelKey="stageSystemName"
                                // options={['tomato nursery stage', 'potato nursery stage',]}
                                options={props.stagesList}
                                label="Stage System Name *"
                                value={values.stageSystemName}
                                onChange={async (name: string, value: any) => {
                                  await setFieldValue(name, value);
                                }}
                                isDisabled={
                                  props.canAddNewRecord
                                    ? !!props.selectedRecommenddation
                                      .calendarSystemName
                                    : true
                                }
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="stageSystemName" />
                      </FormHelperText>
                      <Field name="threatSystemName">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <RenderField
                                key={"threatSystemName"}
                                name="threatSystemName"
                                labelKey="threatSystemName"
                                // options={['tomato white fly', 'potato white fly',]}
                                options={props.threatList}
                                label="Threat System Name *"
                                value={values.threatSystemName}
                                onChange={async (name: string, value: any) => {
                                  await setFieldValue(name, value);
                                }}
                                isDisabled={
                                  props.canAddNewRecord
                                    ? !!props.selectedRecommenddation
                                      .calendarSystemName
                                    : true
                                }
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="threatSystemName" />
                      </FormHelperText>
                      <Field name="dosage">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <Typography variant="subtitle2">
                                Dosage (ml/acre) *
                              </Typography>
                              <TextField
                                name="dosage"
                                value={values.dosage}
                                onChange={async (event: any) => {
                                  await setFieldValue("dosage", event.target.value);
                                }}
                                disabled={
                                  props.canAddNewRecord
                                    ? !!props.selectedRecommenddation
                                      .calendarSystemName
                                    : true
                                }
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="dosage" />
                      </FormHelperText>
                      <Field name="whenToUse">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <Typography variant="subtitle2">
                                When To Use *
                              </Typography>
                              <TextField
                                fullWidth
                                name="whenToUse"
                                onChange={(
                                  event: ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldTouched("whenToUse");
                                  if (
                                    !event.target.value ||
                                    /^\d+$/.test(event.target.value)
                                  ) {
                                    setFieldValue(
                                      "whenToUse",
                                      event.target.value
                                    );
                                    return;
                                  }
                                  event.stopPropagation();
                                }}
                                defaultValue={values?.whenToUse}
                                type="text"
                                value={values.whenToUse || ""}
                                disabled={
                                  props.canAddNewRecord
                                    ? !!props.selectedRecommenddation
                                      .calendarSystemName
                                    : true
                                }
                                placeholder="Accept only numbers"
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="whenToUse" />
                      </FormHelperText>
                      <Field name="whyToUse">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <Typography variant="subtitle2">
                                Why To Use *
                              </Typography>
                              <TextareaAutosize
                                onBlur={async (event) => {
                                  await setFieldValue("whyToUse", event.target.value);
                                }}
                                name="whyToUse"
                                className={classes.textAreaStyles}
                                maxLength={1024}
                                defaultValue={values?.whyToUse}
                                key={values?.whyToUse}
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="whyToUse" />
                      </FormHelperText>
                      <Field name="reminderMessage">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <Typography variant="subtitle2">
                                Reminder Message *
                              </Typography>
                              <TextField
                                name="reminderMessage"
                                value={values.reminderMessage}
                                onChange={async (event: any) => {
                                  await setFieldValue(
                                    "reminderMessage",
                                    event.target.value
                                  );
                                }}
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="reminderMessage" />
                      </FormHelperText>
                      <Field name="applicationOrder">
                        {() => {
                          return (
                            <FormControl
                              className={classes.formMarginHelper}
                              fullWidth
                            >
                              <Typography variant="subtitle2">
                                Application Order *
                              </Typography>
                              <TextField
                                name="applicationOrder"
                                value={values.applicationOrder}
                                onChange={async (event: any) => {
                                  await setFieldValue(
                                    "applicationOrder",
                                    event.target.value
                                  );
                                }}
                                disabled={
                                  props.canAddNewRecord
                                    ? !!props.selectedRecommenddation
                                      .calendarSystemName
                                    : true
                                }
                              />
                            </FormControl>
                          );
                        }}
                      </Field>
                      <FormHelperText error={true}>
                        <ErrorMessage name="applicationOrder" />
                      </FormHelperText>
                      <Stack alignSelf="self-end">
                        <Stack direction={"row"} spacing={2} marginTop="0.5rem">
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            disabled={
                              !(props.selectedRecommenddation.calendarSystemName
                                ? props.canUpdateExistingRecord
                                : props.canAddNewRecord)
                            }
                          >
                            {(props.selectedRecommenddation
                              ?.calendarSystemName &&
                              "Update Recommendation") ||
                              "Add Recommendation"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack flex={1} width={400}>
                      <Box>
                        <Typography variant="subtitle2">
                          Added Product Recommendation(s):
                        </Typography>
                        <Typography variant="caption" component="span">
                          You may reorder the stages by dragging each panel up
                          or down
                        </Typography>
                        <Box my={2}>
                          <FormHelperText error={true}>
                            {isRuleErrorFlag && (
                              <>Create at least one record to save CP Product</>
                            )}
                          </FormHelperText>
                          {renderRecommendationList()}
                        </Box>
                      </Box>
                    </Stack>
                  </Stack>
                </div>
              </Box>
            </Box>
            <Divider />
            <div className={classes.formFooterBtnActionHepler}>
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => props.onPrevious(STEPS.STEP5)}
                  className={classes.buttonHelperNext}
                >
                  Previous
                </Button>
                <Button
                  color="success"
                  variant="contained"
                  data-testid="submit-button"
                  className={classes.buttonHelperNext}
                  type="button"
                  // onClick={() => {
                  //     props.listOfRecommendation.length ? props.onNext(STEPS.STEP7) : setIsRuleErrorFlag(true);
                  // }}
                  onClick={() => {
                    props.onNext(STEPS.STEP7);
                  }}
                >
                  {props.isEdit &&
                    props.actionType !== ActionType.ADD_TRANSLATION
                    ? "Update"
                    : "Create"}
                </Button>
              </Stack>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default CropCalendarForm;
