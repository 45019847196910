import { Box, Stack, TableContainer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import api_endpoints, { base_url } from '../../Api/end_points';
import CustomTabs from '../../Common/CustomTabs';
import DataTable from '../../Common/DataTable';
import DataTableActions from '../../Common/DataTable/tableActions';
import RowsPerPage from '../../Common/RowsPerPage';
import { RequestMethods } from '../../Constants/enums';
import { IRequestBody } from '../../Constants/interfaces';
import { TriggerToastMessage } from '../../Utils/toastTrigger';
import { getLanguages, updateLanguageDetails } from './ApiCalls/Language.Api';
import { LanguageTableDateTableColumns } from './LanguageColumnsData';
import { AddLanguage } from './LanguageModels/AddLanguage';
import { useStyles } from './style';

type LanguageComponentProps = {
  title: string;
};
export const Languages: React.FC<LanguageComponentProps> = (props: LanguageComponentProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [activeTab, setactiveTab] = React.useState(0);
  const [addNewLanguage, toggleAddLanguage] = React.useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = React.useState<any>({});
  const [languageData, setLanguageData] = React.useState<any>([]);
  const [limit, setLimit] = React.useState(5);

  let totalPages: number = 0;
  /**
   * @description Click Handler to toggle the tab view
   * @param event Click Event
   * @param newValue number
   */
  const toggleTabView = (event: React.SyntheticEvent, newValue: number) => {
    setactiveTab(newValue);
  };

  /**
   * @description A callback function called on successfull response from api
   * @param response
   * @param method
   */

  const successCallBack = (response: any, method: RequestMethods) => {
    totalPages = response.data.totalPages;
    setLanguageData(response.data.languageList);
  };

  /**
   * @description A callback function triggered when an api is failed
   */
  const errorCallback = () => {
    TriggerToastMessage('Unable to process your request', 'error');
  };

  /**
   * @description A function to trigger an api call to fetch languages
   * @param limit number
   * @returns
   */
  const fetchLanguages = (): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.master_api_language;
    apiData.showLoader = false;
    apiData.payLoad = { pageNo: currentPage, pageSize: limit };
    getLanguages(apiData, successCallBack, errorCallback);
  };


  /**
   * @description A function which will reset current page
   * if currentPage === 0, it will trigger a method to load languages
   */
  const resetCurrentPage = () => {
    if (currentPage === 0) {
      fetchLanguages();
    } else {
      setCurrentPage(0);
    }
  };


  /**
   * @description A Handler function, which updates the selected number of rows per page
   * @param value number
   */
  const onChangeOfRowsPerPage = (value: number) => {
    setLimit(value);
  };

  const handleActionClick = (action: any, details?: any) => {
    switch (action) {
      case 'Edit_Push':
        toggleAddLanguage(true)
        return setSelectedLanguage(details)
      default:
        break;
    }
  }
  //modal close handler
  const closeHandler = () => {
    toggleAddLanguage(false)
    resetCurrentPage();
  }
  //status change success callback
  const successCallBackStatusChange = (response: any, method: RequestMethods) => {
    TriggerToastMessage(response.message, 'success')
    resetCurrentPage();

  };
  const errorCallbackStatusChange = () => {
    TriggerToastMessage('Unable to process your request', 'error');
  };
  const changeStatusOfLanguage = (status: boolean, values: any) => {

    if (confirm('Do You want to continue?')) {
      let updatedValues =
      {
        "languageId": values.languageId,
        "status": status,
        "displayName": values.displayName
      }
      updateLanguageDetails(updatedValues, successCallBackStatusChange, errorCallbackStatusChange)
    }
    else {
      resetCurrentPage();
    }

  }
  //Dropdown api

  useEffect(() => {
    setLanguageData([]);
    fetchLanguages();
  }, [currentPage, limit]);
  const classes = useStyles();
  return (
    <>
      <TableContainer className={classes.languageTableContainerHepler}>
        <Stack spacing={1}>
          {/* --------------------- Starting of Language Tabs --------------------- */}
          <Box className={classes.customTabBtmBorder}>
            <Stack>
              <CustomTabs
                tabsList={['Language']}
                value={activeTab}
                onChange={toggleTabView} />
            </Stack>
          </Box>
          {/* --------------------- Ending of Language Tabs --------------------- */}
          <Stack direction='row' justifyContent='space-between' className={classes.rowsPerPageStylesHelper}>
            <RowsPerPage
              pagSize={limit}
              onChangeRowsPerPage={(value: any) => { onChangeOfRowsPerPage(value); }}
            />
          </Stack>
          {/* ----------------------- Starting of Language Table ----------------------- */}
          <Box>
            {activeTab === 0 && <DataTable
              dropDownValue={limit}
              enableSelection={false}
              columns={LanguageTableDateTableColumns}
              data={languageData}
              pageNumber={1}
              isEditEnabled={true}
              onPageChange={() => { }}
              onSelectAll={() => { }}
              onDeselectAll={() => { }}
              onRowSelect={() => { }}
              onActionClick={(type: String, row: any) => handleActionClick(type, row)}
              onStatusChanged={(checked: boolean, row: any) => changeStatusOfLanguage(checked, row)}
            />
            }
          </Box>
          {/* ----------------------- Ending of Language Table ----------------------- */}
          {activeTab === 0 && <DataTableActions
            pageNumber={currentPage}
            onPageChange={setCurrentPage}
            onActionClick={() => {
              toggleAddLanguage(true);
              setSelectedLanguage({})
            }}
            canShowAvatar={true}
            totalpages={totalPages}

          />
          }
          {/* Ending of Pagination Component */}
          {addNewLanguage && <AddLanguage
            headerTitle={selectedLanguage.languageCode ? 'Edit Language' : 'Add Language'}
            data={{ ...selectedLanguage }}
            isOpen={addNewLanguage}
            onClose={() => closeHandler()}
          />}

        </Stack>
      </TableContainer>
    </>
  );
};
