// ------------------------------------------------ Stepper as per WireFrame-------------------------------

import { StepButton } from '@mui/material';
import Stack from '@mui/material/Stack';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
// -------------------------------------
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useStyles } from './style';



type StepMetaType = {
    stepLabel: string;
    stepValue: string;
    stepChangehandler?: Function;
}
type StepperProps = {
    steps: StepMetaType[];
    activeStep: number;
    stepChangehandler: any;
}


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 21,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: '#0071CD',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            background: '#0071CD',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        width: '100%',
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#23263080',
        borderRadius: 1,
    },
}));
const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#23263080',
    width: 40,
    height: 40,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    fontSize: '14px',
    border: '3px solid #23263080',
    ...(ownerState.active && {
        background: '#0071CD',
        borderColor: '#0071CD',
        color: '#fff'
    }),
    ...(ownerState.completed && {
        background: '#0071CD',
        borderColor: '#0071CD',
        color: '#fff'
    }),
}));

// ---------------------------Connector Component Ending--------------------------------------

// ---------------------------Icons Component Starting--------------------------------------

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: number } = {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}
// ---------------------------Icons Component Ending--------------------------------------

// ------------------------------- Stepper Component Starting---------------------------------


export default function StepperComponent({ steps, activeStep, stepChangehandler }: StepperProps) {
    const isStepFailed = (step: number) => {
        return step === 1000;
    };
    const classes = useStyles();
    return (
        <Stack sx={{ width: '100%' }} spacing={4}>
            <Stepper nonLinear alternativeLabel activeStep={activeStep - 1} connector={<ColorlibConnector />}>
                {steps.map((stepObj, index) => {
                    return (
                        <Step
                            completed={activeStep >= (index + 1)}
                            className={classes.stepperText}
                            key={stepObj.stepValue}>

                            <StepButton onClick={() => stepChangehandler(index + 1)}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}><Typography variant='subtitle2' >{stepObj.stepLabel}</Typography></StepLabel>
                            </StepButton>
                        </Step>
                    );
                })}
            </Stepper>
        </Stack>
    )

}
// ------------------------------- Stepper Component Ending---------------------------------
