import { Box, Divider, Modal, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api_endpoints, { base_url } from "../../../Api/end_points";
import { TableComponent } from "../../../Common/BasicDataTable/BasicDataTable";
import { ActionType } from "../../../Common/Constants";
import CustomTabs from "../../../Common/CustomTabs";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { IRequestBody } from "../../../Constants/interfaces";
import { RootState } from "../../../redux/reducers";
import { LanguageRecordType } from "../../../redux/reducers/generic_reducer";
import { StagesToCreateAHybrid } from "../HybridCreationStages";
import { useStyles } from "../useStyle";
import { ViewHybridCrop } from "./../ApiCalls/HybridsAPICalls";
import {
  AgronomyPractices,
  ApplicableFilter,
  Features,
  GrownTestimonials,
  Precautions,
} from "./TableHeaders";
import { removeSpacesImagePath } from "../../../Utils/removeSpaceImagePath";
import { Media } from "../../../Common/UploadFile";
import { createColumn } from "../../../Utils/genericUtils";

type HybridDataTypes = {
  fieldName: string;
  fieldLabel: string;
};

const HybridData: Array<HybridDataTypes> = [
  createColumn("Hybrid Image", "media"),
  createColumn("Hybrid Title", "name"),
  createColumn("System Name", "systemName"),
  createColumn("Crop", "crop"),
  createColumn("Agronomy Text/Maturity", "agronomyText_maturity"),
  createColumn("Sowing Period", "sowingPeriod"),
  createColumn("Spacing/Expected Yield", "spacing_ExpectedYield"),
  createColumn("Speed Rate", "speedRate"),
  createColumn("Plant Population", "plantPopulation"),
  createColumn("Rating", "rating"),
  createColumn("key Benefit One", "keyBenefitOne"),
  createColumn("key Benefit Two", "keyBenefitTwo"),
  createColumn("key Benefit Three", "keyBenefitThree"),
  createColumn("Display Order", "displayOrder"),
  createColumn("Available States", "states"),
];



interface RenderFieldProps extends HybridDataTypes {
  data: any;
}

const ViewHybrid: React.FC<any> = (props) => {
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
    };
  });
  const classes = useStyles();
  const [hybridDetails, setHybridDetails] = useState<any>({});
  const [activeTab, setActiveTab] = useState<number>(0);
  const [temp, setTemp] = useState<any>({});

  const handleClose = () => {
    props.onCloseViewHybrid();
  };

  const changeActiveTab = (dataType = "", newValue: number) => {
    setActiveTab(newValue);
  };

  const getStepStages = () => {
    return StagesToCreateAHybrid.map((stepObj: any) => {
      return stepObj.stepLabel;
    });
  };

  useEffect(() => {
    fetchHybridDetails();
  }, []);

  const fetchHybridDetails = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    if (!props.lngId) {
      /** this api call is for un-published HYBRID */
      apiData.endPoint = api_endpoints.hybrid_by_draft_id.replace(
        "__HYBRID__DRAFT__ID__",
        props.hybridViewID
      );
    } else {
      /** this api call is for published HYBRID */
      apiData.endPoint = api_endpoints.view_hybrid_details
        .replace("__CROP_SYSTEM_NAME__", props.cropSystemName)
        .replace("__HYBRID_SYS_NM__", props.systemName);
      /** Language code is mandatory for fetching published Records */
      const cropLanguageObject: LanguageRecordType[] =
        storeData.languages.filter((lngRcd: LanguageRecordType) => {
          return lngRcd.languageId === props.lngId;
        });
      if (cropLanguageObject.length > 0) {
        apiData.customHeaders = {
          "language-code": cropLanguageObject[0].languageCode,
        };
      }
    }
    ViewHybridCrop(apiData).then((response: any) => {
      processCropStageCalendarSystemInfo(response);
    });
  };

  const processCropStageCalendarSystemInfo = (response: any) => {
    const data = response.data.payload;

    const featuresArray = data.features.map((row: any) => ({
      displayOrder: row.displayOrder,
      id: row.id,
      imageUrl: row.media && row.media.length > 0
        ? removeSpacesImagePath(row.systemName, row.media[row.media.length - 1].path)
        : "",
      heading: row.heading,
      systemName: row.systemName,
      text: row.text,
    })).sort((a: any, b: any) => a.displayOrder - b.displayOrder);

    const practicesArray = data.agronomyPractices.map((row: any) => ({
      displayOrder: row.displayOrder,
      id: row.id,
      media: row.media && row.media.length > 0 ? row.media[row.media.length - 1].path : "",
      heading: row.heading,
      isDieasesAndPestControlHeader: row.isDieasesAndPestControlHeader,
      systemName: row.systemName,
      text: row.text,
    })).sort((a: any, b: any) => a.displayOrder - b.displayOrder);

    const testimonialArray = data.growerTestimonials.map((row: any) => ({
      displayOrder: row.displayOrder,
      id: row.id,
      mediaType: row.mediaType,
      mediaUrl: row.mediaType && row.mediaType.toLocaleLowerCase() === "video" ? row.mediaUrl : "N/A",
      image: row.mediaType && row.mediaType.toLocaleLowerCase() === "image" ? row.image || row.mediaUrl : "N/A",
      mediaTitle: row.subtext,
      systemName: row.systemName,
      text: row.text,
    })).sort((a: any, b: any) => a.displayOrder - b.displayOrder);

    const filtersArray = data.filters.map((row: any) => ({
      keySystemName: row.keySystemName,
      valueSystemName: row.valueSystemName,
      shortCode: row.filterStates.map((code: any) => code.shortCode).toString(),
    }));

    const precautionsArrayData = data.precautions.sort((a: any, b: any) => a.displayOrder - b.displayOrder);

    const HybridInfoObj = {
      GeneralDetails: {
        systemName: data.systemName,
        name: data.name,
        crop: data.crop,
        imageUrl: data.media && data.media.length > 0 ? data.media[0].path : "",
        states: [...data.states],
        languageName: data.languageName,
        agronomyText_maturity: data.maturity,
        sowingPeriod: data.month,
        spacing_ExpectedYield: data.expectedYield,
        speedRate: data.rate,
        plantPopulation: data.plantPopulation,
        rating: data.rating,
        keyBenefitOne: data.benefit1,
        keyBenefitTwo: data.benefit2,
        keyBenefitThree: data.benefit3,
        id: data.id,
        displayOrder: data.displayOrder,
        languageId: response.data.languageId,
        languageCode: response.data.languageCode,
        hybridSystemName: response.data.hybridSystemName,
        media: data.media,
      },
      features: featuresArray,
      precautions: precautionsArrayData,
      agronomyPractices: practicesArray,
      growerTestimonials: testimonialArray,
      filters: filtersArray,
    };
    setHybridDetails({ ...HybridInfoObj });
  };

  const renderGeneralDetailsTab = () => {
    return (
      <Box className={classes.formContainer}>
        <Box className={classes.formFieldsHelper}>
          <Box className={classes.formFieldsHelperOne}>
            <LanguageDropdown
              isDisabled={true}
              selectedLanguage={hybridDetails?.GeneralDetails?.languageName}
              key={hybridDetails?.GeneralDetails?.languageCode}
            />
          </Box>
          <Box className={classes.formFieldsHelper}>
            {HybridData.map((notification: HybridDataTypes, index: number) => {
              return (
                <Box>
                  <RenderField
                    fieldName={notification.fieldName}
                    fieldLabel={notification.fieldLabel}
                    data={hybridDetails?.GeneralDetails}
                  />
                  {index < HybridData.length - 1 && (
                    <Divider className={classes.formControlMrtHelper} />
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderFeatureStage = () => {
    return (
      <TableComponent
        headers={[...Features]}
        data={hybridDetails.features || []}
      />
    );
  };

  const renderPrecautionStage = () => {
    return (
      <TableComponent
        headers={[...Precautions]}
        data={hybridDetails.precautions || []}
      />
    );
  };

  const renderAgronomyPracticeStage = () => {
    return (
      <TableComponent
        headers={[...AgronomyPractices]}
        data={hybridDetails.agronomyPractices || []}
      />
    );
  };

  const renderGrownTestimonialStage = () => {
    return (
      <TableComponent
        headers={[...GrownTestimonials]}
        data={hybridDetails.growerTestimonials || []}
      />
    );
  };

  const renderApplicableFilterStage = () => {
    return (
      <TableComponent
        headers={[...ApplicableFilter]}
        data={hybridDetails.filters || []}
      />
    );
  };

  const RenderField = (props: RenderFieldProps) => {
    const { fieldName, fieldLabel, data } = props;
    const classes = useStyles();

    const getLabel = (label: string) => {
      return (
        <Typography
          className={classes.formControlMrtHelper}
          variant="subtitle2"
          fontSize={14}
        >
          {label}
        </Typography>
      );
    };

    
    switch (fieldName) {
      case "states":
        return (
          <>
            {getLabel(fieldLabel)}
            <Typography className={classes.formControlMrtHelper} fontSize={14}>
              {data &&
                data[fieldName] &&
                data[fieldName].map(
                  (state: { id: number; name: string }, index: number) => {
                    return (
                      <>
                        {data[fieldName].length - 1 === index
                          ? state.name
                          : `${state.name}, `}
                      </>
                    );
                  }
                )}
            </Typography>
          </>
        );
      case "imageUrl":
        return (
          <>
            <Stack direction="row" spacing={10}>
              {getLabel(fieldLabel)}
              <div className={classes.viewImage}>
                <img
                  src={data && data[fieldName]}
                  alt="Img should Display here"
                />
              </div>
            </Stack>
          </>
        );
      case "media":
        return (
          <Stack direction="row" gap={4}>
            {getLabel(fieldLabel)}
            <Stack direction={"row"} gap={4} overflow={"auto"}>
              {data &&
                Array.isArray(data[fieldName]) &&
                data[fieldName]
                  .sort(
                    (media: Media, nextMedia: Media) =>
                      media.displayOrder - nextMedia.displayOrder
                  )
                  .slice(0, 5)
                  .map(
                    (media: Media, index: number) =>
                      media?.path && (
                        <div key={media.id} className={classes.viewImage}>
                          <img src={media.path as string} alt="" />
                        </div>
                      )
                  )}
            </Stack>
          </Stack>
        );
      default:
        return (
          <>
            {getLabel(fieldLabel)}
            <Typography
              className={classes.formControlMrtHelper}
              fontSize={14}
              variant="body1"
            >
              {data && data[fieldName] ? data[fieldName] : "N/A"}
            </Typography>
          </>
        );
    }
  };
  const handleEditHybrid = () => {
    props.onCloseViewHybrid();
    props.onEditClick(ActionType.UPDATE, {
      id: hybridDetails.GeneralDetails?.id
        ? hybridDetails.GeneralDetails.id
        : props.hybridViewID,
      languageId: hybridDetails.GeneralDetails?.languageId,
      systemName: hybridDetails.GeneralDetails?.hybridSystemName,
      languageCode: hybridDetails.GeneralDetails?.languageCode,
      cropSystemName: hybridDetails.GeneralDetails?.crop,
    });
  };

  return (
    <Modal
      open={true}
      disableEscapeKeyDown={true}
      className={classes.modalStylesHelper}
    >
      <Paper className={classes.formPaper}>
        <Paper elevation={3} square>
          <ModalHeader
            header={`Hybrid : ${hybridDetails?.GeneralDetails?.crop}`}
            onClose={() => {
              handleClose();
            }}
            showEdit={props.canShowEditButton}
            // editHandler={()=>{props.onEditClick(hybridDetails)}}
            editHandler={() => handleEditHybrid()}
          />
          <Box>
            <CustomTabs
              onChange={changeActiveTab}
              tabsList={getStepStages()}
              value={activeTab}
            />
          </Box>
        </Paper>
        <Box className={classes.viewHybridFormContentHelper}>
          {activeTab === 0 && renderGeneralDetailsTab()}
          {activeTab === 1 && renderFeatureStage()}
          {activeTab === 2 && renderPrecautionStage()}
          {activeTab === 3 && renderAgronomyPracticeStage()}
          {activeTab === 4 && renderGrownTestimonialStage()}
          {activeTab === 5 && renderApplicableFilterStage()}
        </Box>
      </Paper>
    </Modal>
  );
};

export default ViewHybrid;
