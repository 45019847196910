export type ThreatsColumnType = {
    fieldLabel: string;
    fieldName: string;
  };

export const ThreatsDataTableColumns: Array<ThreatsColumnType> = [
    
    {
      fieldLabel: 'Crop',
      fieldName: 'name'
    },
    {
      fieldLabel: 'Crop Threats',
      fieldName: 'threatList'
    },
    {
      fieldLabel: 'Language',
      fieldName: 'languageName'
    },
    {
      fieldLabel: 'Last Updated On',
      fieldName: 'lastUpdatedDate'
    }
];

