import React, { memo } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FormControl, FormControlLabel, MenuItem, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Radio from '@mui/material/Radio';
import { useStyles } from './style'; // Ensure this points to your styles file

type RenderFieldProps = {
  placeholder?: string;
  label: string;
  labelKey?: string;
  name: string;
  onChange: (name: string, value: any) => void;
  options: Array<any>;
  selectedOption?: any;
  value?: string;
  isDisabled?: boolean;
  displayRadioButton?: boolean;
  renderItems?: (option: any) => React.ReactNode;
  required?: boolean;
};

const RenderField: React.FC<RenderFieldProps> = (props: RenderFieldProps) => {
  const classes = useStyles();

  const handleChange = (event: SelectChangeEvent) => {
    const { name, value } = event.target;
    props.onChange(name, value);
  };

  const defaultRenderItems = (option: any) => {
    return (
      <MenuItem key={option.value} value={option}>
        <Typography
          className={classes.lineHeightHelper}
          variant="body1"
          fontSize={14}
        >
          {props.displayRadioButton ? (
            <FormControlLabel
              value={props.labelKey && option[props.labelKey]}
              control={
                <Radio
                  checked={
                    (props.selectedOption &&
                      props.labelKey &&
                      props.selectedOption[props.labelKey] === option[props.labelKey]) ||
                    (props.labelKey && props.selectedOption === option[props.labelKey])
                  }
                />
              }
              label={(props.labelKey && option[props.labelKey]) || option}
            />
          ) : (
            (props.labelKey && option[props.labelKey]) || option
          )}
        </Typography>
      </MenuItem>
    );
  };

  const renderItems = props.renderItems ?? defaultRenderItems;

  return (
    <FormControl className={classes.formControlMrtHelper} fullWidth>
      <Typography variant="subtitle2">
        {props.label} {props.required ? '*' : ''}
      </Typography>
      <Select
        name={props.name}
        MenuProps={{ classes: { paper: classes.Select } }}
        onChange={handleChange}
        displayEmpty={true}
        IconComponent={(props) => (
          <ExpandMoreIcon {...props} className={classes.selectExpandMore} />
        )}
        value={props.selectedOption ?? props.value ?? props.placeholder}
        renderValue={(value) => {
          if (value?.length === 0 && props.placeholder) {
            return <em>{props.placeholder}</em>;
          }

          return (
            (typeof value === 'object' && props.labelKey && value[props.labelKey]) || value
          );
        }}
        disabled={!!props.isDisabled}
      >
        {props.options.map((option: any) => renderItems(option))}
      </Select>
    </FormControl>
  );
};

export default memo(RenderField);