/**
 * @description function triggers an api call to fetch list of languages
 * @param data
 * @param successCallBack function
 * @param errorCallBack funciton
 */

import { api_service } from "../../Api/api_service";
import { IRequestBody } from "../../Constants/interfaces";

export type StatusType = {
  displayName?: string;
  value?: string;
};

export const queryStatusList: Array<StatusType> = [
  { displayName: "Open", value: "2" },
  { displayName: "Closed", value: "3" },
];

export const respondedStatusList: Array<StatusType> = [
    { displayName: "Yes", value: 'true' },
    { displayName: "No", value: 'false' },
];

export const getQueryList = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "queryList");
    })
    .catch((err) => {
      errorCallBack(err, "queryList");
    });
};

export const getQueryByQueryId = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .getByID(data)
    ?.then((response) => {
        if(response.data.statusCode === 200){
            return successCallBack(response.data, "getQueryByQueryId");
        }
        throw response.data;
    })
    .catch((err) => {
      errorCallBack(err, "getQueryByQueryId");
    });
};

export const requestUploadQuery = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "requestUploadQuery");
    })
    .catch((err) => {
      errorCallBack(err, "requestUploadQuery");
    });
};

export const requestReplyQuery = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .postByID(data)
    ?.then((response) => {
      successCallBack(response.data, "requestReplyQuery");
    })
    .catch((err) => {
      errorCallBack(err, "requestReplyQuery");
    });
};

export const deleteQueries = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .delete(data)
    ?.then((response) => {
      successCallBack(response.data, "deleteQuery");
    })
    .catch((err) => {
      errorCallBack(err, "deleteQuery");
    });
};

export const getCrops = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "crops");
    })
    .catch((err) => {
      errorCallBack(err, "crops");
    });
};

export const getStates = (
    data: IRequestBody,
    successCallBack: any,
    errorCallBack: any
) => {
    api_service
        .get(data)
        ?.then((response) => {
            successCallBack(response.data, "states");
        })
        .catch((err) => {
            errorCallBack(err, "states");
        });
};

export const getCategories = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "categories");
    })
    .catch((err) => {
      errorCallBack(err, "categories");
    });
};
