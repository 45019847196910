class Logger {
  debug(...params: any) {
    return;
  }
  error(error: Error | any, ...params: any) {
    console.error(error, ...params);
  }
  info(...params: any) {
    return;
  }
}

export const logger = new Logger();
