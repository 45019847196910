export const STEPS = {
  STEP1: 1,
  STEP2: 2,
  STEP3: 3,
  STEP4: 4,
  STEP5: 5,
  STEP6: 6,
  STEP7: 7,
  STEP8: 8,
};

export const FarmingPractiseList = [
  { label: "Direct Seeded", code: "direct_seedeed" },
  { label: "Transplanted", code: "transplanted" },
  { label: "Seedling", code: "seedling" },
  { label: "Seed", code: "seed" },
  { label: "Not Applicable", code: "not_applicable" },
];

export const CropDurationList = [
  { label: "Short", code: "short" },
  { label: "Long", code: "long" },
  { label: "Not Applicable", code: "not_applicable" },
];

export const MediaTypeList = [
  { label: "Video", code: "Video" },
  { label: "Image", code: "Image" },
];

export const ModuleImageUpload = {
  CROP: "CROP",
  CROP_STAGE: "CROP_STAGE",
  THREAT_STAGE: "CROP_THREAT",
  HYBRID: "HYBRID",
  HYBRID_FEATURE: "HYBRID_FEATURE",
  HYBRID_GT: "HYBRID_GROWER_TESTIMONIAL",
  HYBRID_AP: "HYBRID_AGRONOMY_PRACTICES",
  CP_GENERAL: "CP_PRODUCT",
  CP_FEATURE: "CP_PRODUCT_FEATURE",
  CP_TESTIMONIAL: "CP_PRODUCT_GROWER_TESTIMONIAL",
  DASHBOARD_ASSET_UPLOAD: "DASHBOARD_MEDIA",
};

export const ActionType = {
  CREATE: "Create_Push",
  UPDATE: "Edit_Push",
  VIEW: "View_Push",
  ADD_TRANSLATION: "add_translation",
  DELETE: "Delete",
};
export const InitalPublishDropdown = [
  "Crops",
  "Threats",
  "CP Products",
  "Hybrids",
  "Retailers & Nurseries",
  "Dashboard Media",
  "Push Notifications",
];

export const PublishModuleDD = {
  PUSH_NOTIFICATIONS: "Push Notifications",
  CROPS: "Crops",
  THREATS: "Threats",
  PLANTIX_CP_PRODUCTS: "Plantix CP Products",
  PLANTIX_MAP_PRODUCTS: "Plantix Map Product",
  CP_PRODUCTS: "CP Products",
  HYBRIDS: "Hybrids",
  RETAILERS_NURSUREY: "Retailers & Nurseries",
  DASHBOARD_MEDIA: "Dashboard Media",
};

export const ErrorTableHeaders: any[] = [
  {
    HeaderName: "Sheet",
    FieldName: "sheetName",
  },
  {
    HeaderName: "Line",
    FieldName: "rowNumber",
  },
  {
    HeaderName: "Error",
    FieldName: "failureReason",
  },
];
export const DOWNLOAD_SAMPLE_TEMPLATE_TYPE = {
  CROP: "SAMPLE_MASTER_TEMPLATE",
  CP: "SAMPLE_CP_TEMPLATE",
  HYBRID: "SAMPLE_HYBRID_TEMPLATE",
  RETAIL: "SAMPLE_RETAILER_NURSERY_TEMPLATE",
  DASHBOARD: "SAMPLE_DASHBOARD_MEDIA_TEMPLATE",
  PLANTIX_CP: "SAMPLE_PLANTIX_CP_TEMPLATE",
  PLANTIX_MAP: "SAMPLE_PLANTIX_MAP_TEMPLATE",
};
export const MEDIA_TYPE = ["IMAGE", "VIDEO"];

export const DO_DONT = ["DO", "DONT"];

export const TOASTMASTER_POPUP_TYPE = {
  SUCCESS: "success",
  WARN: "warn",
  ERROR: "error",
};

export const ValidationType = {
  SYS_NM: "SYSTEM_NAME",
  DESC: "DESCRIPTION",
};
