import { ImportantDevices } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { Box, styled, Typography } from "@mui/material";

export const useStyles = makeStyles({
  cropContainerHelper: {
    width: "100%",
  },
  tabContainerHelper: {
    paddingLeft: 16,
  },
  tabHelper: {
    textTransform: "none !important" as "none",
  },
  panelBoxHelper: {
    display: "flex",
    alignItems: "center",
    width: "-webkit-fill-available",
    margin: "8px 0",
    padding: "0 1.5rem",
  },
  titleAndSearchHelper: {
    marginTop: "24px",
    display: "flex",
    width: "-webkit-fill-available",
    padding: "0 1.5rem",
  },
  tableHeader: {
    fontWeight: "700 !important",
    backgroundColor: "#fafafa !important",
    fontSize: "14px !important",
  },
  alignTop: {
    verticalAlign: "top !important",
  },
  linkStyles: {
    color: "#14803C !important",
    fontSize: "14px !important",
  },
  chipBoxHelper: {
    margin: "8px 0",
    display: "flex",
    width: "-webkit-fill-available",
    padding: "0 1.5rem",
  },
  viewImage: {
    background: "#f2f2f2",
    marginBottom: "1rem !important",
    width: "120px",
    height: "120px",
    "& img": {
      width: "100%",
    },
  },
  moduleDropdown:{
    padding:'0 1.5rem',
    '& .MuiFormControl-root':{
      maxWidth:'350px'
    }
  }
});

export const OuterBox = styled(Box)({
  width: "100vw",
  height: "calc(100vh - 60px)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});
export const InnerBox = styled(Box)({
  width: "350px",
  height: "auto",
  display: "flex",
  gap: "1.5rem",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

export const ErroMessage = styled(Typography)({
  fontWeight: 700,
  fontSize: 20,
  color: "#CF3537",
  lineHeight: "28px",
  textAlign: "center",
});
export const ErroMessageDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: 14,
  color: "#14151C",
  lineHeight: "22px",
  textAlign: "center",
});
export const SuccessMessage = styled(Typography)({
  fontWeight: 700,
  fontSize: 20,
  color: "#14803C",
  lineHeight: "28px",
  textAlign: "center",
});
export const SuccessMessageDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: 14,
  color: "#14151C",
  lineHeight: "22px",
  textAlign: "center",
});
export const ErrorMessage = styled(Typography)({
  fontWeight: 700,
  fontSize: 20,
  color: "#CF3537",
  lineHeight: "28px",
  textAlign: "center",
});
export const ErrorMessageDescription = styled(Typography)({
  fontWeight: 400,
  fontSize: 14,
  color: "#14151C",
  lineHeight: "22px",
  textAlign: "center",
});
