export type LanguageTableColumnType = {
  fieldLabel: string;
  fieldName: string;
};

export const LanguageTableDateTableColumns: Array<LanguageTableColumnType> = [
  {
    fieldLabel: 'Language',
    fieldName: 'displayName'
  },
  {
    fieldLabel: 'Language (English)',
    fieldName: 'languageName'
  },
  {
    fieldLabel: 'Language ID',
    fieldName: 'languageCode'
  },
   {
     fieldLabel: '',
   fieldName: 'languageColumnStatus'
  }
];

export const TranslateMasterType: Array<LanguageTableColumnType> = [
  {
    fieldLabel: 'Key',
    fieldName: 'key'
  },
  {
    fieldLabel: 'Value',
    fieldName: 'value'
  }

];
export const TranslateLanguageType: Array<LanguageTableColumnType> = [
  {
    fieldLabel: 'Key',
    fieldName: 'key'
  },
  {
    fieldLabel: 'Value',
    fieldName: 'value'
  },
  {
    fieldLabel: 'Translation',
    fieldName: 'translation'
  },
  {
    fieldLabel: ' ',
    fieldName: ' '
  }
];
