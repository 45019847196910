import { Menu } from "@mui/material";
import React, { useEffect, useState } from "react";

type MenuPopupProps = {
  open: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
};
const MenuPopup: React.FC<MenuPopupProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    if (props.open) setAnchorEl(props.anchorEl);
    else setAnchorEl(null);
  }, [props.open, props.anchorEl]);

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      {props.children}
    </Menu>
  );
};

export default MenuPopup;
