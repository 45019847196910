import {Box, Modal, Paper, Stack} from "@mui/material";
import ButtonWithIcon from "../../../Common/Buttons";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import CreateOrEditModal from "./CreateOrEditModal";
import {useStyles} from "../style";
import BulkUploadModal from "../../../Common/BulkUploadPlantix";

export interface BaseModalProps {
    open: boolean;
    showCreateOrEditModal: boolean;
    actionType: string;
    showBulkUpload: boolean;
    header: string;
    templateLiteral: string;
    validationLiteral: string;
    dataType?: string;
    onCloseModal: () => void;
    onShowCreateOrEditModal: (toggle: boolean) => void;
    onShowBulkUpload: (toggle: boolean) => void;
    onChangeTabs?: (tabs: number) => void;
}

const BaseModalCreatePlantix: React.FC<BaseModalProps> = ({
                                                 open,
                                                 showCreateOrEditModal,
                                                 actionType,
                                                 showBulkUpload,
                                                 header,
                                                 templateLiteral,
                                                 validationLiteral,
                                                 dataType,
                                                 onCloseModal,
                                                 onShowCreateOrEditModal,
                                                 onShowBulkUpload,
                                                 onChangeTabs,
                                             }) => {
    const classes = useStyles();

    return (
        <>
            <Modal open={open}>
                <Paper className={classes.createHybridPaper}>
                    <ModalHeader
                        header={header}
                        onClose={onCloseModal}
                        showDelete={false}
                        showEdit={false}
                    />
                    <Box className="containerBox">
                        <Box className="flexBoxContainerInner">
                            <Stack width={330} spacing={4}>
                                <ButtonWithIcon
                                    label={"Create new data"}
                                    type={"MODAL CREATE"}
                                    onClick={() => onShowCreateOrEditModal(true)}
                                />
                                <ButtonWithIcon
                                    label={"Bulk upload data"}
                                    type={"MODAL CANCEL"}
                                    onClick={() => onShowBulkUpload(true)}
                                />
                            </Stack>
                        </Box>
                    </Box>
                </Paper>
            </Modal>
            <CreateOrEditModal
                actionType={actionType}
                showCreateOrEditModal={showCreateOrEditModal}
                onShowCreateOrEditModal={onShowCreateOrEditModal}
            />
            <BulkUploadModal
                showBulkUpload={showBulkUpload}
                onShowBulkUpload={onShowBulkUpload}
                onChangeTabs={onChangeTabs ? onChangeTabs : () => {}}
                dataType={dataType ?? ''}
                templateLiteral={templateLiteral}
                validationLiteral={validationLiteral}
            />
        </>
    );
};

export default BaseModalCreatePlantix;
