export const removeSpacesImagePath = (
    systemname: string,
    imagepath: string | null
) => {
    const hasSpaces = systemname?.includes(" ");

    if (imagepath === null) {
        return "";
    }

    return hasSpaces
        ? imagepath
            ?.substring(0, imagepath.lastIndexOf("/") + 1)
            ?.replace(/ /g, "") +
        imagepath?.substring(imagepath.lastIndexOf("/") + 1)
        : imagepath;
};
