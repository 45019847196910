import { api_service } from "../../Api/api_service";
import { IRequestBody } from "../../Constants/interfaces";

/** get data list api */
export const GetDataList = (data: IRequestBody, successCallBack: Function, errorCallBack: Function) => {
  api_service.get(data)
    ?.then((res) => successCallBack(res.data, 'data_list'))
    .catch((err) => errorCallBack(err, 'data_list'));
}
/** get my upload list api */
export const GetMyUploadList = (data: IRequestBody, successCallBack: Function, errorCallBack: Function) => {
  api_service.get(data)
    ?.then((res) => successCallBack(res.data, 'upload_list'))
    .catch((err) => errorCallBack(err, 'upload_list'));
}

/** this api call is for get CP product detail by id */
export const GetCPDetails = (data: IRequestBody) => {
  return new Promise((resolve, reject) => {
    api_service.get(data)?.then((response) => {
      return resolve(response.data);
    }).catch((err) => {
      return reject(err);
    });
  });
}
/** fetch crop list */
export const GetCrops = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.then((response) => {
    successCallBack(response.data, 'crops');
  }).catch((err) => {
    errorCallBack(err, 'crops');
  });
};

/** SAVE/UPDATE CP Product */
export const SaveUpdateCpProduct = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.post(data)?.then((response) => {
    successCallBack(response.data, 'save_edit_cp');
  }).catch((err) => {
    errorCallBack(err, 'save_edit_cp');
  });
};

/** Delete published CP Product */
export const DeletePublishedCP = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.delete(data)?.then((response) => {
    successCallBack(response.data, 'delete_cp');
  }).catch((err) => {
    errorCallBack(err, 'delete_cp');
  });
};