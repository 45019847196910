import {
  AUTH_TOKEN,
  BASE_URL,
  LIST_COUNTRIES,
  LIST_CROPS,
  LIST_LANGUAGES,
  LIST_MODULES,
  LIST_NOTIFICATION_TYPES,
  RETAILER_MOBILE_LENGTH,
  SELECTED_COUNTRY,
  UPDATE_LANGUAGE_CODE,
} from "./actions-constants";

const baseURL = (baseDomain: string) => {
  return {
    type: BASE_URL,
    payload: baseDomain,
  };
};
const userAuthToken = (token: string) => {
  return {
    type: AUTH_TOKEN,
    payload: token,
  };
};

const userCountry = (countryCode: string) => {
  return {
    type: SELECTED_COUNTRY,
    payload: countryCode,
  };
};

const userLanguage = (languageCode: string) => {
  return {
    type: UPDATE_LANGUAGE_CODE,
    payload: languageCode,
  };
};

const appLanguages = (list: Array<any>) => {
  return {
    type: LIST_LANGUAGES,
    payload: list,
  };
};

const appNotificationTypes = (list: Array<any>) => {
  return {
    type: LIST_NOTIFICATION_TYPES,
    payload: list,
  };
};

const modulesList = (list: Array<any>) => {
  return {
    type: LIST_MODULES,
    payload: list,
  };
};

const retailerMobileLength = (length: number) => {
  return {
    type: RETAILER_MOBILE_LENGTH,
    payload: length,
  };
};

const appCrops = (list: Array<any>) => {
  return {
    type: LIST_CROPS,
    payload: list,
  };
};

const appCountries = (list: Array<any>) => {
  return {
    type: LIST_COUNTRIES,
    payload: list,
  };
};

export default {
  baseURL,
  userAuthToken,
  userCountry,
  appCrops,
  appNotificationTypes,
  appLanguages,
  appCountries,
  userLanguage,
  modulesList,
  retailerMobileLength,
};
