import * as React from "react";
import Crop from "../../Components/Crop";

export const CropsComponent = () => {
  return (
    <>
      <Crop title={"Crops"} />
    </>
  );
};
