import * as Yup from "yup";
/** STEP-1 */
export const ValidationSchemaGeneralForm = () =>
  Yup.object().shape({
    language: Yup.string().required("Language required"),
    // image: Yup.string().required('Image required'),
    productTitle: Yup.string()
      .max(100, "Product Title should be a max of 100 characters")
      .required("Product Title required"),
    // systemName: Yup.string()
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .min(3, 'System Name should be minimum 3 characters')
    //     .max(50, 'System Name cannot exceed 50 characters')
    //     .matches(/^[0-9a-zA-Z_]+$/, { message: 'Only alphabets and _ are allowed', excludeEmptyString: true })
    //     .required('System name required'),
    crop: Yup.string().required("Crop required"),
    category: Yup.string()
      .max(100, "Category cannot exceed 100 characters")
      .nullable(),
    dosage: Yup.string()
      .max(100, "Dosage cannot exceed 100 characters")
      .required("Dosage required")
      .nullable(),
    whenToUse: Yup.string()
      .max(500, "When To Use cannot exceed 500 characters")
      .required("When to Use required"),
    howToUse: Yup.string()
      .max(500, "How to Apply cannot exceed 500 characters")
      .required("How to Apply required")
      .nullable(),
    applicableCrop: Yup.string()
      .max(300, "Applicable Crop cannot exceed 300 characters")
      .nullable()
      .notRequired(),
    benefit1: Yup.string()
      .trim("Starting & trailing spaces are not allowed")
      .max(1000, "Key Benefits 1 cannot exceed 1000 characters")
      .required("Key Benefits 1 required")
      .nullable(),
    benefit2: Yup.string()
      .trim("Starting & trailing spaces are not allowed")
      .max(1000, "Key Benefits 2 cannot exceed 1000 characters")
      .required("Key Benefits 2 required")
      .optional()
      .nullable(),
    benefit3: Yup.string()
      .trim("Starting & trailing spaces are not allowed")
      .max(1000, "Key Benefits 3 cannot exceed 1000 characters")
      .required("Key Benefits 3 required")
      .optional()
      .nullable(),
  });
/** STEP-2 */
export const ValidationSchemaDosandDonts = () =>
  Yup.object().shape({
    type: Yup.string().required("Type required"),
    // systemName: Yup.string()
    //   .trim("Starting & trailing spaces are not allowed")
    //   .strict()
    //   .min(0, "System Name should be atleast 0 characters ")
    //   .max(1000, "System Name should be a max of 1000 characters")
    //   .matches(/^[0-9a-zA-Z_]+$/, {
    //     message: "Only alphabets and _ are allowed",
    //     excludeEmptyString: true,
    //   })
    //   .required("Name required"),
    text: Yup.string()
      .max(1000, "Description cannot exceed 1000 characters")
      .required("Description required"),
  });
/** STEP-3 */
export const ValidationSchemaFeatures = () =>
  Yup.object().shape({
    // systemName: Yup.string()
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .min(3, 'System Name should be atleast 3 characters ')
    //     .max(50, 'System Name should be a max of 50 characters')
    //     .matches(/^[0-9a-zA-Z_]+$/, { message: 'Only alphabets and _ are allowed', excludeEmptyString: true })
    //     .required('Name required'),
    header: Yup.string()
      .max(100, "Heading should be a max of 100 characters")
      .required("Heading required"),
    description: Yup.string()
      .max(1000, "Description cannot exceed 1000 characters")
      .required("Description required"),
  });
/** STEP-4 */
export const ValidationSchemaGrowerTestimonial = () =>
  Yup.object().shape({
    // systemName: Yup.string()
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .min(3, 'System Name should be atleast 3 characters ')
    //     .max(50, 'System Name should be a max of 50 characters')
    //     .matches(/^[0-9a-zA-Z_]+$/, { message: 'Only alphabets and _ are allowed', excludeEmptyString: true })
    //     .required('Name required'),
    text: Yup.string().required("Title required"),
    mediaType: Yup.string().required("Type required"),
    subtext: Yup.string()
      .max(500, "Description should be a max of 500 characters")
      .optional(),
    mediaUrl: Yup.string().optional(),
  });
/** STEP-5 */
export const ValidationSchemaTargetThreat = () =>
  Yup.object().shape({
    systemName: Yup.string().required("Threat System Name required"),
    stages: Yup.string().required("Stage System Name required"),
  });
/** STEP-6 */
export const ValidationSchemaCropCalendar = () =>
  Yup.object().shape({
    calendarSystemName: Yup.string().required("Calendar System Name required"),
    stageSystemName: Yup.string().required("Stage System Name required"),
    threatSystemName: Yup.string().required("Threat System Name required"),
    dosage: Yup.string().required("Dosage required"),
    whenToUse: Yup.number()
      .typeError("Only numbers are allowed")
      .min(0, "Min allowed value is 0")
      .max(365, "Max allowed value is 365")
      .required("When to Use required"),
    whyToUse: Yup.string()
      .min(5, "Why to Use should be a min of 5 characters")
      .max(500, "Why to Use should be a max of 500 characters")
      .required("Why to Use required"),
    reminderMessage: Yup.string()
      .max(500, "Message should be a max of 500 characters")
      .required("Message required"),
    applicationOrder: Yup.number()
      .typeError("Only numbers are allowed")
      .min(0, "Min allowed value is 0")
      .required("Message required"),
  });
