import { makeStyles } from "@mui/styles";

export const Styles = makeStyles({
  cropContainerHelper: {
    width: "100%",
  },
  tabContainerHelper: {
    paddingLeft: 16,
  },
  tabHelper: {
    textTransform: "none !important" as "none",
  },
  panelBoxHelper: {
    display: "inline-flex",
    alignItems: "center",
    width: "-webkit-fill-available",
  },
  titleAndSearchHelper: {
    margin: "24px 24px auto",
    display: "inline-flex",
    width: "-webkit-fill-available",
  },
  chipBoxHelper: {
    margin: "8px 0",
    display: "inline-flex",
    width: "-webkit-fill-available",
    padding: "0 1.5rem",
  },
  bulkDataBorderBottom: {
    borderBottom: "1px solid #CFD3DB",
    paddingBottom: "1.5rem",
    marginBottom: "1.5rem",
  },
  OddTableRow: {
    padding: "0.3rem 1rem",
    border: "1px solid #CFD3DB",
    "&:nth-child(odd)": {
      backgroundColor: "#F3F4F6",
    },
  },
  tableFirstChild: {
    borderRight: "1px solid #CFD3DB",
    paddingRight: "0.5rem",
  },
  viewImage: {
    background: "#f2f2f2",
    marginBottom: "1rem !important",
    width: "120px",
    height: "120px",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  modalHelper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  formPaperHelper: {
    margin: "30px auto",
    maxWidth: "1150px",
    minWidth: "500px",
  },
  formPaper: {
    margin: "30px auto",
    width: "750px",
    height: "500px",
  },
  formContainer: {
    // height: 'calc(75vh - 150px)',
    height: "350px",
    padding: "0 1rem",
    overflowY: "auto",
    position: "relative",
    scrollbarWidth: "auto",
    "& .MuiTableCell-root": {
      boxSizing: "border-box",
      wordBreak: "break-word",
      width: "150px",
      "&:nth-child(8)": {
        width: "300px",
      },
    },
    "&::-webkit-scrollbar": {
      width: "4px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#CFD3DB",
      "border-radius": "15px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ffffff",
    },
  },
  additonalFormContainer: {
    height: "370px",
    overflowY: "auto",
    width: "850px",
    position: "relative",
    scrollbarWidth: "none",
    paddingBottom: "20px",
    "&::-webkit-scrollbar": {
      width: "8px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#CFD3DB",
      "border-radius": "15px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ffffff",
    },
    "& .MuiFormControl-root": {
      "& .MuiFormHelperText-root": {
        marginLeft: "0px",
      },
    },
  },
  formFieldsHelper: {
    margin: "auto",
    maxWidth: "430px",
  },
  formFieldsHelperOne: {
    maxWidth: "380px",
    margin: "auto",
    position: "relative",
    marginBottom: "1rem",
  },
  formFieldsHelperUnique: {
    margin: "auto",
    maxWidth: "95%",
  },
  threatDataHelper: {
    margin: "auto 150px",
  },
  labelMrbHelper: {
    marginBottom: "8px !important",
  },
  pageTitleNSearchStylesHelper: {
    padding: "0 1.5rem",
    marginTop: "1.5rem",
  },
  myUploadTabDownloadHelper: {
    display: "flex",
    alignItems: "center",
    color: "#14803C !important",
    textDecorationColor: "#14803C !important",
  },
  customTabBtmBorder: {
    borderBottom: "1px solid #CFD3DB",
    padding: "0 1.5rem",
  },
  rowsPerPageStylesHelper: {
    margin: "5px 0",
    padding: "0 1.5rem",
  },
  formContainerChildDivHelper: {
    margin: "auto 20px",
  },
  formMarginHelper: {
    marginTop: "8px !important",
  },
  formControlLabel: {
    margin: "8px auto 0px auto",
  },
  textAreaAutoStyles: {
    minHeight: "80px",
    borderRadius: "5px",
    color: "#696F88",
    resize: "none",
  },
  fontWeightHelper: {
    fontWeight: "400",
  },
});
