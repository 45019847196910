import { api_service } from "../../../Api/api_service";
import api_endpoints, { base_url } from "../../../Api/end_points";
import { IRequestBody } from "../../../Constants/interfaces";

/**
 * @description function triggers an api call to fetch list of languages
 * @param data
 * @param successCallBack function
 * @param errorCallBack funciton
 */
export const getLanguages = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "languages");
    })
    .catch((err) => {
      errorCallBack(err, "languages");
    });
};
export const getCropsStates = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "crops_states");
    })
    .catch((err) => {
      errorCallBack(err, "crops_states");
    });
};
export const getStatesDistricts = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "state_districts");
    })
    .catch((err) => {
      errorCallBack(err, "state_districts");
    });
};

export const getListOfNotificationTypes = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "notification_types");
    })
    .catch((err) => {
      errorCallBack(err, "notification_types");
    });
};
/**
 * @description method to save and update notification
 * @param data
 * @param successCallBack
 * @param errorCallBack
 */
export const saveNotification = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "save_update");
    })
    .catch((err) => {
      errorCallBack(err, "save_update");
    });
};
export const updateNotification = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .put(data)
    ?.then((response) => {
      successCallBack(response.data, "save_update");
    })
    .catch((err) => {
      errorCallBack(err, "save_update");
    });
};
/**
 * @description this api call will return total cost for SMS sent to users
 * @param data payload with user count and charcter count
 * @param successCallBack
 * @param errorCallBack
 */
export const getSMSCost = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "sms_cost");
    })
    .catch((err) => {
      errorCallBack(err, "sms_cost");
    });
};
/**
 * @description this api call will return totalPages (as userCount)
 * @param data this will have payload as crops, states and district ids with static params
 * @param successCallBack
 * @param errorCallBack
 */
export const getUserCount = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "user_count");
    })
    .catch((err) => {
      errorCallBack(err, "user_count");
    });
};
/** List page API: for Data list tab */
export const getNotifications = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "data_list_tab");
    })
    .catch((err) => {
      errorCallBack(err, "data_list_tab");
    });
};
/** List page API: for Published tab */
export const getPublishedData = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "published_tab");
    })
    .catch((err) => {
      errorCallBack(err, "published_tab");
    });
};
/** List page API: for Sent tab */
export const getSentData = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "sent_tab");
    })
    .catch((err) => {
      errorCallBack(err, "sent_tab");
    });
};
/** Getting ntoification details by ID */
export const getNotificationByID = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "data_by_id");
    })
    .catch((err) => {
      errorCallBack(err, "data_by_id");
    });
};
/** Cancel Published ntoification by ID */
export const CancelNotification = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .put(data)
    ?.then((response) => {
      successCallBack(response.data, "cancel_notification");
    })
    .catch((err) => {
      errorCallBack(err, "cancel_notification");
    });
};
/**download sent users data */
export const DownloadUserSentDataByID = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "download_users");
    })
    .catch((err) => {
      errorCallBack(err, "download_users");
    });
};

export const fetchTimezoneOffset = (
  successCallBack: any,
  errorCallBack: any
) => {
  const apiData = {} as IRequestBody;
  apiData.domain = base_url;
  let endPoint: string = api_endpoints.attribute_api;
  apiData.endPoint = endPoint.replace("__ATTRIBUTE__KEY__", "UTC_OFFSET");
  apiData.showLoader = true;
  api_service
    .get(apiData)
    ?.then((response) => {
      successCallBack(response.data, "timezone_offset");
    })
    .catch((err) => {
      errorCallBack(err, "timezone_offset");
    });
};
type notificationTypesProps = {
  label: string;
  value: string;
};

export const notificationTypesNew: Array<notificationTypesProps> = [
  { label: "App Maintenance", value: "app_maintainance" },
  { label: "App Updates", value: "app_updates" },
  { label: "Announcement", value: "announcement" },
  { label: "New Content", value: "new_content" },
  { label: "Data Sync", value: "data_sync" },
];

// export const notificationTypes = [
//   "App Maintenance",
//   "App Updates",
//   "Announcement",
//   "New Content",
//   "Data Sync",
// ];

export type CropStateMapType = {
  name: string;
  uniqueStateId: number;
};
export type StateDistrictMapType = {
  checked?: boolean;
  districtId: number;
  enDistrictName: string;
};
export type FrequencyType = {
  displayName?: string;
  value?: string;
};
export type StatusType = {
  displayName?: string;
  value?: string;
};
export const statusList: Array<StatusType> = [
  { displayName: "Published", value: "2" },
  { displayName: "Cancelled", value: "3" },
];

export const cropStatusList: Array<StatusType> = [
  { displayName: "Enabled", value: "0" },
  { displayName: "Disabled", value: "1" },
];

export const frequencyData: Array<FrequencyType> = [
  { displayName: "Once", value: "Once" },
  { displayName: "Daily", value: "Daily" },
  { displayName: "Weekly", value: "Weekly" },
  { displayName: "Monthly", value: "Monthly" },
];

export type DayType = {
  displayName?: string;
  value?: string;
};
export const dayData: Array<DayType> = [
  { displayName: "Sunday", value: "SUNDAY" },
  { displayName: "Monday", value: "MONDAY" },
  { displayName: "Tuesday", value: "TUESDAY" },
  { displayName: "Wednesday", value: "WEDNESDAY" },
  { displayName: "Thursday", value: "THURSDAY" },
  { displayName: "Friday", value: "FRIDAY" },
  { displayName: "Saturday", value: "SATURDAY" },
];
export type DayOfMonthType = {
  displayName?: string;
  value?: string;
};
export const dayOfMonthData: Array<DayOfMonthType> = [
  { displayName: "1", value: "1" },
  { displayName: "2", value: "2" },
  { displayName: "3", value: "3" },
  { displayName: "4", value: "4" },
  { displayName: "5", value: "5" },
  { displayName: "6", value: "6" },
  { displayName: "7", value: "7" },
  { displayName: "8", value: "8" },
  { displayName: "9", value: "9" },
  { displayName: "10", value: "10" },
  { displayName: "11", value: "11" },
  { displayName: "12", value: "12" },
  { displayName: "13", value: "13" },
  { displayName: "14", value: "14" },
  { displayName: "15", value: "15" },
  { displayName: "16", value: "16" },
  { displayName: "17", value: "17" },
  { displayName: "18", value: "18" },
  { displayName: "19", value: "19" },
  { displayName: "20", value: "20" },
  { displayName: "21", value: "21" },
  { displayName: "22", value: "22" },
  { displayName: "23", value: "23" },
  { displayName: "24", value: "24" },
  { displayName: "25", value: "25" },
  { displayName: "26", value: "26" },
  { displayName: "27", value: "27" },
  { displayName: "28", value: "28" },
  { displayName: "29", value: "29" },
  { displayName: "30", value: "30" },
  { displayName: "Last day of month", value: "last_day_of_month" },
];
