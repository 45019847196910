export const RoutePaths = {
    Dashboard: '/',
    Notfound: '*',
    Login: '/',
    Crops: '/crops',
    Threats: '/threats',
    CPProducts: '/cp/products',
    Hybrids: 'hybrids',
    RetailersAndNurseries: '/retail-nursery',
    Languages: 'languages',
    PushNotifications: '/push/notifications',
    UserAndRoles: '/User-roles',
    QueryResponse: '/query-response',
    DashboardMedia: '/DashboardMedia',
    PlantixMapProduct: '/plantix/map-product',
    Publish: '/publish',
    PlantixCPProducts: '/plantix/cp-products',
    PageNotFound: '*',
    successPage: '/success/:module/:mode'
    // successPage: ['/crops/create/success'
    //     , '/crops/update/success'
    //     , '/threat/create/success'
    //     , '/threat/update/success'
    //     , '/dashboard/create/success'
    //     , '/dashboard/update/success',
    //     , '/hybrids/create/success',
    //     , '/hybrids/update/success'
    // ]
};
