import _ from '../../Utils/lodash';
import { LOGOUT, UPDATED_SELECTED_MODULE } from '../action/actions-constants';
import { IActionParams } from './user_reducer';

export type publish_module_reducer = {
    selectedModule: string
};

const LoaderInitialState: publish_module_reducer = {
    selectedModule: ''
};
export const publishModuleSelectedReducer = (state: publish_module_reducer = LoaderInitialState, { type, payload }: IActionParams): publish_module_reducer => {
    switch (type) {
        case UPDATED_SELECTED_MODULE: {
            return _.extend({}, state, { selectedModule: payload });
        }
        case LOGOUT: {
            return _.extend({}, LoaderInitialState);
        }
        default: {
            return state;
        }
    }
};
