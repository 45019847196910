import { Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import * as React from 'react';

type RowsPerPageType = {
  onChangeRowsPerPage: Function;
  pagSize: number;
};

const RowsPerPage: React.FC<RowsPerPageType> = ({
  pagSize,
  onChangeRowsPerPage
}) => {
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(pagSize);
  const handleChange = (event: SelectChangeEvent) => {
    const pageSize = parseInt(event.target.value, 10);
    setRowsPerPage(pageSize);
    onChangeRowsPerPage(pageSize);
  };

  return (
    <Stack direction='row' alignItems='center'>
      <div>
        <Typography component='div' variant='subtitle2'>
          Show
        </Typography>
      </div>
      <FormControl component='div'>
        <Select
          value={String(rowsPerPage)}
          onChange={handleChange}
          style={{
            height: '33px',
            width: '60px',
            margin: '0px 10px'
          }}
          >
          {[5, 20, 100].map((page: number, index: number) => {
            return (
              <MenuItem
                key={index}
                value={page}>
                <Typography
                  style={{ lineHeight: 'unset' }} variant='body2'
                >
                  {page}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <div>
        <Typography component='div' variant='subtitle2'>
          entries
        </Typography>
      </div>
    </Stack>
  );
};

export default RowsPerPage;
