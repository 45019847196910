import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles({
    uploadBtnCaptionText: {
        color: '#696F88',
        '&.MuiTypography-root': {
            fontSize: '14px'
        }
    }
})
