export enum RequestMethods {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE'
}

export enum SORT_OPTIONS {
  'asc' = 'ASC',
  'dsc' = 'DESC'
}
