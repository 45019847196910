export const StagesToNewProduct = [
  {
    stepLabel: 'General Details',
    stepValue: 'general-details',
  },
  {
    stepLabel: 'Do\'s & Don\'ts',
    stepValue: 'dos-and-donts',
  },
  {
    stepLabel: 'Features',
    stepValue: 'features',
  },
  {
    stepLabel: 'Grower Testimonials',
    stepValue: 'grower-testimonials',
  },
  {
    stepLabel: 'Target Threats',
    stepValue: 'target-threats',
  },
  {
    stepLabel: 'Crop Calendar Recommendation',
    stepValue: 'crop-calendar-recommendation',
  },
].map(stage => ({
  ...stage,
  hasErrors: false,
  isFormValid: false,
}));
