export const StagesToCreateAHybrid = [
  {
    stepLabel: 'General Details',
    stepValue: 'general-details',
  },
  {
    stepLabel: 'Features',
    stepValue: 'features',
  },
  {
    stepLabel: 'Precautions',
    stepValue: 'precautions',
  },
  {
    stepLabel: 'Agronomy Practices',
    stepValue: 'agronomy-practices',
  },
  {
    stepLabel: 'Grower Testimonials',
    stepValue: 'grower-testimonials',
  },
  {
    stepLabel: 'Applicable Filters',
    stepValue: 'applicable-filters',
  },
].map(stage => ({
  ...stage,
  hasErrors: false,
  isFormValid: false,
}));