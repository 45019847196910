import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ASSETS_PATH } from '../../Constants/contants';
import { useStyles, StylesProp } from './styles';

const COLOR_REJECTED: string = '#CF3537';
const COLOR_APPROVED: string = '#14803C';

export const TYPE_PUSH_APPROVED: string = 'PUSH_APPROVED';
export const TYPE_NO_PUSH: string = 'NO_PUSH';

type StatesProps = {
  onActionClick?: Function;
  type: typeof TYPE_PUSH_APPROVED | typeof TYPE_NO_PUSH;
};

type StateDataType = {
  action: string;
  buttonLabel: string;
  iconPath: string;
  styles: StylesProp;
  subTitle: string;
  title: string;
};

const defaultButtonLabel: string = 'Back';

const getStateData = (type: string) => {
  switch (type) {
    case TYPE_PUSH_APPROVED:
      return {
        iconPath: `${ASSETS_PATH}/states/Push_Approved.svg`,
        title: 'Push notification created!',
        subTitle:
          'The notifications will be pushed to the mobile application on the scheduled date',
        buttonLabel: defaultButtonLabel,
        action: defaultButtonLabel,
        styles: { color: COLOR_APPROVED, width: '300px' }
      };
    case 'PUSH_REJECTED':
      return {
        iconPath: `${ASSETS_PATH}/states/Push_Rejected.svg`,
        title: 'Push notification creation failed',
        subTitle:
          'An error occurred while creating your push notification, please try again later.',
        buttonLabel: defaultButtonLabel,
        action: defaultButtonLabel,
        styles: { color: COLOR_REJECTED, width: '350px' }
      };
    case TYPE_NO_PUSH:
      return {
        iconPath: `${ASSETS_PATH}/states/No_Push.svg`,
        title: 'No notification created',
        subTitle: 'Click on below button to create your push notification.',
        buttonLabel: 'Create new push notification',
        action: 'Create_Push',
        styles: { color: '', width: '350px' }
      };
    default:
      return {
        iconPath: `${ASSETS_PATH}/states/Push_Approved.svg`,
        title: 'Push notification created!',
        subTitle:
          'The notifications will be pushed to the mobile application on the scheduled date',
        buttonLabel: defaultButtonLabel,
        action: defaultButtonLabel,
        styles: { color: COLOR_APPROVED, width: '300px' }
      };
  }
};

const States: React.FC<StatesProps> = ({ type, onActionClick }) => {
  const stateData: StateDataType = getStateData(type);

  // classes
  const classes = useStyles(stateData.styles);

  // handlers
  const handleActionClick = (action: string) => {
    onActionClick!(action);
  };

  return (
    <div className={classes.root}>
      <img src={stateData?.iconPath} alt='Plant' />
      <Typography variant='subtitle1'>{stateData?.title}</Typography>
      <Typography fontSize={14}>{stateData?.subTitle}</Typography>
      <Button
        color='success'
        variant='contained'
        fullWidth
        onClick={() => {
          handleActionClick(stateData.action);
        }}
      >
        {stateData.buttonLabel}
      </Button>
    </div>
  );
};

export default States;
