import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  flexStyle: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  flexStyleCarousel: {
    width: "100%",
  },
  ResponseHeader: {
    paddingBottom: "12px",
    paddingRight: "8px",
  },
  messageStyle: {
    paddingBottom: "12px",
    width: "600px",
  },
  flexWithDirStyle: {
    display: "flex",
    flexDirection: "column",
  },
  summary: {
    height: "400px",
    paddingTop: 5,
    border: "1px solid #CFD3DB",
    borderRadius: "6px",
    borderCollapse: "unset",
  },
  mainMediaView: {
    paddingTop: 20,
  },
  summaryWhite: {
    height: "400px",
    paddingTop: 5,
    border: "1px solid #CFD3DB",
    borderRadius: "6px",
    borderCollapse: "unset",
  },
  carousel: {
    height: "10% !important",
  },
  carouselDiv: {
    height: "500px",
  },
  playerWrapper: {
    flex: 1,
    paddingLeft: "25%",
  },
  playerDiv: {
    paddingTop: "8%",
    paddingLeft: "25%",
  },

  ImageStyle: {
    // paddingLeft: "200px",
    // height: "300px",
    // width: "450px",
    marginTop: "4px",
    alignSelf: "center",
  },
  IconImageStyle: {
    height: "50px",
    marginTop: "15px",
  },
  VideoPlayWrapper: {
    marginBottom: "1rem !important",
    "& img": {
      width: "50%",
    },
    marginTop: "10px",
  },
  ImageTextWrapper: {
    width: "150px",
    marginTop: "4px",
    flex: 1,
    flexDirection: "column",
  },
  UnderlineTextStyle: {
    textDecoration: "underline",
  },
  TestImageStyle: {
    position: "relative",
    top: 5,
    left: 0,
    width: "154px",
    height: "85px",
  },
  playerStyle: {
    position: "absolute",
    bottom: "20px",
    left: "60px",
    width: "40px",
    height: "40px",
  },
  ImageWrapper: { position: "relative", left: 0, top: 0 },
  mediaImageStyle: {
    width: "450px",
    height: "250px",
  },
  tableStackStyle: { marginLeft: "20px" },
  PaperStyle: {
    margin: "30px auto",
    width: "80%",
    height: "70%",
    paddingBottom: 32,
  },
});
