import { ExpandMore } from "@mui/icons-material";
import { Box, Select as MUISelect, MenuItem } from "@mui/material";
import Label from "./Label";

type SelectProps = {
  options?: any[];
  label?: string;
  placeholder?: string;
  selectedValue?: string;
  optionValueKey?: string;
  optionLabelKey?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
};

const Select: React.FC<SelectProps> = (props) => {
  const { options, onChange, disabled } = props;
  const safeOptions = Array.isArray(options) ? options : [];
  const safeDisabled = typeof disabled === "boolean" ? disabled : false;
  const safeOnChange = typeof onChange === "function" ? onChange : () => {};

  return (
    <Box sx={{ maxWidth: 350 }}>
      <Label>{props.label}</Label>
      <MUISelect
        disabled={safeDisabled}
        fullWidth
        defaultValue={
          props.selectedValue ? props.selectedValue : props.placeholder
        }
        onChange={(e) => safeOnChange(e.target.value)}
        IconComponent={ExpandMore}
        MenuProps={{
          MenuListProps: {
            sx: {
              maxHeight: "20vh",
            },
          },
        }}
      >
        <MenuItem value={props.placeholder} sx={{ display: "none" }}>
          {props.placeholder}
        </MenuItem>
        {safeOptions.map((option) => {
          const safeValue = props.optionValueKey
            ? option[props.optionValueKey]
            : option;
          const safeLabel = props.optionLabelKey
            ? option[props.optionLabelKey]
            : option;

          return (
            <MenuItem key={safeValue} value={safeValue} sx={{ fontSize: 14 }}>
              {safeLabel}
            </MenuItem>
          );
        })}
      </MUISelect>
    </Box>
  );
};

export default Select;
