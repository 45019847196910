import { styled, Box, Typography } from '@mui/material';

export const OuterBox = styled(Box)(
    {
        width: '100vw',
        height: 'calc(100vh - 84px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
);
export const InnerBox = styled(Box)(
    {
        width: '350px',
        height: 'auto',
        display: 'flex',
        gap: '1.5rem',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
    }
);

export const SuccessMessage = styled(Typography)({
    fontWeight: 700,
    fontSize: 20,
    color: '#14803C',
    lineHeight: '28px',
    textAlign: 'center'
});
export const SuccessMessageDescription = styled(Typography)({
    fontWeight: 400,
    fontSize: 14,
    color: '#14151C',
    lineHeight: '22px',
    textAlign: 'center'
});
export const ErrorMessage = styled(Typography)({
    fontWeight: 700,
    fontSize: 20,
    color: '#CF3537',
    lineHeight: '28px',
    textAlign: 'center'
});
export const ErrorMessageDescription = styled(Typography)({
    fontWeight: 400,
    fontSize: 14,
    color: '#14151C',
    lineHeight: '22px',
    textAlign: 'center'
});
