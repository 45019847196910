import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    Select: {
        '& ul': {
            maxHeight: '20vh',
        }
    },
    textAreaStyles: {
        borderColor: 'rgba(0,0,0,0.23)',
        borderRadius: '5px',
        color: '#696F88',
        resize: 'none',
        height: '96px !important',
        overflowY: 'scroll',
        outlineColor: '#0071CD',
        outlineWidth: '2px',
        borderWidth: '1px',
        '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87)'
        },

    },
    rmPicture: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    formPaper: {
        margin: '30px auto',
        width: '750px',
        height: '70vh',
    },
    formContainer: {
        height: 'calc(70vh - 140px)',
        overflowY: 'auto',
        position: 'relative',
        scrollbarWidth: 'auto',
        '&::-webkit-scrollbar': {
            width: '8px !important',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#CFD3DB',
            'border-radius': '15px',

        },
        '&::-webkit-scrollbar-track': {
            background: '#ffffff',
        },
    },
    formFieldsHelperOne: {
        maxWidth: '380px',
        margin: 'auto'
    },
    formFieldsHelper: {
        margin: 'auto auto 8px auto',
        maxWidth: '430px',
    },
    rpPicture: {
        '&:hover': {
            cursor: 'pointer'
        }
    },
    selectExpandMore: {
        position: 'absolute',
        right: '3px'
    },
    formControlMrtHelper: {
        '&.MuiFormControl-root': {
            marginTop: '8px'
        },
        '& .MuiFormHelperText-root': {
            marginLeft: '0px'
        }
    },
    formErrorMsgLanguageHelper: {
        marginLeft: '0',
        position: 'absolute',
        top: '65px',
        left: '290px',
    },
    formSendSMSHelper: {
        display: 'flex',
        margin: '1.5rem 0 0',
        '& .MuiCheckbox-root': {
            padding: 0,
            height: 16,
            width: 16,
            marginLeft: 'auto',
        }
    },
    formUploadImageHelper: {
        whiteSpace: 'nowrap',
        height: '128px',
        '&.MuiButton-root': {
            background: '#F3F4F6',
            border: '1px dashed #C2C7D0',
            color: '#14151C',
            '&:hover': { background: '#C2C7D0' },
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',

    },
    formTimePickerHelper: {
        svg: {
            width: '18px',
            height: '18px'
        },

    },
    formTotalCostHelper: {
        margin: '0 auto 8px auto'
    },
    formButtonHelper: {
        width: 'fit-content',
        margin: '16px 16px auto auto'
    },
    formOutlineHelper: {
        outline: 'none'
    },
    lineHeightHelper: {
        lineHeight: 'unset'
    },
    uploadImgBtnIconColorHelper: {
        color: '#696F88'
    },
    formHelperTextFontSizeHelper: {
        fontSize: '11px !important'
    }

})
