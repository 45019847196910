import {Box, Button, Divider, FormHelperText, Stack, Typography,} from "@mui/material";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {useEffect, useState} from "react";
import {initialThreatStageDetail, ThreatStageKeys} from ".";
import api_endpoints from "../../../Api/end_points";
import {GetCropStagesWithSystemName} from "../../../Api/generic_apicalls";
import ButtonWithIcon, {CANCEL_TYPE, CREATE_TYPE,} from "../../../Common/Buttons";
import {IThreatInfo} from "../../../Common/Constants/types";
import SortableTableComponent from "../../../Common/SortableRecords";
import {IRequestBody} from "../../../Constants/interfaces";
import {checkForDuplicates} from "../../../Utils/genericUtils";
import {TriggerToastMessage} from "../../../Utils/toastTrigger";
import {useStyles} from "../style";
import {preparePayloadForThreat} from "../threats.helper";
import {ActionType, STEPS} from "../../../Common/Constants";
import {SaveThreatData} from "./ThreatAPICalls";
import {ThreatValidationSchema} from "./validationSchema";
import RenderField from "../../../Common/RenderField";

export type ThreatStagePropTypes = {
  ListOfStages: Array<IThreatInfo>;
  selectedStage: ThreatStageKeys;
  onSelect: Function;
  onSave: Function;
  onRemove: Function;
  onNext: Function;
  onPrevious: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  updateListOrder: Function;
  isEdit: Boolean;
  actionType: String;
  threatGeneralDetails: Array<any>;
  disableSystemName: boolean;
  onClose: Function;
  isRecordInDraftStatus: boolean;
  draftID: number | null | undefined;
  isPrimaryThreat: boolean;
};

export const ThreatStage = (props: ThreatStagePropTypes) => {
  const classes = useStyles();
  /** state variables */
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);
  const [cropStages, setCropStages] = useState<any[]>([]);

  const renderAddedThreat = () => {
    if (props.ListOfStages.length === 0) {
      return (
        <>
          {" "}
          <Stack flex={1}>
            <Box>
              <Typography variant="subtitle2">
                Added Threat Stage(s) :
              </Typography>
              <Typography variant="caption" component="span">
                You may reorder the stages by dragging each panel up or down
              </Typography>
              <FormHelperText error={true}>
                {isRuleErrorFlag && <>{"Please add one threat to continue"}</>}
              </FormHelperText>
            </Box>
          </Stack>
        </>
      );
    }
    return (
      <Stack flex={1}>
        <Box>
          <Typography variant="subtitle2">Added Threat Stage(s):</Typography>
          <Typography variant="caption" component="span">
            You may reorder the stages by dragging each panel up or down
          </Typography>
          <FormHelperText error={true}></FormHelperText>
          <SortableTableComponent
            name={"General Details FAQ's"}
            items={props.ListOfStages}
            updateCropStageOrder={(newList: Array<ThreatStageKeys>) => {
              props.updateListOrder(newList);
            }}
            key={props.ListOfStages.length}
            listProps={props}
            secMatchKey={"stageSystemName"}
            displayKey={"threatSystemName"}
            initialState={initialThreatStageDetail}
            selectedRecord={props.selectedStage}
            // isDisabled={props.actionType === ActionType.ADD_TRANSLATION ? true : false}
            isDisabled={
              !props.isPrimaryThreat && props.actionType !== ActionType.CREATE
            }
          />
        </Box>
      </Stack>
    );
  };

  const saveThreatInformation = (payloadForDraftRecord: any) => {
    const apiData = {} as IRequestBody;
    apiData.endPoint = api_endpoints.save_draft_threats;
    apiData.payLoad = payloadForDraftRecord;
    SaveThreatData(apiData, successCallBack, errorCallback);
  };

  const submitThreatInformation = () => {
    const payloadForDraftRecord = preparePayloadForThreat(
      props.threatGeneralDetails,
      props.ListOfStages,
      props.draftID
    );
    saveThreatInformation(payloadForDraftRecord);
  };
  const successCallBack = (response: any, type: string, imgType: string) => {
    switch (type) {
      case "save_threat":
      case "update_threat": {
        /** closing the popup after getting success code*/
        if (response.statusCode === 200 || response.statusCode === 201) {
          props.onClose(true, props.isEdit);
        }
        break;
      }

      default:
        break;
    }
  };
  const errorCallback = (_error: any, type: string, imgType: string) => {
    switch (type) {
      case "save_threat":
      case "update_threat": {
        /** Closing the popup, passing false to display failed creation */
        break;
      }
      default: {
        /**Generic alert to display API fail */
        TriggerToastMessage(
          "Unable to process your request!!!" + type,
          "error"
        );
      }
    }
  };

  const fetchStageSystemName = (value: any) => {
    if (value?.filteredCrops?.name) {
      const apiData = {} as IRequestBody;
      apiData.endPoint = api_endpoints.get_crop_stages.replace(
        "__CROP_SYSTEM_NAME__",
        value.filteredCrops.name
      );
      apiData.payLoad = {
        pagaNo: 0,
        pageSize: 100,
      };
      GetCropStagesWithSystemName(
        apiData,
        (response: any) => {
          if (response.statusCode === 200) {
            setCropStages(response.data.stages);
          }
        },
        () => {
          TriggerToastMessage("Unable to get crop stages!", "error");
        }
      );
    }
  };

  /** Getting latest unique key to see latest
   * selected record for edit
   */
  useEffect(() => {
    props.updateUniqueKey();
  }, [props.selectedStage.threatSystemName]);

  return (
    <Box>
      <Box className={classes.formContainer}>
        <Box margin="1rem 20px">
          <Stack direction="row" spacing={2}>
            <Formik
              validationSchema={ThreatValidationSchema}
              enableReinitialize={true}
              initialValues={{
                threatSystemName: props.selectedStage.threatSystemName,
                stageSystemName: props.selectedStage.stageSystemName,
              }}
              onSubmit={(values, _onSubmitProps: any) => {
                if (
                  checkForDuplicates(
                    props.ListOfStages,
                    {
                      threatSystemName: values.threatSystemName,
                      stageSystemName: values.stageSystemName,
                    },
                    values!.stageSystemName,
                    props.selectedStage.stageSystemName
                  )
                ) {
                  _onSubmitProps.setFieldError(
                    "stageSystemName",
                    "Combination with stage system name & threat system name exists!"
                  );
                  _onSubmitProps.setSubmitting(false);
                  return;
                }
                let data: any = values;
                props.onSave(data);
                _onSubmitProps.resetForm();
                props.updateUniqueKey();
                setIsRuleErrorFlag(false);
                /**ignoring duplicate display order check */
                // checkDuplicate();
              }}
            >
              {({ values }) => (
                <Stack
                  flex={1}
                  borderRight="1px solid #C2C7D0"
                  paddingRight={2}
                >
                  <Form>
                    <Stack spacing={2}>
                      <Box>
                        <Field name="threatSystemname" id="threatSystemname">
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <RenderField
                                name="threatSystemName"
                                label="Threat System Name *"
                                labelKey="threatSystemName"
                                value={values.threatSystemName}
                                options={props.threatGeneralDetails}
                                isDisabled={
                                  props.actionType ===
                                    ActionType.ADD_TRANSLATION ||
                                  !(
                                    props.actionType !==
                                      ActionType.ADD_TRANSLATION &&
                                    !props.selectedStage.hasOwnProperty(
                                      "threatId"
                                    )
                                  )
                                }
                                //isDisabled={(props.actionType === ActionType.ADD_TRANSLATION && !props.selectedStage.threatSystemName)}
                                onChange={(name: any, value: any) => {
                                  setFieldValue(
                                    "threatSystemName",
                                    value.threatSystemName
                                  );
                                  fetchStageSystemName(value);
                                }}
                              />
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="threatSystemName" />
                        </FormHelperText>
                        <Field name="stageSystemName" id="stageSystemName">
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <RenderField
                                name="stageSystemName"
                                label="Stage System Name *"
                                labelKey="stageSystemName"
                                value={
                                  values?.stageSystemName
                                    ? `${values.stageSystemName}`
                                    : ""
                                }
                                options={cropStages}
                                onChange={(name: any, value: any) => {
                                  setFieldValue("stageSystemName", value);
                                }}
                                //isDisabled={(props.actionType === ActionType.ADD_TRANSLATION && !props.selectedStage.threatSystemName)}
                                isDisabled={
                                  props.actionType ===
                                    ActionType.ADD_TRANSLATION ||
                                  !(
                                    props.actionType !==
                                      ActionType.ADD_TRANSLATION &&
                                    !props.selectedStage.hasOwnProperty(
                                      "stageId"
                                    )
                                  )
                                }
                              />
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="stageSystemName" />
                        </FormHelperText>
                      </Box>
                      <Stack alignSelf="self-end" maxWidth={150}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="success"
                          disabled={
                            props.actionType === ActionType.ADD_TRANSLATION ||
                            props.selectedStage.stageSystemName != ""
                          }
                        >
                          {(props.selectedStage?.threatSystemName &&
                            "Update Stage") ||
                            "Add"}
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                </Stack>
              )}
            </Formik>
            {
              /**
               * added stages will be listed here
               */

              renderAddedThreat()
            }
          </Stack>
        </Box>
      </Box>
      <>
        <Divider />
        <div className={classes.formActionFooterBtnHelper}>
          <Stack direction="row" spacing={2}>
            <ButtonWithIcon
              showCreateProgress={false}
              type={CANCEL_TYPE}
              label="Previous"
              onClick={() => {
                props.onPrevious(STEPS.STEP1);
              }}
            />
            <ButtonWithIcon
              showCreateProgress={false}
              type={CREATE_TYPE}
              label={props.isEdit ? "Update" : "Create"}
              onClick={() => {
                props.ListOfStages.length
                  ? submitThreatInformation()
                  : setIsRuleErrorFlag(true);
              }}
            />
          </Stack>
        </div>
      </>
    </Box>
  );
};
