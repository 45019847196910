import * as Yup from "yup";

export const ValidationSchema = () =>
  Yup.object().shape({
    retailerName: Yup.string().required("Retailer Name required"),
    shortAddress: Yup.string().required("Short address required"),
    fullAddress: Yup.string().required("Full address required"),
    phoneNumber: Yup.string()
      .required("Phone number required")
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(10, "Must be a minimum of 10 digits")
      .max(15, "Must be a maximum of 15 digits"),
    pincode: Yup.string()
      // .required("Pincode required")
      .optional()
      .matches(/^[0-9]+$/, "Must be only digits")
      .max(6, "Must be a maximum of 6 digits")
      .nullable(),
    state: Yup.string().required("States required"),
    district: Yup.string().required("Districts required"),
    taluka: Yup.string().required("Taluka required"),
    village: Yup.string().optional().nullable(),
    type: Yup.string().required("Type required"),
    latitude: Yup.string().required("Latitude required"),
    longitude: Yup.string().required("Longitude required"),
    category: Yup.array().required("Category required"),
  });
