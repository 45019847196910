const cropImageMedia = (data: any, storeData: any) => {
    if (!data) return null;

    const url = new URL(data);

    const pathParts = url.pathname.split("/");
    const index = pathParts.indexOf(storeData.countryCode ?? "IN");

    if (index !== -1) {
        return pathParts.slice(index + 1).join("/");
    }

    return data;
}

export default cropImageMedia;
