import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  cropContainerHelper: {
    width: '100%'
  },
  pageTitleNSearchStylesHelper: {
    padding: '0 1.5rem', marginTop: '1.5rem'
  },
  tabContainerHelper: {
    paddingLeft: 16
  },
  tabHelper: {
    textTransform: 'none !important' as 'none'
  },
  panelBoxHelper: {
    display: 'flex',
    alignItems: 'center',
    width: '-webkit-fill-available'
  },
  titleAndSearchHelper: {
    margin: '24px 24px auto',
    display: 'flex',
    width: '-webkit-fill-available'
  },
  chipBoxHelper: {
    margin: '8px 0',
    display: 'flex',
    width: '-webkit-fill-available',
    padding: '0 1.5rem'
  },
  bulkDataBorderBottom: {
    borderBottom: '1px solid #CFD3DB',
    paddingBottom: '1.5rem',
    marginBottom: '1.5rem'
  },
  OddTableRow: {
    padding: '0.3rem 1rem',
    border: '1px solid #CFD3DB',
    '&:nth-child(odd)': {
      backgroundColor: '#F3F4F6'
    }
  },
  tableFirstChild: {
    borderRight: '1px solid #CFD3DB',
    paddingRight: '0.5rem'
  },
  viewImage: {
    background: '#f2f2f2',
    marginBottom: '1rem !important',
    width: '120px',
    height: '120px',
    '& img': {
      width: '100%'
    }
  },
  formContainer: {
    height: '370px',
    overflowY: 'auto',
    position: 'relative',
    scrollbarWidth: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#CFD3DB',
      'border-radius': '15px',

    },
    '&::-webkit-scrollbar-track': {
      background: '#ffffff',
    },
    '& .MuiTableCell-root': {
      minWidth: '115px',
      wordBreak: 'break-all',
      boxSizing: 'border-box',
      '&:last-child': {
        width: '35%'
      }

    }
  },
  threadStagesTableContainer: {
    '& .MuiTableCell-root': {
      width: '10%',
      wordBreak: 'normal',
      boxSizing: 'border-box',
      '&:last-child': {
        width: '65%'
      }

    }

  },

  formFieldsHelperOne: {
    maxWidth: '380px',
    margin: 'auto',
    position: 'relative',
    marginBottom: '1rem'
  },
  formFieldsLanguageHelperOne: {
    margin: '1rem auto',
    position: 'relative',
    '& .MuiBox-root': {
      marginTop: '0',
      width: 'unset',
      margin: 'unset'
    }
  },
  formFieldsHelper: {
    margin: 'auto',
    maxWidth: '430px'
  },
  formFieldsViewHelper: {
    margin: 'auto',
  },
  formFieldsHelperUnique: {
    margin: 'auto',
    maxWidth: '95%'
  },
  modalStylesHelper:
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  languagErrorMsgHepler: {
    position: 'absolute',
    left: '105px'
  },
  stepperHeplerStyles: {
    paddingBottom: '1rem'
  },
  formGlobalContainerHelper: {
    marginTop: '1rem'
  },
  viewCropFormContentHelper: {
    overflow: 'hidden auto',
    height: '420px'

  },
  rmPicture: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  rpPicture: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  formPaper: {
    margin: '30px auto',
    width: '800px',
    minHeight: '500px',
    borderRadius: '6px !important',
    '& .MuiStepper-root': {
      paddingBottom: '1rem'
    }
  },
  formUploadImageHelper: {
    whiteSpace: 'nowrap',
    height: '128px',
    '&.MuiButton-root': {
      background: '#F3F4F6',
      border: '1px dashed #C2C7D0',
      color: '#14151C',
      '&:hover': { background: '#C2C7D0' },
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

  },
  fontVariantHelper: {
    fontWeight: 400
  },
  languageHelpTextForm: {
    marginLeft: '0', position: 'absolute', top: '65px', left: '460px'
  },
  formControlMrtHelper: {
    '&.MuiFormControl-root': {
      marginTop: '8px'
    }
  },
  formActionFooterBtnHelper: {
    width: 'fit-content', margin: '0px 0px auto auto', padding: 16
  },
  textAreaStyles: {
    borderColor: 'rgba(0,0,0,0.23)',
    borderRadius: '5px',
    color: '#696F88',
    resize: 'none',
    height: '96px !important',
    overflowY: 'scroll',
    outlineColor: '#0071CD',
    outlineWidth: '2px',
    borderWidth: '1px',
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    },

  },
  formMarginHelper: {
    marginTop: '8px !important'
  },
  formLanguageErrorHelper: {
    position: 'relative',
    left: '105px',
  },
  newFaqBtnHelper: {
    '&.MuiButton-root': {
      margin: '1rem 0',
      fontSize: '12px',
      '&:hover': {
        background: '#14803C',
        color: '#fff'
      }
    }
  },
  myUploadTabDownloadHelper: {
    display: 'flex',
    alignItems: 'center',
    color: '#14803C !important',
    textDecorationColor: '#14803C !important',
  },
  rowsPerPageStylesHelper: {
    margin: '5px 0', padding: '0 1.5rem'
  },
  customTabBtmBorder:{
    borderBottom: '1px solid #CFD3DB', padding: '0 1.5rem'
  }

});
