import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  uploadBtn: {
    border: '1px solid #CFD3DB',
    padding: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: '4px'
  },
  modelFooterBtns: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    padding: '1rem',
    boxSizing: 'border-box',
    borderTop: '1px solid #CFD3DB'
  },
  innerModelWidth: {
    maxWidth: '450px',
    marginInline: 'auto',
    '& .MuiFormHelperText-root': {
      marginLeft: '0px'
    },
    '& .Mui-disabled': {
      cursor: 'not-allowed',
    }
  },
  addLanguageInnerModel: {
    minWidth: '550px', height: '250px', overflow: 'auto', padding: '1rem'
  },
  tableActionBtnsAlignment: {
    display: 'flex',
    paddingLeft: '1.5rem',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  formPaper: {
    margin: '30px auto',
    width: '750px',
    height: '500px',
    position: 'relative'
  },
  formBoxHelper: {
    overflowY: 'auto', padding: '1rem 0'
  },
  languageTableContainerHepler: {
    marginTop: '1.5rem'
  },
  customTabBtmBorder:{
    borderBottom: '1px solid #CFD3DB', padding: '0 1.5rem'
  },
  rowsPerPageStylesHelper: {
    margin: '5px 0', padding: '0 1.5rem'
  },
  formContainer: {
    height: '370px',
    overflowY: 'auto',
    position: 'relative',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      width: '2px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#CFD3DB',
      'border-radius': '15px',

    },
    '&::-webkit-scrollbar-track': {
      background: '#ffffff',
    },
    '& .MuiTableCell-root': {
      minWidth: '115px',
      wordBreak: 'break-all',
      boxSizing: 'border-box',
      '&:last-child': {
        width: '35%'
      }

    }
  },
  formMrtHelper:{
    marginTop: '8px'
  }
});
