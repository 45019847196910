import { ValidationType } from "./../Common/Constants";
import showToast from "./showToast";

const xlsx = require("json-as-xlsx");
export const ConvertJSONToXLS = (data: any, settings: any) => {
  xlsx(data, settings, () => {
    showToast("File exported successfully", "success");
  });
};
/**
 *
 * @param value represents the selected file by user
 * @returns the error message if inavlid or empty string if valid
 */
export const validateFileExtension = (filename: string): string => {
  const ext =
    RegExp(/\.([^.]+)$/)
      .exec(filename)?.[1]
      ?.toLowerCase() ?? null;

  return ext && ["png", "jpg", "jpeg"].includes(ext)
    ? ""
    : "Invalid image file format";
};

export const validateFileSize = (fileSize: number): string => {
  return fileSize / 1000 / 1000 > 5
    ? "More than 5 MB file size not allowed"
    : "";
};

export const genericValidateImage = (value: any, optional = false) => {
  if (!value && !optional) {
    return "Image required";
  } else if (value instanceof File) {
    const extError = validateFileExtension(value?.name);

    if (extError) {
      return extError;
    }

    const sizeError = validateFileSize(value?.size);
    if (sizeError) {
      return sizeError;
    }
  }
  return "";
};
/**
 * Generic System name Validator
 */
export const genericFormFieldValidation = (
  value: string,
  type: string,
  skipValidate: boolean = false
) => {
  switch (type) {
    case ValidationType.SYS_NM: {
      let sysNmErr: string = "";
      let pattern = /^\w+$/;

      if (!value) {
        return "System name required";
      }

      if (value) {
        if (!skipValidate && !pattern.test(value)) {
          return "Only alphabets and _ are allowed";
        }

        if (value.length > 100) {
          return "System Name should be between 0 to 100 characters";
        }
      }

      return sysNmErr;
    }
    case ValidationType.DESC: {
      /** Description generic validation here */
      return;
    }
    default:
      return;
  }
};
