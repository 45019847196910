import { Box, Modal, Paper, Stack } from "@mui/material";
import * as React from "react";
import ButtonWithIcon from "../Buttons";
import ModalHeader from "../Modal/ModalHeader";
interface IModalProps {
  isOpen: boolean;
  onModalClose: Function;
  title: string;
  onAddNew: Function;
  onBulkUpload: Function;
}
const AddNewContentModal: React.FC<IModalProps> = (props: IModalProps) => {
  return (
    <>
      <Modal
        open={props.isOpen}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper
          style={{
            margin: "30px auto",
            maxWidth: "750px",
            minWidth: "500px",
          }}
        >
          <ModalHeader
            header={props.title}
            onClose={() => {
              props.onModalClose();
            }}
            showDelete={false}
            showEdit={false}
          />
          <Box
            style={{
              overflowY: "auto",
              margin: "2rem 0",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack sx={{ width: "330px" }} spacing={4}>
                <ButtonWithIcon
                  label={"Create new data"}
                  type={"MODAL CREATE"}
                  onClick={() => {
                    props.onAddNew();
                  }}
                />
                <ButtonWithIcon
                  label={"Bulk upload data"}
                  type={"MODAL CANCEL"}
                  onClick={() => {
                    props.onBulkUpload();
                  }}
                />
              </Stack>
            </Box>
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default AddNewContentModal;
