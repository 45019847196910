import _ from './../../Utils/lodash';
import { LOGOUT, USER_LOGIN_DETAILS } from './../action/actions-constants';

export interface IUserDetails {
    name: string
}

const initialState: IUserDetails = {
    name: ''
};

export interface IActionParams {
    payload: any;
    type: String;
}
export function userReducer(state: IUserDetails = initialState, { type, payload }: IActionParams): IUserDetails {
    switch (type) {
        case USER_LOGIN_DETAILS: {
            return _.extend({}, state, payload);
        }
        case LOGOUT: {
            return _.extend({}, initialState);
        }
        default: { return state; }
    }
}
