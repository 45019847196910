import _ from "./../../Utils/lodash";
import {
  LIST_COUNTRIES,
  LIST_CROPS,
  LIST_LANGUAGES,
  LIST_MODULES,
  LIST_NOTIFICATION_TYPES,
  LOGOUT,
  RETAILER_MOBILE_LENGTH,
} from "./../action/actions-constants";
import { IActionParams } from "./user_reducer";

type AvailableCropType = {
  label: string;
};

export type LanguageRecordType = {
  displayName: string;
  languageCode: string;
  languageId: number;
  languageName: string;
  inActiveFlag: number;
};

export type CropRecordType = {
  name: string;
  id: number;
};

type StateType = {
  id: 0;
  languageId: number;
  name: string;
  shortCode: string;
  uniqueStateId: number;
};

type CountryType = {
  baseUrl: string;
  countryCode: string;
  countryName: string;
};

interface IListOflanguages {
  languageList: Array<LanguageRecordType>;
  pageSize: number;
  pageNo: number;
  totalPages: number;
}

interface IlistOfCrops {
  cropsList: Array<CropRecordType>;
  pageNumber: number;
  pageSize: number;
}

export type GenericAppConfig = {
  ListOfCrops: IlistOfCrops;
  ListOfLanguages: IListOflanguages;
  ListOfModules: Array<any>;
  ListOfNotification: Array<any>;
  ListOfStates: Array<StateType>;
  ListOfCountries: Array<CountryType>;
  RetailerMobileLength: number;
};

export const GenericInitialState: GenericAppConfig = {
  ListOfCrops: {} as IlistOfCrops,
  ListOfLanguages: {} as IListOflanguages,
  ListOfStates: [],
  ListOfNotification: [],
  ListOfModules: [],
  ListOfCountries: [],
  RetailerMobileLength: 0,
};

export const genericReducer = (
  state: GenericAppConfig = GenericInitialState,
  { type, payload }: IActionParams
): GenericAppConfig => {
  switch (type) {
    case LIST_CROPS: {
      return _.extend({}, state, { ListOfCrops: payload });
    }
    case LIST_NOTIFICATION_TYPES: {
      return _.extend({}, state, { ListOfNotification: payload });
    }
    case LIST_LANGUAGES: {
      return _.extend({}, state, { ListOfLanguages: payload });
    }
    case LIST_MODULES: {
      return _.extend({}, state, { ListOfModules: payload });
    }
    case LIST_COUNTRIES: {
      return _.extend({}, state, { ListOfCountries: payload });
    }
    case RETAILER_MOBILE_LENGTH: {
      return _.extend({}, state, { RetailerMobileLength: payload });
    }
    case LOGOUT: {
      return _.extend({}, GenericInitialState);
    }
    default: {
      return state;
    }
  }
};

export type CropDataType = {
  id: number;
  name: string;
  imageurl: number;
  imageLocalPath: string;
  isactive: boolean;
  selectedFlag: boolean;
  nameTranslation: string;
  languageId: number;
  languageName: string;
  createdDate: string;
};
