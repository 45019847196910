import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    basicTbHeaderHelper: {
        '& .MuiTableCell-root': {
            fontWeight: '700 !important',
            color: '#232630 !important',
            fontSize: '12px',
            verticalAlign: 'baseline !important',
            textAlign: 'left',
            padding: ' 1rem 1rem 0.2rem',
            lineHeight: '1rem'
        }
    },
    basicTbBodyHelper: {
        '& .MuiTableCell-root': {
            verticalAlign: 'top !important',
            wordBreak:'break-word'

        }
    },
    stageImageDiv: {
        width: 70,
        height: 70,
        background: '#ccc'
    }
})