import { Box, Divider, Modal, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api_endpoints, { base_url } from "../../../Api/end_points";
import { TableComponent } from "../../../Common/BasicDataTable/BasicDataTable";
import { ActionType } from "../../../Common/Constants";
import CustomTabs from "../../../Common/CustomTabs";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { IRequestBody } from "../../../Constants/interfaces";
import { RootState } from "../../../redux/reducers";
import { LanguageRecordType } from "../../../redux/reducers/generic_reducer";
import { StagesToNewProduct } from "../stepperStages";
import { Styles } from "../style";
import { GetCPDetails } from "../CPprouctsAPICalls";
import {
  DosAndDonts,
  GrowerTestimonials,
  KeyFeatures,
  Recommendations,
  TargetThreats,
} from "./TableHeaders";
import { removeSpacesImagePath } from "../../../Utils/removeSpaceImagePath";
import _ from "lodash";
import {createColumn} from "../../../Utils/genericUtils";

type CPGeneralDataTypes = {
  fieldName: string;
  fieldLabel: string;
};
const CPGeneralData: Array<CPGeneralDataTypes> = [
  createColumn("Product Images", "media"),
  createColumn("Product Image", "imagePath"),
  createColumn("Product Title", "productTitle"),
  createColumn("Product System Name", "productSystemName"),
  createColumn("Category", "category"),
  createColumn("Crop", "cropSystemName"),
  createColumn("Dosage", "dosage"),
  createColumn("When to Use", "whenToUse"),
  createColumn("How to Apply", "howToUse"),
  createColumn("Applicable Crop", "applicableCrop"),
  createColumn("Key Benefits 1", "benefit1"),
  createColumn("Key Benefits 2", "benefit2"),
  createColumn("Key Benefits 3", "benefit3"),
];
interface RenderFieldProps extends CPGeneralDataTypes {
  data: any;
}
const getLabel = (label: string) => {
  const classes = Styles();
  return (
    <Typography
      className={classes.labelMrbHelper}
      variant="subtitle2"
      fontSize={14}
    >
      {label}
    </Typography>
  );
};
const RenderField = (props: RenderFieldProps) => {
  const { fieldName, fieldLabel, data } = props;
  const classes = Styles();

  switch (fieldName) {
    case "imagePath":
      return (
        <Stack direction="row" spacing={10}>
          {getLabel(fieldLabel)}
          <div className={classes.viewImage}>
            {data?.[fieldName] && <img src={data?.[fieldName]} alt="Product" />}
          </div>
        </Stack>
      );
    case "media":
      return (
        <Stack direction="row" spacing={1}>
          <Box sx={{ width: 80, minWidth: 80 }}>{getLabel(fieldLabel)}</Box>
          <Box
            sx={{
              flexGrow: 1,
              overflowX: "auto",
              display: "flex",
              flexDirection: "row",
              gap: 3,
              flexWrap: "nowrap",
              paddingBottom: 1,
            }}
          >
            {data?.[fieldName]
              ?.sort((a: any, b: any) => a.displayOrder - b.displayOrder)
              .slice(0, 5)
              .map((item: any) => {
                return (
                  item.path && (
                    <Box
                      key={item.displayOrder}
                      sx={{ width: 120, minWidth: 120, height: 120 }}
                    >
                      <img
                        src={item.path}
                        alt="Product"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Box>
                  )
                );
              })}
          </Box>
        </Stack>
      );

    default:
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography
            className={classes.labelMrbHelper}
            fontSize={14}
            variant="body1"
          >
            {data && data[fieldName] ? data[fieldName] : "N/A"}
          </Typography>
        </>
      );
  }
};
const ViewCpProducts: React.FC<any> = (props) => {
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
    };
  });
  // states
  const [activeTab, setActiveTab] = useState<number>(0);
  const [cpDetails, setCPDetails] = useState<any>();

  const classes = Styles();
  // variables

  const handleClose = () => {
    props.onCloseViewCrop();
  };

  const getStepStages = () => {
    return StagesToNewProduct.map((stepObj: any) => {
      return stepObj.stepLabel;
    });
  };

  const changeActiveTab = (_: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  type dosAndDontType = {
    type: string;
    systemName: string;
    text: string;
    displayOrder: number;
    id: number;
  };
  const processCropStageCalendarSystemInfo = (response: any) => {
    let data: any = response.payload;
    /** step-1 data format: START */
    let dosAndDont: Array<dosAndDontType> = [];
    if (data.dos && data.dos.length) {
      [...data.dos].map((item: dosAndDontType) => {
        item.type = "Do";
        dosAndDont.push(item);
      });
    }
    if (data.donts && data.donts.length) {
      [...data.donts].map((item: dosAndDontType) => {
        item.type = `Don't`;
        dosAndDont.push(item);
      });
    }
    /** step-1 data format: END */
    /** step-5 data format: START */
    let threatData: Array<any> = [];
    if (data.threatStageMapping && data.threatStageMapping.length) {
      [...data.threatStageMapping].map((item: any) => {
        item.stages.map((s: any) => {
          let tempObj = { ...item };
          tempObj.stages = s;
          threatData.push(tempObj);
        });
      });
    }

    const threatDataSort = _.sortBy(threatData, ["displayOrder", "asc"]);
    const growerTestimonialsSort = _.sortBy(data.growerTestimonials, [
      "displayOrder",
      "asc",
    ]);

    /** step-5 data format: END */
    const CropInfoObj = {
      GeneralDetails: {
        id: response.id,
        languageId: data.languageId,
        languageName: data.languageName,
        languageCode: response.languageCode,
        imagePath: removeSpacesImagePath(
          data.productSystemName,
          data.imagePath
        ),
        media: data.media,
        productTitle: data.productTitle,
        productSystemName: data.productSystemName,
        category: data.category,
        cropSystemName: data.cropSystemName,
        dosage: data.dosage,
        whenToUse: data.whenToUse,
        howToUse: data.howToUse,
        applicableCrop: data.applicableCrop,
        benefit1: data.benefit1,
        benefit2: data.benefit2,
        benefit3: data.benefit3,
      },
      dosAndDont: _.sortBy(_.sortBy(dosAndDont, "displayOrder"), "type"),
      keyFeatures: data.keyFeatures
        ?.map((feature: any) => ({
          ...feature,
          imagePath: removeSpacesImagePath(
            feature.systemName,
            feature.imagePath
          ),
        }))
        .sort((a: any, b: any) => a.displayOrder - b.displayOrder),
      growerTestimonials: [...growerTestimonialsSort],
      targetThreats: threatDataSort,
      recommendations: data.recommendations ?? [],
    };
    setCPDetails({ ...CropInfoObj });
  };
  /** STEP-1 */
  const renderGeneralDetailsTab = () => {
    return (
      <Box className={classes.formContainer}>
        <Box className={classes.formFieldsHelper}>
          <Box className={classes.formFieldsHelperOne}>
            <LanguageDropdown
              isDisabled={true}
              selectedLanguage={cpDetails?.GeneralDetails?.languageName}
              key={cpDetails?.GeneralDetails?.languageName}
            />
          </Box>
          <Box className={classes.formFieldsHelperUnique}>
            {CPGeneralData.map(
              (notification: CPGeneralDataTypes, index: number) => {
                if (
                  notification.fieldName === "imagePath" &&
                  cpDetails?.GeneralDetails?.["media"]
                )
                  return null;

                if (
                  notification.fieldName === "media" &&
                  !cpDetails?.GeneralDetails?.["media"]
                )
                  return null;

                return (
                  <>
                    <RenderField
                      fieldName={notification.fieldName}
                      fieldLabel={notification.fieldLabel}
                      data={cpDetails?.GeneralDetails}
                    />
                    {index < CPGeneralData.length - 1 && (
                      <Divider className={classes.labelMrbHelper} />
                    )}
                  </>
                );
              }
            )}
          </Box>
        </Box>
      </Box>
    );
  };
  /** STEP-2 */
  const renderCropGrowthStages = () => {
    return (
      <>
        <Box className={classes.formContainer}>
          <TableComponent
            headers={[...DosAndDonts]}
            data={cpDetails.dosAndDont || []}
          />
        </Box>
      </>
    );
  };
  /** STEP-3 */
  const renderKeyFeatures = () => {
    return (
      <>
        <Box className={classes.formContainer}>
          <TableComponent
            headers={[...KeyFeatures]}
            data={cpDetails.keyFeatures || []}
          />
        </Box>
      </>
    );
  };
  /** STEP-4 */
  const renderGrowerTestimonials = () => {
    return (
      <>
        <Box className={classes.formContainer}>
          <TableComponent
            headers={[...GrowerTestimonials]}
            data={cpDetails.growerTestimonials || []}
          />
        </Box>
      </>
    );
  };
  /** STEP-5 */
  const renderTargetThreats = () => {
    return (
      <>
        <Box className={classes.formContainer}>
          <TableComponent
            headers={[...TargetThreats]}
            data={cpDetails.targetThreats || []}
          />
        </Box>
      </>
    );
  };
  /** STEP-6 */
  const renderRecommendations = () => {
    return (
      <>
        <Box className={classes.formContainer}>
          <TableComponent
            headers={[...Recommendations]}
            data={cpDetails.recommendations || []}
          />
        </Box>
      </>
    );
  };
  const fetchCPDetails = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = "";
    if (props.lngId) {
      /** this api call is for published CP products */
      apiData.endPoint = api_endpoints.get_cp_by_sys_nm.replace(
        "__SYSTEM_NAME__",
        props.sysNm
      );
      const cropLanguageObject: LanguageRecordType[] =
        storeData.languages.filter((lngRcd: LanguageRecordType) => {
          return lngRcd.languageId === props.lngId;
        });
      if (cropLanguageObject.length > 0) {
        apiData.customHeaders = {
          "language-code": cropLanguageObject[0].languageCode,
        };
      }
    } else {
      /** this api call is for newly created/unpublished CP products */
      apiData.endPoint = `crops/cp-product-drafts/${props.id}`;
    }

    GetCPDetails(apiData)
      .then((response: any) => {
        if (response.statusCode === 200) {
          processCropStageCalendarSystemInfo(response.data);
          return;
        }
        throw new Error(response.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleEditCP = () => {
    props.onCloseViewCrop();
    props.onEditClick(ActionType.UPDATE, {
      id: cpDetails.GeneralDetails.id ? cpDetails.GeneralDetails.id : props.id,
      languageId: cpDetails.GeneralDetails.languageId,
      system: cpDetails.GeneralDetails.productSystemName,
      languageCode: cpDetails.GeneralDetails.languageCode,
    });
  };
  useEffect(() => {
    fetchCPDetails();
  }, []);

  return (
    <Modal open={true} disableEscapeKeyDown={true}>
      <Paper className={classes.formPaper}>
        <ModalHeader
          header={`CP Products: ${props.sysNm}`}
          onClose={() => {
            handleClose();
          }}
          showEdit={props.canShowEditButton}
          editHandler={() => handleEditCP()}
        />
        <Box>
          <Box>
            <CustomTabs
              onChange={changeActiveTab}
              tabsList={getStepStages()}
              value={activeTab}
            />
          </Box>
          {activeTab === 0 && renderGeneralDetailsTab()}
          {activeTab === 1 && renderCropGrowthStages()}
          {activeTab === 2 && renderKeyFeatures()}
          {activeTab === 3 && renderGrowerTestimonials()}
          {activeTab === 4 && renderTargetThreats()}
          {activeTab === 5 && renderRecommendations()}
        </Box>
      </Paper>
    </Modal>
  );
};

export default ViewCpProducts;
