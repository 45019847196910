export type CropsTableColumnType = {
    fieldLabel: string;
    fieldName: string;
};

export const CropsTableColumnType: Array<CropsTableColumnType> = [
    {
        fieldLabel: 'Crop ID',
        fieldName: 'id'
    },
    {
        fieldLabel: 'Title',
        fieldName: 'title'
    },
    {
        fieldLabel: 'Language',
        fieldName: 'language'
    },
    {
        fieldLabel: 'Image',
        fieldName: 'image'
    },
    {
        fieldLabel: 'Last Updated On',
        fieldName: 'lastUpdatedOn'
    },

    {
        fieldLabel: 'Status',
        fieldName: 'cropColumnStatus'
    }

];

export const CropsMyUploadsTableColumnType: Array<CropsTableColumnType> = [
    {
        fieldLabel: 'File Name',
        fieldName: 'fileName'
    },
    {
        fieldLabel: 'Uploaded On',
        fieldName: 'cropsUploadDate'
    },
    {
        fieldLabel: 'Status',
        fieldName: 'comnMyUploadStatus'
    }

];
