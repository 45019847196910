import _ from './../../Utils/lodash';
import { LOGOUT, SHOW_LOADER } from './../action/actions-constants';
import { IActionParams } from './user_reducer';

export type LoaderState = {
    showLoader: boolean
};

const LoaderInitialState: LoaderState = {
    showLoader: false
};
export const loaderReducer = (state: LoaderState = LoaderInitialState, { type, payload }: IActionParams): LoaderState => {
    switch (type) {
        case SHOW_LOADER: {
            return _.extend({}, state, { showLoader: payload });
        }
        case LOGOUT: {
            return _.extend({}, LoaderInitialState);
        }
        default: {
            return state;
        }
    }
};
