import { Configuration } from "@azure/msal-browser/dist/config/Configuration";

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID!,
    authority:process.env.REACT_APP_AUTHORITY?.toString(),
    redirectUri: process.env.REACT_APP_REDIRECT_URL?.toString(),
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL?.toString(),
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  // scopes: ["User.Read"],
  scopes: ["User.Read", "openid"],
};

export const graphConfig = {
  graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
};
