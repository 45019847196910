import {
  Box,
  Modal,
  Paper,
  Stack,
  FormHelperText,
  TextField,
  Divider,
  Button,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { api_service } from "../../../../Api/api_service";
import api_endpoints, { base_url } from "../../../../Api/end_points";
import { ActionType } from "../../../../Common/Constants";
import LanguageDropdown from "../../../../Common/LanguageDropdown";
import ModalHeader from "../../../../Common/Modal/ModalHeader";
import { UploadFile } from "../../../../Common/UploadFile";
import { IRequestBody } from "../../../../Constants/interfaces";
import { TriggerToastMessage } from "../../../../Utils/toastTrigger";
import { usePlantixCPProduct } from "../../PlantixCPProducts";
import { useStyles } from "../style";
import { PlantixCPValidationSchema } from "./PlantixCPValidationSchema";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/reducers";
import { RoutePaths } from "../../../../Constants/routeConstants";
import { useNavigate } from "react-router-dom";

const { plantix_cp_product_drafts } = api_endpoints;

interface CreateOrEditModalProps {
  actionType: string;
  showCreateOrEditModal: boolean;
  onShowCreateOrEditModal: (toggle: boolean) => void;
  lngId?: number;
  sysNm?: string;
  id?: string;
}

type LangObjectType = {
  languageId: number | undefined;
  languageName: string;
  languageCode: string;
};

const { plantix_cp_product_detail } = api_endpoints;

const CreateOrEditModal: React.FC<CreateOrEditModalProps> = (props) => {
  const nav = useNavigate();
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
    };
  });

  const classes = useStyles();
  const { isEdit, isTranslate, selectedSystemName, selectedLangId } =
    usePlantixCPProduct();
  const [data, setData] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const [selectedLangObj, setSelectedLangObj] = useState<LangObjectType>({
    languageId: 0,
    languageName: "",
    languageCode: "",
  });
  const [_isEdit, set_IsEdit] = useState(false);

  const handleLanguageChange = (language: string) => {
    let langArrData = language.split("##");
    let langData: LangObjectType = {
      languageId: parseInt(langArrData[0]),
      languageName: langArrData[1],
      languageCode: langArrData[2],
    };
    setSelectedLangObj(langData);
  };

  const fetchPlantixCPDetails = () => {
    if (selectedSystemName || props?.id) {
      const apiData = {} as IRequestBody;
      apiData.domain = base_url;
      if (!props?.id) {
        const langCode =
          storeData.languages.find(
            (lang) =>
              lang.languageId === selectedLangId ||
              lang.languageId === props.lngId
          )?.languageCode ?? "en";
        apiData.customHeaders = {
          "language-code": langCode,
        };
        apiData.endPoint = plantix_cp_product_detail.replace(
          "__systemName__",
          selectedSystemName
        );
      } else {
        /** this api call is for newly created/unpublished Plantix CP products */
        apiData.endPoint = `plantix/cp-product-drafts/${props.id}`;
      }

      setLoading(true);
      api_service
        .get(apiData)
        ?.then((res) => {
          setData({
            ...res?.data?.data?.payload,
            languageCode: res.data?.data?.languageCode ?? "en",
            id: res.data?.data?.id,
          })
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    if (isEdit || isTranslate || props?.id) {
      if (isEdit || props?.id) set_IsEdit(true);
      fetchPlantixCPDetails();
    } else {
      setData({});
    }
  }, [isEdit, isTranslate, selectedSystemName, selectedLangId, props?.id]);

  return (
    <Modal open={props.showCreateOrEditModal} disableEscapeKeyDown={true}>
      <Paper className={classes.formPaper}>
        <Paper elevation={3} square>
          <ModalHeader
            header={
              _isEdit
                ? "Update CP Product"
                : isTranslate
                ? "Add Translation"
                : "New CP Product"
            }
            showDelete={false}
            showEdit={false}
            onClose={() => {
              if (confirm("You may lose your data.?")) {
                props.onShowCreateOrEditModal(false);
                set_IsEdit(false);
                setSelectedLangObj({
                  languageId: 0,
                  languageName: "",
                  languageCode: "",
                });
              }
            }}
          />
          {!isLoading && (
            <Formik
              initialValues={{
                languageId: !isTranslate ? data.languageId : "",
                languageName: !isTranslate ? data.languageName : "",
                languageCode: !isTranslate ? data.languageCode : "",
                imagePath: data.imagePath,
                productTitle: data.productTitle,
                productSystemName: data.productSystemName,
                productId: data.productId,
                id: data.id,
                category: data.category,
                cropSystemName: data.cropSystemName ?? "",
                dosage: data.dosage,
                whenToUse: data.whenToUse,
                howToUse: data.howToUse,
                applicableCrop: data.applicableCrop ?? "",
                benefit1: data.benefit1,
                benefit2: data.benefit2,
                benefit3: data.benefit3,
                statusId: data.statusId,
              }}
              validationSchema={PlantixCPValidationSchema}
              onSubmit={(values) => {
                const saveOrEdit = (imagePath: string) => {
                  let attributes: any = {};
                  attributes.id = values.id;
                  attributes.smartGrowerProductId = null;
                  attributes.productSystemName = values.productSystemName;
                  attributes.smartGrowerCropId = null;
                  // attributes.cropSystemName = null;
                  attributes.languageId = values.languageId;
                  attributes.languageName = values.languageName;
                  attributes.languageCode = values.languageCode;
                  let payload: any = {};
                  payload.productId = values.productId;
                  payload.productSystemName = values.productSystemName;
                  payload.productTitle = values.productTitle;
                  // payload.cropSystemName = null;
                  payload.languageId = values.languageId;
                  payload.languageName = values.languageName;
                  payload.imagePath = imagePath;
                  payload.category = values.category;
                  payload.dosage = values.dosage;
                  payload.whenToUse = values.whenToUse;
                  payload.howToUse = values.howToUse;
                  payload.benefit1 = values.benefit1;
                  payload.benefit2 = values.benefit2;
                  payload.benefit3 = values.benefit3;
                  payload.applicableCrop = values.applicableCrop;
                  payload.translation = true;
                  attributes.payload = payload;
                  attributes.statusId = values.statusId;
                  const apiData = {} as IRequestBody;
                  apiData.domain = base_url;
                  apiData.endPoint = plantix_cp_product_drafts;
                  apiData.payLoad = attributes;
                  api_service
                    .post(apiData)
                    .then((res) => {
                      console.log(res);

                      props.onShowCreateOrEditModal(false);
                      setSelectedLangObj({
                        languageId: 0,
                        languageName: "",
                        languageCode: "",
                      });

                      if (res && (res.status === 200 || res.status === 201)) {
                        const SuccessPageURL = RoutePaths.successPage
                          .replace(":module", "Plantix Cp Products")
                          .replace(":mode", _isEdit ? "updated" : "created");
                        nav(SuccessPageURL);
                      } else {
                        TriggerToastMessage(
                          "Save/Update Plantix CP Product falied !!",
                          "error"
                        );
                      }
                    })
                    .catch((err) => console.log(err));
                };

                const validateSaveOrEdit = () => {
                  if (typeof values.imagePath === "string") {
                    return saveOrEdit(values.imagePath);
                  } else {
                    const apiData = {} as IRequestBody;
                    apiData.domain = base_url;
                    apiData.endPoint = api_endpoints.fileupload_S3bucket;

                    const payload = {
                      productSystemName: values.productSystemName,
                      type: "PLANTIX_CP_PRODUCT",
                      fileNameWithExtension: `${
                        values.productSystemName
                      }.${values.imagePath.name.split(".").at(-1)}`,
                    };

                    let formData = new FormData();
                    formData.append("file", values.imagePath);
                    formData.append("data", JSON.stringify(payload));
                    apiData.payLoad = formData;

                    return api_service
                      .post(apiData)
                      .then((res) => {
                        return saveOrEdit(res.data.data.imageUrl);
                      })
                      .catch(() =>
                        TriggerToastMessage(
                          "Failed to upload the image, try again later.",
                          "error"
                        )
                      );
                  }
                };

                if (!_isEdit) {
                  const apiData = {} as IRequestBody;
                  apiData.domain = base_url;
                  apiData.endPoint =
                    api_endpoints.plantix_cp_product_prensence.replace(
                      "__systemName__",
                      values.productSystemName
                    );

                  apiData.customHeaders = {
                    "language-code": values.languageCode ?? "en",
                  };

                  api_service
                    .get(apiData)
                    ?.then((res) => {
                      if (!res.data.data.isCPProductExist) {
                        return validateSaveOrEdit();
                      } else {
                        TriggerToastMessage(
                          `Product with ${values.productSystemName} is already exist`,
                          "error"
                        );
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                      TriggerToastMessage(
                        "Verify System name API failed, try later.",
                        "error"
                      );
                    });
                }

                if (_isEdit) {
                  validateSaveOrEdit();
                }
              }}
            >
              {({ values, setFieldTouched, setFieldValue }) => {
                return (
                  <Form id="plantix-cp-products-form">
                    <Box className={classes.formContainer}>
                      <Box className={classes.formFieldsHelperOne}>
                        <LanguageDropdown
                          onChangeLanguage={(langData: string) => {
                            handleLanguageChange(langData);
                            setFieldValue(
                              "languageId",
                              langData.split("##")[0]
                            );
                            setFieldValue(
                              "languageName",
                              langData.split("##")[1]
                            );
                            setFieldValue(
                              "languageCode",
                              langData.split("##")[2]
                            );
                          }}
                          selectedLanguage={
                            values.languageName || selectedLangObj.languageName
                          }
                          isDisabled={_isEdit}
                          displayPrimaryLanguage={!isTranslate}
                        />
                        <FormHelperText error={true} className="langError">
                          <ErrorMessage name="languageName" />
                        </FormHelperText>
                      </Box>
                      <Box className={classes.formFieldsHelper}>
                        <Field
                          name="imagePath" >
                          {(imageProps: any) => {
                            const { form } = imageProps;
                            const { setFieldValue } = form;

                            return (
                              <UploadFile
                                isRequired
                                name="imagePath"
                                acceptedFileTypes={[
                                  "image/png",
                                  "image/jpg",
                                  "image/jpeg",
                                ]}
                                maxFileSize={5}
                                imageUrl={values.imagePath}
                                onChange={(data: any) =>
                                  setFieldValue("imagePath", data)
                                }
                                canRemoveOrReplace={!isTranslate}
                              />
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="imagePath" />
                        </FormHelperText>
                        <Field name="productTitle">
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <FormControl
                                className={classes.formControlMrtHelper}
                                fullWidth
                              >
                                <Typography variant="subtitle2">
                                  Product Title *
                                </Typography>
                                <TextField
                                  name="productTitle"
                                  type="text"
                                  value={values?.productTitle}
                                  fullWidth
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      "productTitle",
                                      event.target.value
                                    );
                                  }}
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="productTitle" />
                        </FormHelperText>
                        <Field
                          name="productSystemName">
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <FormControl
                                className={classes.formControlMrtHelper}
                                fullWidth
                              >
                                <Typography variant="subtitle2">
                                  Product System Name *
                                </Typography>
                                <TextField
                                  name="productSystemName"
                                  type="text"
                                  value={values?.productSystemName}
                                  fullWidth
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      "productSystemName",
                                      event.target.value
                                        .trim()
                                        .toLocaleUpperCase()
                                    );
                                  }}
                                  disabled={isTranslate || _isEdit}
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="productSystemName" />
                        </FormHelperText>
                        <Field
                          name="category">
                          {(_props: any) => {
                            const { form } = _props;
                            const { setFieldValue } = form;
                            return (
                              <FormControl
                                className={classes.formControlMrtHelper}
                                fullWidth
                              >
                                <Typography variant="subtitle2">
                                  Category
                                </Typography>
                                <TextField
                                  name="category"
                                  type="text"
                                  value={values?.category}
                                  fullWidth
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setFieldValue(
                                      "category",
                                      event.target.value
                                        .trim()
                                        .toLocaleUpperCase()
                                    );
                                    setFieldTouched("category");
                                  }}
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="category" />
                        </FormHelperText>
                        <Field name="dosage">
                          {() => {
                            return (
                              <FormControl
                                className={classes.formMarginHelper}
                                fullWidth
                              >
                                <Typography variant="subtitle2">
                                  Dosage *
                                </Typography>
                                <TextField
                                  name="dosage"
                                  type="text"
                                  value={values.dosage}
                                  onChange={(event: any) =>
                                    setFieldValue("dosage", event.target.value)
                                  }
                                />
                              </FormControl>
                            );
                          }}
                        </Field>
                        <FormHelperText error={true}>
                          <ErrorMessage name="dosage" />
                        </FormHelperText>
                        <Stack flex={1}>
                          <FormControl className={classes.formMarginHelper}>
                            <Typography fontSize={14} variant="subtitle2">
                              When to Use *
                            </Typography>
                            <Field name="whenToUse">
                              {() => {
                                return (
                                  <TextareaAutosize
                                    onBlur={
                                      // TODO
                                      (event) => {
                                        setFieldValue(
                                          "whenToUse",
                                          event.target.value
                                        );
                                      }
                                    }
                                    name="whenToUse"
                                    className={classes.textAreaStyles}
                                    maxLength={1024}
                                    defaultValue={values.whenToUse}
                                  />
                                );
                              }}
                            </Field>
                            <FormHelperText error={true}>
                              <ErrorMessage name="whenToUse" />
                            </FormHelperText>
                          </FormControl>
                        </Stack>
                        <Stack flex={1}>
                          <FormControl
                            className={classes.formFiledMrHelper}
                            fullWidth
                          >
                            <Typography fontSize={14} variant="subtitle2">
                              How to Apply *
                            </Typography>
                            <Field name="howToUse">
                              {() => {
                                return (
                                  <TextareaAutosize
                                    name="howToUse"
                                    className={classes.textAreaStyles}
                                    maxLength={1024}
                                    onBlur={(
                                      event: React.ChangeEvent<HTMLTextAreaElement>
                                    ) => {
                                      setFieldValue(
                                        "howToUse",
                                        event.target.value
                                      );
                                    }}
                                    defaultValue={values.howToUse}
                                    // key={values.howToUse}
                                  />
                                );
                              }}
                            </Field>
                            <FormHelperText error={true}>
                              <ErrorMessage name="howToUse" />
                            </FormHelperText>
                          </FormControl>
                        </Stack>
                        <FormControl
                          className={classes.formFiledMrHelper}
                          fullWidth
                        >
                          <Typography fontSize={14} variant="subtitle2">
                            Key Benefits 1 *
                          </Typography>
                          <Field name="benefit1">
                            {() => {
                              return (
                                <TextareaAutosize
                                  name="benefit1"
                                  className={classes.textAreaStyles}
                                  maxLength={1024}
                                  onBlur={(
                                    event: React.ChangeEvent<HTMLTextAreaElement>
                                  ) => {
                                    setFieldValue(
                                      "benefit1",
                                      event.target.value
                                    );
                                  }}
                                  defaultValue={values.benefit1}
                                  // key={values.benefit1}
                                />
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="benefit1" />
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          className={classes.formFiledMrHelper}
                          fullWidth
                        >
                          <Typography fontSize={14} variant="subtitle2">
                            Key Benefits 2
                          </Typography>
                          <Field name="benefit2">
                            {() => {
                              return (
                                <TextareaAutosize
                                  name="benefit2"
                                  className={classes.textAreaStyles}
                                  maxLength={1024}
                                  onBlur={(
                                    event: React.ChangeEvent<HTMLTextAreaElement>
                                  ) => {
                                    setFieldValue(
                                      "benefit2",
                                      event.target.value
                                    );
                                  }}
                                  defaultValue={values.benefit2}
                                  // key={values.benefit2}
                                />
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="benefit2" />
                          </FormHelperText>
                        </FormControl>
                        <FormControl
                          className={classes.formFiledMrHelper}
                          fullWidth
                        >
                          <Typography fontSize={14} variant="subtitle2">
                            Key Benefits 3
                          </Typography>
                          <Field name="benefit3">
                            {() => {
                              return (
                                <TextareaAutosize
                                  name="benefit3"
                                  className={classes.textAreaStyles}
                                  maxLength={1024}
                                  onBlur={(
                                    event: React.ChangeEvent<HTMLTextAreaElement>
                                  ) => {
                                    setFieldValue(
                                      "benefit3",
                                      event.target.value
                                    );
                                  }}
                                  defaultValue={values.benefit3}
                                  // key={values.benefit3}
                                />
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="benefit3" />
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </Box>
                    <Divider />
                    <Box className={classes.modalFooterActionBtnsHelper}>
                      <Button
                        color="success"
                        variant="contained"
                        data-testid={"submit-button"}
                        className={classes.buttonHelperNext}
                        type="submit"
                      >
                        {_isEdit &&
                        props.actionType !== ActionType.ADD_TRANSLATION
                          ? "Update"
                          : "Create"}
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
          )}
        </Paper>
      </Paper>
    </Modal>
  );
};

export default CreateOrEditModal;
