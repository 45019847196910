import * as Yup from "yup";

export const GeneralSchemaValidation = () =>
  Yup.object().shape({
    languageName: Yup.string().required("Please select language"),
    filteredCrops: Yup.object()
      .nullable()
      .shape({
        label: Yup.string().trim().strict(),
        id: Yup.number().nullable(),
      })
      .required("Please select a crop"),
    // threatSystemName: Yup.string()
    //   .trim("Starting & trailing spaces are not allowed")
    //   .strict()
    //   .min(0, "Threat System Name should be minimum 0 characters")
    //   .max(100, "Threat System Name should not exceed 100 characters")
    //   .matches(/^[0-9a-zA-Z_]+$/, {
    //     message: "Only alphabets and _ are allowed",
    //     excludeEmptyString: true,
    //   })
    //   .required("Threat System Name required"),
    threatDisplayLabel: Yup.string()
      .trim("Starting & trailing spaces are not allowed")
      .strict()
      .min(0, "Mimimum of 0 characters are required")
      .max(100, "Maximum of 100 characters are allowed")
      .required("Threat Display Label is required"),
    threatFAQ: Yup.array()
      .of(
        Yup.object().shape({
          header: Yup.string()
            .trim("Starting & trailing spaces are not allowed")
            .strict()
            .min(0, "Title should be a minimum of 0 characters")
            .max(100, "Title should be a maximum of 100 characters")
            .nullable()
            .optional(),
          text: Yup.string()
            .min(0, "Response should be a minimum of 0 characters")
            .trim("Starting & trailing spaces are not allowed")
            .strict()
            .max(1000, "Response should be a maximum of 1000 characters")
            .nullable()
            .optional(),
        })
      )
      .optional(),
  });

export const ThreatValidationSchema = () =>
  Yup.object().shape({
    threatSystemName: Yup.string().required("Threat System Name required"),
    stageSystemName: Yup.string().required("Stage System Name required"),
  });
