import { api_service } from "../../Api/api_service";
import { IRequestBody } from "../../Constants/interfaces";

export const GetRetailerDataList = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "retailer_data_list");
    })
    .catch((err) => {
      errorCallBack(err, "retailer_data_list");
    });
};

export const retailerToggleStatusAPI = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .patch(data)
    ?.then((response) => {
      successCallBack(response.data, "retailer_toggle_Status");
    })
    .catch((err) => {
      errorCallBack(err, "retailer_toggle_Status");
    });
};

export const getRetailersViewDraftInfo = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "view_retailers_info");
    })
    .catch((err) => {
      errorCallBack(err, "view_retailers_info");
    });
};

export const getRetailerStates = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "retailer_states");
    })
    .catch((err) => {
      errorCallBack(err, "retailer_states");
    });
};

export const getRetailerStatesDistricts = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "retailer_districts");
    })
    .catch((err) => {
      errorCallBack(err, "retailer_districts");
    });
};

export const getRetailerDistrictTalukaAPI = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .get(data)
    ?.then((response) => {
      successCallBack(response.data, "retailer_Taluka");
    })
    .catch((err) => {
      errorCallBack(err, "retailer_Taluka");
    });
};

export const fileUploadAPI = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  return api_service
    .post(data)
    .then((response) => {
      // successCallBack(response.data, "retailer_image_upload");
      return response.data;
    })
    .catch((err) => {
      // errorCallBack(err, "retailer_image_upload");
    });
};

export const fileRemoveAPI = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .delete(data)
    .then((response) => {
      successCallBack(response.data, "retailer_image_remove");
    })
    .catch((err) => {
      errorCallBack(err, "retailer_image_remove");
    });
};

export const retailerSubmitFormAPI = (
  data: IRequestBody,
  successCallBack: any,
  errorCallBack: any
) => {
  api_service
    .post(data)
    ?.then((response) => {
      successCallBack(response.data, "retailer_submit_form");
    })
    .catch((err) => {
      errorCallBack(err, "retailer_submit_form");
    });
};
