import Box from "@mui/material/Box";
import { makeStyles, styled } from "@mui/styles";

export const useStyles = makeStyles({
  paginationHelper: {
    marginLeft: "auto",
  },
  tableBodyHelper: {
    overflowY: "scroll",
    height: "30vh !important",
    display: "block !important",
    width: "inherit !important",
  },
  theadHelper: {
    display: "table !important",
    width: "inherit !important",
  },
  tableRowHelper: {
    display: "table !important",
    width: "inherit !important",
  },
  tableHelper: {
    // border: '1px solid #CFD3DB',
    // borderRadius: '6px',
    borderCollapse: 'unset',
    minWidth: 650
  },
  tableContainerHelper: {
    border: '1px solid #CFD3DB',
    borderRadius: '6px',
    marginInline: 'auto',
    maxHeight: 'calc(100vh - 400px)',
    overflowY: 'auto',

    '&.MuiTableContainer-root': {
      width: 'calc(100% - 48px)',
      overflowX: 'revert',
      minHeight: '300px'
    }
  },
  tableBodyContainerHelper: {
    maxHeight: 'calc(100vh - 420px)',
    borderRadius: '6px',
    overflowY: 'auto'
  },
  checkboxHelper: {
    p: 0,
    height: 16,
    width: 16,
  },
  readOnlyBadge: {
    display: "inline-block",
    background: "#C6E6FF",
    borderRadius: "4px",
    color: "#005693",
    fontWeight: "600",
    fontSize: "12px",
    padding: "4px 12px",
  },
  viewButtonHelper: {
    padding: 0,
    color: "#14803C !important",
    fontWeight: "400 !important",
    textDecoration: "underline !important",
  },
  addTransButtonHelper: {
    padding: 0,
    color: "#14803C !important",
    fontWeight: "400 !important",
    textDecoration: "underline !important",
  },
  editButtonHelper: {
    padding: 0,
    textDecoration: "underline !important",
    fontWeight: "400 !important",
    color: "#0071CD !important",
  },
  deleteButtonHelper: {
    color: '#CF3537',
    padding: 0,
    textDecoration: "underline !important",
    fontWeight: "400 !important",
  },
  downloadButtonHelper: {
    padding: 0,
    fontWeight: "400 !important",
    color: "#0071CD !important",
  },
  actionHelper: {
    width: 'calc(100% - 48px)',
    display: "flex",
    alignItems: "center",
    margin: "16px auto",
    padding: "0 1.5rem",
  },
  circleAddButtonHelper: {
    backgroundColor: "#14803C !important",
    marginLeft: "auto",
    height: "60px !important",
    width: "60px !important",
    cursor: "pointer",
  },
  textWordBreakHelper: {
    wordBreak: "break-all",
    maxWidth: "200px",
  },
  tableHeadCellHelper: {
    backgroundColor: "#F3F4F6 !important",
    whiteSpace: "nowrap",
    boxSizing: 'border-box'
  },
  noBorderHelper: {
    "&:last-child td, &:last-child th": { border: 0 },
    verticalAlign: "top !important",
    '& .MuiTableCell-root': {
      minWidth: '100px',
      boxSizing:'border-box'
    }
  },
  emptyCellHelper: {
    width: "130px",
  },
  VideoWrapper: {
    borderRadius: "4px",
    width: "80px",
    height: "50px",
    marginLeft: "10px",
  },
  MediaWrapper: {
    display: "flex",
    width: "200px",
    height: "50px",
    alignItems: "center",
  },
  ImageStyle: {
    height: "50px",
    width: "80px",
    borderRadius: "4px",
  },
  IconImageStyle: {
    height: "30px",
    alignSelf: "center",
  },
  disableBtnColor: {
    padding: 0,
    color: "#C2C7D0 !important",
    fontWeight: "400 !important",
    textDecoration: "underline !important",
  },
  noDataFound: {
    width: '100%',
    textAlign: 'center',
    '&.MuiTypography-root': {
      fontSize: '1.3rem'
    }
  }
});

export const ReadOnlyBadge_Published = styled(Box)({
  display: "inline-flex",
  background: "#DFFBE8",
  borderRadius: "4px",
  color: "#14803C",
  fontWeight: "600",
  fontSize: "12px",
  padding: "4px 12px",
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginLeft: '10px'
  }
});
export const ReadOnlyBadge_Cancelled = styled(Box)({
  display: "inline-flex",
  background: "#FFD9D9",
  borderRadius: "4px",
  color: "#CF3537",
  fontWeight: "600",
  fontSize: "12px",
  padding: "4px 12px",
  alignItems: 'center',
  '& .MuiSvgIcon-root': {
    marginLeft: '10px'
  }
});
export const ReadOnlyBadge_Created = styled(Box)({
  display: "inline-flex",
  background: "#C6E6FF",
  borderRadius: "4px",
  alignItems: 'center',
  color: "#005693",
  fontWeight: "600",
  fontSize: "12px",
  padding: "4px 12px",
});
