import React from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button, FormControl, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { loginRequest } from "../../Config/authConfig";
import appActions from "../../redux/action";
import { useStyles } from "./loginStyles";
import Greetings from "../../Layouts/Greetings";

type LoginProps = {
  onSignInSuccess: Function;
};

const Login: React.FC<LoginProps> = ({ onSignInSuccess }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userName, setUserName] = React.useState<string>("");
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleResponse = async (response: any) => {
    if (response) {
      if (isAuthenticated) {
        await dispatch(appActions.apiData.userAuthToken(response.idToken));
        await dispatch(
          appActions.userDetails.userDetails({
            name: response.account.name,
          })
        );
      }
      onSignInSuccess(response);
    }
  };

  instance.handleRedirectPromise().then(handleResponse);

  const handleSignIn = (msalInst: any) => {
    if (userName) {
      msalInst.loginRedirect({
        ...loginRequest,
        loginHint: `${userName}@syngenta.com`,
        forceRefresh: true,
      });
    }
  };

  const handleUserNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setUserName(value);
  };

  return (
    <Greetings>
      <Typography fontSize={14}>
        Please sign in using your Syngenta credentials
      </Typography>
      <FormControl variant="standard" fullWidth>
        <Typography
          className={classes.emailLabelHelper}
          variant="subtitle2"
        >
          Email
        </Typography>
        <TextField
          InputProps={{
            style: {
              height: "40px",
              background: "#F3F4F6",
              color: "#696F88",
            },
            endAdornment: <>@syngenta.com</>,
          }}
          fullWidth
          value={userName}
          onChange={handleUserNameChange}
        />
      </FormControl>
      <Button
        color="success"
        className={classes.signInButtondHelper}
        variant="contained"
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          handleSignIn(instance);
        }}
      >
        <Typography fontSize={12} variant="subtitle2">
          Sign in
        </Typography>
      </Button>
    </Greetings>
  );
};

export default Login;
