import { Tab, Tabs, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from "react-i18next";
import { useStyles } from './style';
interface ICustomTabsProps {
  onChange: any;
  tabsList: Array<string>;
  value: number;
  isQuery?: boolean;
}

const CustomTabs: React.FC<ICustomTabsProps> = (props: ICustomTabsProps) => {
  // classes
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Tabs
        className={classes.tabContainerHelper}
        value={props.value}
        sx={{ paddingLeft: '0' }}
      >
        {props.tabsList.map((tab: string, index: number) => {
          return (
            <Tab
              key={`${tab}-${index}`}
              className={classes.tabHelper}
              onClick={() => props.onChange('', index)}
              label={
                <Typography
                  variant='body1'
                  fontSize={14}
                  className={index === props.value ? classes.activeTab : ''}
                >
                  {props.isQuery
                    ? t('str_response') + ' ' + (index + 1)
                    : props.tabsList[index]}
                </Typography>
              }
            />
          );
        })}
      </Tabs>
    </>
  );
};

export default CustomTabs;
