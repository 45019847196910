import {Box, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import ReactPlayer from "react-player";
import {Carousel} from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {formatDate} from "../../../Utility/DateFormat";
import "../overrides.css";
import {useStyles} from "./style";

type ResponseViewProps = {
    ViewAPIResponse: any;
    activeTab: any;
};

const ResponseView: React.FC<ResponseViewProps> = ({ViewAPIResponse, activeTab}) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [viewResponse, setViewResponse] = useState<any>(ViewAPIResponse);
    const [showGrowerMediaURL, setShowGrowerMediaURL] = useState<string>("");
    const [showSRMediaURL, setShowSRMediaURL] = useState<string>("");

    useEffect(() => {
        setViewResponse(ViewAPIResponse);
    }, [ViewAPIResponse]);

    const renderMediaList = (mediaList: any[], type: string) => (
        mediaList.length > 0 ? (
            <div className={classes.carouselDiv}>
                <Carousel showThumbs={false} width="90%" className={classes.carousel}>
                    {mediaList.map((media, index) => (
                        <div key={index} className={classes.flexStyleCarousel}>
                            {media.type === "AUDIO" ? (
                                <div className={classes.playerDiv}>
                                    <ReactPlayer
                                        className="react-player"
                                        url={media.url}
                                        width="500px"
                                        height="150px"
                                        playing={false}
                                        controls
                                        onStart={() => type === "Grower" ? setShowGrowerMediaURL("") : setShowSRMediaURL("")}
                                    />
                                </div>
                            ) : media.type === "IMAGE" ? (
                                <img src={media.url} className={classes.ImageStyle} alt="Media"/>
                            ) : (
                                <div className={classes.playerWrapper}>
                                    <ReactPlayer
                                        className="react-player"
                                        url={media.url}
                                        playing={false}
                                        controls
                                    />
                                </div>
                            )}
                        </div>
                    ))}
                </Carousel>
            </div>
        ) : null
    );

    const renderMediaURL = (type: string) => {
        const mediaURL = type === "Grower" ? showGrowerMediaURL : showSRMediaURL;
        return mediaURL && (
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                {mediaURL.endsWith(".mp4") ? (
                    <div className="player-wrapper">
                        <ReactPlayer className="react-player" url={mediaURL} playing={false} controls/>
                    </div>
                ) : (
                    <img alt="Media" src={mediaURL} className={classes.mediaImageStyle}/>
                )}
            </div>
        );
    };

    return (
        <>
            {viewResponse?.queryConversations?.length > 0 && viewResponse.queryConversations.map((item: any, index: number) => (
                index === activeTab && (
                    <Box key={index} style={{height: "600px", overflow: "auto"}}>
                        {item.queryGrowerMessage && (
                            <Box sx={{backgroundColor: "#F3F4F6", pl: 4, paddingBottom: 5}}>
                                <Box className={classes.flexStyle}>
                                    <Typography variant="body2" pt={3} pb={1} pr={1}>
                                        {t("str_date_time")}
                                    </Typography>
                                    <Typography variant="body2" pt={3} pb={1} pr={1}>
                                        {formatDate(item.queryGrowerMessage.lastUpdatedDate, "DD/MM/YYYY, hh:mm A")}
                                    </Typography>
                                </Box>
                                <Box className={classes.flexStyle}>
                                    <Typography variant="subtitle2" className={classes.ResponseHeader}>
                                        {t("str_grower_message")}
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.messageStyle}>
                                        {item.queryGrowerMessage.message}
                                    </Typography>
                                </Box>
                                {renderMediaList(item.queryGrowerMessage.queryMediaList, "Grower")}
                                {renderMediaURL("Grower")}
                            </Box>
                        )}
                        {item.querySrResponse && (
                            <Box sx={{backgroundColor: "#FFFFFF", pl: 4}}>
                                <Box className={classes.flexStyle}>
                                    <Typography variant="body2" pt={3} pb={1} pr={1}>
                                        {t("str_date_time")}
                                    </Typography>
                                    <Typography variant="body2" pt={3} pb={1} pr={1}>
                                        {formatDate(item.querySrResponse.lastUpdatedDate, "DD/MM/YYYY, hh:mm A")}
                                    </Typography>
                                </Box>
                                <Box className={classes.flexStyle}>
                                    <Typography variant="subtitle2" className={classes.ResponseHeader}>
                                        {t("str_support_message")}
                                    </Typography>
                                    <Typography variant="subtitle2" className={classes.messageStyle}>
                                        {item.querySrResponse.message}
                                    </Typography>
                                </Box>
                                {renderMediaList(item.querySrResponse.queryMediaList, "SR")}
                                {renderMediaURL("SR")}
                            </Box>
                        )}
                    </Box>
                )
            ))}
        </>
    );
};

export default ResponseView;
