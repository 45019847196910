import Typography from '@mui/material/Typography';
import React from 'react';
import { ASSETS_PATH } from '../../Constants/contants';
import { useStyles } from './styles';

enum ChipType {
  CHIP_SCHEDULED = 'SCHEDULED',
  CHIP_SENT = 'SENT'
}

type ChipTypes = {
  label: string;
  type: string;
};

const Chip: React.FC<ChipTypes> = (props) => {
  const classes = useStyles();

  switch (props.type) {
    case ChipType.CHIP_SCHEDULED:
      return (
        <div className={classes.scheduledChipHelper}>
          <Typography variant='subtitle2'>{props.label}</Typography>
        </div>
      );
    case ChipType.CHIP_SENT:
      return (
        <div className={classes.sentChipHelper}>
          <Typography variant='subtitle2'>{props.label}</Typography>
          <img src={`${ASSETS_PATH}Circular_Check.svg`} alt='circular_check' />
        </div>
      );
    default:
      return null;
  }
};

export default Chip;
