import _ from '../../Utils/lodash';
import { HIDE_TOAST, SHOW_TOAST } from '../action/actions-constants';
export interface IToastProps {
    text: string,
    type: string,
    time: number
};

const initialState: IToastProps = {
    text: '',
    type: '',
    time: 5000
};
interface IActionParams {
    payload: any;
    type: String;
};

export const ToastReducer = (state: IToastProps = initialState, { type, payload }: IActionParams): IToastProps => {
    switch (type) {
        case SHOW_TOAST:
            return _.extend({}, state, { ...payload });
        case HIDE_TOAST:
            return _.extend({}, state, initialState);
        default: { return state; }
    }
};
