import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  searchBoxHelper: {
    marginLeft: 'auto'
  },
  iconHelper: {
    color: '#23263080'
  },
  textFieldHelper: {
    width: '100%'
  }
});
