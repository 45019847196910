import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    stepperText: {
        '& .MuiStepLabel-root .Mui-completed': {
            color: '#23263080', // circle color (COMPLETED)
        },
        '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
        {
            color: '#0071CD', // Just text label (COMPLETED)
        },
        '& .MuiStepLabel-root .Mui-active': {
            color: '#23263080', // circle color (ACTIVE)
        },
        '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
        {
            color: '#0071CD', // Just text label (ACTIVE)
        },
        '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
            fill: 'black', // circle's number (ACTIVE)
        },
    }
})