import { Box, TableContainer, Stack } from "@mui/material";
import { DOWNLOAD_SAMPLE_TEMPLATE_TYPE } from "../../Common/Constants";
import CustomTabs from "../../Common/CustomTabs";
import DataTable from "../../Common/DataTable";
import DataTableActions from "../../Common/DataTable/tableActions";
import { DownLoadSampleTemplate } from "../../Common/DownloadSampleTemplate";
import FiltersAndSort from "../../Common/FilterAndSort";
import PageTitle from "../../Common/PageTitle";
import { ACTION_TYPE } from "../../Common/RenderTableActions";
import RowsPerPage from "../../Common/RowsPerPage";
import SearchBar from "../../Common/SearchBar";
import ModalCreatePopUp from "./ModalCreatePopUp";
import { useStyles } from "./style";
import PlantixView from "./PlantixView";

interface PlantixMapProductProps {
  searchValue: string;
  activeTab: number;
  limit: number;
  sortId: "" | "0" | "1";
  filterObj: any;
  currentPage: number;
  totalPages: number;
  open: boolean;
  showCreateOrEditModal: boolean;
  actionType: string;
  showBulkUpload: boolean;
  onSearchValueChange: (newValue: string) => void;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
  onClickAction: (clickAction: string, detail?: any) => void;
  onKnowMorePopUp: (flag: boolean, rowData: any) => void;
  onOpenModal: () => void;
  onCloseModal: () => void;
  onShowCreateOrEditModal: (toggle: boolean) => void;
  onShowBulkUpload: (toggle: boolean) => void;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  setSortId: React.Dispatch<React.SetStateAction<"" | "0" | "1">>;
  setFilterObj: React.Dispatch<React.SetStateAction<any>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  onSelectAll: (status: boolean) => void;
  onRowSelect: (status: boolean, rowInfo: any) => void;
  dataToDisplay: any[];
  buttonExportVisible?: boolean;
}

type ColumnTypes = {
  fieldLabel: string;
  fieldName: string;
};

const DataListColumns: ColumnTypes[] = [
  {
    fieldLabel: "Crop",
    fieldName: "cropName",
  },
  {
    fieldLabel: "Disease",
    fieldName: "diseaseName",
  },
  {
    fieldLabel: "CP System Name",
    fieldName: "systemName",
  },
  {
    fieldLabel: "Eppo Code",
    fieldName: "eppoCode",
  },
  {
    fieldLabel: "Peat ID",
    fieldName: "peatId",
  },
  {
    fieldLabel: "Last Updated On",
    fieldName: "lastUpdatedOn",
  },
];

const MyUploadColumns: ColumnTypes[] = [
  {
    fieldLabel: "File Name",
    fieldName: "fileName",
  },
  {
    fieldLabel: "Uploaded On",
    fieldName: "cropsUploadDate",
  },
  {
    fieldLabel: "Status",
    fieldName: "comnMyUploadStatus",
  },
];

const PlantixMapProductView: React.FC<PlantixMapProductProps> = (props) => {

  return (
    <PlantixView
      typePlantix={'map'}
      searchValue={props.searchValue}
      activeTab={props.activeTab}
      limit={props.limit}
      sortId={props.sortId}
      filterObj={props.filterObj}
      currentPage={props.currentPage}
      totalPages={props.totalPages}
      open={props.open}
      showCreateOrEditModal={props.showCreateOrEditModal}
      actionType={props.actionType}
      showBulkUpload={props.showBulkUpload}
      dataToDisplay={props.dataToDisplay}
      showViewModal={false}
      onSearchValueChange={props.onSearchValueChange}
      onChange={props.onChange}
      onClickAction={props.onClickAction}
      onKnowMorePopUp={props.onKnowMorePopUp}
      onCloseModal={props.onCloseModal}
      onShowCreateOrEditModal={props.onShowCreateOrEditModal}
      onShowBulkUpload={props.onShowBulkUpload}
      setLimit={props.setLimit}
      setSortId={props.setSortId}
      setFilterObj={props.setFilterObj}
      setCurrentPage={props.setCurrentPage}
      onSelectAll={props.onSelectAll}
      onRowSelect={props.onRowSelect}
      buttonExportVisible={props.buttonExportVisible}
      tabsList={["Data List", "My uploads"]}
      dataColumns={props.activeTab === 0 ? DataListColumns : MyUploadColumns}
      downloadLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.PLANTIX_MAP}
      actionsType={ACTION_TYPE.TYPE_CP_PRODUCT}
    />

  );
};

export default PlantixMapProductView;
