import { createContext, useContext } from "react";
import { PublishProgressType } from "./usePublishProgress";
import CollapsibleBox from "../CollapsibleBox";
import { IconButton, Stack, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

type PublishProgressContextType = {
  listPublishProgress: PublishProgressType[];
  addPublishProgress: (publishProgress: PublishProgressType) => void;
  deletePublishProgress: (publishName: string) => void;
  deleteAllPublishProgress: () => void;
};

export const PublishProgressContext = createContext<PublishProgressContextType>(
  {
    listPublishProgress: [],
    addPublishProgress: () => {},
    deletePublishProgress: () => {},
      deleteAllPublishProgress: () => {},
  }
);

export const usePublishProgressContext = () => {
  return useContext(PublishProgressContext);
};

const PublishProgress: React.FC = () => {
  const { listPublishProgress, deletePublishProgress } =
    usePublishProgressContext();
  const listPublishProgressLength = listPublishProgress.length;

  return listPublishProgressLength > 0 ? (
    <CollapsibleBox title="Publish Progress">
      <Stack
        sx={{
          maxHeight: "10em",
          overflowY: "auto",
          color: "rgba(0, 0, 0, 0.87)",
          bgcolor: "white",
          padding: "0.5em",
        }}
        gap={"0.5em"}
      >
        {listPublishProgress.map((item) => (
          <Stack
            key={item.publishName}
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            paddingInlineEnd={"0.5em"}
          >
            <Stack>
              <Typography variant="body2" fontWeight={"bold"}>
                {item.module} : {item.publishName}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontStyle:
                    item.status === "Publishing" ? "italic" : "inherit",
                }}
                color={item.status === "Error" ? "#CF3537" : "inherit"}
              >
                {item.message ??
                  (item.status === "Publishing"
                    ? "Publishing..."
                    : "Published")}
              </Typography>
            </Stack>
            {item.status === "Error" && (
              <Stack direction={"row"}>
                <IconButton
                  size="small"
                  onClick={() => {
                    deletePublishProgress(item.publishName);
                  }}
                  data-testid="publish-delete-button"
                >
                  <Close />
                </IconButton>
              </Stack>
            )}
          </Stack>
        ))}
      </Stack>
    </CollapsibleBox>
  ) : null;
};

export default PublishProgress;
