import React from 'react';
import { useSelector } from 'react-redux';
import { ASSETS_PATH } from '../../Constants/contants';
import { RootState } from '../../redux/reducers';
import { useStyles } from './styles';

const Loader: React.FC = () => {
  // classes
  const classes = useStyles();

  const loaderStatus = useSelector(
    (state: RootState) => {
      return state.loader.showLoader;
    }
  );
  //console.log(loaderStatus)
  return (
    <React.Fragment>
      {loaderStatus && <div className={classes.loaderContainer}><img className={classes.progressHelper} alt='loader' src={`${ASSETS_PATH}loader.gif`} /></div>}
    </React.Fragment>
  );

};

export default Loader;
