import { api_service } from '../../../Api/api_service';
import api_endpoints, { base_url } from '../../../Api/end_points';
import { RequestMethods } from '../../../Constants/enums';
import { IRequestBody } from '../../../Constants/interfaces';

export const getLanguages = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
    api_service.get(data)?.then((response) => {
        successCallBack(response.data, RequestMethods.Get);
    }).catch((err) => {
        errorCallBack(err, RequestMethods.Get);
    });
};

export const submitLanguageDetails = (payload: any, successCallBack: any, errorCallBack: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.add_language;
    apiData.showLoader = true;
    apiData.payLoad = payload;
    api_service.post(apiData)?.then((response) => {
        successCallBack(response.data, RequestMethods.Post);
    }).catch((err) => {
        errorCallBack(err, RequestMethods.Post);
    });
}

export const updateLanguageDetails = (payload: any, successCallBack: any, errorCallBack: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.update_language;
    //apiData.showLoader = true;
    apiData.payLoad = payload;
    api_service.put(apiData)?.then((response) => {
        successCallBack(response.data, RequestMethods.Post);
    }).catch((err) => {
        errorCallBack(err, RequestMethods.Post);
    });
}