interface IUseManageCheckbox {
    dataToDisplayIsChecked: any;
    setDataToDisplayIsChecked: (data: any) => void;
    currentPage: number;
    setDataToDisplay: (data: any) => void;
}

export const useManageCheckbox = ({
    dataToDisplayIsChecked,
    setDataToDisplayIsChecked,
    currentPage,
    setDataToDisplay
                                  }: IUseManageCheckbox) => {
    const AssignCheckedFlag = (data: any, status: boolean) => {
        const filterData: any = data.map((isdata: any) => {
            isdata.checked = status;
            return isdata;
        });

        filterDataBasedOnPageChecked(currentPage, filterData);
    };

    // Filter data based on page checked
    const filterDataBasedOnPageChecked = (page: number, data: any) => {
        if (dataToDisplayIsChecked.length < 1) {
            setDataToDisplayIsChecked([
                {
                    page: page,
                    data: data,
                },
            ]);
            return;
        }

        const checkPageIsExist = dataToDisplayIsChecked.some(
            (oldData: any) => oldData.page === page
        );
        if (checkPageIsExist) {
            const filterData: any = dataToDisplayIsChecked.map((isdata: any) => {
                if (isdata.page === page) {
                    return {
                        page,
                        data,
                    };
                }
                return isdata;
            });
            setDataToDisplayIsChecked(filterData);
            return;
        }

        setDataToDisplayIsChecked([
            ...dataToDisplayIsChecked,
            {
                page: page,
                data: data,
            },
        ]);
    };

    const replaceDataAlreadyExistWithChecked = () => {
        if (dataToDisplayIsChecked.length < 1) return;

        const dataCheckedPage = dataToDisplayIsChecked.filter((data: any) => {
            return data.page === currentPage;
        });
        if (currentPage === dataCheckedPage?.[0]?.page) {
            setDataToDisplay(dataCheckedPage?.[0]?.data);
        }
    };

    const convertMatrictToArray = () => {
        const data = dataToDisplayIsChecked.map((data: any) => {
            return data.data;
        });

        const dataFromOtherPage = dataToDisplayIsChecked.some((data: any) => {
            if (data.page !== currentPage) {
                return data.data.some((data: any) => data.checked === true);
            }
            return false;
        });

        return {
            data: data.flat(),
            dataFromOtherPage,
        };
    };

    return { AssignCheckedFlag, filterDataBasedOnPageChecked, replaceDataAlreadyExistWithChecked, convertMatrictToArray };
}
