import { api_service } from "../../Api/api_service";
import { RequestMethods } from "../../Constants/enums";
import { IRequestBody } from "../../Constants/interfaces";

export const getPublishedHistory = (
  apiConfig: IRequestBody,
  successCallback: (response: any, method: RequestMethods) => void,
  errorCallback: () => void
) => {
  api_service
    .get(apiConfig)
    ?.then((response) => {
      successCallback(response.data, RequestMethods.Get);
    })
    .catch((err) => {
      errorCallback();
    });
};
