import { ButtonGroup } from "@mui/material";
import Filter, { FilterProps } from "./Filter";
import Sort, { SortProps } from "./Sort";

type FilterAndSortReduxProps = {
  showFilter?: boolean;
} & SortProps &
  FilterProps;

const FilterAndSortRedux: React.FC<FilterAndSortReduxProps> = (props) => {
  return (
    <ButtonGroup
      sx={{ marginLeft: "auto" }}
      variant="text"
      aria-label="text button group"
    >
      {props.showFilter ? (
        <Filter
          filters={props.filters}
          onChangeFilters={props.onChangeFilters}
          initialFilter={props.initialFilter}
        />
      ) : null}
      <Sort
        sort={props.sort}
        onChangeSort={props.onChangeSort}
        initialSort={props.initialSort}
      />
    </ButtonGroup>
  );
};

export default FilterAndSortRedux;
