import * as Yup from "yup";

/** step-1 validation schema */
export const GeneralSchemaValidation = () =>
  Yup.object().shape({
    language: Yup.string().required("Language required"),
    // imageUrl: Yup.string().required('Upload Crop image'),
    cropName: Yup.string()
      .min(0, "Crop Title should be minimum 0 characters")
      .max(100, "Crop Title should not exceed 100 characters")
      .required("Title required"),
    // systemName: Yup.string()
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .min(3, 'System Name should be minimum 3 characters')
    //     .max(50, 'System Name should not exceed 50 characters')
    //     .matches(/^[0-9a-zA-Z_]+$/, { message: 'Only alphabets and _ are allowed', excludeEmptyString: true })
    //     .required('System name required'),
    // cropStates: Yup.array().required("States required").nullable(),
  });
/** step-2 validation schema */
export const CropGrowthValidationSchema = () =>
  Yup.object().shape({
    title: Yup.string()
      .min(0, "Stage name should be atleast 0 characters ")
      .max(100, "Stage name should be a max of 100 characters")
      .required("Stage title required"),
    // systemName: Yup.string()
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .min(3, 'Stage System name should be atleast 3 characters ')
    //     .max(50, 'Stage System name should be a max of 50 characters')
    //     .matches(/^[0-9a-zA-Z_]+$/, { message: 'Only alphabets and _ are allowed', excludeEmptyString: true })
    //     .required('Stage name required'),
    description: Yup.string()
      .min(0, "Description should be atleast 0 characters ")
      .max(100, "Description should be a max of 100 characters")
      .required("Description required"),
    // imagePath: Yup.string().required('Upload state image'),
  });
/** step-3 validation schema */
export const FilterKeySchemaValidation = () =>
  Yup.object().shape({
    // systemName: Yup.string()
    //     .min(3, 'System name should be atleast 3 characters ')
    //     .max(50, 'System name should be a max of 50 characters')
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .matches(/^[0-9a-zA-Z_]+$/, { message: 'Only alphabets and _ are allowed', excludeEmptyString: true })
    //     .required('Filter key name required'),
    filterName: Yup.string()
      .max(100, "Display label should be a max of 100 characters")
      .required("Display label required"),
    filterValues: Yup.array()
      .of(
        Yup.object().shape({
          valueSystemName: Yup.string()
            .matches(/^[0-9a-zA-Z_]+$/, "Only alphabets and _ are allowed")
            .required("Filter value required"),
          displayLabel: Yup.string().required("Display label required"),
        })
      )
      .required(),
  });
/** step-4 validation schema */
export const CropCalendarSchemaValidation = () =>
  Yup.object().shape({
    generic: Yup.boolean().required("Select generic"),
    // calendarName: Yup.string()
    //     .min(3, 'Calendar system name should be atleast 3 characters ')
    //     .max(50, 'Calendar system name should be a max of 50 characters')
    //     .trim('Starting & trailing spaces are not allowed')
    //     .strict()
    //     .matches(/^[0-9a-zA-Z_]+$/, { message: 'Only alphabets and _ are allowed', excludeEmptyString: true })
    //     .required('Calendar name required'),
    practice: Yup.string().required("Select farming practice"),
    duration: Yup.string().required("Select crop duration"),
    // dosOrdotApplicable: Yup.object().shape({
    //     isDotApplicable: Yup.string()
    //         .when('isDosApplicable', {
    //             is: (val: any) => !!val,
    //             then: Yup.string(),
    //             otherwise: Yup.string().required('Please select DOT or DOS')
    //         }),
    //     isDosApplicable: Yup.string()
    //         .when('isDotApplicable', {
    //             is: (val: any) => !!val,
    //             then: Yup.string(),
    //             otherwise: Yup.string().required('Please select DOT or DOS')
    //         })
    // }, [['isDosApplicable', 'isDotApplicable']])
    isDotApplicable: Yup.string()
      .nullable()
      .required("Date of transplant is required."),
    isDosApplicable: Yup.string()
      .nullable()
      .required("Date of sowing is required."),
  });
/** step-5 validation schema */
export const CropStageCalendarSchemaValidation = () =>
  Yup.object().shape({
    calendarName: Yup.string().required("Calendar system  name required"),
    // stageSystemName: Yup.string().required('Stage system name required'),
    applicable: Yup.string().required("Select applicable"),
    startDay: Yup.number()
      .min(0)
      .max(365)
      .typeError("Only numbers are allowed")
      .required("Start day required"),
    // endDay: Yup.number()
    //     .min(0)
    //     .max(365)
    //     .typeError('Only numbers are allowed')
    //     .required('End day required').when('startDay', (st, ed) => {
    //         return st && ed.min(st);
    //     }),
    reference: Yup.string().required("Select reference"),
  });
