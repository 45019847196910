import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  Select: {
    '& ul': {
      maxHeight: '20vh'
    }
  },
  textAreaStyles: {
    borderColor: 'rgba(0,0,0,0.23)',
    borderRadius: '5px',
    color: '#696F88',
    resize: 'none',
    height: '96px !important',
    overflowY: 'scroll',
    outlineColor: '#0071CD',
    outlineWidth: '2px',
    borderWidth: '1px',
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)'
    }
  },
  rmPicture: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  formPaperBulkUpload: {
    margin: '30px auto',
    width: '570px',
    minHeight: '500px',
    borderRadius: '6px !important',
    '& .MuiStepper-root': {
      paddingBottom: '1rem'
    }
  },
  formPaper: {
    margin: '30px auto',
    width: '800px',
    minHeight: '500px',
    borderRadius: '6px !important',
    '& .MuiStepper-root': {
      paddingBottom: '1rem'
    }
  },
  fabBtnShadowHelper: {
    '&.MuiButtonBase-root': {
      boxShadow: 'none'
    }
  },
  lineHeightHelper: {
    lineHeight: 'unset'
  },
  formContainer: {
    height: '370px',
    overflowY: 'auto',
    position: 'relative',
    scrollbarWidth: 'auto',
    '& .MuiFormHelperText-root': {
      fontSize: '11px'
    },
    '&::-webkit-scrollbar': {
      width: '8px !important'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#CFD3DB',
      'border-radius': '15px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#ffffff'
    }
  },
  formFieldsHelperOne: {
    maxWidth: '400px',
    margin: 'auto'
  },
  formFieldsHelper: {
    margin: 'auto auto 8px auto',
    maxWidth: '430px'
  },
  rpPicture: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  selectExpandMore: {
    position: 'absolute',
    right: '3px'
  },
  formControlMrtHelper: {
    '&.MuiFormControl-root': {
      marginTop: '8px'
    },
    '& .MuiFormHelperText-root': {
      marginLeft: '0px'
    }
  },
  formErrorMsgLanguageHelper: {
    marginLeft: '0',
    position: 'absolute',
    top: '65px',
    left: '290px'
  },
  formSendSMSHelper: {
    display: 'flex',
    margin: '1.5rem 0 0',
    '& .MuiCheckbox-root': {
      padding: 0,
      height: 16,
      // width: 16,
      marginLeft: 'auto',
      width: '100%'
    }
  },
  formUploadImageHelper: {
    whiteSpace: 'nowrap',
    height: '128px',
    '&.MuiButton-root': {
      background: '#F3F4F6',
      border: '1px dashed #C2C7D0',
      color: '#14151C',
      '&:hover': { background: '#C2C7D0' }
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  formTimePickerHelper: {
    svg: {
      width: '18px',
      height: '18px'
    }
  },
  formTotalCostHelper: {
    margin: '0 auto 8px auto'
  },
  formButtonHelper: {
    width: 'fit-content',
    margin: '16px 16px auto auto'
  },
  formLocationRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: 5
  },
  formTypeRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 50,
    '& .MuiCheckbox-root': {
      paddingLeft: 0
    }
  },
  bulkUpdloadDownloadBtn: {
    '&.MuiButton-root': {
      fontWeight: '600',
      color: '#14151C'
    }
  },
  bulkDataBorderBottom: {
    borderBottom: '1px solid #CFD3DB',
    paddingBottom: '1.5rem',
    marginBottom: '1.5rem'
  },
  viewCropFormContentHelper: {
    overflow: 'hidden auto',
    height: '420px'
  },
  formFieldsHelperUnique: {
    margin: 'auto',
    maxWidth: '100%'
  },
  viewImage: {
    background: '#f2f2f2',
    marginBottom: '1rem !important',
    width: '120px',
    height: '120px',
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  formLocationHelper: {
    width: '135px'
  },
  bulkUploadActionBtnHelper: {
    marginInline: 'auto'
  },
  bulkUploadMainBtn: {
    marginInline: 'auto', margin: '1rem 0'
  },
  bulkUploadModalContainerHelper: {
   overflowY: 'auto', padding: '1rem'
  },
  formOutline: {
    outline: 'none',
    paddingTop: '0.4rem'
  },
  formLabelCreateRetailerHelper: {
    lineHeight: 'unset'
  },
  formLabelMrtBtnHelper: {
    marginBottom: '8px !important'
  },
  bulkUploadFabBtnHelper: {
    '&.MuiButtonBase-root': {
      boxShadow: 'none'
    }
  },
  uploadImgBtnIconColorHelper: {
    color: '#696F88'
  },
  formHelperTextFontSizeHelper: {
    fontSize: '11px !important'
  }
});
