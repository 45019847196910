import { TYPE_NO_PUSH, TYPE_PUSH_APPROVED } from "../../Common/States";

interface StateType {
    isLoading: boolean;
    data: any;
    selectedPushList: Array<any>;
    showActionProgress: boolean;
    showCreateProgress: boolean;
    stateType?: typeof TYPE_NO_PUSH | typeof TYPE_PUSH_APPROVED;
}

const SET_LOADING: string = "SET_LOADING";
const SET_DATA: string = "SET_DATA";
const SET_SELECTED_PUSH_LIST: string = "SET_SELECTED_PUSH_LIST";
const SET_STATE_TYPE: string = "SET_STATE_TYPE";
const SET_SHOW_ACTION_PROGRESS: string = "SET_SHOW_ACTION_PROGRESS";
const SET_SHOW_CREATE_PROGRESS: string = "SET_SHOW_CREATE_PROGRESS";

export interface ActionType {
    type: typeof SET_LOADING | typeof SET_DATA;
    payload: any;
}

const initialState = {
    isLoading: false,
    data: {},
    stateType: "",
    showActionProgress: false,
    showCreateProgress: false,
    selectedPushList: [],
};

export const publishPushReducer = (state: StateType = initialState, action: ActionType) => {
    switch (action.type) {
        case SET_LOADING:
            return { ...state, isLoading: action.payload };
        case SET_DATA:
            return { ...state, data: action.payload.data };
        case SET_SELECTED_PUSH_LIST:
            return { ...state, selectedPushList: action.payload.data };
        case SET_STATE_TYPE:
            return { ...state, stateType: action.payload.data };
        case SET_SHOW_ACTION_PROGRESS:
            return { ...state, showActionProgress: action.payload };
        case SET_SHOW_CREATE_PROGRESS:
            return { ...state, showCreateProgress: action.payload };
        default:
            return state;
    }
}

export {
    SET_LOADING,
    SET_DATA,
    SET_SELECTED_PUSH_LIST,
    SET_STATE_TYPE,
    SET_SHOW_ACTION_PROGRESS,
    SET_SHOW_CREATE_PROGRESS,
    initialState
}