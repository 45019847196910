import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LanguageIcon from "@mui/icons-material/Language";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/reducers";
import { LanguageRecordType } from "../../redux/reducers/generic_reducer";
import { useStyles } from "./styles";
import { LanguageDropdownProps, LanguageType } from "./types";

const LanguageDropdown: React.FC<LanguageDropdownProps> = (props) => {
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
      primaryLanguageCode: state.auth.languageCode,
    };
  });

  // classes
  const classes = useStyles();
  const [languageList, setLanguageList] = useState<LanguageType[]>(
    storeData.languages
  );
  const [selectedLanguage, setSelectedLanguage] = useState<string | string[]>(
    props.selectedLanguage
  );
  const [selectedAll, setSelectedAll] = useState(false);

  // handlers
  const handleChange = (event: SelectChangeEvent<typeof selectedLanguage>) => {
    if (props.multiple) {
      setSelectedLanguage(event.target.value as string[]);
      props.onChangeLanguage && props.onChangeLanguage(event.target.value);
    } else {
      const { value } = event.target;
      setSelectedLanguage(value && (value as string).split("##")[1]);
      props.onChangeLanguage && props.onChangeLanguage(value);
    }
  };

  const handleSelectAll: React.MouseEventHandler<HTMLLIElement> = (e) => {
    if (!selectedAll) {
      const allLanguages = languageList.map(
        (value) =>
          `${value.languageId}##${value.languageName}##${value.languageCode}`
      );
      setSelectedLanguage(allLanguages);
      setSelectedAll(true);
      props.onChangeLanguage &&
        props.onChangeLanguage(allLanguages as string[]);
    }

    if (selectedAll) {
      setSelectedLanguage([]);
      setSelectedAll(false);
      props.onChangeLanguage && props.onChangeLanguage([]);
    }
  };

  const handleSelectRow = (value: string, insertIndex: number) => {
    if (!selectedLanguage.includes(value)) {
      let selectedData = [
        ...(selectedLanguage as string[]).slice(0, insertIndex),
        value,
        ...(selectedLanguage as string[]).slice(insertIndex),
      ];
      setSelectedLanguage(selectedData);
      props.onChangeLanguage && props.onChangeLanguage(selectedData);
    }

    if (selectedLanguage.includes(value)) {
      let selectedData = (selectedLanguage as string[]).filter(
        (curr) => curr !== value
      );
      setSelectedLanguage(selectedData);
      props.onChangeLanguage && props.onChangeLanguage(selectedData);
    }
  };

  React.useEffect(() => {
    if (props.displayPrimaryLanguage !== "ALL") {
      if (props.displayPrimaryLanguage) {
        const primaryLanguageObj = _.filter(
          storeData.languages,
          (o: LanguageType) => o.languageCode === storeData.primaryLanguageCode
        );
        setLanguageList(primaryLanguageObj);
      } else {
        const primaryLanguageObj = _.filter(
          storeData.languages,
          (o: LanguageType) => o.languageCode !== storeData.primaryLanguageCode
        );
        setLanguageList(primaryLanguageObj);
      }
    }
  }, []);

  React.useEffect(() => {
    setSelectedLanguage(props.selectedLanguage);
  }, [props.selectedLanguage?.length]);

  return (
    <Box className={classes.languagePanelHelper} role={props.role}>
      <LanguageIcon fontSize="small" />
      <Typography component="div" variant="subtitle2" width="110px">
        Language *
      </Typography>
      <FormControl
        component="div"
        fullWidth
        className={classes.formControlHelper}
      >
        {props.multiple ? (
          <Select
            name="language-multiple"
            sx={{ minWidth: "300px", maxWidth: "350px" }}
            multiple
            value={selectedLanguage as string[]}
            renderValue={(value) =>
              value.map((item) => item.split("##")[1]).join(", ")
            }
            MenuProps={{ classes: { paper: classes.Select } }}
            readOnly={!!props.isDisabled}
            disabled={!!props.isDisabled}
            style={props.isDisabled ? { backgroundColor: "#F3F4F6" } : {}}
          >
            <MenuItem value={"Select All"} onClick={(e) => handleSelectAll(e)}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{ p: 0.5 }}
                    size="small"
                    checked={selectedLanguage.length === languageList.length}
                  />
                }
                label={
                  <Typography style={{ lineHeight: "unset" }} fontSize={14}>
                    Select All
                  </Typography>
                }
              />
            </MenuItem>
            {languageList?.map((value: LanguageRecordType, index: number) => {
              const langValue = `${value.languageId}##${value.languageName}##${value.languageCode}`;

              return (
                <MenuItem
                  key={index}
                  value={langValue}
                  onClick={() => handleSelectRow(langValue, index)}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{ p: 0.5 }}
                        size="small"
                        checked={selectedLanguage.includes(langValue)}
                      />
                    }
                    label={
                      <Typography style={{ lineHeight: "unset" }} fontSize={14}>
                        {value.languageName}
                      </Typography>
                    }
                  />
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          <Select
            name="language"
            sx={{ minWidth: "300px", maxWidth: "350px" }}
            value={selectedLanguage as string}
            MenuProps={{ classes: { paper: classes.Select } }}
            onChange={handleChange}
            IconComponent={() =>
              !props.isDisabled ? (
                <ExpandMoreIcon
                  sx={{
                    position: "absolute",
                    right: "3px",
                  }}
                />
              ) : null
            }
            renderValue={(value) => value}
            readOnly={!!props.isDisabled}
          >
            {languageList?.map((value: LanguageRecordType, index: number) => {
              return (
                <MenuItem
                  key={index}
                  value={`${value.languageId}##${value.languageName}##${value.languageCode}`}
                >
                  <Typography style={{ lineHeight: "unset" }} fontSize={14}>
                    {value.languageName}
                  </Typography>
                </MenuItem>
              );
            })}
          </Select>
        )}
      </FormControl>
    </Box>
  );
};

LanguageDropdown.defaultProps = {
  multiple: false,
};

export default LanguageDropdown;
