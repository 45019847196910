import { combineReducers } from 'redux';
import { appReducer, IAPIDataState } from './auth_reducer';
import { GenericAppConfig, genericReducer } from './generic_reducer';
import { loaderReducer, LoaderState } from './loader_reducer';
import { publishModuleSelectedReducer, publish_module_reducer } from './publish_selected_reducer';
import { IToastProps, ToastReducer } from './toast_reducer';
import { IUserDetails, userReducer } from './user_reducer';
import { publishPushReducer } from './publish_push_reducer';
export interface IRootAppReducerState {
    auth: IAPIDataState;
    generic: GenericAppConfig;
    loader: LoaderState;
    user: IUserDetails;
    infoToast: IToastProps;
    publishModuleSelectedReducer: publish_module_reducer
}
const rootReducer = combineReducers({
    auth: appReducer,
    user: userReducer,
    loader: loaderReducer,
    generic: genericReducer,
    infoToast: ToastReducer,
    publishModuleSelectedReducer: publishModuleSelectedReducer,
    publishPushReducer
});
export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
