import {
    SET_DATA,
    SET_SELECTED_PUSH_LIST,
} from "../../redux/reducers/publish_push_reducer";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/reducers";

export const usePushPublish = () => {
    const dispatch = useDispatch();
    const state = useSelector((state: RootState) => state.publishPushReducer);

    const toggleSelection = (checked: boolean) => {
        const newData = state?.data.map((obj: any) => ({ ...obj, checked }));
        dispatch({ type: SET_DATA, payload: { data: newData } });
    };

    const handleSelectAll = () => {
        toggleSelection(true);
        dispatch({ type: SET_SELECTED_PUSH_LIST, payload: { data: state.data } });
    };

    const handleDeselectAll = () => {
        toggleSelection(false);
        dispatch({ type: SET_SELECTED_PUSH_LIST, payload: { data: [] } });
    };

    const handleSelectPush = (checked: boolean, pushNotification: any) => {
        const pushIndex = state?.data.findIndex((push: any) => push.id === pushNotification.id);
        state.data[pushIndex].checked = checked;
        const updatedSelectedList = checked
            ? [...state.selectedPushList, pushNotification]
            : state.selectedPushList.filter((selectedPush: any) => selectedPush.id !== pushNotification.id);

        dispatch({ type: SET_SELECTED_PUSH_LIST, payload: { data: updatedSelectedList } });
        dispatch({ type: SET_DATA, payload: { data: [...state.data] } });
    };
    return {
        state,
        handleSelectAll,
        handleDeselectAll,
        handleSelectPush,
    };
};
