export type TableHeaderTypes = {
    HeaderName: string,
    FieldName: string,
    isBoolean?: boolean,
    truthLabel?: string,
    falseLabel?: string,
};

export const Features: TableHeaderTypes[] = [
    {
        'HeaderName': 'Image',
        'FieldName': 'imageUrl'
    },
    {
        'HeaderName': 'System Name',
        'FieldName': 'systemName'
    },
    {
        'HeaderName': 'Heading',
        'FieldName': 'heading'
    },
    {
        'HeaderName': 'Description/Text',
        'FieldName': 'text'
    },
    {
        'HeaderName': 'Display Order',
        'FieldName': 'displayOrder'
    },
];

export const Precautions : TableHeaderTypes[]=[
    {
        'HeaderName': 'Precaution System Name',
        'FieldName': 'systemName'
    },
    {
        'HeaderName': 'Precaution Description',
        'FieldName': 'text'
    },
    {
        'HeaderName': 'Display Order',
        'FieldName': 'displayOrder'
    }
];

export const AgronomyPractices : TableHeaderTypes[]=[
    {
        'HeaderName': 'Image',
        'FieldName': 'media'
    },
    {
        'HeaderName': 'System Name',
        'FieldName': 'systemName'
    },
    {
        'HeaderName': 'Heading',
        'FieldName': 'heading'
    },
    {
        'HeaderName': 'Description/Text',
        'FieldName': 'text'
    },
    {
        'HeaderName': 'Display Order',
        'FieldName': 'displayOrder'
    },
];

export const GrownTestimonials : TableHeaderTypes[]=[
    {
        'HeaderName': 'System Name',
        'FieldName': 'systemName'
    },
    {
        'HeaderName': 'Media Title',
        'FieldName': 'mediaTitle'
    },
    {
        'HeaderName': 'Media Type',
        'FieldName': 'mediaType'
    },
    {
        'HeaderName': 'Media URL',
        'FieldName': 'mediaUrl'
    },
    {
        'HeaderName': 'Image',
        'FieldName': 'image'
    },
    {
        'HeaderName': 'Description/Text',
        'FieldName': 'text'
    },
    {
        'HeaderName': 'Display Order',
        'FieldName': 'displayOrder'
    }
];

export const ApplicableFilter : TableHeaderTypes[]=[
    {
        'HeaderName': 'Filter Key System Name',
        'FieldName': 'keySystemName'
    },
    {
        'HeaderName': 'Filter Value',
        'FieldName': 'valueSystemName'
    },
    {
        'HeaderName': 'State Code',
        'FieldName': 'shortCode'
    }
]