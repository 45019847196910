export const StagesToCreateACrop = [
  {
    stepLabel: 'General Details',
    stepValue: 'general-details',
    hasErrors: false,
    isFormValid: false
  },
  {
    stepLabel: 'Crop Growth Stages',
    stepValue: 'crop-growth-stages',
    hasErrors: false,
    isFormValid: false
  },
  {
    stepLabel: 'Filter Key',
    stepValue: 'filter-key',
    hasErrors: false,
    isFormValid: false
  },
  {
    stepLabel: 'Crop Calendar',
    stepValue: 'crop-calendar',
    hasErrors: false,
    isFormValid: false
  },
  {
    stepLabel: 'Crop Stage Calendar',
    stepValue: 'crop-state-calendar',
    hasErrors: false,
    isFormValid: false
  },
];
