import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  buttonHelper: {
    textTransform: 'unset !important' as 'unset'
  },
  buttonGroupHelper: {
    marginLeft: 'auto'
  },
  accordionSummeryHelper: {
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)'
    }
  },
  customFilterBadge: {
    minWidth: 'max-content',
    background: '#CF3537',
    padding: '0.5px 10px',
    color: '#fff',
    fontSize: '12px',
    fontWeight: '600',
    borderRadius: '10px',
  }
});
