import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, Form, Formik, useField } from "formik";
import { useState } from "react";
import { GetImageURLMappedWithCROP } from "../../../Api/generic_apicalls";
import ButtonWithIcon, {
  CANCEL_TYPE,
  CREATE_TYPE,
} from "../../../Common/Buttons";
import SortableComponent from "../../../Common/SortableRecords";
import { UploadFile } from "../../../Common/UploadFile";
import { checkForDuplicates } from "../../../Utils/genericUtils";
import { TestimonialSchemaValidation } from "../HybridValidationSchema";
import { useStyles } from "../useStyle";
import { InitialTestimonials, TestimonialKeys } from "./index";
import {
  ModuleImageUpload,
  STEPS,
  ValidationType,
} from "../../../Common/Constants";
import { genericFormFieldValidation } from "../../../Utils/fileHelper";
import RenderField from "../../../Common/RenderField";

type GrowerProps = {
  ListOfStages: Array<TestimonialKeys>;
  selectedStage: TestimonialKeys;
  onSelect: Function;
  onSave: Function;
  onRemove: Function;
  onNext: Function;
  onPrevious: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  systemName: string;
  updateListOrder: Function;
  isEdit: boolean;
  hybridSystemName: string;
  cropSystemName: string;
  actionType: string;
  isPrimaryProduct: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
  getLatestImgURL: Function;
  isUpdateField: boolean;
};


export const MyTextField = ({ label, ...props }: any) => {
  const classes = useStyles();
  const [field] = useField(props);
  return (
    <>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      <TextField {...field} {...props} fullWidth></TextField>
    </>
  );
};
const MediaType = [{ label: "IMAGE" }, { label: "VIDEO" }];

const TextArea = ({ label, ...props }: any) => {
  const classes = useStyles();
  const [field] = useField(props);

  return (
    <>
      <Typography variant="subtitle2" className={classes.label}>
        {label}
      </Typography>
      <Typography fontSize="14px" color="#696F88">
        Max. 1000 characters
      </Typography>
      <TextareaAutosize
        {...field}
        {...props}
        className={classes.textAreaStyles}
      ></TextareaAutosize>
    </>
  );
};

export const GrowerTestimonialsForm: React.FC<GrowerProps> = (
  props: GrowerProps
) => {
  const classes = useStyles();
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);

  const nextStepHandler = (step: number) => {
    props.onNext(step);
  };

  const renderAddedTestimonials = () => {
    if (props.ListOfStages.length === 0) {
      return (
        <>
          {" "}
          <Stack flex={1} width={380}>
            <Box>
              <Typography variant="subtitle2">Added Testimonial(s):</Typography>
              <Typography variant="caption" component="span">
                You may reorder the testimonials by dragging each panel up or
                down
              </Typography>
              <FormHelperText error={true}>
                {isRuleErrorFlag && (
                  <>{"Please add one testimonial to continue"}</>
                )}
              </FormHelperText>
            </Box>
          </Stack>
        </>
      );
    }
    return (
      <>
        <Stack flex={1} width={380}>
          <Box>
            <Typography variant="subtitle2">Added Testimonial(s):</Typography>
            <Typography variant="caption" component="span">
              You may reorder the testimonials by dragging each panel up or down
            </Typography>
            <FormHelperText error={true}></FormHelperText>
            <Box my={2}></Box>
            <SortableComponent
              name={"Grower Testimonials"}
              items={props.ListOfStages}
              updateCropStageOrder={(newList: Array<GrowerProps>) => {
                props.updateListOrder(newList);
              }}
              key={props.ListOfStages.length}
              listProps={props}
              displayKey={"systemName"}
              initialState={InitialTestimonials}
              selectedRecord={props.selectedStage}
              // isDisabled={
              //   !props.isPrimaryProduct &&
              //   props.actionType !== ActionType.CREATE
              // }
              showRemoveButton={props.isPrimaryProduct}
              isDraggable={true}
            />
          </Box>
        </Stack>
      </>
    );
  };

  return (
    <>
      <Box>
        <Box className={classes.formContainer}>
          <div className={classes.formContainerChildDivStylesHepler}>
            <Stack direction="row" spacing={2}>
              <Formik
                initialValues={{
                  displayOrder: props.selectedStage.displayOrder,
                  id: props.selectedStage.id ?? null,
                  mediaType: props.selectedStage.mediaType ?? "",
                  mediaUrl: props.selectedStage.mediaUrl,
                  subtext: props.selectedStage.subtext,
                  systemName: props.selectedStage.systemName,
                  text: props.selectedStage.text,
                }}
                validationSchema={TestimonialSchemaValidation}
                enableReinitialize={true}
                onSubmit={(values: any, _onSubmitProps: any) => {
                  if (
                    checkForDuplicates(
                      props.ListOfStages,
                      { systemName: values!.systemName },
                      values!.systemName,
                      props.selectedStage.systemName
                    )
                  ) {
                    _onSubmitProps.setFieldError(
                      "systemName",
                      "Duplicate system name"
                    );
                    _onSubmitProps.setSubmitting(false);
                    return;
                  }
                  let growerData: any = { ...values };
                  // check if image is a string or file object and upload to s3 if it is a object
                  if (values.mediaUrl && typeof values.mediaUrl === "object") {
                    /** Generic Image Upload api call for combination of Current module Sysytem Name
                     *  and selected CROP system name
                     */
                    GetImageURLMappedWithCROP(
                      values.mediaUrl,
                      props.hybridSystemName,
                      ModuleImageUpload.HYBRID_GT,
                      props.cropSystemName,
                      "hybridSystemName",
                      (response: any) => {
                        growerData.mediaUrl = response.data.imageUrl;
                        props.getLatestImgURL(response.data.imageUrl);
                        props.onSave(growerData);
                        _onSubmitProps.resetForm();
                      },
                      () => {
                        _onSubmitProps.setFieldError(
                          "mediaUrl",
                          "Unable to upload file."
                        );
                        _onSubmitProps.setSubmitting(false);
                        return;
                      }
                    );
                  } else {
                    props.onSave(growerData);
                    _onSubmitProps.resetForm();
                  }
                }}
              >
                {({
                  values,
                  setFieldValue,
                  errors,
                }) => (
                  <Stack
                    flex={1}
                    justifyContent="flex-end"
                    direction="row"
                    gap={2}
                  >
                    <Form>
                      <Stack spacing={2} width={380}>
                        <Box>
                          <Field
                            name="systemName"
                            validate={
                              props.selectedStage.systemName
                                ? null
                                : (value: string) =>
                                    genericFormFieldValidation(
                                      value,
                                      ValidationType.SYS_NM
                                    )
                            }
                          >
                            {() => {
                              return (
                                <FormControl fullWidth>
                                  <Typography variant="subtitle2">
                                    Grower Testimonial System Name *
                                  </Typography>
                                  <TextField
                                    name="systemName"
                                    onChange={(event: any) => {
                                      setFieldValue(
                                        "systemName",
                                        event.target.value
                                          .trim()
                                          .toLocaleUpperCase()
                                      );
                                    }}
                                    defaultValue={values.systemName}
                                    value={values?.systemName}
                                    disabled={
                                      props.canAddNewRecord
                                        ? !!props.selectedStage.systemName
                                        : true
                                    }
                                  />
                                </FormControl>
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="systemName" />
                          </FormHelperText>
                          <MyTextField
                            name="subtext"
                            type="text"
                            label={`Media Title *`}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFieldValue("subtext", event.target.value);
                            }}
                          />
                          <FormHelperText
                            error={true}
                            className={classes.languagErrorMsgHepler}
                          >
                            <ErrorMessage name="subtext" />
                          </FormHelperText>
                          <Field name="mediaType" id="mediaType">
                            {(_props: any) => {
                              const { form } = _props;
                              const { setFieldValue } = form;
                              return (
                                <FormControl
                                  className={classes.formControlMrtHelper}
                                  fullWidth
                                >
                                  <RenderField
                                    label={`Media Type *`}
                                    labelKey={"label"}
                                    name={"mediaType"}
                                    onChange={(name: string, value: any) => {
                                      setFieldValue("mediaType", value?.label);
                                      /** Resetting Image/Video Media fields properties
                                       * on type change
                                       */
                                      setFieldValue("mediaUrl", "");
                                    }}
                                    options={MediaType}
                                    value={values.mediaType}
                                    isDisabled={
                                      props.canAddNewRecord
                                        ? !!props.selectedStage.systemName
                                        : true
                                    }
                                  />
                                </FormControl>
                              );
                            }}
                          </Field>
                          <FormHelperText
                            error={true}
                            className={classes.languagErrorMsgHepler}
                          >
                            <ErrorMessage name={`mediaType`} />
                          </FormHelperText>
                          <TextArea
                            name="text"
                            as="textarea"
                            label={`Grower Testimonial Description`}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              setFieldValue("text", event.target.value);
                            }}
                          />
                          <FormHelperText
                            error={true}
                            className={classes.languagErrorMsgHepler}
                          >
                            <ErrorMessage name="text" />
                          </FormHelperText>
                          {values.mediaType === "IMAGE" ? (
                            <Field name={`mediaUrl`}>
                              {() => {
                                return (
                                  <>
                                    <UploadFile
                                      name="mediaUrl"
                                      acceptedFileTypes={[
                                        "image/png",
                                        "image/jpg",
                                        "image/jpeg",
                                      ]}
                                      isRequired={false}
                                      maxFileSize={5}
                                      imageUrl={values.mediaUrl}
                                      onChange={(data: any) => {
                                        setFieldValue("mediaUrl", data);
                                      }}
                                      canRemoveOrReplace={props.canAddNewRecord}
                                    />
                                    <FormHelperText
                                      error={true}
                                      className={classes.languagErrorMsgHepler}
                                      key={errors.mediaUrl}
                                    >
                                      <ErrorMessage name="mediaUrl" />
                                    </FormHelperText>
                                  </>
                                );
                              }}
                            </Field>
                          ) : null}
                          {values.mediaType === "VIDEO" ? (
                            <>
                              <MyTextField
                                name="mediaUrl"
                                type="text"
                                label={`MediaURL`}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  setFieldValue("mediaUrl", event.target.value);
                                }}
                              />
                              <FormHelperText
                                error={true}
                                className={classes.languagErrorMsgHepler}
                                key={errors.mediaUrl}
                              >
                                <ErrorMessage name="mediaUrl" />
                              </FormHelperText>
                            </>
                          ) : null}
                        </Box>
                        <Stack alignSelf="self-end" maxWidth={150}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            disabled={
                              !(props.selectedStage.systemName
                                ? props.canUpdateExistingRecord
                                : props.canAddNewRecord)
                            }
                          >
                            {(props.selectedStage?.systemName && "Update") ||
                              "Add Testimonial"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                    <Divider orientation="vertical" flexItem />
                  </Stack>
                )}
              </Formik>
              {
                /** added testimonials will be listed here */
                renderAddedTestimonials()
              }
            </Stack>
          </div>
        </Box>
        <>
          <Divider />
          <div className={classes.formActionFooterBtnHelper}>
            <Stack direction="row" spacing={2}>
              <ButtonWithIcon
                showCreateProgress={false}
                type={CANCEL_TYPE}
                label="Previous"
                onClick={() => {
                  props.onPrevious(STEPS.STEP4);
                }}
              />
              <ButtonWithIcon
                showCreateProgress={false}
                type={CREATE_TYPE}
                label="Next"
                onClick={() => {
                  // props.ListOfStages.length ? nextStepHandler(STEPS.STEP6) : setIsRuleErrorFlag(true);
                  nextStepHandler(STEPS.STEP6);
                }}
              />
            </Stack>
          </div>
        </>
      </Box>
    </>
  );
};
