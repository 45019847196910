import { api_service } from "../../Api/api_service";
import { IRequestBody } from "../../Constants/interfaces";

export const getDashboardMediaData = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.then((response) => {
    successCallBack(response.data, 'dashboard_data_list');
  }).catch((err) => {
    errorCallBack(err, 'dashboard_data_list');
  });
};
export const getDashboardStates = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.then((response) => {
    successCallBack(response.data, 'Dashboard_states');
  }).catch((err) => {
    errorCallBack(err, 'Dashboard_states');
  });
};
export const DeleteDashboard = (apiData: IRequestBody, successCallBack: Function, errorCallBack: Function) => {
  return new Promise((resolve, reject) => {
    api_service.delete(apiData)?.then((response) => {
      return resolve(successCallBack(response.data, 'delete_dashboard'));
    }).catch((err) => {
      return reject(errorCallBack(err, 'delete_dashboard'));
    });
  });
}
export const saveDashboardMedia = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.post(data)?.then((response) => {
    successCallBack(response.data, 'save_dashboardmedia');
  }).catch((err) => {
    errorCallBack(err, 'save_dashboardmedia');
  });
};
export const viewDashboardMedia = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.then((response) => {
    successCallBack(response.data, 'view_dashboardmedia');
  }).catch((err) => {
    errorCallBack(err, 'view_dashboardmedia');
  });
};
export const DashboardUploadList = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.then((response) => {
    successCallBack(response.data, 'dashboard_upload_list');
  }).catch((err) => {
    errorCallBack(err, 'dashboard_upload_list');
  });
};

