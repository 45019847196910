import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles({
  tabContainerHelper: {
    paddingLeft: 16,
    '& .MuiTabs-scroller':{
      overflowX:'auto !important',
      '&::-webkit-scrollbar':{
        display: 'none'
      }
    }
  },
  tabHelper: {
    textTransform: 'none !important' as 'none'
  },
  activeTab: {
    fontWeight: '600 !important'
  }
});
