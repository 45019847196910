import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, ButtonGroup, IconButton, Stack, Typography} from '@mui/material';
import React from 'react';

type ModalHeaderProps = {
    header: string;
    onClose: Function;
    showDelete?: boolean
    showEdit?: boolean;
    editHandler?: Function;
};

const ModalHeader: React.FC<ModalHeaderProps> = (props: ModalHeaderProps) => {
    const modalEditHandler = () => {
        props.editHandler && props.editHandler();
    }
    return (
        <Box
            style={{display: 'flex', justifyContent: 'space-between', padding: 16}}
        >
            <Typography component='div' variant='subtitle1' fontSize={18}>
                {props.header}
            </Typography>
            <Stack direction='row' alignItems='center'>
                <ButtonGroup variant='text'>
                    {props.showDelete && <Button>Delete</Button>}
                    {props.showEdit && <Button data-testid="edit-button" onClick={() => modalEditHandler()}>Edit</Button>}
                </ButtonGroup>
                <IconButton
                    data-testid='close-button'
                    style={{marginTop: 'auto'}}
                    onClick={() => {
                        props.onClose();
                    }}
                >
                    <CloseIcon fontSize='small' color='action'/>
                </IconButton>
            </Stack>
        </Box>
    );
};

export default ModalHeader;
