export  const StagesToCreateThreat = [
    {
      stepLabel: 'General Details and FAQ',
      stepValue: 'general-details-and-FAQ',
      hasErrors: false,
      isFormValid: false
    },
    {
      stepLabel: 'Threat Stage',
      stepValue: 'threat-stages',
      hasErrors: false,
      isFormValid: false
    },
]