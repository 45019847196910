import { boolean } from 'yup/lib/locale';
import { HIDE_TOAST, SHOW_LOADER, SHOW_TOAST } from './actions-constants';

const Constants = {
    showLoader: boolean
};

const showLoader = (flag: boolean) => {
    return {
        type: SHOW_LOADER,
        payload: flag
    };
};

export const showToaster = (toastInfo: any) => {
    return {
        type: SHOW_TOAST,
        payload: toastInfo
    };
};

export const hideToaster = () => {
    return {
        type: HIDE_TOAST,
    };
}

export default {
    Constants,
    showLoader,
    hideToaster,
    showToaster
};
