import { useEffect, useState } from "react";

export type PublishProgressType = {
  module: string;
  publishName: string;
  status: "Published" | "Publishing" | "Error";
  message?: string;
};

const usePublishProgress = () => {
  const [listPublishProgress, setListPublishProgress] = useState<
    PublishProgressType[]
  >([]);

  const deletePublishProgress = (publishName: string) => {
    setListPublishProgress((prev) => {
      return prev.filter((item) => item.publishName !== publishName);
    });
  };

  const deleteAllPublishProgress = () => {
    setListPublishProgress([]);
  }

  const addPublishProgress = (publishProgress: PublishProgressType) => {
    setListPublishProgress((prev) => {
      const existingItemIndex = prev.findIndex(
        (item) => item.publishName === publishProgress.publishName
      );

      if (existingItemIndex !== -1) {
        const newList = [...prev];
        newList[existingItemIndex] = publishProgress;

        return newList;
      } else {
        return [...prev, publishProgress];
      }
    });
  };

  useEffect(() => {
    listPublishProgress.forEach((item) => {
      if (item.status === "Published")
        setTimeout(() => deletePublishProgress(item.publishName), 2000);
    });
  }, [listPublishProgress]);

  return { listPublishProgress, addPublishProgress, deletePublishProgress, deleteAllPublishProgress };
};

export default usePublishProgress;
