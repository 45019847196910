import React from "react";
import RadioWithLabel from "../../RadioWithLabel";
import AccordionList from "../AccordionList";
import { SortProps } from ".";

const TITLE = "Last Updated On";

const LastUpdatedOn: React.FC<SortProps> = (props) => {
  return (
    <AccordionList title={TITLE}>
      {[
        { key: "1", value: "Descending" },
        { key: "0", value: "Ascending" },
      ].map((obj: any, index: number) => {
        return (
          <RadioWithLabel
            name={TITLE}
            checked={props.sort.lastUpdatedOn === obj.key || false}
            label={obj.value}
            value={obj.key}
            onChange={() => {
              props.onChangeSort?.({ ...props.sort, lastUpdatedOn: obj.key });
            }}
            key={`${obj.val}-${index}`}
          />
        );
      })}
    </AccordionList>
  );
};

export default LastUpdatedOn;
