import { Box } from "@mui/material";
import { styled } from "@mui/styles";

export const OddTableRow = styled(Box)({
    padding: '0.3rem 1rem',
        border: '1px solid #CFD3DB',
        '&:nth-child(odd)': {
          backgroundColor: '#F3F4F6'
        },
        '& .MuiTypography-root':{
            overflow:'hidden',
            textOverflow:'ellipsis'
        },
        '&:hover':{
            cursor:'default',
            
        },
        '&.higlightSelected': {
            backgroundColor: '#14803C',
            color: '#fff',
            '& .MuiButton-root': {
                color: '#fff'
            }
        },
        '& .sortableActionBtnsMxAuto':{
            marginLeft:'auto'
        },
        '& .nineDotsIcon':{
            marginLeft:'1rem'
        }
});