import { useSelector } from "react-redux";
import AccordionList from "../AccordionList";
import { RootState } from "../../../redux/reducers";
import CheckboxWithLabel from "../../CheckboxWithLabel";
import { FilterProps } from ".";

const Language: React.FC<FilterProps> = (props) => {
  const languages = useSelector(
    (state: RootState) => state.generic.ListOfLanguages.languageList
  );

  const languageIds = props.filters.languageIds ?? [];

  const onChangeCheckbox = (name: string, checked: boolean) => {
    const newLanguageIds = checked
      ? [...languageIds, name]
      : languageIds.filter((id) => id !== name);

    props.onChangeFilters?.({
      ...props.filters,
      languageIds: newLanguageIds,
    });
  };

  return (
    <AccordionList title="Language">
      {languages.map((language, index: number) => {
        const isChecked = languageIds.includes(
          `${language.languageName}:::${language.languageId}`
        );

        return (
          <CheckboxWithLabel
            name={`${language.languageName}:::${language.languageId}`}
            checked={isChecked}
            label={language.languageName}
            onChange={onChangeCheckbox}
            key={`${language.languageCode}-${index}`}
          />
        );
      })}
    </AccordionList>
  );
};

export default Language;
