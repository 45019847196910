import { Button } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RoutePaths } from "../../Constants/routeConstants";
import {
    InnerBox,
    OuterBox,
    SuccessMessage,
    SuccessMessageDescription
} from "./style";
export const SuccessPage = () => {
    const navigation = useNavigate();
    const location = useLocation();
    const params = useParams();

    const navigateToPreviousPage = () => {
        const path = decodeURI(location.pathname);
        if (path.includes('/success/pushnotification')) {
            navigation(RoutePaths.PushNotifications);
        } else if (path.includes('/success/crops')) {
            navigation(RoutePaths.Crops);
        } else if (path.includes('/success/threats')) {
            navigation(RoutePaths.Threats);
        } else if (path.includes('/success/hybrids')) {
            navigation(RoutePaths.Hybrids);
        } else if (path.includes('/success/dashboard')) {
            navigation(RoutePaths.DashboardMedia);
        } else if (path.includes('/success/retailers')) {
            navigation(RoutePaths.RetailersAndNurseries);
        } else if (path.includes('/success/Cp Product') || path.includes('/success/Plantix Cp Products')) {
            navigation(-1);
        } else {
            navigation('/');
        }
    }

    const getMessage = () => {
        let message: string;
        switch (params.mode) {
            case 'created':
                message = 'created';
                break;
            case 'updated':
                message = 'updated';
                break;
            case 'published':
                message = 'published';
                break;
            default:
                message = '';
        }
        return <>{`Data successfully ${message}`}</>;
    }
    return (
        <OuterBox className="OuterBox">
            <InnerBox>
                <img
                    width={100}
                    alt="publish success"
                    src={process.env.PUBLIC_URL + "/assets/publishsuccess-1.svg"}
                />
                <SuccessMessage>{getMessage()}</SuccessMessage>
                <SuccessMessageDescription>
                    Please visit publish module to publish this record
                </SuccessMessageDescription>
                <Button
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{ my: "1rem" }}
                    onClick={() => navigateToPreviousPage()}
                >
                    Back
                </Button>
            </InnerBox>
        </OuterBox>
    );
};
