import { AuthenticationResult } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Loader from "./Common/Loader";
import ProgressProvider from "./Common/ProgressProvider";
import Toasters from "./Common/Toaster";
import Dashboard from "./Components/Dashboard";
import { UserContext, UserContextType } from "./Components/Login/userContext";
import UnAuthRoutes from "./Routes/unAuthRoutes";
import { RootState } from "./redux/reducers";

const THEME = createTheme({
  palette: {
    primary: {
      main: "#0071CD",
    },
    success: {
      main: "#14803C",
    },
    info: {
      main: "#232630",
    },
    error: {
      main: "#CF3537",
    },
  },
  typography: {
    fontFamily: "Noto Sans",
    subtitle1: {
      fontWeight: 700,
      fontSize: 20,
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: 14,
    },
    body1: {
      fontWeight: 500,
      fontSize: 16,
    },
    body2: {
      fontWeight: 400,
      fontSize: 12,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "unset",
        },
        outlined: {
          borderColor: "#DFE2E7",
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: "44px",
          color: "#696F88",
          fontSize: "14px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&:hover": {
            background: "#C6E6FF",
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        grouped: {
          ":not(:last-of-type)": {
            borderColor: "#CFD3DB",
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderTopLeftRadius: "6px",
          borderTopRightRadius: "6px",
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: "14px",
          zIndex: 9,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          fontSize: "14px",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
      },
    },
  },
});

const App = () => {
  // variables
  const storeData = useSelector((state: RootState) => {
    return {
      token: state.auth.authToken,
    };
  });
  const {
    accounts,
    // instance,
  } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // states
  const [userContext, setUserContext] = React.useState<UserContextType>({
    name: (accounts.length && accounts[0].name) || "",
  });

  // handlers
  const updateUserName = (name: string) => {
    setUserContext({
      name,
    });
  };

  // effects
  React.useEffect(() => {
    if (storeData.token && accounts.length) {
      const name: string = (accounts.length && accounts[0].name) || "";
      updateUserName(name);
    }
  }, [accounts, storeData.token]);

  const handleSignInSuccess = (response: AuthenticationResult) => {
    //console.log(response);
    return;
  };

  return (
    <ThemeProvider theme={THEME}>
      <Loader />
      <Toasters />
      <BrowserRouter>
        <Routes>
          <Route
            path="*"
            element={
              (!storeData.token && (
                <UnAuthRoutes onSignInSuccess={handleSignInSuccess} />
              )) || (
                <UserContext.Provider value={userContext}>
                  <ProgressProvider>
                    <Dashboard />
                  </ProgressProvider>
                </UserContext.Provider>
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
