import * as Yup from "yup";

export const ValidationSchema = () =>
  Yup.object().shape(
    {
      // mobile: Yup.string().optional(),
      // language: Yup.array()
      //   .of(Yup.string())
      //   .when("mobile", {
      //     is: (mobile: string) => !mobile,
      //     then: Yup.array().of(Yup.string()).required("Language required"),
      //     otherwise: Yup.array().of(Yup.string()),
      //   }),
      notificationTypeID: Yup.string().required("Notification type required"),
      filteredCrops: Yup.array().required("Crops required").nullable(),
      stateNames: Yup.array().required("States required").nullable(),
      districts: Yup.array().required("Districts required").nullable(),
      frequency: Yup.string().required("Frequency required"),
      dayOfWeek: Yup.string().when("frequency", {
        is: (frequency: string) =>
          frequency && frequency.toLowerCase() === "weekly",
        then: Yup.string().required("Day of week required"),
        otherwise: Yup.string().optional().nullable(),
      }),
      dayOfMonth: Yup.string().when("frequency", {
        is: (frequency: string) =>
          frequency && frequency.toLowerCase() === "monthly",
        then: Yup.string().required("Day of month required"),
        otherwise: Yup.string().optional().nullable(),
      }),
      startDate: Yup.date().required("Date required").nullable(),
      endDate: Yup.date().when("frequency", {
        is: (frequency: string) => frequency && frequency !== "Once",
        then: Yup.date().required("End Date required").nullable(),
        otherwise: Yup.date().optional().nullable(),
      }),
      time: Yup.string().required("Time required"),
      title: Yup.string().when("language", {
        is: (language: string[]) => !language || language.length === 0,
        then: Yup.string().required("Title is required"),
        otherwise: Yup.string(),
      }),
      message: Yup.string().when("language", {
        is: (language: string[]) => !language || language.length === 0,
        then: Yup.string()
          .max(1024, "Max value 1024")
          .required("Message required"),
        otherwise: Yup.string(),
      }),
      hyperLink: Yup.string().optional(),
      sendAsSMS: Yup.boolean().optional(),
      smsCost: Yup.string().optional().nullable(),
    },
    [
      ["endDate", "startDate"],
      ["frequency", "dayOfWeek"],
      ["frequency", "dayOfMonth"],
      ["frequency", "dayOfMonth"],
      ["frequency", "endDate"],
      ["image", "image"],
    ]
  );
