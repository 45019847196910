import _ from '../../Utils/lodash';
import {
    AUTH_TOKEN,
    BASE_URL,
    LOGOUT,
    SELECTED_COUNTRY,
    UPDATE_LANGUAGE_CODE
} from '../action/actions-constants';
export interface IAPIDataState {
    authToken?: string;
    baseUrl?: string;
    countryCode?: string;
    languageCode?: string;
}

const initialState: IAPIDataState = {
    authToken: '',
    baseUrl: '',
    countryCode: '',
    languageCode: ''
};
interface IActionParams {
    payload: any;
    type: String;
}

// const appReducer = (state: IAPIDataState = initialState, { type, payload }: IActionParams) => {
export function appReducer(state: IAPIDataState = initialState, { type, payload }: IActionParams): IAPIDataState {
    switch (type) {
        case AUTH_TOKEN: {
            return _.extend({}, state, { authToken: payload });
        }
        case BASE_URL: {
            return _.extend({}, state, { baseUrl: payload });
        }
        case SELECTED_COUNTRY: {
            return _.extend({}, state, { countryCode: payload });
        }
        case UPDATE_LANGUAGE_CODE: {
            return _.extend({}, state, { languageCode: payload });
        }
        case LOGOUT: {
            return _.extend({}, initialState);
        }
        default: { return state; }
    }
}

// export default appReducer;
