import {
  Box,
  FormControl,
  FormHelperText,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { GeneralFormType } from ".";
import { api_service } from "../../../Api/api_service";
import api_endpoints, { base_url } from "../../../Api/end_points";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import { IRequestBody } from "../../../Constants/interfaces";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { useStyles } from "./styles";
import { ValidationSchemaGeneralForm } from "./validationSchema";
import {
  ActionType,
  ModuleImageUpload,
  STEPS,
  ValidationType,
} from "../../../Common/Constants";
import {
  genericFormFieldValidation,
} from "../../../Utils/fileHelper";
import { removeSpacesImagePath } from "../../../Utils/removeSpaceImagePath";
import { Media, UploadFile } from "../../../Common/UploadFile";
import { GetImageURLMappedWithCROP } from "../../../Api/generic_apicalls";
import RenderField from "../../../Common/RenderField";
import {removeImageField} from "../../../Utils/genericUtils";

export type GeneralFormProps = {
  generalFormDetails: any;
  formRef?: any;
  onNext: Function;
  cropList: Array<any> | undefined;
  // fetchCrop: Function;
  actionType: string;
  isPrimaryCP: boolean;
  getFileURL: Function;
  targetThreatList: Array<any>;
  recommendationList: Array<any>;
  resetThreatAndCalendar: Function;
  isEdit: boolean;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  getLatestImgURL: Function;
  baseLanguage?: string;
};



export type LangObjectType = {
  languageId: number | undefined;
  languageName: string;
  languageCode: string;
};

const GenralForm: React.FC<GeneralFormProps> = (props) => {
  // variables
  const classes = useStyles();
  // states
  const [selectedLangObj, setSelectedLangObj] = useState<LangObjectType>({
    languageId: props.generalFormDetails.languageId,
    languageName: props.generalFormDetails.languageId,
    languageCode: props.generalFormDetails.languageId,
  });
  const [imageFile] = useState<any>(
    props.generalFormDetails.image
  );
  console.log('imageFile', props)
  // exctract props
  const handleLanguageChange = (language: string, values: any) => {
    let langArrData = language.split("##");
    let langData: LangObjectType = {
      languageId: parseInt(langArrData[0]),
      languageName: langArrData[1],
      languageCode: langArrData[2],
    };
    setSelectedLangObj(langData);
  };


  /** ----------Unique System name validation------------ */
  const checkSysNameAvailability = (generalDetails: any) => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.verify_cp_sysnm.replace(
      "__CP_NAME__",
      generalDetails.systemName
    );
    apiData.showLoader = true;
    apiData.customHeaders = {
      "language-code": selectedLangObj.languageCode,
    };
    api_service
      .get(apiData)
      ?.then((response) => {
        let res = response.data;
        if (res && res.statusCode === 200) {
          if (!res.data.isCPProductExist) {
            /** If given system name not exist than only go to next step */
            if (imageFile) {
              props.getFileURL(imageFile, {
                productSystemName: generalDetails.systemName,
                cropSystemName: generalDetails.crop,
                type: ModuleImageUpload.CP_GENERAL,
              });
            }
            props.onNext(STEPS.STEP2, generalDetails);
          } else {
            TriggerToastMessage(res.message, "error");
          }
        }
      })
      .catch(() => {
        TriggerToastMessage(
          "Verify Sysytem name API failed, try later.",
          "error"
        );
      });
  };
  /** Initial values for General Detail Form */
  const initialGeneralState: GeneralFormType = {
    language: props.generalFormDetails.language,
    media: props.generalFormDetails.media ?? [],
    image: removeSpacesImagePath(
      props.generalFormDetails.systemName,
      props.generalFormDetails.image
    ),
    productTitle: props.generalFormDetails.productTitle,
    systemName: props.generalFormDetails.systemName,
    crop: props.generalFormDetails.crop,
    category: props.generalFormDetails.category,
    dosage: props.generalFormDetails.dosage,
    whenToUse: props.generalFormDetails.whenToUse,
    howToUse: props.generalFormDetails.howToUse,
    applicableCrop: props.generalFormDetails.applicableCrop,
    benefit1: props.generalFormDetails.benefit1,
    benefit2: props.generalFormDetails.benefit2,
    benefit3: props.generalFormDetails.benefit3,
  };

  useEffect(() => {
    if (props.generalFormDetails.language) {
      let langData: LangObjectType = {
        languageId: props.generalFormDetails.languageId,
        languageName: props.generalFormDetails.language
          ? props.generalFormDetails.language
          : "",
        languageCode: props.generalFormDetails.languageCode,
      };
      setSelectedLangObj(langData);
    }
  }, [props.generalFormDetails.language]);
  return (
    <Formik
      initialValues={initialGeneralState}
      validationSchema={ValidationSchemaGeneralForm}
      onSubmit={(values: any, _onSubmitProps: any) => {
        /** Calling save generalForm method */
        let generalDetails: any = { ...values };
        generalDetails.languageId = selectedLangObj.languageId;
        generalDetails.languageCode = selectedLangObj.languageCode;

        const media = values.media.filter((media: any) => media) as Media[];
        if (media.some((media) => media?.path instanceof File)) {
          for (let i = 0; i < media.length; i++) {
            if (media[i].path instanceof File) {
              GetImageURLMappedWithCROP(
                media[i].path as File,
                values.systemName,
                ModuleImageUpload.CP_GENERAL,
                values.crop,
                "productSystemName",
                (response: any) => {
                  generalDetails.imageUrl = response.data.imageUrl;
                  generalDetails.media[i] = {
                    ...generalDetails.media[i],
                    path: response.data.imageUrl,
                  };
                  props.getLatestImgURL(response.data.imageUrl);
                },
                (error: any) => {
                  console.error(error, "ERROR");
                  /** Image Upload Error handler */
                  _onSubmitProps.setFieldError(
                    "imageUrl",
                    "Unable to upload file."
                  );
                  _onSubmitProps.setSubmitting(false);
                  return;
                },
                (i + 1).toString().padStart(2, "0")
              );
            }
          }

          generalDetails.media = generalDetails.media.filter(
            (media: Media) => media
          );

          props.onNext(STEPS.STEP2, generalDetails);
          _onSubmitProps.resetForm();
        } else {
          generalDetails.media = generalDetails.media.filter(
            (media: Media) => media
          );
          props.onNext(STEPS.STEP2, generalDetails);
          _onSubmitProps.resetForm();
        }

        if (
          props.actionType === ActionType.CREATE ||
          (props.actionType === ActionType.ADD_TRANSLATION &&
            values.language !== props.baseLanguage)
        ) {
          /** for cp creation only check the sys name availability */
          checkSysNameAvailability(generalDetails);
        } else {
          /** no check for cp non-create flow */
          if (imageFile !== undefined) {
            props.getFileURL(imageFile, {
              productSystemName: generalDetails.systemName,
              cropSystemName: generalDetails.crop,
              type: ModuleImageUpload.CP_GENERAL,
            });
          }
          props.onNext(STEPS.STEP2, generalDetails);
        }
      }}
      ref={props.formRef}
      enableReinitialize={true}
    >
      {({ values, setFieldValue }) => (
        <>
          <Form className={classes.formOutline}>
            <Box className={classes.formContainer}>
              <Box className={classes.formFieldsHelperOne}>
                <Field name="language">
                  {() => {
                    return (
                      <LanguageDropdown
                        selectedLanguage={
                          values.language || selectedLangObj.languageName
                        }
                        onChangeLanguage={(langData: string) => {
                          handleLanguageChange(langData, { ...values });
                          setFieldValue("language", langData.split("##")[1]);
                        }}
                        key={selectedLangObj?.languageName || values.language}
                        displayPrimaryLanguage={
                          props.actionType !== ActionType.ADD_TRANSLATION
                        }
                      />
                    );
                  }}
                </Field>
              </Box>
              <FormHelperText
                error={true}
                className={classes.languagErrorMsgHepler}
              >
                <ErrorMessage name="language" />
              </FormHelperText>
              <div className={classes.formFieldsHelper}>
                <Field
                  name="media"
                  validate={(value: Media[]) => {
                    if (props.actionType === ActionType.ADD_TRANSLATION) {
                      return "";
                    }
                    return value?.length === 0 ||
                      value?.every((media) => media == null || media.path === "")
                      ? "Image Required"
                      : "";
                  }}
                >
                  {(imageProps: any) => (
                    <UploadFile
                      name="imageUrl"
                      acceptedFileTypes={[
                        "image/png",
                        "image/jpg",
                        "image/jpeg",
                      ]}
                      maxFileSize={5}
                      imageUrl={""}
                      onChange={(
                        file: File,
                        currentMedia: Partial<Media>[],
                        index: number
                      ) => {
                        const newMedia = [...currentMedia];
                        newMedia[index] = {
                          id: currentMedia[index]?.id ?? null,
                          displayOrder: index + 1,
                          type: "IMAGE",
                          path: file,
                        };
                        setFieldValue(`media`, newMedia);
                      }}
                      onRemoveImage={(index, currentMedia) => {
                        const newMedia = removeImageField(index, currentMedia);
                        setFieldValue(`media`, newMedia);
                      }}
                      canRemoveOrReplace={props.canAddNewRecord}
                      multiple={true}
                      media={values.media}
                    />
                  )}
                </Field>
                <FormHelperText error={true}>
                  <ErrorMessage name="media" />
                </FormHelperText>
                <Field name="productTitle">
                  {() => {
                    return (
                      <FormControl
                        className={classes.formMarginHelper}
                        fullWidth
                      >
                        <Typography variant="subtitle2">
                          Product Title *
                        </Typography>
                        <TextField
                          name="productTitle"
                          value={values.productTitle}
                          onChange={(event: any) =>
                            setFieldValue("productTitle", event.target.value)
                          }
                          defaultValue={values.productTitle}
                        />
                      </FormControl>
                    );
                  }}
                </Field>
                <FormHelperText error={true}>
                  <ErrorMessage name="productTitle" />
                </FormHelperText>
                <Field
                  name="systemName"
                  validate={
                    props.actionType === ActionType.UPDATE
                      ? null
                      : (value: string) =>
                          genericFormFieldValidation(
                            value,
                            ValidationType.SYS_NM
                          )
                  }
                >
                  {() => {
                    return (
                      <FormControl
                        className={classes.formMarginHelper}
                        fullWidth
                      >
                        <Typography variant="subtitle2">
                          System Name *
                        </Typography>
                        <TextField
                          name="systemName"
                          onChange={(event: any) =>
                            setFieldValue(
                              "systemName",
                              event.target.value.trim().toUpperCase()
                            )
                          }
                          defaultValue={values?.systemName}
                          value={values.systemName}
                          disabled={!props.isSystemNameEditable}
                        />
                      </FormControl>
                    );
                  }}
                </Field>
                <FormHelperText error={true}>
                  <ErrorMessage name="systemName" />
                </FormHelperText>
                <Field name="crop">
                  {() => {
                    return (
                      <FormControl
                        className={classes.formMarginHelper}
                        fullWidth
                      >
                        <RenderField
                          key={values.crop}
                          name="crop"
                          labelKey="name"
                          options={props.cropList ? props.cropList : []}
                          label="Crop *"
                          value={values.crop}
                          onChange={(name: string, value: any) => {
                            if (
                              (props.targetThreatList.length ||
                                props.recommendationList.length) &&
                              confirm(
                                "Alert!! As you are trying to change the crop, the step5 and step6 details will be Reset."
                              )
                            ) {
                              props.resetThreatAndCalendar();
                              setFieldValue("crop", value.name);
                            } else if (
                              !(
                                props.targetThreatList.length ||
                                props.recommendationList.length
                              )
                            ) {
                              setFieldValue("crop", value.name);
                            }
                          }}
                          isDisabled={!props.isSystemNameEditable}
                        />
                      </FormControl>
                    );
                  }}
                </Field>
                <FormHelperText error={true}>
                  <ErrorMessage name="crop" />
                </FormHelperText>
                <Field name="category">
                  {() => {
                    return (
                      <FormControl
                        className={classes.formMarginHelper}
                        fullWidth
                      >
                        <Typography variant="subtitle2">Category</Typography>
                        <TextField
                          name="category"
                          value={values.category}
                          onChange={(event: any) =>
                            setFieldValue("category", event.target.value)
                          }
                        />
                      </FormControl>
                    );
                  }}
                </Field>
                <FormHelperText error={true}>
                  <ErrorMessage name="category" />
                </FormHelperText>
                <Field name="dosage">
                  {() => {
                    return (
                      <FormControl
                        className={classes.formMarginHelper}
                        fullWidth
                      >
                        <Typography variant="subtitle2">Dosage *</Typography>
                        <TextField
                          name="dosage"
                          value={values.dosage}
                          onChange={(event: any) =>
                            setFieldValue("dosage", event.target.value)
                          }
                        />
                      </FormControl>
                    );
                  }}
                </Field>
                <FormHelperText error={true}>
                  <ErrorMessage name="dosage" />
                </FormHelperText>
                <Stack flex={1}>
                  <FormControl className={classes.formMarginHelper}>
                    <Typography fontSize={14} variant="subtitle2">
                      When to Use *
                    </Typography>
                    <Field name="whenToUse">
                      {() => {
                        return (
                          <TextareaAutosize
                            onBlur={(event) =>
                              setFieldValue("whenToUse", event.target.value)
                            }
                            name="whenToUse"
                            className={classes.textAreaStyles}
                            maxLength={1024}
                            defaultValue={values.whenToUse}
                            key={values.whenToUse}
                          />
                        );
                      }}
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="whenToUse" />
                    </FormHelperText>
                  </FormControl>
                </Stack>
                <Stack flex={1}>
                  <FormControl className={classes.formFiledMrHelper} fullWidth>
                    <Typography fontSize={14} variant="subtitle2">
                      How to Apply *
                    </Typography>
                    <Field name="howToUse">
                      {() => {
                        return (
                          <TextareaAutosize
                            name="howToUse"
                            className={classes.textAreaStyles}
                            maxLength={1024}
                            onBlur={(
                              event: React.ChangeEvent<HTMLTextAreaElement>
                            ) => setFieldValue("howToUse", event.target.value)}
                            defaultValue={values.howToUse}
                            key={values.howToUse}
                          />
                        );
                      }}
                    </Field>
                    <FormHelperText error={true}>
                      <ErrorMessage name="howToUse" />
                    </FormHelperText>
                  </FormControl>
                </Stack>
                <FormControl className={classes.formFiledMrHelper} fullWidth>
                  <Typography fontSize={14} variant="subtitle2">
                    Applicable Crop{" "}
                  </Typography>
                  <Field name="applicableCrop">
                    {() => {
                      return (
                        <TextareaAutosize
                          name="applicableCrop"
                          className={classes.textAreaStyles}
                          maxLength={1024}
                          onBlur={(
                            event: React.ChangeEvent<HTMLTextAreaElement>
                          ) =>
                            setFieldValue("applicableCrop", event.target.value)
                          }
                          defaultValue={values.applicableCrop}
                          key={values.applicableCrop}
                        />
                      );
                    }}
                  </Field>
                  <FormHelperText error={true}>
                    <ErrorMessage name="applicableCrop" />
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formFiledMrHelper} fullWidth>
                  <Typography fontSize={14} variant="subtitle2">
                    Key Benefits 1 *
                  </Typography>
                  <Field name="benefit1">
                    {() => {
                      return (
                        <TextareaAutosize
                          name="benefit1"
                          className={classes.textAreaStyles}
                          maxLength={1024}
                          onBlur={(
                            event: React.ChangeEvent<HTMLTextAreaElement>
                          ) => setFieldValue("benefit1", event.target.value)}
                          defaultValue={values.benefit1}
                          key={values.benefit1}
                        />
                      );
                    }}
                  </Field>
                  <FormHelperText error={true}>
                    <ErrorMessage name="benefit1" />
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formFiledMrHelper} fullWidth>
                  <Typography fontSize={14} variant="subtitle2">
                    Key Benefits 2
                  </Typography>
                  <Field name="benefit2">
                    {() => {
                      return (
                        <TextareaAutosize
                          name="benefit2"
                          className={classes.textAreaStyles}
                          maxLength={1024}
                          onBlur={(
                            event: React.ChangeEvent<HTMLTextAreaElement>
                          ) => setFieldValue("benefit2", event.target.value)}
                          defaultValue={values.benefit2}
                          key={values.benefit2}
                        />
                      );
                    }}
                  </Field>
                  <FormHelperText error={true}>
                    <ErrorMessage name="benefit2" />
                  </FormHelperText>
                </FormControl>
                <FormControl className={classes.formFiledMrHelper} fullWidth>
                  <Typography fontSize={14} variant="subtitle2">
                    Key Benefits 3
                  </Typography>
                  <Field name="benefit3">
                    {() => {
                      return (
                        <TextareaAutosize
                          name="benefit3"
                          className={classes.textAreaStyles}
                          maxLength={1024}
                          onBlur={(
                            event: React.ChangeEvent<HTMLTextAreaElement>
                          ) => setFieldValue("benefit3", event.target.value)}
                          defaultValue={values.benefit3}
                          key={values.benefit3}
                        />
                      );
                    }}
                  </Field>
                  <FormHelperText error={true}>
                    <ErrorMessage name="benefit3" />
                  </FormHelperText>
                </FormControl>
              </div>
            </Box>
            <Divider />
            <Box className={classes.modalFooterButtonHelper}>
              <Button
                color="success"
                variant="contained"
                className={classes.buttonHelperNext}
                type="submit"
              >
                Next
              </Button>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default GenralForm;
