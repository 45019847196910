import { SortOutlined } from "@mui/icons-material";
import { Box, Button, Divider, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ClearAndApplyButtons from "../ClearAndApplyButtons";
import MenuPopup from "../MenuPopup";
import LastUpdatedOn from "./LastUpdatedOn";

export type SortTypesProps = {
  lastUpdatedOn: string;
};

export type SortProps = {
  initialSort?: Partial<SortTypesProps>;
  sort: Partial<SortTypesProps>;
  onChangeSort?: (sort: Partial<SortTypesProps>) => void;
};

const Sort: React.FC<SortProps> = (props) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);

  const [tempSort, setTempSort] = useState<Partial<SortTypesProps>>({});

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleChangeSort = (sort: Partial<SortTypesProps>) => {
    setTempSort(sort);
  };

  const handleResetSort = () => {
    props.onChangeSort?.(props.initialSort ?? {});
  };

  const handleClearSort = () => {
    handleResetSort();
    handleClose();
  };

  const handleApplySort = () => {
    props.onChangeSort?.(tempSort);
    handleClose();
  };

  const renderSortList = (sort: Partial<SortTypesProps>) => {
    const renderedSort = [];

    if (sort.lastUpdatedOn) {
      renderedSort.push(
        <LastUpdatedOn
          key="lastUpdatedOn"
          sort={tempSort}
          onChangeSort={handleChangeSort}
        />
      );
    }

    return renderedSort;
  };

  useEffect(() => {
    setTempSort(props.sort);
  }, [props.sort]);

  return (
    <>
      <Button
        sx={{
          textTransform: "unset !important" as "unset",
          alignItems: "center",
        }}
        color="inherit"
        ref={buttonRef}
        endIcon={<SortOutlined />}
        onClick={handleClick}
      >
        <Typography variant="subtitle2">Sort</Typography>
      </Button>
      <MenuPopup anchorEl={buttonRef.current} onClose={handleClose} open={open}>
        <Box sx={{ display: "flex", flexDirection: "column", minWidth: 300 }}>
          {renderSortList(props.sort)}
          <Divider />
          <ClearAndApplyButtons
            onClickClear={handleClearSort}
            onClickApply={handleApplySort}
          />
        </Box>
      </MenuPopup>
    </>
  );
};

export default Sort;
