export type TableHeaderTypes = {
    HeaderName: string,
    FieldName: string,
    isBoolean?: boolean,
    truthLabel?: string,
    falseLabel?: string,
};

export const ThreatStages: TableHeaderTypes[] = [
    {
        'HeaderName': 'Stage ID',
        'FieldName': 'stageId'
    },
    {
        'HeaderName': 'Threat ID',
        'FieldName': 'threatId'
    },
    {
        'HeaderName': 'Stage Display Order',
        'FieldName': 'stageDisplayOrder'
    },
    {
        'HeaderName': 'Threat Display Order',
        'FieldName': 'threatDisplayOrder'
    },
    {
        'HeaderName': 'Stage Name',
        'FieldName': 'stageSystemName'
    },
    {
        'HeaderName': 'Threat System Name',
        'FieldName': 'threatSystemName'
    },
]

export const ThreatDetailsStages: TableHeaderTypes[] = [
    {
        'HeaderName': 'Display Order',
        'FieldName': 'displayOrder'
    },
    {
        'HeaderName': 'Threat Image',
        'FieldName': 'imagePath'
    },
    {
        'HeaderName': 'Threat System Name',
        'FieldName': 'threatSystemName'
    },
    {
        'HeaderName': 'Threat Display Label',
        'FieldName': 'threatDisplayLabel'
    },
    {
        'HeaderName': 'FAQ',
        'FieldName': 'threatFaq'
    },


]