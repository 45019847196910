import React, { useState } from "react";
import { Modal, Paper, Box, Typography, Divider, Stack } from "@mui/material";
import RespondQueryModal from "../Respond/RespondQueryModal";
import { useStyles } from "./style";
import { useTranslation } from "react-i18next";

type RespondProps = {
  onCloseRespond: Function;
  QueryInRespond: any;
  OnReplySuccess: Function;
};

const Respond: React.FC<RespondProps> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showRespond, setShowRespond] = useState<boolean>(true);

  const handleCloseRespondPopup = () => {
    setShowRespond(false);
    props.onCloseRespond();
  };

  const headers = [
    t("str_query_ID"),
    t("str_phone_no"),
    t("str_grower_state"),
    t("str_grower_dist"),
    t("str_crop"),
    t("str_category"),
  ];

  return (
    <Modal
      open={showRespond}
      className={classes.flexStyle}
    >
      <Paper className={classes.PaperStyle}>
        <RespondQueryModal
          header={headers}
          onClose={() => {
            handleCloseRespondPopup();
          }}
          QueryInRespond={props.QueryInRespond}
          OnReplySuccess={props.OnReplySuccess}
        />
      </Paper>
    </Modal>
  );
};

export default Respond;
