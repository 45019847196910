import { useMemo } from "react";

import DownloadProgress, {
  DownloadProgressContext,
  useDownloadProgress,
  useDownloadProgressContext,
} from "./DownloadProgress";
import { Box } from "@mui/material";
import PublishProgress, {
  PublishProgressContext,
  usePublishProgress,
  usePublishProgressContext,
} from "./PublishProgress";

const ProgressProvider: React.FC = ({ children }) => {
  const { listDownloadProgress, addDownloadProgress, deleteDownloadProgress } =
    useDownloadProgress();

  const { listPublishProgress, addPublishProgress, deleteAllPublishProgress, deletePublishProgress } =
    usePublishProgress();

  const downloadProgressValue = useMemo(
    () => ({
      listDownloadProgress,
      addDownloadProgress,
      deleteDownloadProgress,
    }),
    [listDownloadProgress, addDownloadProgress, deleteDownloadProgress]
  );

  const publishProgressValue = useMemo(
    () => ({
      listPublishProgress,
      addPublishProgress,
      deletePublishProgress,
        deleteAllPublishProgress
    }),
    [listPublishProgress, addPublishProgress, deletePublishProgress, deleteAllPublishProgress]
  );

  return (
    <DownloadProgressContext.Provider value={downloadProgressValue}>
      <PublishProgressContext.Provider value={publishProgressValue}>
        {children}
        <Box
          sx={{
            display: "flex",
            position: "absolute",
            right: 0,
            bottom: 0,
            marginRight: "3%",
            marginBottom: "3%",
            gap: "1em",
          }}
        >
          <PublishProgress />
          <DownloadProgress />
        </Box>
      </PublishProgressContext.Provider>
    </DownloadProgressContext.Provider>
  );
};

export { useDownloadProgressContext, usePublishProgressContext };
export default ProgressProvider;
