import { ToastOptions, toast } from "react-toastify";

const showToast = (
  message: string,
  type: "success" | "error" | "info" | "warn" = "success"
) => {
  const toastOptions: ToastOptions = {
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    autoClose: 1000,
  };

  switch (type) {
    case "success":
      toast.success(message, toastOptions);
      break;
    case "error":
      toast.error(message, toastOptions);
      break;
    case "info":
      toast.info(message, toastOptions);
      break;
    case "warn":
      toast.warn(message, toastOptions);
      break;
    default:
      throw new Error("Invalid toast type");
  }
};

export default showToast;
