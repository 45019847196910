import { api_service } from '../../../Api/api_service';
import { IRequestBody } from '../../../Constants/interfaces';

export const getCrops = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.get(data)?.then((response) => {
    successCallBack(response.data, 'crops');
  }).catch((err) => {
    errorCallBack(err, 'crops');
  });
};

/** save Threat data */
export const SaveThreatData = (data: IRequestBody, successCallBack: any, errorCallBack: any) => {
  api_service.post(data)?.then((response) => {
    successCallBack(response.data, 'save_threat');
  }).catch((err) => {
    errorCallBack(err, 'save_threat');
  });
};


export const validateThreatPresence = (apiData: IRequestBody) => {
  return new Promise((resolve, reject) => {
    api_service.get(apiData)?.then((response) => {
      if (response.data.statusCode === 200) {
        return resolve(response.data);
      }
      throw new Error(response.data.message);
    }).catch((err) => {
      return reject(err.message);
    });
  })
}
