import { RoutePaths } from "../../Constants/routeConstants";

export type ServicesType = {
  active: boolean;
  iconPath: string;
  path: string;
  role?: string;
  serviceName: string;
};

const BASE_PATH: string = `${process.env.PUBLIC_URL}/assets/services`;
export const ASSET_PATH: string = `${process.env.PUBLIC_URL}/assets`;

export const services: Array<ServicesType> = [
  {
    serviceName: "Crops",
    role: "admin",
    iconPath: `${BASE_PATH}/Crops_Icon.svg`,
    active: true,
    path: RoutePaths.Crops,
  },
  {
    serviceName: "Threats",
    role: "admin",
    iconPath: `${BASE_PATH}/Threats_Icon.svg`,
    active: true,
    path: RoutePaths.Threats,
  },
  {
    serviceName: "CP Products",
    role: "admin",
    iconPath: `${BASE_PATH}/CP_Products_Icon.svg`,
    active: true,
    path: RoutePaths.CPProducts,
  },
  {
    serviceName: "Hybrids",
    role: "admin",
    iconPath: `${BASE_PATH}/Hybrids_Icon.svg`,
    active: true,
    path: RoutePaths.Hybrids,
  },
  {
    serviceName: "Retailers & Nurseries",
    role: "admin",
    iconPath: `${BASE_PATH}/Retailers_Icon.svg`,
    active: true,
    path: RoutePaths.RetailersAndNurseries,
  },
  {
    serviceName: "Dashboard Media",
    role: "admin",
    iconPath: `${BASE_PATH}/Dashboard_Icon.svg`,
    active: true,
    path: RoutePaths.DashboardMedia,
  },
  {
    serviceName: "Languages",
    role: "admin",
    iconPath: `${BASE_PATH}/Languages_Icon.svg`,
    active: true,
    path: RoutePaths.Languages,
  },
  {
    serviceName: "Push Notifications",
    role: "admin",
    iconPath: `${BASE_PATH}/Push_Notification_Icon.svg`,
    active: true,
    path: RoutePaths.PushNotifications,
  },
  {
    serviceName: "Users & Roles",
    role: "admin",
    iconPath: `${BASE_PATH}/Iconuser_and_roles.svg`,
    active: true,
    path: RoutePaths.UserAndRoles,
  },
  {
    serviceName: "Query Response",
    role: "admin",
    iconPath: `${BASE_PATH}/query_and_response.svg`,
    active: true,
    path: RoutePaths.QueryResponse,
  },
  {
    serviceName: "Plantix CP Products",
    role: "admin",
    iconPath: `${BASE_PATH}/Plantix_CP_Products_Icon.svg`,
    active: true,
    path: RoutePaths.PlantixCPProducts,
  },
  {
    serviceName: "Plantix Map Product",
    role: "admin",
    iconPath: `${BASE_PATH}/Plantix_Map_Product_Icon.svg`,
    active: true,
    path: RoutePaths.PlantixMapProduct,
  },
];
