import { Box, Button, Stack } from "@mui/material";

type ClearAndApplyButtonsProps = {
  onClickClear: () => void;
  onClickApply: () => void;
};

const ClearAndApplyButtons: React.FC<ClearAndApplyButtonsProps> = (props) => {
  return (
    <Box p={2} pb={0}>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          variant="outlined"
          color="success"
          fullWidth
          sx={{ borderColor: "#14803C" }}
          onClick={props.onClickClear}
        >
          Clear
        </Button>
        <Button
          onClick={props.onClickApply}
          variant="contained"
          color="success"
          fullWidth
        >
          Apply
        </Button>
      </Stack>
    </Box>
  );
};

export default ClearAndApplyButtons;
