import { makeStyles } from '@mui/styles';

export type StylesProp = {
  color: string;
  width: string;
};

export const useStyles = makeStyles({
  root: (props: StylesProp) => ({
    textAlign: 'center',
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    width: props?.width,
    '& .MuiTypography-root': {
      margin: '32px auto'
    },
    '& .MuiTypography-subtitle1': {
      color: props?.color
    }
  })
});
