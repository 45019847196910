import { Box, Button, Modal, Paper, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import api_endpoints, { base_url } from "../../../Api/end_points";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { RequestMethods } from "../../../Constants/enums";
import { IRequestBody } from "../../../Constants/interfaces";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { putPublish } from "../PublishApiCalls/PublishApiCalls";
import { useStyles } from "../style";
import { PublishModuleDD } from "../../../Common/Constants";
import { viewDashboardMedia } from "../../DashboardMedia/DashboardMediaApiCalls";
import { NewDashboardValidationOnResponse } from "../../DashboardMedia/AddEditDashboardModal/ValidationSchemaDashboard";
import { usePublishProgressContext } from "../../../Common/ProgressProvider";
import { createColumn } from "../../../Utils/genericUtils";

type PublishProps = {
  publishDetails: any;
  onClosePublish: Function;
  isSuccessFail: Function;
  module?: string;
};

type NotificationDataTypes = {
  fieldName: string;
  fieldLabel: string;
};

export const notificationData: Array<NotificationDataTypes> = [
  createColumn("Notification Type", "type"),
  createColumn("Notification Image", "notificationImage"),
  createColumn("State", "state"),
  createColumn("District", "districts"),
  createColumn("Frequency (Days)", "frequency"),
  createColumn("Scheduled Date", "startDate"),
  createColumn("Notification Title", "notificationTitle"),
  createColumn("Message", "message"),
  createColumn("Hyperlink", "hyperlink"),
  createColumn("Status", "status"),
];


const PublishView: React.FC<PublishProps> = (props) => {
  const { addPublishProgress } = usePublishProgressContext();
  // states
  const [showPublish, setShowPublish] = useState<boolean>(true);
  // variables
  const { publishDetails } = props;

  const handleClose = () => {
    setShowPublish(false);
    props.onClosePublish();
  };

  const successCallBack = (response: any, method: RequestMethods) => {
    addPublishProgress({
      module: props.module!,
      publishName: publishDetails.title,
      status: "Published",
    });
  };

  const errorCallback = (err: Error) => {
    addPublishProgress({
      module: props.module!,
      publishName: publishDetails.title,
      status: "Error",
      message: err.message,
    });
  };

  const validateResponse = (response: any) => {
    return NewDashboardValidationOnResponse().validate(response.data.payload, {
      strict: true,
      abortEarly: false,
    });
  };

  const handleValidationError = (err: any) => {
    let errorMsg = "";
    if (err.inner.length > 0) {
      errorMsg = err.inner
        .map((e: { path: any }) => {
          return e.path;
        })
        .join(", ");
      errorMsg += " is required, please fill in first!";
    }
    TriggerToastMessage(errorMsg, "error");
  };

  const fetchDraftMediaContent = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.get_draft_media_content.replace(
      "__MEDIA_ID__",
      publishDetails.id
    );
    apiData.showLoader = false;
    return viewDashboardMedia(
      apiData,
      (response: any, method: RequestMethods) => {
        validateResponse(response)
          .then(() => {
            putPublishableAPI();
          })
          .catch(handleValidationError);
      },
      errorCallback
    );
  };

  const putPublishable = (): void => {
    if (props.module === PublishModuleDD.DASHBOARD_MEDIA) {
      fetchDraftMediaContent();
    } else {
      putPublishableAPI();
    }
  };

  const putPublishableAPI = (): void => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.publish_selected_data.replace(
      "__DATATYPE__",
      props.module! === "Plantix Map Product"
        ? "Plantix Peatid Mapping"
        : props.module!
    );
    apiData.showLoader = false;
    apiData.endPoint = `${apiData.endPoint}?dataId=${publishDetails.id}`;

    addPublishProgress({
      module: props.module!,
      publishName: publishDetails.title,
      status: "Publishing",
    });
    putPublish(apiData, successCallBack, errorCallback);
  };

  const classes = useStyles();
  return (
    <Modal
      open={showPublish}
    >
      <Paper className={classes.formPaper}>
        <ModalHeader
          header={`Publish Data`}
          onClose={handleClose}
        />
        <Box className={classes.publishModalHelper}>
          <Stack spacing={4} alignItems="center">
            <Typography align="center" variant="body2">
              Are you sure you want to publish the data? <br />
              The data will be published and updated to the mobile application
            </Typography>
            <Stack
              spacing={2}
              className={classes.publishModalHelperTwo}
              display={"block"}
            >
              <Button
                fullWidth
                variant="contained"
                color="success"
                onClick={() => {
                  putPublishable();
                  handleClose();
                }}
              >
                Yes
              </Button>
              <Button
                fullWidth
                variant="outlined"
                color="info"
                onClick={handleClose}
              >
                cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Paper>
    </Modal>
  );
};

export default PublishView;
