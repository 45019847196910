const ICON_BASE_PATH: string = process.env.PUBLIC_URL;

const PORT: string = "8080"; // '3001' // '8080';

export const BASE_URL: string = `http://localhost:${PORT}`;
export const ASSETS_PATH: string = `${ICON_BASE_PATH}/assets/`;
export const S3_BUCKET_URL: string =
  "https://cropwise-small-holder-assets.s3.eu-central-1.amazonaws.com/admin-portal";

const path: string = "/api";
const version: string = "/v1";

export const PushNotificationUrls = {
  PUSH_NOTIFICATIONS: `${BASE_URL}${path}${version}/push-notifications`,
  LANGUAGES: `${BASE_URL}${path}${version}/languages`,
  STATES: `${BASE_URL}${path}${version}/states`,
};

export const MediaType = [
  {
    label: "Image",
    key: "IMAGE",
  },
  {
    label: "Video",
    key: "VIDEO",
  },
];

export const Category = [
  {
    label: "All",
    key: "ALL",
  },
  { label: "Image", key: "IMAGE" },
  {
    label: "Video",
    key: "VIDEO",
  },
];

export const RetailerType = [
  { label: "Retailer" },
  { label: "Nursery" },
  { label: "Franchise" },
];
export const RetailerCategory = [
  { label: "Crop Protection", value: "CROP PROTECTION" },
  { label: "Field Crops", value: "FIELD CROPS" },
  { label: "Vegetable Seeds", value: "VEG SEEDS" },
];
