import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  scheduledChipHelper: {
    backgroundColor: '#C6E6FF',
    color: '#005693',
    textAlign: 'center',
    borderRadius: '5px',
    textTransform: 'capitalize'
  },
  sentChipHelper: {
    backgroundColor: '#DFFBE8',
    color: '#14803C',
    borderRadius: '5px',
    textTransform: 'capitalize',
    display: 'flex',
    justifyContent: 'center'
  }
});
