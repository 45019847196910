import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from '../Components/Login';
import { RoutePaths } from '../Constants/routeConstants';
type unAuthRouteProps = {
    onSignInSuccess: Function
};
const UnAuthRoutes: React.FC<unAuthRouteProps> = ({ onSignInSuccess}: unAuthRouteProps) => {
    return (
        <>
            <Routes>
                <Route
                    path={RoutePaths.Login}
                    element={
                        <Login
                            onSignInSuccess={() => { onSignInSuccess(); }}
                        />
                    }
                />
                <Route
                    path={RoutePaths.PageNotFound}
                    element={
                        <Suspense fallback={null}>
                            <Login
                            onSignInSuccess={() => { onSignInSuccess(); }}
                        />
                        </Suspense>
                    }
                />
                {/* <Route path={RoutePaths.Notfound} element={<Notfound />} /> */}
                {/* <Route path={RoutePaths.Logout} element={<Logout />} /> */}
            </Routes>
        </>
    );
};

export default UnAuthRoutes;
