import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {Button, Link} from "@mui/material";
import * as React from "react";
import {useStyles} from "./style";

interface ITemplateProps {
    displayAsButton: boolean;
    literal: string;
}

export const DownLoadSampleTemplate: React.FC<ITemplateProps> = (
    props: ITemplateProps
) => {
    const classes = useStyles();


    const downloadSampleTemplate = () => {
        const LINK_TO_OPEN =
            "https://syngenta-my.sharepoint.com/:f:/p/ankit_bhatnagar/EqT32zV-8tpHukevAX4rMCUBPtMWiOSgXM8vqFsuoerabg?e=dvz06b";
        window.open(LINK_TO_OPEN, "_blank", "noopener")?.focus();
    };
    const renderButton = () => {
        return (
            <>
                <Button
                    className={classes.bulkUpdloadDownloadBtn}
                    startIcon={<FileDownloadOutlinedIcon/>}
                    variant="outlined"
                    onClick={() => downloadSampleTemplate()}
                >
                    Download
                </Button>
            </>
        );
    };

    const renderLink = () => {
        return (
            <Link
                onClick={() => downloadSampleTemplate()}
                href="#"
                variant="subtitle2"
                underline="always"
                className={classes.myUploadTabDownloadHelper}
            >
                <FileDownloadOutlinedIcon/>
                Download sample template
            </Link>
        );
    };

    return <>{props.displayAsButton ? renderButton() : renderLink()}</>;
};
