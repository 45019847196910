import React, { memo, PropsWithChildren } from "react";
import { Box, Icon, Paper, Typography } from "@mui/material";
import { ASSETS_PATH } from "../../Constants/contants";
import { useStyles } from "./useStyle";

const Greetings: React.FC<PropsWithChildren<any>> = ({ children }) => {
    const classes = useStyles();

    return (
        <div className={classes.contentHelper}>
            <div>
                <Box className={classes.loginContainerHelper}>
                    <div>
                        <img
                            style={{ height: "120px" }}
                            src={`${ASSETS_PATH}Web_Portal_Logo.svg`}
                            alt="Smartgrower admin web portal"
                        />
                    </div>
                </Box>
            </div>
            <Paper className={classes.paperHelper}>
                <div className={classes.innerContentHelper}>
                    <Icon className={classes.iconHelper}>
                        <img src={`${ASSETS_PATH}Plant_Icon.svg`} alt="Plant" />
                    </Icon>
                    <Typography variant="subtitle1">Hello There,</Typography>
                    {children}
                </div>
            </Paper>
        </div>
    )
}

export default memo(Greetings);