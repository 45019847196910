import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  cropContainerHelper: {
    width: '100%'
  },
  tabContainerHelper: {
    paddingLeft: 16
  },
  tabHelper: {
    textTransform: 'none !important' as 'none'
  },
  panelBoxHelper: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '-webkit-fill-available'
  },
  titleAndSearchHelper: {
    margin: '24px 24px auto',
    display: 'inline-flex',
    width: '-webkit-fill-available'
  },
  bulkUploadAttachCloseBtnsHelper: {
    color: '#696F88',
    marginRight: '0.5rem',
    '&.MuiSvgIcon-root': {
      fontSize: '1.2rem'
    }
  },
  chipBoxHelper: {
    margin: '8px 0',
    display: 'inline-flex',
    width: '-webkit-fill-available',
    padding: '0 1.5rem'
  },
  bulkDataBorderBottom: {
    borderBottom: '1px solid #CFD3DB',
    paddingBottom: '1.5rem',
    marginBottom: '1.5rem'
  },
  OddTableRow: {
    padding: '0.3rem 1rem',
    border: '1px solid #CFD3DB',
    '&:nth-child(odd)': {
      backgroundColor: '#F3F4F6'
    }
  },
  tableFirstChild: {
    borderRight: '1px solid #CFD3DB',
    paddingRight: '0.5rem'
  },
  viewImage: {
    background: '#f2f2f2',
    marginBottom: '1rem !important',
    width: '120px',
    height: '120px',
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  uploadBtn: {
    border: '1px solid #CFD3DB',
    padding: '0.3rem',
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: '4px'
  },
  formPaper: {
    margin: '30px auto',
    width: '800px',
    minHeight: '500px',
    borderRadius: '6px !important',
    '& .MuiStepper-root': {
      paddingBottom: '1rem'
    }
  },
  formContainer: {
    height: '370px',
    overflowY: 'auto',
    position: 'relative',
    scrollbarWidth: 'auto',
    '&::-webkit-scrollbar': {
      width: '4px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#CFD3DB',
      'border-radius': '15px',

    },
    '&::-webkit-scrollbar-track': {
      background: '#ffffff',
    },
  },
  formFieldsHelperOne: {
    maxWidth: '380px',
    margin: 'auto',
    position: 'relative',
    marginBottom: '1rem'
  },
  formFieldsHelper: {
    margin: 'auto',
    maxWidth: '430px'
  },
  formFieldsHelperUnique: {
    margin: 'auto',
    maxWidth: '95%'
  },
  modalStylesHelper:
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  languagErrorMsgHepler: {
    position: 'absolute',
    left: '105px'
  },
  stepperHeplerStyles: {
    paddingBottom: '1rem'
  },
  formGlobalContainerHelper: {
    marginTop: '1rem'
  },
  viewCropFormContentHelper: {
    overflow: 'hidden auto',
    height: '420px'

  },
  rmPicture: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  rpPicture: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  bulkUpdloadDownloadBtn: {
    '&.MuiButton-root': {
      fontWeight: '600',
      color: '#14151C'
    }
  },
  customTabBtmBorder:{
    borderBottom: '1px solid #CFD3DB', padding: '0 1.5rem'
  },
  pageTitleNSearchStylesHelper: {
    padding: '0 1.5rem', marginTop: '1.5rem'
  },
  myUploadTabDownloadHelper: {
    display: 'flex',
    alignItems: 'center',
    color: '#14803C !important',
    textDecorationColor: '#14803C !important',
  },
  rowsPerPageStylesHelper: {
    margin: '5px 0', padding: '0 1.5rem'
  },
});
