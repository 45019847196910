// utils.ts
import React from "react";

export const handleStateChangeUtility = (
    event: React.ChangeEvent<HTMLInputElement>,
    stateList: any[],
    setStateList: React.Dispatch<React.SetStateAction<any[]>>,
    setSelectedStates: React.Dispatch<React.SetStateAction<any | null>>,
    SELECT_ALL: string
  ) => {
    const { checked, value } = event.target;

    if (value === SELECT_ALL) {
      handleAllSatesSelection(checked, stateList, setStateList, setSelectedStates, SELECT_ALL);
    } else {
      const updatedStateList = stateList.map((state) =>
        state.name === value ? { ...state, checked } : state
      );

      updatedStateList[0].checked = updatedStateList.every((state) => state.checked) && updatedStateList.length > 1;

      const filteredStates = updatedStateList.filter((state) => state.checked && state.name !== SELECT_ALL);

      setStateList(updatedStateList);
      setSelectedStates(filteredStates.length ? filteredStates : null);

      return filteredStates.length ? filteredStates : null;
    }
  };

  const handleAllSatesSelection = (
    checked: boolean,
    stateList: any[],
    setStateList: React.Dispatch<React.SetStateAction<any[]>>,
    setSelectedStates: React.Dispatch<React.SetStateAction<any | null>>,
    SELECT_ALL: string
  ) => {
    const updatedStateList = stateList.map((state) => ({
      ...state,
      checked,
    }));

    const isAllSelected = updatedStateList.every((state) => state.checked) && updatedStateList.length > 1;
    updatedStateList[0].checked = isAllSelected;

    const filteredStates = updatedStateList.filter((state) => state.checked && state.name !== SELECT_ALL);

    setStateList(updatedStateList);
    setSelectedStates(filteredStates.length ? filteredStates : null);

    return filteredStates.length ? filteredStates : null;
  };
