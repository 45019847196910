import {
  Box,
  Checkbox,
  Divider,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import {
  fetchTimezoneOffset,
  getNotificationByID,
} from "../AddEditNotification/PushNotificationAPICalls";
import { useStyles } from "../style";
import api_endpoints, { base_url } from "./../../../Api/end_points";
import { IRequestBody } from "../../../Constants/interfaces";
import { createColumn } from "../../../Utils/genericUtils";
// type NotificationDetails = {
//   id: string;
//   type: string;
//   frequency: number;
//   startDate: string;
//   status: string;
// };

type ViewNotificationProps = {
  notificationDetails: any;
  onCloseViewNotification: Function;
};

type NotificationPreviewTypes = {
  fieldLabel: string;
  fieldName: string;
};

export const notificationPreview: Array<NotificationPreviewTypes> = [
  createColumn("Notification Type", "notificationType"),
  createColumn("Notification Image", "imageUrl"),
  createColumn("Crops", "cropNames"),
  createColumn("State", "stateNames"),
  createColumn("District", "districtNames"),
  createColumn("Frequency", "frequency"),
  createColumn("Scheduled Date", "fromDate"),
  createColumn("Day Of Week", "dayOfWeek"),
  createColumn("Day Of Month", "dayOfMonth"),
  createColumn("Time", "notificationTime"),
  createColumn("Notification Title", "notificationTitle"),
  createColumn("Message", "message"),
  createColumn("Hyperlink", "hyperlink"),
  createColumn("Status", "statusId"),
  createColumn("Total Cost", "smsCost"),
];

interface IRenderFieldProps extends NotificationPreviewTypes {
  data: any;
  timezoneOffset: string;
}

const getLabel = (label: string) => {
  return (
    <Typography variant="subtitle2" fontSize={14} marginY="1rem">
      {label}
    </Typography>
  );
};

const RenderField = (props: IRenderFieldProps) => {
  const { fieldName, fieldLabel, data } = props;
  const classes = useStyles();

  switch (fieldName) {
    case "notificationType":
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography className={classes.testWrapControl} fontSize={14}>
            {data && data[fieldName]?.notificationTypeName}
          </Typography>
          <Divider />
        </>
      );
    case "cropNames":
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography fontSize={14} className={classes.testWrapControl}>
            {data &&
              ((data["cropIds"] as number[])?.includes(0)
                ? data[fieldName].replace("undefined", "Growers Without Crops")
                : data[fieldName])}
          </Typography>
          <Divider />
        </>
      );
    case "stateNames":
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography fontSize={14} className={classes.testWrapControl}>
            {data && data[fieldName]}
          </Typography>
          <Divider />
        </>
      );
    case "districtNames":
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography fontSize={14} className={classes.testWrapControl}>
            {data && data[fieldName]}
          </Typography>
          <Divider />
        </>
      );
    case "imageUrl":
      return (
        <>
          <Stack direction="row" spacing={10}>
            {getLabel(fieldLabel)}
            <div className={classes.viewImage}>
              {data && data.imageUrl && (
                <img src={data.imageUrl} alt={"push img"} />
              )}
            </div>
          </Stack>
          <Divider />
        </>
      );
    case "fromDate":
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography fontSize={14} className={classes.testWrapControl}>
            From: {data && data[fieldName]} <br />
            {/* From: {data && moment(data[fieldName], 'DD/MM/YYYY').add(moment().utcOffset(), 'm').format('DD/MM/YYYY')} <br /> */}
            {
              data && data.frequency !== "Once" && <> To: {data.toDate} </>
              // <> To: {moment(data.toDate,'DD/MM/YYYY').add(moment().utcOffset(), 'm').format('DD/MM/YYYY')} </>
            }
          </Typography>
          <Divider className="" />
        </>
      );
    case "notificationTime":
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography fontSize={14} className={classes.testWrapControl}>
            {data &&
              moment(data[fieldName], "HH:mm:ss")
                .add(props.timezoneOffset, "minutes")
                .format("hh:mm A")}
          </Typography>
          <Divider />
        </>
      );
    case "dayOfWeek":
      return (
        <>
          {data && data.frequency === "Weekly" && data.dayOfWeek && (
            <>
              {getLabel(fieldLabel)}
              <Typography fontSize={14} className={classes.testWrapControl}>
                {data && data[fieldName]}
              </Typography>
              <Divider />
            </>
          )}
        </>
      );
    case "dayOfMonth":
      return (
        <>
          {data && data.frequency === "Monthly" && data.dayOfMonth && (
            <>
              {getLabel(fieldLabel)}
              <Typography fontSize={14} className={classes.testWrapControl}>
                {data && data[fieldName]}
              </Typography>
              <Divider />
            </>
          )}
        </>
      );
    case "statusId":
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography fontSize={14} className={classes.testWrapControl}>
            {data !== undefined &&
            data.statusId &&
            data &&
            data[fieldName] === 1
              ? "Created"
              : data && data[fieldName] === 2
              ? "Published"
              : data && data[fieldName] === 3
              ? "Cancelled"
              : "--"}
          </Typography>
          {data && data.smsCost && <Divider />}
        </>
      );
    case "smsCost":
      return (
        <>
          {data && data.smsCost && (
            <>
              {getLabel(fieldLabel)}
              <Typography fontSize={14} className={classes.testWrapControl}>
                {data && data[fieldName]}
              </Typography>
            </>
          )}
        </>
      );
    default:
      return (
        <>
          {getLabel(fieldLabel)}
          <Typography
            fontSize={14}
            variant="body1"
            className={classes.testWrapControl}
          >
            {data && data[fieldName]}
          </Typography>
          <Divider />
        </>
      );
  }
};

const ViewNotification: React.FC<ViewNotificationProps> = (props) => {
  const classes = useStyles();
  // states
  const [showViewNotification, setShowViewNotification] =
    useState<boolean>(true);
  const [dataToDisplayState, setDataToDisplayState] = useState<any>();
  // variables
  const { notificationDetails } = props;
  const [timezoneOffset, setTimezone] = useState("+000");

  const handleClose = () => {
    setShowViewNotification(false);
    props.onCloseViewNotification();
  };
  const successCallBack = (response: any, type: string) => {
    if (response.statusCode !== 200) {
      return;
    }
    if (type === "timezone_offset") {
      setTimezone(response.data);
    } else if (type === "data_by_id") {
      setDataToDisplayState(response?.data);
    } else {
      return;
    }
  };
  const errorCallback = (error: any, type: string) => {
    /**Generic alert to display API fail */
    TriggerToastMessage("Unable to process your request", "error");
  };
  /** Get push notification API call */
  const getNotificationById = () => {
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = `${api_endpoints.create_edit_notification}/${notificationDetails.id}`; //notificationDetails.id
    apiData.showLoader = true;
    return getNotificationByID(apiData, successCallBack, errorCallback);
  };

  /**
   * fetchtimezone
   */
  const getTimeZoneOffset = () => {
    /** prepare selected filter payload */
    fetchTimezoneOffset(successCallBack, errorCallback);
  };
  useEffect(() => {
    //console.log('DataToDisplay useEffect:', dataToDisplayState);
  }, [dataToDisplayState]);

  useEffect(() => {
    getTimeZoneOffset();
  }, []);
  /** API to get all the Push notification details */
  useEffect(() => {
    getNotificationById();
  }, []);
  return (
    <Modal
      open={showViewNotification}
      className={classes.formModal}
    >
      <Paper className={classes.formPaper}>
        <ModalHeader
          header={`Push Notification ID: ${notificationDetails.id}`}
          onClose={() => {
            handleClose();
          }}
        />
        <Box className={classes.formContainer}>
          <Box className={classes.formFieldsHelper}>
            <RenderField
              fieldName={"mobileNo"}
              fieldLabel={"Mobile Number with Country Code"}
              data={dataToDisplayState}
              timezoneOffset={timezoneOffset}
            />
          </Box>
          <Box className={classes.formFieldsHelperOne}>
            <LanguageDropdown
              selectedLanguage={dataToDisplayState?.languageName}
              isDisabled={true}
              key={dataToDisplayState?.languageName}
            />
          </Box>
          {/* Checkbox */}
          <Box className={classes.formFieldsHelper}>
            <Stack
              direction="row"
              justifyContent="space-between"
              marginTop="1rem"
              className={classes.formSendSMSHelper}
            >
              <Typography variant="subtitle2">
                Send push notification as SMS?
              </Typography>
              <Checkbox
                checked={dataToDisplayState?.sendSms}
                size="small"
                name="sendAsSMS"
                key={dataToDisplayState?.sendSms}
              />
            </Stack>
            <Box>
              {notificationPreview.map(
                (notification: NotificationPreviewTypes) => {
                  return (
                    <>
                      <RenderField
                        fieldName={notification.fieldName}
                        fieldLabel={notification.fieldLabel}
                        data={dataToDisplayState}
                        timezoneOffset={timezoneOffset}
                      />
                    </>
                  );
                }
              )}
            </Box>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default ViewNotification;
