import AddIcon from "@mui/icons-material/Add";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import {Box, Button, Divider, FormControl, FormHelperText, Stack, TextField, Typography,} from "@mui/material";
import {styled} from "@mui/material/styles";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {ChangeEvent, useEffect, useState} from "react";
import {CropGrowthStageKeys, initialCropGrowthStage} from ".";
import api_endpoints, {base_url} from "../../../Api/end_points";
import ButtonWithIcon, {CANCEL_TYPE, CREATE_TYPE,} from "../../../Common/Buttons";
import {S3ImageUploadType} from "../../../Common/Constants/types";
import SortableComponent from "../../../Common/SortableRecords";
import {IRequestBody} from "../../../Constants/interfaces";
import {checkForDuplicates, clearImageObject,} from "../../../Utils/genericUtils";
import {TriggerToastMessage} from "../../../Utils/toastTrigger";
import {CropGrowthValidationSchema} from "../CropValidationSchema";
import {useStyles} from "../useStyle";
import {GetFileS3BucketURL} from "../../../Api/generic_apicalls";
import {ActionType, ModuleImageUpload, STEPS, ValidationType,} from "../../../Common/Constants";
import {genericFormFieldValidation, genericValidateImage,} from "../../../Utils/fileHelper";

type CropGrowthFormPropTypes = {
  ListOfStages: Array<CropGrowthStageKeys>;
  selectedStage: CropGrowthStageKeys;
  onSelect: Function;
  onSave: Function;
  onRemove: Function;
  onNext: Function;
  onPrevious: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  cropSystemName: string;
  updateListOrder: Function;
  isEdit: boolean;
  getLatestImgURL: Function;
  isPrimaryCrop: boolean;
  actionType: string;
  isSystemNameEditable: boolean;
  canAddNewRecord: boolean;
  canUpdateExistingRecord: boolean;
};
const Input = styled("input")({
  display: "none",
});
export const CropGrowthStagesForm = (props: CropGrowthFormPropTypes) => {
  const classes = useStyles();
  /** state variables */
  const [isRuleErrorFlag, setIsRuleErrorFlag] = useState<boolean>(false);

  const renderAddedCropStates = () => {
    if (props.ListOfStages.length === 0) {
      return (
        <>
          <Stack flex={1}>
            <Box>
              <Typography variant="subtitle2">Added Stage(s):</Typography>
              <Typography variant="caption" component="span">
                You may reorder the stages by dragging each panel up or down
              </Typography>
              <FormHelperText error={true}>
                {isRuleErrorFlag && (
                  <>{"Please add one crop growth stage to continue"}</>
                )}
              </FormHelperText>
            </Box>
          </Stack>
        </>
      );
    }
    return (
      <>
        <Stack flex={1}>
          <Box>
            <Typography variant="subtitle2">Added Stage(s):</Typography>
            <Typography variant="caption" component="span">
              You may reorder the stages by dragging each panel up or down
            </Typography>
            <FormHelperText error={true}></FormHelperText>
            <Box my={2}></Box>
            <SortableComponent
              name={"Crop stage"}
              items={props.ListOfStages}
              updateCropStageOrder={(newList: Array<CropGrowthStageKeys>) => {
                props.updateListOrder(newList);
              }}
              key={props.ListOfStages.length}
              listProps={props}
              displayKey={"systemName"}
              initialState={initialCropGrowthStage}
              selectedRecord={props.selectedStage}
              isDisabled={
                !props.isPrimaryCrop && props.actionType !== ActionType.CREATE
              }
            />
          </Box>
        </Stack>
      </>
    );
  };

  const nextStepHandler = (step: number) => {
    props.onNext(step);
  };
  /** Filed level validation for Image file upload */
  const validateImage = (value: any, name: any) => {
    // let errorResult = genericValidateImage(value);
    // return errorResult;
    /** If, it is add trans, than ignore Image Validation */
    if (props.actionType !== ActionType.ADD_TRANSLATION) {
      return genericValidateImage(value);
    }
    return "";
  };

  const getFileUploadPayload = (file: any, data: S3ImageUploadType) => {
    let fileNameArr: any = file.name.split(".");
    let s3Data: any = {
      cropSystemName: props.cropSystemName, //cropSystemName should be common for all images related to same crop
      type: data.type,
      fileNameWithExtension: `${data.name?.replaceAll(" ", "")}.${
        fileNameArr[fileNameArr.length - 1]
      }`,
    };
    return s3Data;
  };
  const getFileS3URL_Save = (values: any) => {
    /** fetching image S3 url from api and saving the stage afte rsuccess */
    const apiData = {} as IRequestBody;
    apiData.domain = base_url;
    apiData.endPoint = api_endpoints.fileupload_S3bucket;
    apiData.showLoader = true;
    if (
      values.imagePath &&
      typeof values.imagePath !== "string" &&
      props.cropSystemName
    ) {
      /** generating crop stage growth related payload */
      let imgData: any = {
        name: values.systemName,
        type: ModuleImageUpload.CROP_STAGE,
      };
      let payload = getFileUploadPayload(values.imagePath, imgData);
      let formData: any = new FormData();
      formData.append("file", values.imagePath);
      formData.append("data", JSON.stringify(payload));
      apiData.payLoad = formData;
      return GetFileS3BucketURL(
        apiData,
        (response: any) => {
          /** SUCCESS CALLBACK */
          let imgUrl: string = response.data.imageUrl;
          /** storing latest img file urls do delete if not saved */
          props.getLatestImgURL(imgUrl);
          values.imagePath = imgUrl;
          props.onSave(values);
        },
        (_error: any, type: string) => {
          /** ERROR CALLBACK */
          TriggerToastMessage(
            "Unable to process your request!!! - " + type,
            "error"
          );
        },
        ModuleImageUpload.CROP_STAGE
      );
    } else if (
      props.cropSystemName === "" ||
      props.cropSystemName === undefined
    ) {
      TriggerToastMessage("Crop system name not available", "info");
    } else {
      /** if no change in image, direct save the record */
      props.onSave(values);
    }
  };
  /** Getting latest unique key to see latest
   * selected record for edit
   */
  useEffect(() => {
    props.updateUniqueKey();
  }, [props.selectedStage.systemName]);
  return (
    <>
      <Box>
        <Box className={classes.formContainer}>
          <div className={classes.formContainerChildDivHelper}>
            <Stack direction="row" spacing={2}>
              <Formik
                validationSchema={CropGrowthValidationSchema}
                enableReinitialize={true}
                validateOnBlur={false}
                initialValues={{
                  title: props.selectedStage.title,
                  systemName: props.selectedStage.systemName,
                  description: props.selectedStage.description,
                  imagePath: props.selectedStage.imagePath,
                }}
                onSubmit={(
                  values: CropGrowthStageKeys,
                  _onSubmitProps: any
                ) => {
                  if (
                    checkForDuplicates(
                      props.ListOfStages,
                      { systemName: values!.systemName },
                      values!.systemName,
                      props.selectedStage.systemName
                    )
                  ) {
                    _onSubmitProps.setFieldError(
                      "systemName",
                      "Duplicate system name"
                    );
                    _onSubmitProps.setSubmitting(false);
                    return;
                  } else {
                    _onSubmitProps.setSubmitting(false);
                    getFileS3URL_Save(values);
                    clearImageObject("upload-img");
                    _onSubmitProps.resetForm();
                    props.updateUniqueKey();
                    setIsRuleErrorFlag(false);
                  }
                }}
              >
                {({ values, setFieldTouched }) => (
                  <Stack
                    flex={1}
                    justifyContent="flex-end"
                    direction="row"
                    gap={2}
                  >
                    <Form>
                      <Stack spacing={2} minWidth={350}>
                        <Box>
                          <Field name="title" id="title">
                            {(_props: any) => {
                              const { form } = _props;
                              const { setFieldValue } = form;
                              return (
                                <FormControl
                                  className={classes.formControlMrtHelper}
                                  fullWidth
                                >
                                  <Typography variant="subtitle2">
                                    Stage Title *
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    name={'title'}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setFieldValue(
                                        "title",
                                        event.target.value
                                      );
                                    }}
                                    defaultValue={values?.title}
                                    key={props.uniqueKey}
                                    disabled={false}
                                  />
                                </FormControl>
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="title" />
                          </FormHelperText>
                          <Field
                            name="systemName"
                            id="systemName"
                            validate={(value: string) =>
                              genericFormFieldValidation(
                                value,
                                ValidationType.SYS_NM,
                                Boolean(props.selectedStage.systemName)
                              )
                            }
                          >
                            {(_props: any) => {
                              const { form } = _props;
                              const { setFieldValue } = form;
                              return (
                                <FormControl
                                  className={classes.formControlMrtHelper}
                                  fullWidth
                                >
                                  <Typography variant="subtitle2">
                                    Stage System Name *
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    name={'systemName'}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setFieldValue(
                                        "systemName",
                                        event.target.value
                                          .trim()
                                          .toLocaleUpperCase()
                                      );
                                      setFieldTouched("systemName");
                                    }}
                                    defaultValue={values?.systemName}
                                    value={values?.systemName}
                                    key={props.uniqueKey}
                                    disabled={
                                      props.canAddNewRecord
                                        ? !!props.selectedStage.systemName
                                        : true
                                    }
                                  />
                                </FormControl>
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="systemName" />
                          </FormHelperText>
                          <Field name="description" id="description">
                            {(_props: any) => {
                              const { form } = _props;
                              const { setFieldValue } = form;
                              return (
                                <FormControl
                                  className={classes.formControlMrtHelper}
                                  fullWidth
                                >
                                  <Typography variant="subtitle2">
                                    Description *
                                  </Typography>
                                  <textarea
                                    rows={4}
                                    name={'description'}
                                    className={
                                      classes.textAreaCustomStylesHelper
                                    }
                                    onBlur={(
                                      event: React.ChangeEvent<HTMLTextAreaElement>
                                    ) => {
                                      setFieldTouched("description");
                                      setFieldValue(
                                        "description",
                                        event.target.value
                                      );
                                    }}
                                    defaultValue={values?.description}
                                    key={props.uniqueKey}
                                  ></textarea>
                                </FormControl>
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="description" />
                          </FormHelperText>
                          {/* Image upload start */}
                          <Field
                            name="imagePath"
                            validate={(value: any) =>
                              validateImage(value, values.systemName)
                            }
                          >
                            {(imageProps: any) => {
                              const { form } = imageProps;
                              const { setFieldValue } = form;
                              return (
                                <Stack>
                                  <Typography
                                    variant="subtitle2"
                                    fontSize={14}
                                    component="span"
                                    width="100px"
                                  >
                                    Stage Image *
                                  </Typography>
                                  <Stack
                                    direction="row"
                                    justifyContent={
                                      props.canAddNewRecord
                                        ? "space-between"
                                        : ""
                                    }
                                    spacing={props.canAddNewRecord ? 1 : 6}
                                    alignItems="flex-start"
                                    marginTop={3}
                                  >
                                    <Stack>
                                      <label
                                        htmlFor="contained-button-file"
                                        id="upload-img"
                                      >
                                        <Input
                                          accept=".jpg, .png, .jpeg"
                                          id="contained-button-file"
                                          type="file"
                                          onChange={(
                                            event: ChangeEvent<HTMLInputElement>
                                          ) => {
                                            let imgFile: any =
                                              event.target.files &&
                                              event.target.files[0];
                                            setFieldValue("imagePath", imgFile);
                                          }}
                                          disabled={!props.canAddNewRecord}
                                        />
                                        {/* Displaying image either by Raw data or by using URL */}
                                        {values.imagePath && (
                                          <img
                                              alt="Crop Stage"
                                            src={
                                              typeof values.imagePath !==
                                              "string"
                                                ? `${URL.createObjectURL(
                                                    values.imagePath
                                                  )}`
                                                : `${values.imagePath}`
                                            }
                                            width="128px"
                                            height="128px"
                                          />
                                        )}
                                        {!values.imagePath && (
                                          <Button
                                            variant="contained"
                                            size="small"
                                            component="span"
                                            disableRipple
                                            disableElevation
                                            disableTouchRipple
                                            className={
                                              classes.uploadAnImageBtnHelper
                                            }
                                          >
                                            <AddIcon
                                              className={
                                                classes.uploadImgBtnIconColorHelper
                                              }
                                            />{" "}
                                            Upload an image
                                          </Button>
                                        )}
                                      </label>
                                    </Stack>
                                    {props.canAddNewRecord && (
                                      <Stack spacing={2}>
                                        <FormHelperText
                                          className={
                                            classes.formHelperTextFontSizeHelper
                                          }
                                        >
                                          JPG, JPEG or PNG. Max size of 5 MB
                                        </FormHelperText>
                                        <Stack
                                          direction="row"
                                          alignItems="center"
                                          justifyContent="center"
                                        >
                                          <PublishOutlinedIcon fontSize="inherit" />
                                          <Typography variant="body2">
                                            <label
                                              className={classes.rpPicture}
                                              htmlFor="contained-button-file2"
                                            >
                                              {values.imagePath && (
                                                <Input
                                                  accept=".jpg, .png, .jpeg"
                                                  id="contained-button-file2"
                                                  // multiple
                                                  type="file"
                                                  onChange={(
                                                    event: ChangeEvent<HTMLInputElement>
                                                  ) => {
                                                    let imgFile: any =
                                                      event.target.files &&
                                                      event.target.files[0];
                                                    setFieldValue(
                                                      "imagePath",
                                                      imgFile
                                                    );
                                                  }}
                                                  disabled={
                                                    !props.canAddNewRecord
                                                  }
                                                />
                                              )}
                                              Replace picture
                                            </label>
                                          </Typography>
                                        </Stack>
                                        <Stack
                                          className={classes.rmPicture}
                                          direction="row"
                                          alignItems="center"
                                          justifyContent="center"
                                          onClick={() => {
                                            if (props.canAddNewRecord) {
                                              setFieldValue("imagePath", "");
                                            }
                                            if (props.canAddNewRecord) {
                                              setFieldValue("imagePath", "");
                                              clearImageObject("upload-img");
                                            }
                                          }}
                                        >
                                          <DeleteOutlinedIcon fontSize="inherit" />
                                          <Typography variant="body2">
                                            Remove picture
                                          </Typography>
                                        </Stack>
                                      </Stack>
                                    )}
                                  </Stack>
                                </Stack>
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="imagePath" />
                          </FormHelperText>
                          {/* Image upload end */}
                        </Box>
                        <Stack alignSelf="self-end" maxWidth={150}>
                          <Button
                            disabled={
                              !(props.selectedStage.systemName
                                ? props.canUpdateExistingRecord
                                : props.canAddNewRecord)
                            }
                            //disabled={(props.isEdit && !props.isPrimaryCrop) ? true : false}
                            type="submit"
                            variant="contained"
                            color="success"
                          >
                            {(props.selectedStage?.systemName &&
                              "Update Stage") ||
                              "Add Stage"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Form>
                    <Divider orientation="vertical" flexItem />
                  </Stack>
                )}
              </Formik>
              {
                /**
                 * added stages will be listed here
                 */

                renderAddedCropStates()
              }
            </Stack>
          </div>
        </Box>
        <>
          <Divider />
          <div className={classes.modalFooterActionBtnsHelper}>
            <Stack direction="row" spacing={2}>
              <ButtonWithIcon
                showCreateProgress={false}
                type={CANCEL_TYPE}
                label="Previous"
                onClick={() => {
                  props.onPrevious(STEPS.STEP1);
                }}
              />
              <ButtonWithIcon
                showCreateProgress={false}
                type={CREATE_TYPE}
                label="Next"
                onClick={() => {
                  props.ListOfStages.length > 0 ? nextStepHandler(STEPS.STEP3) : setIsRuleErrorFlag(true);
                }}
              />
            </Stack>
          </div>
        </>
      </Box>
    </>
  );
};
