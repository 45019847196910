export type TableHeaderTypes = {
  HeaderName: string;
  FieldName: string;
  isBoolean?: boolean;
  truthLabel?: string;
  falseLabel?: string;
  callback?: (content?: any, rowData?: any) => any;
};

/** CP Product Basic table headers for View Model */

export const DosAndDonts: TableHeaderTypes[] = [
  {
    HeaderName: "Type",
    FieldName: "type",
  },
  {
    HeaderName: "Guideline System Name",
    FieldName: "systemName",
  },
  {
    HeaderName: "Description/Text",
    FieldName: "text",
  },
  {
    HeaderName: "Display Order",
    FieldName: "displayOrder",
  },
];

export const KeyFeatures: TableHeaderTypes[] = [
  {
    HeaderName: "Image",
    FieldName: "imagePath",
  },
  {
    HeaderName: "System Name",
    FieldName: "systemName",
  },
  {
    HeaderName: "Heading",
    FieldName: "header",
  },
  {
    HeaderName: "Description/Text",
    FieldName: "description",
  },
];
export const GrowerTestimonials: TableHeaderTypes[] = [
  {
    HeaderName: "System Name",
    FieldName: "systemName",
  },
  {
    HeaderName: "Media Title",
    FieldName: "text",
  },
  {
    HeaderName: "Media Type",
    FieldName: "mediaType",
  },
  {
    HeaderName: "Media",
    FieldName: "mediaUrl",
  },
  // {
  //     'HeaderName': 'Image',
  //     'FieldName': 'image'
  // },
  {
    HeaderName: "Description/Text",
    FieldName: "subtext",
  },
  {
    HeaderName: "Display Order",
    FieldName: "displayOrder",
  },
];
export const TargetThreats: TableHeaderTypes[] = [
  {
    HeaderName: "Threat System Name",
    FieldName: "systemName",
  },
  {
    HeaderName: "Threat Display Order",
    FieldName: "stageThreatDisplayOrders",
    callback: (content: any, rowData: any) => {
      return (
        content.find(
          (item: any) =>
            item.threatSystemName === rowData.systemName &&
            item.stageSystemName === rowData.stages
        )?.cpThreatDisplayOrder ?? "-"
      );
    },
  },
  {
    HeaderName: "Stage System Name",
    FieldName: "stages",
  },
  {
    HeaderName: "Stage Display Order",
    FieldName: "stageThreatDisplayOrders",
    callback: (content: any, rowData: any) => {
      return (
        content.find(
          (item: any) =>
            item.threatSystemName === rowData.systemName &&
            item.stageSystemName === rowData.stages
        )?.cpDisplayOrder ?? "-"
      );
    },
  },
];
export const Recommendations: TableHeaderTypes[] = [
  {
    HeaderName: "Calendar System Name",
    FieldName: "calendarSystemName",
  },
  {
    HeaderName: "Stage System Name",
    FieldName: "stageSystemName",
  },
  // {
  //     'HeaderName': 'Product System Name',
  //     'FieldName': 'productSystemName'
  // },
  {
    HeaderName: "Threat System Name",
    FieldName: "threatSystemName",
  },
  {
    HeaderName: "Dosage (ml/acre)",
    FieldName: "dosage",
  },
  {
    HeaderName: "When to Use",
    FieldName: "whenToUse",
  },
  {
    HeaderName: "Why to Use",
    FieldName: "whyToUse",
  },
  {
    HeaderName: "Reminder Message System Name",
    FieldName: "reminderMessage",
  },
  {
    HeaderName: "Application Order",
    FieldName: "applicationOrder",
  },
];
/** ------------------------------------------------------------ */
