import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  Select: {
    "& ul": {
      maxHeight: "20vh",
    },
  },
  pageTitleNSearchStylesHelper: {
    padding: "0 1.5rem",
    marginTop: "1.5rem",
  },
  rowsPerPageStylesHelper: {
    margin: "5px 0",
    padding: "0 1.5rem",
  },
  customTabBtmBorder: {
    borderBottom: "1px solid #CFD3DB",
    padding: "0 1.5rem",
  },
  cropContainerHelper: {
    width: "100%",
  },
  tabContainerHelper: {
    paddingLeft: 16,
  },
  tabHelper: {
    textTransform: "none !important" as "none",
  },
  panelBoxHelper: {
    display: "inline-flex",
    alignItems: "center",
    width: "-webkit-fill-available",
  },
  titleAndSearchHelper: {
    margin: "24px 24px auto",
    display: "inline-flex",
    width: "-webkit-fill-available",
  },
  chipBoxHelper: {
    margin: "8px 0",
    display: "inline-flex",
    width: "-webkit-fill-available",
    padding: "0 1.5rem",
  },
  bulkDataBorderBottom: {
    borderBottom: "1px solid #CFD3DB",
    paddingBottom: "1.5rem",
    marginBottom: "1.5rem",
  },
  OddTableRow: {
    padding: "0.3rem 1rem",
    border: "1px solid #CFD3DB",
    "&:nth-child(odd)": {
      backgroundColor: "#F3F4F6",
    },
  },
  tableFirstChild: {
    borderRight: "1px solid #CFD3DB",
    paddingRight: "0.5rem",
  },
  viewImage: {
    background: "#f2f2f2",
    marginBottom: "1rem !important",
    width: "120px",
    height: "120px",
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  uploadBtn: {
    border: "1px solid #CFD3DB",
    padding: "0.3rem",
    display: "flex",
    justifyContent: "flex-start",
    borderRadius: "4px",
  },
  createHybridPaper: {
    margin: "30px auto",
    maxWidth: "750px",
    minWidth: "500px",
    "& .containerBox": {
      overflowY: "auto",
      margin: "2rem 0",
      "& .flexBoxContainerInner": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
  formPaper: {
    margin: "30px auto",
    width: "800px",
    minHeight: "500px",
    borderRadius: "6px !important",
    "& .MuiStepper-root": {
      paddingBottom: "1rem",
    },
  },
  formContainer: {
    height: "370px",
    overflowY: "auto",
    position: "relative",
    scrollbarWidth: "auto",
    "&::-webkit-scrollbar": {
      width: "4px !important",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#CFD3DB",
      "border-radius": "15px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#ffffff",
    },
  },
  formContainerChildDivStylesHepler: {
    margin: "1rem 20px",
  },
  formFieldsHelperOne: {
    maxWidth: "380px",
    margin: "auto",
    position: "relative",
    marginBottom: "1rem",
    "& .langError": {
      position: "absolute",
      left: "105px",
    },
  },
  formFieldsHelper: {
    margin: "auto",
    maxWidth: "530px",
  },
  formFieldsHelperUnique: {
    margin: "auto",
    maxWidth: "95%",
  },
  formFiledMrHelper: {
    margin: "8px auto 0px auto !important",
  },
  formPaperHelper: {
    margin: "30px auto",
    maxWidth: "1150px",
    minWidth: "500px",
  },
  bulkUploadModal: {
    overflowY: "auto",
    padding: "1rem",
  },
  bulkUpdloadDownloadBtn: {
    "&.MuiButton-root": {
      fontWeight: "600",
      color: "#14151C",
    },
  },
  bulkUploadFabBtnHelper: {
    "&.MuiButtonBase-root": {
      boxShadow: "none",
    },
  },
  modalFooterActionBtnsHelper: {
    width: "fit-content",
    margin: "0px 0px auto auto",
    padding: 16,
  },
  modalStylesHelper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttonHelperNext: {
    marginRight: ".5rem",
    width: "91px",
  },
  languagErrorMsgHepler: {},
  stepperHeplerStyles: {
    paddingBottom: "1rem",
  },
  formGlobalContainerHelper: {
    marginTop: "1rem",
  },
  viewHybridFormContentHelper: {
    overflow: "hidden auto",
    height: "420px",
  },
  rmPicture: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  rpPicture: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  dropDown: {
    height: "38px",
    width: "330px",
    borderRadius: "4px",
    borderWidth: "1px",
    borderColor: "#C2C7D0",
  },
  inputField: {
    height: "38px",
    width: "410px",
    borderRadius: "4px",
    borderWidth: "1px",
    borderColor: "#C2C7D0",
  },
  label: {
    color: "#14151C",
    "&.MuiTypography-root": {
      margin: "8px 0 5px",
    },
  },
  formErrorMsgLanguageHelper: {
    marginLeft: "0",
    position: "absolute",
    left: "105px",
  },
  textField: {
    height: "65px",
    width: "100%",
    borderWidth: "1px",
    borderColor: "#C2C7D0",
    borderRadius: "4px",
  },
  textInput: {
    borderRadius: "4px",
    borderWidth: "1px",
    borderColor: "#C2C7D0",
  },
  textAreaStyles: {
    borderColor: "rgba(0,0,0,0.23)",
    borderRadius: "5px",
    color: "#696F88",
    resize: "none",
    boxSizing: "border-box",
    width: "100%",
    height: "96px !important",
    overflowY: "scroll",
    outlineColor: "#0071CD",
    outlineWidth: "2px",
    borderWidth: "1px",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
    },
  },
  textAreaField: {},
  conditionText: {
    fontSize: "14px",
    color: "#696F88",
    marginTop: "0px",
    marginBottom: "0px",
  },
  formControlMrtHelper: {
    marginTop: "8px !important",
    "&.MuiFormControl-root": {
      marginTop: "8px",
    },
    "& .MuiFormHelperText-root": {
      marginLeft: "0px",
    },
  },
  selectExpandIconHelper: {
    position: "absolute",
    right: "3px",
  },
  deleteButtonHelper: {
    color: "#CF3537",
    padding: 0,
    textDecoration: "underline !important",
    fontWeight: "400 !important",
  },
  formActionFooterBtnHelper: {
    width: "fit-content",
    margin: "0px 0px auto auto",
    padding: 16,
  },
  bulkUploadAttachCloseBtnsHelper: {
    color: "#696F88",
    marginRight: "0.5rem",
    "&.MuiSvgIcon-root": {
      fontSize: "1.2rem",
    },
  },
  formMarginHelper: {
    marginTop: "8px !important",
  },
  selectExpandMore: {
    position: "absolute",
    right: "3px",
  },
  lineHeightHelper: {
    lineHeight: "unset",
  },
});
