export const USER_LOGIN_DETAILS = 'USER_LOGIN_DETAILS';
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const BASE_URL = 'BASE_URL';
export const SHOW_LOADER = 'ShowLoader';
export const SELECTED_COUNTRY = 'SelectedCountry';
export const LIST_LANGUAGES = 'Languages_list';
export const LIST_NOTIFICATION_TYPES = 'Notification_types';
export const LIST_CROPS = 'Crops_list';
export const LOGOUT = 'Logout';
export const UPDATE_LANGUAGE_CODE = 'LANGUAGE_CODE';
export const HIDE_TOAST = 'HIDE_TOAST';
export const SHOW_TOAST = 'SHOW_TOAST';
export const LIST_MODULES = 'module_list';
export const RETAILER_MOBILE_LENGTH = 'retailer_mobile_length';
export const UPDATED_SELECTED_MODULE = 'UPDATED_SELECTED_MODULE';
export const LIST_COUNTRIES = 'countries_list';
