export type RetailerColumnType = {
  fieldName: string;
  fieldLabel: string;
};

export const RetailerColumn: Array<RetailerColumnType> = [
  {
    fieldLabel: "Name",
    fieldName: "name",
  },
  {
    fieldLabel: "Mobile No.",
    fieldName: "phonenumber",
  },
  {
    fieldLabel: "Type",
    fieldName: "type",
  },
  {
    fieldLabel: "Category",
    fieldName: "category",
  },
  {
    fieldLabel: "Short Address",
    fieldName: "shortaddress",
  },
  {
    fieldLabel: "Created On",
    fieldName: "createdOn",
  },
  {
    fieldLabel: "Status",
    fieldName: "cropColumnStatus",
  },
];

export const RetailerMyUploadsTableColumn: Array<RetailerColumnType> = [
  {
    fieldLabel: "File Name",
    fieldName: "fileName",
  },
  {
    fieldLabel: "Uploaded On",
    fieldName: "cropsUploadDate",
  },
  {
    fieldLabel: "Status",
    fieldName: "comnMyUploadStatus",
  },
];
