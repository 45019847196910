import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GeneralDetailsKeys, initialGeneralDetailStage } from ".";
import api_endpoints from "../../../Api/end_points";
import ButtonWithIcon, { CREATE_TYPE } from "../../../Common/Buttons";
import { IGeneralDetails } from "../../../Common/Constants/types";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import SortableTableComponent from "../../../Common/SortableRecords";
import { UploadFile } from "../../../Common/UploadFile";
import { IRequestBody } from "../../../Constants/interfaces";
import { RootState } from "../../../redux/reducers";
import {
  genericValidateImage,
  genericFormFieldValidation,
} from "../../../Utils/fileHelper";
import { checkForDuplicates } from "../../../Utils/genericUtils";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { useStyles } from "../style";
import { getFileUploadPayload } from "../../../Api/generic_apicalls";
import {
  ActionType,
  ModuleImageUpload,
  STEPS,
  ValidationType,
} from "../../../Common/Constants";
import { getCrops, validateThreatPresence } from "./ThreatAPICalls";
import { GeneralSchemaValidation } from "./validationSchema";
import getRandomizeValue from "../../../Utils/randomizer";
import RenderField from "../../../Common/RenderField";

export type GeneralFormPropTypes = {
  ListOfStages: Array<IGeneralDetails>;
  selectedStage: IGeneralDetails;
  onSelect: Function;
  onSave: Function;
  onRemove: Function;
  onNext: Function;
  updateUniqueKey: Function;
  uniqueKey: number;
  updateListOrder: Function;
  isEdit: boolean;
  threatSystemName?: String;
  onPrevious?: Function;
  getLatestImgURL?: Function;
  disableSystemName: boolean;
  actionType: string;
  isPrimaryThreat: boolean;
  baseLanguageOfThreat?: string;
};

type LangObjectType = {
  languageId: number;
  languageName: string;
  languageCode: string;
};
type AvailableCropType = {
  checked: boolean;
  label: string;
};

export const GeneralDetailsThreats = (props: GeneralFormPropTypes) => {
  const storeData = useSelector((state: RootState) => {
    return {
      primaryLanguageCode: state.auth.languageCode,
    };
  });
  const classes = useStyles();
  /** state variables */
  const [threatFaqArray] = useState<any>([
    {
      header: "",
      text: "",
    },
  ]);
  const [isRuleErrorFlag] = useState<boolean>(false);
  const [selectedLangObj, setSelectedLangObj] = useState<LangObjectType>({
    languageId: 0,
    languageName: "",
    languageCode: "",
  });
  const [crops, setCrops] = useState<Array<AvailableCropType>>([]);
  const renderAddedThreat = () => {
    if (props.ListOfStages.length === 0) {
      return (
        <>
          {" "}
          <Stack flex={1} width={400}>
            <Box>
              <Typography variant="subtitle2">Added Threat(s):</Typography>
              <Typography variant="caption" component="span">
                You may reorder the threats by dragging each panel up or down
              </Typography>
              <FormHelperText error={true}>
                {isRuleErrorFlag && <>{"Please add one threat to continue"}</>}
              </FormHelperText>
            </Box>
          </Stack>
        </>
      );
    }
    return (
      <Stack flex={1} width={400}>
        <Box>
          <Typography variant="subtitle2">Added Threat(s):</Typography>
          {props.actionType !== ActionType.ADD_TRANSLATION && (
            <Typography variant="caption" component="span">
              You may reorder the stages by dragging each panel up or down
            </Typography>
          )}
          <FormHelperText error={true}></FormHelperText>
          <Box my={2}></Box>
          <SortableTableComponent
            key={getRandomizeValue()}
            name={"General Details FAQ's"}
            items={[...props.ListOfStages]}
            updateCropStageOrder={(newList: Array<GeneralDetailsKeys>) => {
              props.updateListOrder(newList);
            }}
            listProps={props}
            displayKey={"threatSystemName"}
            initialState={initialGeneralDetailStage}
            selectedRecord={props.selectedStage}
            secMatchKey={""}
            // isDisabled={props.actionType === ActionType.ADD_TRANSLATION ? true : false}
            isDisabled={
              !props.isPrimaryThreat && props.actionType !== ActionType.CREATE
            }
          />
        </Box>
      </Stack>
    );
  };

  const successCallBack = (response: any, type: string) => {
    switch (type) {
      case "crops": {
        let cropsData = response.data.cropsList;
        cropsData.map((eachCrop: any) => {
          eachCrop.label = eachCrop.name;
        });
        setCrops(cropsData);
        break;
      }
      default:
        break;
    }
  };
  const errorCallback = (_error: any, type: string) => {
    switch (type) {
      default: {
        /**Generic alert to display API fail */
        TriggerToastMessage(
          "Unable to process your request!!! - " + type,
          "error"
        );
      }
    }
  };

  const fetchCrops = () => {
    const apiData = {} as IRequestBody;
    apiData.endPoint = api_endpoints.crops;
    apiData.showLoader = true;
    apiData.payLoad = {
      pageNo: 0,
      pageSize: 100,
    };
    apiData.customHeaders = {
      "language-code": selectedLangObj.languageCode,
    };
    return getCrops(apiData, successCallBack, errorCallback);
  };

  const nextStepHandler = (step: number) => {
    props.onNext(step);
  };

  const handleLanguageChange = (language: string) => {
    let langArrData = language.split("##");
    let langData: LangObjectType = {
      languageId: parseInt(langArrData[0]),
      languageName: langArrData[1],
      languageCode: langArrData[2],
    };
    setSelectedLangObj(langData);
    return langData;
  };
  /**
   * method which verifies of user is selecting same language
   * throughout the added stages
   * @returns
   * @param languageInfo
   */

  const isSameLanguage = (languageInfo: string) => {
    if (props.ListOfStages.length <= 1) {
      return true;
    }
    const newLangInfo = languageInfo.split("##");
    /**
     * this condition is to handle translation records
     * when user adding translations for the first time,
     * all records have primary language assigned to them
     */
    if (
      props.ListOfStages.length > 0 &&
      props.actionType === ActionType.ADD_TRANSLATION
    ) {
      // record selected for translation
      // now check if there are any records without primary language code
      const primaryLanguageRecords: IGeneralDetails[] = _.filter(
        props.ListOfStages,
        (o: IGeneralDetails) =>
          o.languageCode?.toLocaleLowerCase() ===
          storeData.primaryLanguageCode?.toLocaleLowerCase()
      );
      // if user is changing language for the first time
      if (primaryLanguageRecords.length === props.ListOfStages.length) {
        const newRecords = [...props.ListOfStages].map((o: IGeneralDetails) => {
          o.languageId = parseInt(newLangInfo[0], 10);
          o.languageName = newLangInfo[1];
          o.languageCode = newLangInfo[2];
          return o;
        });
        props.updateListOrder([...newRecords]);
        props.updateUniqueKey();
        return true;
      }
      return false;
    }

    return props.ListOfStages.length > 0 &&
      props.ListOfStages[0].languageName === newLangInfo[1];

  };
  /**
   * method which verifies of user is selecting same crop
   * throughout the added stages
   * @param cropInfo
   * @returns
   */
  const isSameCrop = (cropInfo: any) => {
    /**
     * if no stages exist
     */
    if (props.ListOfStages.length === 0) {
      return true;
    }
    /**
     * user is trying to add second stage
     */
    if (
      props.ListOfStages.length === 1 &&
      props.selectedStage.threatSystemName.toLocaleUpperCase() === ""
    ) {
      return props.ListOfStages[0].filteredCrops.id === cropInfo.id;
    }
    /**
     * when user is trying to edit the one & only stage
     */
    if (
      props.ListOfStages.length === 1 &&
      props.selectedStage &&
      props.selectedStage.threatSystemName.toLocaleUpperCase() !== ""
    ) {
      return true;
    }
    /**
     * when added stages are > 1, need to validate
     * selected crop WRT crop selected in first stage
     */
    return props.ListOfStages.length > 1 &&
      props.ListOfStages[0].filteredCrops.id === cropInfo.id;

  };

  const checkForDuplicateDraft = (
    cropSystemName: string,
    languageObject: LangObjectType,
    successCallBack: Function,
    errorCallback: Function
  ) => {
    if (props.baseLanguageOfThreat === languageObject.languageName) {
      return successCallBack();
    }
    if (props.actionType === ActionType.CREATE) {
      return successCallBack();
    }

    // check for presence of draft record
    const threats_presence_body = {} as IRequestBody;
    threats_presence_body.endPoint =
      api_endpoints.threats_presence_threat.replace(
        "__CROP_SYSTEM_NAME__",
        cropSystemName
      );
    threats_presence_body.customHeaders = {
      "language-code": languageObject.languageCode,
    };
    validateThreatPresence(threats_presence_body)
      .then((response: any) => {
        if (!response.data.isCropExist) {
          return successCallBack();
        }
        throw new Error(response.message);
      })
      .catch((err) => {
        return errorCallback(err);
      });
    return true;
  };

  const submitGeneralDetails = (data: GeneralDetailsKeys) => {
    props.onSave(data);
    // if (props.actionType !== ActionType.UPDATE) {
    //   setSelectedLangObj({ languageId: 0, languageName: "", languageCode: "" });
    //   handleLanguageChange("");
    // }
    setCrops([]);
    props.updateUniqueKey();
  };
  /** Filed level validation for Image file upload */
  const validateImage = (value: any) => {
    /** If, it is add trans, than ignore Image Validation */
    if (props.actionType !== ActionType.ADD_TRANSLATION) {
      return genericValidateImage(value);
    }
    return "";
  };
  useEffect(() => {
    if (props.selectedStage.languageId) {
      setSelectedLangObj({
        languageCode: props.selectedStage.languageCode || "",
        languageId: props.selectedStage.languageId || 0,
        languageName: props.selectedStage.languageName || "",
      });
    }
  }, [props.selectedStage.languageCode]);

  useEffect(() => {
    if (selectedLangObj.languageCode) {
      fetchCrops();
    }
  }, [selectedLangObj]);

  /** Getting latest unique key to see latest
   * selected record for edit
   */
  useEffect(() => {
    props.updateUniqueKey();
  }, [props.selectedStage.threatSystemName]);


  return (
    <>
      <Box>
        <Formik
          enableReinitialize={true}
          validationSchema={GeneralSchemaValidation}
          validateOnBlur={false}
          initialValues={{
            filteredCrops: props.selectedStage.filteredCrops || null,
            languageName: props.selectedStage.languageName ?? selectedLangObj?.languageName,
            languageId: props.selectedStage.languageId ?? selectedLangObj.languageId,
            languageCode: props.selectedStage.languageCode ?? selectedLangObj.languageCode,
            threatSystemName: props.selectedStage.threatSystemName || "",
            threatDisplayLabel: props.selectedStage.threatDisplayLabel || "",
            imagePath: props.selectedStage.imagePath || "",
            threatFAQ:
              props.selectedStage.threatFAQ &&
                props.selectedStage.threatFAQ.length > 0
                ? [...props.selectedStage.threatFAQ]
                : [...threatFaqArray],
          }}
          onSubmit={(values, _onSubmitProps: any) => {
            // check for duplicate threat system names
            if (
              checkForDuplicates(
                props.ListOfStages,
                { threatSystemName: values!.threatSystemName },
                values!.threatSystemName,
                props.selectedStage.threatSystemName
              )
            ) {
              _onSubmitProps.setFieldError(
                "threatSystemName",
                "Duplicate threat system name"
              );
              _onSubmitProps.setSubmitting(false);
              return;
            }
            let data: any = values;
            // assign selected language data to prepare payload
            data.languageId = selectedLangObj.languageId;
            data.languageCode = selectedLangObj.languageCode;
            checkForDuplicateDraft(
              values.filteredCrops.name,
              selectedLangObj,
              () => {
                if (typeof values.imagePath === "object") {
                  getFileUploadPayload(
                    values.imagePath,
                    values.filteredCrops.name,
                    ModuleImageUpload.THREAT_STAGE,
                    (response: any) => {
                      values.imagePath = response.data.imageUrl;
                      submitGeneralDetails(data);
                      _onSubmitProps.resetForm();
                    },
                    () => {
                      _onSubmitProps.setFieldError(
                        "imagePath",
                        "Unable to upload file."
                      );
                      _onSubmitProps.setSubmitting(false);
                      return;
                    }
                  );
                } else {
                  submitGeneralDetails(data);
                  _onSubmitProps.resetForm();
                }
              },
              (err: Error) => {
                TriggerToastMessage(err.message, "error");
              }
            );
          }}
        >
          {({
            values,
            setFieldValue,
            setFieldError,
            setFieldTouched
          }) => (
            <Box className={classes.formContainer}>
              <Box margin="1rem 20px">
                <Box className={classes.formFieldsHelperOne}>
                  <Field name="languageName">
                    {() => {
                      return (
                        <>
                          <LanguageDropdown
                            selectedLanguage={
                              values.languageName ??
                              selectedLangObj.languageName
                            }
                            onChangeLanguage={(langData: string) => {
                              if (isSameLanguage(langData)) {
                                const languageInfo =
                                  handleLanguageChange(langData);
                                setFieldValue(
                                  "languageName",
                                  languageInfo.languageName
                                );
                                setFieldTouched("languageName");
                                return;
                              }
                              setFieldError(
                                "languageName",
                                "Please remove the added stage to select different language"
                              );
                              setFieldTouched("languageName");
                              return;
                            }}
                            isDisabled={props?.isEdit && props.actionType === ActionType.UPDATE}
                            displayPrimaryLanguage={
                              props.actionType !== ActionType.ADD_TRANSLATION
                            }
                            key={getRandomizeValue()}
                          />

                          <ErrorMessage name="languageName">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText
                                error={true}
                                className={classes.formLanguageErrorHelper}
                              >
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                        </>
                      );
                    }}
                  </Field>
                </Box>
                <Stack direction="row" spacing={2}>
                  <Form>
                    <Stack
                      flex={1}
                      width={350}
                      justifyContent="flex-end"
                      borderRight="1px solid #C2C7D0"
                      paddingRight={2}
                    >
                      <Stack spacing={2}>
                        <Box>
                          <Field name="filteredCrops">
                            {() => {
                              return (
                                <RenderField
                                  label={"Crops *"}
                                  labelKey={"label"}
                                  name={"crop"}
                                  onChange={(name: string, value: any) => {
                                    if (isSameCrop(value)) {
                                      setFieldValue("filteredCrops", value);
                                    } else {
                                      setFieldError(
                                        "filteredCrops",
                                        "Please remove the added stages to change the crop"
                                      );
                                    }
                                    setFieldTouched("filteredCrops");
                                  }}
                                  options={crops}
                                  selectedOption={values.filteredCrops}
                                  value={""}
                                  isDisabled={
                                    props.actionType ===
                                    ActionType.ADD_TRANSLATION
                                  }
                                />
                              );
                            }}
                          </Field>
                          <ErrorMessage name="filteredCrops">
                            {(
                              errorMsg:
                                | boolean
                                | React.ReactChild
                                | React.ReactFragment
                                | React.ReactPortal
                                | null
                                | undefined
                            ) => (
                              <FormHelperText
                                error={true}
                                sx={{ marginLeft: "0" }}
                              >
                                {errorMsg}
                              </FormHelperText>
                            )}
                          </ErrorMessage>
                          <Field
                            name="threatSystemName"
                            id="threatSystemName"
                            validate={
                              props.selectedStage.threatSystemName
                                ? null
                                : (value: string) =>
                                  genericFormFieldValidation(
                                    value,
                                    ValidationType.SYS_NM
                                  )
                            }
                          >
                            {() => {
                              return (
                                <FormControl
                                  className={classes.formControlMrtHelper}
                                  fullWidth
                                >
                                  <Typography variant="subtitle2">
                                    Threat System Name *
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setFieldValue(
                                        "threatSystemName",
                                        event.target.value
                                          .trim()
                                          .toLocaleUpperCase()
                                      );
                                      setFieldTouched("threatSystemName");
                                    }}
                                    name="threatSystemName"
                                    defaultValue={values?.threatSystemName}
                                    value={values?.threatSystemName}
                                    key={props.uniqueKey}
                                    disabled={
                                      props.actionType ===
                                      ActionType.ADD_TRANSLATION ||
                                      !(
                                        props.actionType !==
                                        ActionType.ADD_TRANSLATION &&
                                        !props.selectedStage.hasOwnProperty(
                                          "id"
                                        )
                                      )
                                    }
                                  />
                                </FormControl>
                              );
                            }}
                          </Field>

                          <FormHelperText error={true}>
                            <ErrorMessage name="threatSystemName" />
                          </FormHelperText>
                          <Field
                            name="threatDisplayLabel"
                            id="threatDisplayLabel"
                          >
                            {() => {
                              return (
                                <FormControl
                                  className={classes.formControlMrtHelper}
                                  fullWidth
                                >
                                  <Typography variant="subtitle2">
                                    Threat Display Label *
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setFieldValue(
                                        "threatDisplayLabel",
                                        event.target.value
                                      );
                                      setFieldTouched("threatDisplayLabel");
                                    }}
                                    name="threatDisplayLabel"
                                    defaultValue={values?.threatDisplayLabel}
                                    value={values?.threatDisplayLabel}
                                    key={props.uniqueKey}
                                  />
                                </FormControl>
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="threatDisplayLabel" />
                          </FormHelperText>
                          <Field name="imagePath" validate={validateImage}>
                            {() => {
                              return (
                                <UploadFile
                                  name="imageUrl"
                                  acceptedFileTypes={[
                                    "image/png",
                                    "image/jpg",
                                    "image/jpeg",
                                  ]}
                                  isRequired={true}
                                  maxFileSize={5}
                                  imageUrl={values.imagePath}
                                  onChange={(data: any) =>
                                    setFieldValue("imagePath", data)
                                  }
                                  canRemoveOrReplace={
                                    props.actionType !==
                                    ActionType.ADD_TRANSLATION
                                  }
                                />
                              );
                            }}
                          </Field>
                          <FormHelperText error={true}>
                            <ErrorMessage name="imagePath" />
                          </FormHelperText>
                          <FieldArray
                            name="threatFAQ"
                            render={({ push, remove }) => (
                              <div>
                                {values.threatFAQ.map(
                                  (threatObj: any, index: number) => {
                                    return (
                                      <>
                                        <FormControl
                                          className={
                                            classes.formControlMrtHelper
                                          }
                                          fullWidth
                                        >
                                          <Stack
                                            direction="row"
                                            alignItems="center"
                                            justifyContent="space-between"
                                          >
                                            <Typography variant="subtitle2">{`FAQ: ${index + 1
                                              }`}</Typography>
                                            {index >= 0 &&
                                              props.actionType !==
                                              ActionType.ADD_TRANSLATION && (
                                                <Button
                                                  color="info"
                                                  variant="text"
                                                  endIcon={
                                                    <DeleteOutlinedIcon />
                                                  }
                                                  onClick={() => remove(index)}
                                                ></Button>
                                              )}
                                          </Stack>
                                          <TextField
                                            fullWidth
                                            name={`threatFAQ.${index}.header`}
                                            defaultValue={threatObj.header}
                                            value={threatObj.header}
                                            onChange={(
                                              event: React.ChangeEvent<HTMLInputElement>
                                            ) =>
                                              setFieldValue(
                                                `threatFAQ.${index}.header`,
                                                event?.target.value
                                              )
                                            }
                                          />
                                        </FormControl>
                                        <FormHelperText error={true}>
                                          <ErrorMessage
                                            name={`threatFAQ.${index}.header`}
                                          />
                                        </FormHelperText>
                                        <FormControl
                                          className={classes.formMarginHelper}
                                          fullWidth
                                        >
                                          <Typography variant="subtitle2">{`Response: ${index + 1
                                            }`}</Typography>
                                          <Typography
                                            fontSize={12}
                                            color="#696F88"
                                          >
                                            Max. 1000 characters
                                          </Typography>
                                          <TextareaAutosize
                                            name={`threatFAQ.${index}.text`}
                                            className={classes.textAreaStyles}
                                            onChange={(
                                              event: React.ChangeEvent<HTMLTextAreaElement>
                                            ) =>
                                              setFieldValue(
                                                `threatFAQ.${index}.text`,
                                                event?.target.value
                                              )
                                            }
                                            defaultValue={threatObj.text}
                                            value={threatObj.text}
                                          //key={threatObj.response}
                                          />
                                        </FormControl>
                                        <FormHelperText error={true}>
                                          <ErrorMessage
                                            name={`threatFAQ.${index}.text`}
                                          />
                                        </FormHelperText>
                                      </>
                                    );
                                  }
                                )}
                                {props.actionType !==
                                  ActionType.ADD_TRANSLATION && (
                                    <Button
                                      type="button"
                                      onClick={() =>
                                        push({ header: "", text: "" })
                                      }
                                      variant="outlined"
                                      color="success"
                                      className={classes.newFaqBtnHelper}
                                    >
                                      Add New FAQ Field
                                    </Button>
                                  )}
                              </div>
                            )}
                          />
                        </Box>
                        <Stack alignSelf="self-end" maxWidth={150}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            disabled={
                              props.actionType === ActionType.ADD_TRANSLATION &&
                              !props.selectedStage.threatSystemName
                            }
                          >
                            {(props.selectedStage?.threatSystemName &&
                              "Update Threat") ||
                              "Add Threat"}
                          </Button>
                        </Stack>
                      </Stack>
                    </Stack>
                  </Form>
                  {
                    /**
                     * added stages will be listed here
                     */
                    renderAddedThreat()
                  }
                </Stack>
              </Box>
            </Box>
          )}
        </Formik>
        <>
          <Divider />
          <div className={classes.formActionFooterBtnHelper}>
            <Stack direction="row" spacing={2}>
              <ButtonWithIcon
                showCreateProgress={false}
                type={CREATE_TYPE}
                label="Next"
                onClick={() => {
                  //props.ListOfStages.length ? nextStepHandler(STEPS.STEP2) : setIsRuleErrorFlag(true);
                  nextStepHandler(STEPS.STEP2);
                }}
              />
            </Stack>
          </div>
        </>
      </Box>
    </>
  );
};
