import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import PushNotifications from "../../Components/PushNotifications";
import { TYPE_NO_PUSH, TYPE_PUSH_APPROVED } from '../../Common/States';
import { PushNotificationUrls } from "../../Constants/contants";
import { apiCall, METHODS } from "../../Utility/apiCall";
import { RootState } from '../../redux/reducers';
import {
  SET_DATA,
  SET_LOADING,
  SET_SELECTED_PUSH_LIST,
  SET_SHOW_ACTION_PROGRESS,
  SET_SHOW_CREATE_PROGRESS,
  SET_STATE_TYPE
} from "../../redux/reducers/publish_push_reducer";
import { NotificationType } from "../../Components/PushNotifications/AddEditNotification";
import { usePushPublish } from "../hooks/usePushPublish";

const DEFAULT_PAGE_SIZE = 5;

export type FilterType = {
  languageIds: string[];
  notificationTypes: string[];
  moduleNames: string[];
  pageNumber: number;
  pageSize: number;
  sortBy: string;
  status: string[];
  frequencyIds: string[];
  crop: any[];
  category: string[];
};

const defaultFilters: FilterType = {
  sortBy: "ID",
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
  languageIds: [],
  status: [],
  notificationTypes: [],
  moduleNames: [],
  frequencyIds: [],
  crop: [],
  category: []
};

let previousFilters: FilterType = { ...defaultFilters };

type PushNotificationCardProps = {
  title: string;
};

const PushNotificationCard: React.FC<PushNotificationCardProps> = ({ title }) => {
  const {
    handleDeselectAll,
    handleSelectAll,
    handleSelectPush,
  } = usePushPublish();

  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.publishPushReducer);
  const [filters, setFilters] = useState<FilterType>({ ...defaultFilters });

  const setIsLoading = (loading: boolean) => dispatch({ type: SET_LOADING, payload: loading });

  const prepareUrl = (defaultPath: string, params: FilterType) => {
    let path = `${defaultPath}?`;
    for (const [key, value] of Object.entries(params)) {
      if (Array.isArray(value) && (key === 'languageIds' || key === 'status' || key === 'notificationTypes')) {
        value.forEach((id) => path += `&${key}=${id}`);
      } else {
        path += `&${key}=${value}`;
      }
    }
    return encodeURI(path);
  };

  const getNotifications = () => {
    setIsLoading(true);
    apiCall(METHODS.METHOD_GET, prepareUrl(PushNotificationUrls.PUSH_NOTIFICATIONS, filters))
      .then((res: any) => {
        dispatch({ type: SET_DATA, payload: { data: res?.data?.results } });
        if (!res?.data?.results?.length) {
          dispatch({ type: SET_STATE_TYPE, payload: { data: TYPE_NO_PUSH } });
        }
      })
      .catch((err) => console.error(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    previousFilters = JSON.parse(JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    setFilters({ ...defaultFilters });
  }, []);
  //
  // const handleSearch = (searchValue: string) => {
  //   setIsLoading(true);
  //   if (searchValue) {
  //     apiCall(METHODS.METHOD_GET, `${PushNotificationUrls.PUSH_NOTIFICATIONS}/${searchValue}`)
  //       .then((res: any) => {
  //         dispatch({ type: SET_DATA, payload: { data: res?.data ? [res?.data] : [] } });
  //         if (!res?.data) {
  //           dispatch({ type: SET_STATE_TYPE, payload: { data: TYPE_NO_PUSH } });
  //         }
  //       })
  //       .catch((err) => console.error(err))
  //       .finally(() => setIsLoading(false));
  //   } else {
  //     getNotifications();
  //   }
  // };
  //
  // const handleDeletePush = () => {
  //   const ids = state.selectedPushList.map((push: any) => push.id).join(',');
  //   dispatch({ type: SET_SHOW_ACTION_PROGRESS, payload: true });
  //   apiCall(METHODS.METHOD_DELETE, `${PushNotificationUrls.PUSH_NOTIFICATIONS}/${ids}`)
  //     .then(() => {
  //       dispatch({ type: SET_SELECTED_PUSH_LIST, payload: { data: [] } });
  //       getNotifications();
  //     })
  //     .catch((err) => console.error(err))
  //     .finally(() => {
  //       dispatch({ type: SET_SHOW_ACTION_PROGRESS, payload: false });
  //     });
  // };
  //
  const handleRefresh = () => {
    dispatch({ type: SET_STATE_TYPE, payload: { data: '' } });
    getNotifications();
  };
  //
  //
  // const updateFilters = (fieldName: string, value: any) => {
  //   setFilters((prevState) => ({ ...prevState, [fieldName]: value }));
  // };
  //
  // const handleChangeRowsPerPage = (fieldName: string, pageSize: number) => {
  //   updateFilters(fieldName, pageSize);
  // };
  //
  // const handlePageChange = (pageNumber: number) => {
  //   updateFilters('pageNumber', pageNumber);
  // };
  //
  // const handleSort = (sortValue: string) => {
  //   updateFilters('sortBy', sortValue);
  // };
  //
  // const handleChangeFilters = (obj: FilterType) => {
  //   if (!_.isEqual(previousFilters, obj)) {
  //     updateFilters('languageIds', obj.languageIds);
  //   }
  // };
  //
  const handleCreatePush = (push: NotificationType) => {
    dispatch({ type: SET_SHOW_CREATE_PROGRESS, payload: true });
    apiCall(METHODS.METHOD_POST, PushNotificationUrls.PUSH_NOTIFICATIONS, {
      ...push,
      districtIds: push.selectedDistrictObj.map((district: any) => district.id),
      frequency: 5
    })
      .then(() => {
        dispatch({ type: SET_STATE_TYPE, payload: { data: TYPE_PUSH_APPROVED } });
      })
      .catch((err) => console.error(err))
      .finally(() => {
        dispatch({ type: SET_SHOW_CREATE_PROGRESS, payload: false });
      });
  };


  return (
    <PushNotifications
      title={title}
      isLoading={state.isLoading}
      data={[]}
      stateType={state.stateType}
      showActionProgress={state.showActionProgress}
      showCreateProgress={state.showCreateProgress}
      filters={filters}
      onSelectAll={handleSelectAll}
      onDeselectAll={handleDeselectAll}
      onSelectPush={handleSelectPush}
      onCreatePush={handleCreatePush}
      onDeletePush={() => {}}
      onChangeRowsPerPage={() => {}}
      onRefresh={handleRefresh}
      onSeach={() => {}}
      onSort={() => {}}
      onPageChange={() => {}}
      onChangeFilters={() => {}}
    />
  );
};

export default PushNotificationCard;
