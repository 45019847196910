import apiData from './api_data_action';
import generic_actions from './generic_actions';
import userDetails from './user_actions';

const appActions = {
    apiData,
    userDetails,
    generic_actions
};

export default appActions;
