import {
  Box,
  Modal,
  Paper,
  Typography,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { api_service } from "../../../Api/api_service";
import api_endpoints, { base_url } from "../../../Api/end_points";
import { ActionType } from "../../../Common/Constants";
import LanguageDropdown from "../../../Common/LanguageDropdown";
import ModalHeader from "../../../Common/Modal/ModalHeader";
import { IRequestBody } from "../../../Constants/interfaces";
import { RootState } from "../../../redux/reducers";
import { TriggerToastMessage } from "../../../Utils/toastTrigger";
import { usePlantixCPProduct } from "../PlantixCPProducts";
import { useStyles } from "../style";
import { createColumn } from "../../../Utils/genericUtils";

const { plantix_cp_product_detail } = api_endpoints;


const LabelPreview: Array<{ fieldName: string; fieldLabel: string }> = [
  createColumn("Product Image", "imagePath"),
  createColumn("Product Title", "productTitle"),
  createColumn("Category", "category"),
  // createColumn("applicableCrop", "Crop"),
  createColumn("Dosage", "dosage"),
  createColumn("When to Use", "whenToUse"),
  createColumn("How to use", "howToUse"),
  createColumn("Key Benefit 1", "benefit1"),
  createColumn("Key Benefit 2", "benefit2"),
  createColumn("Key Benefit 3", "benefit3"),
];

const ViewPlantixCPProduct: React.FC<any> = (props) => {
  const storeData = useSelector((state: RootState) => {
    return {
      languages: state.generic?.ListOfLanguages?.languageList,
    };
  });

  const { selectedSystemName, selectedLangId } = usePlantixCPProduct();
  const [data, setData] = useState<any>({});
  const [isLoading, setLoading] = useState(false);

  const classes = useStyles();

  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    if (selectedSystemName || props?.id) {
      const apiData = {} as IRequestBody;
      apiData.domain = base_url;

      if (!props?.id) {
        const langCode = storeData.languages.find(
          (lang) => lang.languageId === selectedLangId
        )?.languageCode ?? "en";

        apiData.customHeaders = {
          'language-code': langCode
        }
        apiData.endPoint = plantix_cp_product_detail.replace(
          "__systemName__",
          selectedSystemName
        );
      } else {
        /** this api call is for newly created/unpublished Plantix CP products */
        apiData.endPoint = `plantix/cp-product-drafts/${props.id}`;
      }

      setLoading(true);

      api_service
        .get(apiData)
        ?.then((res) => {
          setData({
            ...res?.data?.data?.payload,
            id: res?.data?.data?.id,
            languageCode: res?.data?.data?.languageCode,
          })
          if (res.status === 500) {
            handleClose()
            TriggerToastMessage('Unable to process your request', 'error');
          }
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setLoading(false);
        });
    }
  }, [selectedSystemName]);

  const handleEditCP = () => {
    props.onClose();
    props.onEditClick(
      ActionType.UPDATE,
      {
        id: data.id ? data.id : props.id,
        languageId: data.languageId,
        system: data.productSystemName,
        languageCode: data.languageCode
      }
    );
  }

  return (
    <Modal
      open={true}
      disableEscapeKeyDown={true}
      className={classes.modalStylesHelper}
    >
      <Paper className={classes.formPaper}>
        <Paper elevation={3} square>
          <ModalHeader
            header="Plantix CP Product"
            showDelete={false}
            showEdit={props.canShowEditButton}
            onClose={() => handleClose()}
            editHandler={() => handleEditCP()}
          />
        </Paper>
        <Box className={classes.formContainer}>
          {(!isLoading && data && Object.keys(data).length > 0) && (
            <>
              <Box className={classes.formFieldsHelperOne}>
                {/* <LanguageDropdown
                  // onChangeLanguage={() => {
                  //   // TODO
                  // }}
                  selectedLanguage={data.languageName}
                  isDisabled={props.actionType === ActionType.UPDATE}
                  // isDisabled={true}
                  displayPrimaryLanguage={
                    props.actionType !== ActionType.ADD_TRANSLATION
                  }
                /> */}
                <LanguageDropdown
                  isDisabled={true}
                  selectedLanguage={data.languageName}
                  key={data.languageName}
                />
              </Box>
              <Box className={classes.formFieldsHelper}>
                {LabelPreview.map((item, index) => (
                  <div key={index}>
                    <Typography
                      variant="subtitle2"
                      style={{ paddingTop: "6px" }}
                    >
                      {item.fieldLabel}
                    </Typography>
                    {item.fieldName === "imagePath" ? (
                      <div
                        className={classes.viewImage}
                        style={{ marginLeft: "auto", marginRight: "auto" }}
                      >
                        <img src={data[item.fieldName]} alt={"product img"} />
                      </div>
                    ) : (
                      <Typography
                        fontSize={14}
                        className={classes.testWrapControl}
                      >
                        {data[item.fieldName] ?? '-'}
                      </Typography>
                    )}
                    <Divider />
                  </div>
                ))}
              </Box>
            </>
          )}
        </Box>
      </Paper>
    </Modal>
  );
};

export default ViewPlantixCPProduct;
