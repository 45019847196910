import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Published from "../../Components/Publish/Publish";
import { TYPE_NO_PUSH } from "../../Common/States";
import { PushNotificationUrls } from "../../Constants/contants";
import { apiCall, METHODS } from "../../Utility/apiCall";
import { RootState } from "../../redux/reducers";
import {
  SET_DATA,
  SET_LOADING,
  SET_SELECTED_PUSH_LIST,
  SET_SHOW_ACTION_PROGRESS,
  SET_STATE_TYPE,
} from "../../redux/reducers/publish_push_reducer";
import { usePushPublish } from "../hooks/usePushPublish";

type PublishCardProps = {
  title: string;
};

const DEFAULT_PAGE_SIZE = 5;

export type FilterType = {
  sortBy: string;
  pageSize: number;
  pageNumber: number;
  languageIds: string[];
  status: string[];
  notificationTypes: string[];
  moduleNames: string[];
  frequencyIds: string[];
  crop: any[];
  category: string[];
};

const defaultFilters: FilterType = {
  sortBy: "ID",
  pageSize: DEFAULT_PAGE_SIZE,
  pageNumber: 1,
  languageIds: [],
  status: [],
  notificationTypes: [],
  moduleNames: [],
  frequencyIds: [],
  crop: [],
  category: [],
};

let previousFilters: FilterType = { ...defaultFilters };

const PublishCard: React.FC<PublishCardProps> = ({ title }) => {
  const {
    handleDeselectAll,
  } = usePushPublish();

  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.publishPushReducer);
  const [filters, setFilters] = useState<FilterType>({ ...defaultFilters });

  // const setIsLoading = (loading: boolean) => dispatch({ type: SET_LOADING, payload: loading });

  useEffect(() => {
    previousFilters = JSON.parse(JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    setFilters({ ...defaultFilters });
  }, []);

  // const handleSearch = (searchValue: string) => {
  //   setIsLoading(true);
  //   if (searchValue) {
  //     apiCall(METHODS.METHOD_GET, `${PushNotificationUrls.PUSH_NOTIFICATIONS}/${searchValue}`)
  //       .then((res: any) => {
  //         dispatch({ type: SET_DATA, payload: { data: [res?.data] } });
  //         if (!res?.data) {
  //           dispatch({ type: SET_STATE_TYPE, payload: { data: TYPE_NO_PUSH } });
  //         }
  //       })
  //       .catch((err) => console.error(err))
  //       .finally(() => setIsLoading(false));
  //   }
  // };


  // const handleDeletePush = () => {
  //   const ids = state.selectedPushList.map((push: any) => push.id).join(',');
  //   dispatch({ type: SET_SHOW_ACTION_PROGRESS, payload: true });
  //   apiCall(METHODS.METHOD_DELETE, `${PushNotificationUrls.PUSH_NOTIFICATIONS}/${ids}`)
  //     .then(() => {
  //       dispatch({ type: SET_SELECTED_PUSH_LIST, payload: { data: [] } });
  //     })
  //     .catch((err) => console.error(err))
  //     .finally(() => {
  //       dispatch({ type: SET_SHOW_ACTION_PROGRESS, payload: false });
  //     });
  // };

  const handleRefresh = () => {
    dispatch({ type: SET_STATE_TYPE, payload: { data: "" } });
  };



  // const updateFilters = (fieldName: string, value: any) => {
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     [fieldName]: value,
  //   }));
  // };

  // const handleChangeRowsPerPage = (fieldName: string, pageSize: number) => {
  //   updateFilters(fieldName, pageSize);
  // };

  // const handlePageChange = (pageNumber: number) => {
  //   updateFilters("pageNumber", pageNumber);
  // };

  // const handleSort = (sortValue: string) => {
  //   updateFilters("sortBy", sortValue);
  // };
  //
  // const handleChangeFilters = (filters: FilterType) => {
  //   if (!_.isEqual(previousFilters, filters)) {
  //     updateFilters("languageIds", filters.languageIds);
  //   }
  // };



  return (
    <Published
      onDeselectAll={handleDeselectAll}
      // onChangeRowsPerPage={handleChangeRowsPerPage}
      onRefresh={handleRefresh}
      // onSearch={handleSearch}
      // onPageChange={handlePageChange}
      title={title}
      isLoading={state.isLoading}
      data={[]}
      stateType={state.stateType}
      showActionProgress={state.showActionProgress}
      filters={filters}
    />
  );
};

export default PublishCard;
