import { DOWNLOAD_SAMPLE_TEMPLATE_TYPE, PublishModuleDD } from "../../../Common/Constants";
import BaseModalCreatePlantix, {BaseModalProps} from "../../PlantixCPProducts/ModalCreatePopUp/BaseComponentCreate";

const ModalCreatePopUp2: React.FC<Omit<BaseModalProps, 'header' | 'templateLiteral' | 'validationLiteral' | 'dataType'>> = (props) => {
    return (
        <BaseModalCreatePlantix
            {...props}
            header="Add New Data"
            templateLiteral={DOWNLOAD_SAMPLE_TEMPLATE_TYPE.PLANTIX_MAP}
            validationLiteral={PublishModuleDD.PLANTIX_MAP_PRODUCTS}
            dataType="Plantix Peatid Mapping"
        />
    );
};

export default ModalCreatePopUp2;
