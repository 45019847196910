import { applyMiddleware, compose, createStore, Middleware } from "redux";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import immutableToJS from "./../../Utils/immutable-to-js";
import { logger } from "./../../Utils/logger";
import promiseMiddleware from "./../middleware/promise-middleware";
import rootReducer from "./../reducers/index";

const persistConfig = {
  key: "app",
  storage,
  blacklist: ["loader", "infoToast", "publishModuleSelectedReducer"],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const __DEV__: boolean = true; // using it temporarily
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureMiddleWares = (): Middleware[] => {
  let middlewares = [promiseMiddleware, thunk];
  // if (process.env['__DEV__']) {
  if (__DEV__) {
    const reduxLogger = createLogger({
      // ...options
      collapsed: true,
      duration: true,
      diff: true,
      colors: {
        title: (action: Object) => {
          return "Blue";
        },
        prevState: (prevState: Object) => {
          return "Magento";
        },
        action: (action: Object) => {
          return "red";
        },
        nextState: (nextState: Object) => {
          return "green";
        },
        error: (error: any, prevState: Object) => {
          return "red";
        },
      },
      stateTransformer: (state: any) => {
        return immutableToJS(state);
      },
    });
    middlewares.push(reduxLogger);
  }
  return middlewares;
};

const checkForHotReload = (str: any) => {
  const { hot } = module as any; //This is related to Webpack's hot module replacement
  // if (process.env['__DEV__'] && hot) {
  if (__DEV__ && hot) {
    hot.accept("./../reducers/", () => str.replaceReducer(rootReducer));
  }
};
export const store: any = createStore(
  persistedReducer,
  {},
  composeEnhancers(applyMiddleware(...configureMiddleWares()))
);
export const storeCustomInitial: any = (initial: any) =>
  createStore(
    persistedReducer,
    initial,
    composeEnhancers(applyMiddleware(...configureMiddleWares()))
  );
store.subscribe(function () {
  // retrieve latest store state here
  // Ex:
  try {
    let storeSize = JSON.stringify(store.getState()).length / 1000000;
    if (storeSize > 4) {
      // utility.alert({ message: 'Low offline storage space. Please go online to free space.' });
      logger.debug(`Store size is ${storeSize} Mega Bytes`, storeSize);
    }
    //  logger.info(`Store size is ${storeSize} Mega Bytes`, storeSize);
  } catch (e) {
    // Exception occurs when circular reference is present in store.
    //  utility.alert({ message: 'Very low offline storage space. Please go online to free space.' });
  }
});
const prepareAppStore = (callback: any) => {
  //alert('prepare app store');
  let persistor = persistStore(store, {}, callback);
  checkForHotReload(store);
  return { store, persistor };
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof store>;

export default prepareAppStore;
